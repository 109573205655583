package com.hyperether.goodjob.scenes.contacts

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.database.Job
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class ContactsViewModel(
    val repository: Repository,
) : ViewModel() {


    val showEditPopUp: MutableState<Boolean> = mutableStateOf(false)
    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")
    val siteNameInput: MutableState<String> = mutableStateOf("")
    val siteEmailInput: MutableState<String> = mutableStateOf("")
    val siteContactNameInput: MutableState<String> = mutableStateOf("")
    val siteAddressInput: MutableState<String> = mutableStateOf("")
    val sitePhoneInput: MutableState<String> = mutableStateOf("")
    private val _contactSites = mutableStateOf<List<Site>>(emptyList())
    val contactSites: State<List<Site>> get() = _contactSites


    private val _contacts = MutableStateFlow<List<Contact>>(emptyList())
    val contacts: StateFlow<List<Contact>> = _contacts

    val showFilterDialog = mutableStateOf(false)

    private val _jobs = MutableStateFlow<List<Job>>(emptyList())
    val jobs: StateFlow<List<Job>> = _jobs

    init {
//        viewModelScope.launch {
//            repository.getContactsFromLocal().collect { contactsList ->
//                _contacts.value = contactsList
//            }
//        }
    }

    fun getContactsFromLocal(){
        viewModelScope.launch {
            repository.getContactsFromLocal().collect { contactsList ->
                _contacts.value = contactsList
            }
        }
    }

    fun fetchJobsByParentId(parentId: String) {
        viewModelScope.launch {
            val jobList = repository.getJobsByParentId(parentId)
            _jobs.value = jobList
        }
    }

    fun addSite(site: Site, onSuccess: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch {
            when (val result = repository.addSite(site)) {
                is Resource.Success -> {
                    onSuccess()
                    println("Site added successfully")
                }

                is Resource.Error -> {
                    onError(result.text ?: "An unknown error occurred")
                    errorText.value = result.text ?: "An unknown error occurred"
                    println("Error while adding site")
                }
            }
        }
    }

    fun getSitesUnderContact(siteRequest: SiteRequest) {
        viewModelScope.launch {
            val result = repository.getSitesUnderContact(siteRequest)
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                _contactSites.value = result.data ?: emptyList()
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }
    }

    fun deleteSelectedContacts(
        selectedContacts: List<com.hyperether.goodjob.database.Contact>,
        onAllDeleted: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            try {
                selectedContacts.forEachIndexed { index, job ->
                    val isLastJob = index == selectedContacts.size - 1

                    job._id?.let { id ->
                        val result = repository.deleteContactById(id)
                        if (result is Resource.Success && isLastJob) {
                            onAllDeleted()
                        } else if (result is Resource.Error) {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError(id)
                            return@launch
                        }
                    }
                }
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }

    suspend fun getContactForJob(id: String): Contact? {
        return repository.getContactById(id)
    }
}