package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.daily_activities
import com.hyperether.goodjob.resources.details
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employee_details
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmployeeDetails(
    employeeId: String,
    isMobileView: Boolean,
    viewModel: EmployeesViewModel,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    navController: NavHostController
) {
    if (isMobileView) {
        val employees by viewModel.employees.collectAsState(emptyList())
        val employee = employees.find { it.id == employeeId.toLong() }

        println(employee.toString())


        Box(modifier = Modifier.fillMaxSize()) {

            Column {
                MobileHeaderBackAndAction(
                    title = stringResource(Res.string.employee_details),
                    back = { navController.popBackStack() },
                    actionEnabled = viewModel.isDetailsView.value
                ) {
                    Image(
                        painter = painterResource(Res.drawable.edit),
                        contentDescription = "",
                        modifier = Modifier
                            .padding(end = 16.dp)
                            .clickable {
                            }
                    )
                }

                Column(
                    modifier = Modifier
                        .background(Color.LightGray.copy(alpha = 0.2f))
                        .padding(8.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(40.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.details),
                            color = if (viewModel.isDetailsView.value) BlueApp else Color.Gray,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(start = 40.dp)
                                .clickable {
                                    viewModel.isDetailsView.value = true
                                }
                        )

                        Spacer(modifier = Modifier.weight(0.5f))

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.daily_activities),
                            color = if (viewModel.isDetailsView.value) Color.Gray else BlueApp,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .padding(end = 40.dp)
                                .clickable {
                                    viewModel.isDetailsView.value = false
                                }
                        )
                    }
                }
                Box(
                    modifier = Modifier
                        .height(2.dp)
                        .fillMaxWidth(0.5f)
                        .background(if (viewModel.isDetailsView.value) BlueApp else BlueApp)
                        .align(if (viewModel.isDetailsView.value) Alignment.Start else Alignment.End)
                )
                if (viewModel.isDetailsView.value) {
                    EmployeeHeaderView(employee)
                    EmployeeContactsView(employee)
                    EmployeeDetailsView(employee)
                    EmployeeLeaveDetails(employee)
                } else {

                }
            }
        }
    } else {
        EmployeeDetailsWeb(
            employeeId = employeeId,
            employeeDetailsViewModel = employeeDetailsViewModel,
            navController = navController
        )
    }
}