package com.hyperether.goodjob.models

import com.hyperether.goodjob.database.User
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Job(
    @SerialName("_id") var id: String? = null,
    @SerialName("jobTitle") var jobTitle: String? = null,
    @SerialName("status") var status: String? = null,
    @SerialName("employees") var employees: List<com.hyperether.goodjob.models.User?> = emptyList(),
    @SerialName("creatorId") var creatorId: String? = null,
    @SerialName("workspaceId") var workspaceId: String? = null,
    @SerialName("start_at") var start_at: String? = null,
    @SerialName("end_at") var end_at: String? = null,
    @SerialName("repeat") var repeat: String? = null,
    @SerialName("dueDate") var dueDate: String? = null,
    @SerialName("startDate") var startDate: String? = null,
    @SerialName("jobDuration") var jobDuration: Long? = null,
    @SerialName("steps") var steps: List<Step?> = emptyList(),
    @SerialName("userId") var userId: String? = null,
    @SerialName("contactId") var contactId: String? = null,
    @SerialName("contactSiteName") var contactSiteName: String? = null,
    @SerialName("contactParentId") var contactParentId: String? = null,
    @SerialName("createdAt") var createdAt: Long? = null,
    @SerialName("updatedAt") var updatedAt: Long? = null,
    @SerialName("notes") var notes: String? = null,
    @SerialName("fileId") var fileId: String? = null,
)
