package com.hyperether.goodjob.scenes.register

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import androidx.compose.ui.window.DialogProperties
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.forgot_your_password
import com.hyperether.goodjob.resources.no_worries
import com.hyperether.goodjob.resources.send_reset_link
import com.hyperether.goodjob.resources.your_email_address
import com.hyperether.goodjob.theme.DarkGrey
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ForgotPasswordView(onDismiss: () -> Unit, emailInput: MutableState<String>, onSendResetLink: () -> Unit) {

    Dialog(
        onDismissRequest = {

        },
        properties = DialogProperties(usePlatformDefaultWidth = false),
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.White)
                .verticalScroll(rememberScrollState())
        ) {
            Column(
                modifier = Modifier
                    .padding(horizontal = 16.dp, vertical = 16.dp)
                    .fillMaxWidth()
            ) {
                Image(
                    painter = painterResource(Res.drawable.back_arrow),
                    contentDescription = null,
                    modifier = Modifier.clickable {
                        onDismiss()
                    }
                )

                Text(
                    text = stringResource(Res.string.forgot_your_password),
                    fontSize = 24.sp,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold,
                    textAlign = TextAlign.Left,
                    modifier = Modifier.padding(top = 20.dp)
                )

                Text(
                    text = stringResource(Res.string.no_worries),
                    color = DarkGrey,
                    textAlign = TextAlign.Start,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(top = 10.dp)
                )

                AppInputField(
                    emailInput,
                    stringResource(Res.string.email_address),
                    placeholder = stringResource(Res.string.your_email_address)
                )

                FilledButton(
                    onClick = {
                        onSendResetLink()
                    },
                    text = stringResource(Res.string.send_reset_link),
                    modifier = Modifier.padding(top = 30.dp),
                    isEnabled = emailInput.value.isNotEmpty()
                )

            }


        }
    }
}