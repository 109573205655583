package com.hyperether.goodjob

import App
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import androidx.navigation.compose.rememberNavController
import com.hyperether.goodjob.database.DriverFactory
import com.hyperether.goodjob.database.SharedDatabase
import com.hyperether.goodjob.database.WorkspaceDaoImpl
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.navigation.availableRoutes
import com.hyperether.goodjob.repository.WebRepositoryImpl
import com.hyperether.goodjob.repository.prefs.Preferences
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.ClientProvider
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import com.hyperether.goodjob.repository.remote.model.AddCardRequest
import com.hyperether.goodjob.repository.remote.model.AddPlanRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.employees.EmployeeDetailsViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.jobs.JobViewModel
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import com.hyperether.goodjob.util.Constants
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.skiko.wasm.onWasmReady
import org.lighthousegames.logging.logging
import org.w3c.dom.url.URLSearchParams
import web.navigator.navigator

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    val sharedDatabase = SharedDatabase(DriverFactory())
    val workspaceDao = WorkspaceDaoImpl(sharedDatabase)
    val mapper = Mapper()
    ClientProvider.initialize()
    val queryParams = URLSearchParams(document.location?.search ?: "")
    //todo check if hash is for register or forgot password
    val path= window.location.pathname
    val hash = queryParams.get("hash")

    val prefsManager = PrefsManager(Preferences())
    val goodJobApiImpl = GoodJobApiImpl()

    val repository = WebRepositoryImpl(prefsManager, goodJobApiImpl)
    val workspaceViewModel = WorkspaceViewModel(repository)

    val registerViewModel = RegisterViewModel(repository)
    val employeesViewModel = EmployeesViewModel(repository)
    val employeeDetailsViewModel = EmployeeDetailsViewModel(repository)
    val addNewViewModel = AddNewViewModel(repository, uploadDocumentStatus = mutableStateOf(""), fileName = mutableStateOf(""))
    val log = logging("WEB MAIN")
    var paymentIntentId = ""

    onWasmReady {
        CanvasBasedWindow("Good job") {
            val navController = rememberNavController()
            val pricingViewModel = PricingViewModel(repository) {
                navController.navigate(Screen.Workspace.route)
            }
            App(
                navController,
                repository,
                workspaceViewModel,
                pricingViewModel,
                registerViewModel,
                employeesViewModel,
                employeeDetailsViewModel,
                addNewViewModel,
                prefsManager,
                getCurrentRoute(),
                onChoosePlanClick = { amount, currency ->

                    val customerId = prefsManager.getUser()?.customerId ?: return@App
                    pricingViewModel.getEphemeralKey(
                        EphemeralKeyRequest(customerId = customerId),
                        onSuccess = { key ->
                            localStorage.setItem("amount", amount.toString())
                            localStorage.setItem("currency", currency)
                            pricingViewModel.choosePlan(
                                choosePlanRequest = ChoosePlanRequest(
                                    amount = amount,
                                    currency = currency,
                                    prefsManager.getUser()?.customerId
                                ),
                                onSuccess = { response ->
                                    paymentIntentId = response.paymentIntent.toString()
                                    key.ephemeralKey?.secret?.let { emphemralKey ->
                                        response.clientSecret?.let { clientSecret ->
                                            localStorage.setItem("client_secret", clientSecret)
                                            localStorage.setItem("ephemeral_key", emphemralKey)
                                            println("Storing amount in localStorage: $amount")
                                            js("runStripe();")
                                        }
                                    }

                                },
                                onError = { errorMessage ->
                                    println("Choose Plan Error: $errorMessage")
                                    pricingViewModel.error.value = Resource.Error(errorMessage)
                                }
                            )
                        },
                        onError = { errorMessage ->
                            println("Ephemeral Key Error: $errorMessage")
                            pricingViewModel.error.value = Resource.Error(errorMessage)
                        }
                    )
                },
                onLogout = {}
                ,
                onUploadDocumentClick = {},
                onOpenPdf = {}
            )
            handleDeepLink(
                hash,
                path,
                goodJobApiImpl,
                prefsManager,
                navigate = { route ->
                    navController.navigate(route)
                }
            )
            navController.addOnDestinationChangedListener { _, destination, _ ->
                window.history.pushState(null, "", destination.route!!.split("?")[0])
                log.d("addOnDestinationChangedListener") { "$destination" }
            }

            LaunchedEffect(Unit) {
                val listener: (org.w3c.dom.events.Event) -> Unit = {
                    log.d("current route:") { getCurrentRoute() }
                    navController.navigate(getCurrentRoute())
                }
                window.addEventListener("popstate", listener)
                execute("Ja sam car")
            }

            LaunchedEffect(Unit) {
                delay(3000)
                window.asDynamic().onPaymentSuccess = { paymentMethodId: String ->
                    pricingViewModel.addCard(AddCardRequest(
                        prefsManager.getUser()?.customerId,
                        paymentMethodId,
                        paymentIntentId,
                        pricingViewModel.selectedPlanId.value
                    ),
                        onSuccess = {
                            navController.navigate(Screen.Workspace.route)


                        },
                        onError = {

                        })
                }
            }

        }
    }
}

fun execute(s: String) {
    val str: dynamic = object {
        val string = s
    }
    js("console.log(str, 'iz funkcije')")
}

fun getCurrentRoute(): String {
    val route = window.location.pathname.replace("/", "")
    if (route.isNotEmpty() && availableRoutes.contains(route)) {
        return route
    }
    return "splash"
}

fun handleDeepLink(
    hash: String?,
    path: String,
    goodJobApiImpl: GoodJobApiImpl,
    prefsManager: PrefsManager,
    navigate: (route: String) -> Unit
) {
    CoroutineScope(Dispatchers.Default).launch {
        hash?.let {

            if (navigator.userAgent.contains("Android")) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.hyperether.goodjob&referrer=${path}hash$it";
                return@launch
            } else if (navigator.userAgent.contains("iPhone") || navigator.userAgent.contains("iPad")) {
                //todo finish for ios
                window.location.href = "https://apps.apple.com/app/id123456789?referrer=12345";
                return@launch
            }
            delay(1000L)

            val result = goodJobApiImpl.confirmation(
                hash
            )
            if (result is Resource.Success) {
                navigate(Screen.Register.route)
            } else {
                handleNavigation(prefsManager, navigate)
            }
        } ?: run {
            handleNavigation(prefsManager, navigate)
        }
    }
}

fun handleNavigation(
    prefsManager: PrefsManager,
    navigate: (route: String) -> Unit
) {
    if (prefsManager.getUser() != null) {
        if (prefsManager.getUser()?.hash.isNullOrEmpty()) {
            if (prefsManager.getUser()?.planId?.isNotEmpty() == true) {
                navigate(Screen.Workspace.route)
            } else {
                navigate(Screen.Pricing.route)
            }
        } else {
            navigate(Screen.Register.route)
        }
    } else {
        navigate(Screen.Landing.route)
    }
    println("No hash found in URL query parameters.")
}