package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.PaintingStyle
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_job
import com.hyperether.goodjob.resources.add_notes
import com.hyperether.goodjob.resources.add_steps
import com.hyperether.goodjob.resources.assign_employee
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.baseline_file_present_24
import com.hyperether.goodjob.resources.custom
import com.hyperether.goodjob.resources.date_error
import com.hyperether.goodjob.resources.enter_job_title
import com.hyperether.goodjob.resources.every_2_weeks
import com.hyperether.goodjob.resources.every_day
import com.hyperether.goodjob.resources.every_month
import com.hyperether.goodjob.resources.every_week
import com.hyperether.goodjob.resources.files
import com.hyperether.goodjob.resources.job_duration
import com.hyperether.goodjob.resources.job_status
import com.hyperether.goodjob.resources.job_title
import com.hyperether.goodjob.resources.max_size
import com.hyperether.goodjob.resources.never
import com.hyperether.goodjob.resources.only_support
import com.hyperether.goodjob.resources.provide_information
import com.hyperether.goodjob.resources.repeat
import com.hyperether.goodjob.resources.select_employee
import com.hyperether.goodjob.resources.select_job_status
import com.hyperether.goodjob.resources.try_another_document
import com.hyperether.goodjob.resources.upload_cloud
import com.hyperether.goodjob.resources.upload_documents
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.DurationInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.Constants
import com.hyperether.planner.model.EventStatus
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewJobScreen(viewModel: AddNewViewModel, navHostController: NavHostController, onUploadDocumentClick: () -> Unit) {

    val employees by viewModel.employees.collectAsState(initial = emptyList())

    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        MobileHeaderBackAndAction(
            title = stringResource(Res.string.add_new_job),
            back = {
                viewModel.clearJobValues()
                navHostController.popBackStack()
            }
        )

        Spacer(modifier = Modifier.height(15.dp))

        Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
            androidx.compose.material3.Text(
                text = stringResource(Res.string.about_job),
                color = Color.Black,
                fontSize = 24.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(top = 10.dp)
            )
            AppInputField(
                viewModel.jobTitleInput,
                stringResource(Res.string.job_title),
                placeholder = stringResource(Res.string.enter_job_title)
            )
            val items = listOf(
                EventStatus.PENDING.name,
                EventStatus.COMPLETED.name,
                EventStatus.DELAYED.name
            )
            AddNewJobDropdownInput(
                viewModel.jobStatusValue,
                stringResource(Res.string.job_status),
                placeholder = stringResource(Res.string.select_job_status),
                dropdownItems = items,
                painter = painterResource(Res.drawable.baseline_arrow_drop_down_24)
            )
            EmployeesDropdownInput(
                viewModel.assignEmployeeValue,
                stringResource(Res.string.assign_employee),
                placeholder = stringResource(Res.string.select_employee),
                dropdownItems = employees,
                painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                onContactSelected = { employee ->
                    viewModel.selectedEmployee.value = employee
                }
            )
            OutlinedCalendarInput(
                onClick = {
                    viewModel.isCalendar1Visible.value = true
                },
                viewModel.dateInputValue
            )

            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                OutlinedButton(
                    onClick = { viewModel.isTimePicker1Visible.value = true },
                    border = BorderStroke(1.dp, Color.DarkGray),
                    shape = RoundedCornerShape(8),
                    colors = ButtonDefaults.outlinedButtonColors(
                        contentColor = Color.White, containerColor = Color.Transparent
                    ),
                    modifier = Modifier
                        .height(73.dp)
                        .weight(1f).padding(top = 15.dp)
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = viewModel.startInputValue.value,
                            color = Color.DarkGray,
                            fontSize = 13.sp,
                            textAlign = TextAlign.Start,
                            modifier = Modifier.weight(4f).padding(top = 5.dp).offset(x = (-10).dp)
                        )
                    }
                }

                Spacer(modifier = Modifier.width(8.dp))

                OutlinedButton(
                    onClick = { viewModel.isTimePicker2Visible.value = true },
                    border = BorderStroke(1.dp, Color.DarkGray),
                    shape = RoundedCornerShape(8),
                    colors = ButtonDefaults.outlinedButtonColors(
                        contentColor = Color.White, containerColor = Color.Transparent
                    ),
                    modifier = Modifier
                        .height(73.dp)
                        .weight(1f).padding(top = 15.dp)
                ) {
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = viewModel.endInputValue.value,
                            color = Color.DarkGray,
                            fontSize = 13.sp,
                            textAlign = TextAlign.Start,
                            modifier = Modifier.weight(4f).padding(top = 5.dp).offset(x = (-10).dp)
                        )
                    }
                }
            }
            val itemsRepeat =
                listOf(
                    stringResource(Res.string.never),
                    stringResource(Res.string.every_day),
                    stringResource(Res.string.every_week),
                    stringResource(Res.string.every_2_weeks),
                    stringResource(Res.string.every_month),
                    stringResource(Res.string.custom)
                )

            AddNewJobDropdownInput(
                viewModel.repeatValue,
                stringResource(Res.string.repeat),
                placeholder = "Never",
                dropdownItems = itemsRepeat,
                painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
            )
            OutlinedCalendarInput(
                onClick = {
                    viewModel.isCalendar2Visible.value = true
                },
                viewModel.dueDateValue
            )

            DurationInputField(
                viewModel.durationText,
                stringResource(Res.string.job_duration),
                placeholder = viewModel.durationText.value
            )
            Spacer(modifier = Modifier.height(15.dp))

            Text(
                text = stringResource(Res.string.add_steps),
                fontSize = 18.sp,
                color = TextColor,
                textAlign = TextAlign.Center
            )
            Spacer(modifier = Modifier.height(15.dp))

            StepInputFields(viewModel.steps)

            Text(
                text = stringResource(Res.string.files),
                fontSize = 18.sp,
                color = TextColor,
                textAlign = TextAlign.Center
            )
            AppInputField(
                viewModel.notesValue,
                stringResource(Res.string.add_notes),
                placeholder = stringResource(Res.string.provide_information)
            )
            Spacer(modifier = Modifier.height(15.dp))
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(200.dp)
                    .align(Alignment.CenterHorizontally)
                    .drawBehind {
                        val paint = Paint().apply {
                            color = BlueApp
                            style = PaintingStyle.Stroke
                            strokeWidth = 2.dp.toPx()
                            pathEffect = PathEffect.dashPathEffect(floatArrayOf(10f, 10f), 0f)
                        }
                        drawIntoCanvas { canvas ->
                            canvas.drawRoundRect(
                                left = 0f,
                                top = 0f,
                                right = size.width,
                                bottom = size.height,
                                radiusX = 16.dp.toPx(),
                                radiusY = 16.dp.toPx(),
                                paint = paint
                            )
                        }
                    },
                contentAlignment = Alignment.Center
            ) {
                Column(
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Center,
                    modifier = Modifier.fillMaxSize().
                    clickable {
                        onUploadDocumentClick()
                    }
                ) {
                    if (viewModel.documentStatus.value == "") {
                        Image(
                            painter = painterResource(Res.drawable.upload_cloud),
                            contentDescription = "",
                            modifier = Modifier.size(40.dp)
                        )

                        Spacer(modifier = Modifier.height(8.dp))

                        Text(
                            text = stringResource(Res.string.upload_documents),
                            color = Color.Black,
                            fontWeight = FontWeight.Bold
                        )

                        Spacer(modifier = Modifier.height(4.dp))

                        Text(
                            text = stringResource(Res.string.max_size),
                            color = Color.LightGray
                        )
                    } else if (viewModel.documentStatus.value == Constants.DOCUMENT_LOADING) {
                        viewModel.showDocumentLoader.value = true
                        if(viewModel.showDocumentLoader.value) {
                            CircularProgressIndicator(
                                modifier = Modifier
                                    .size(25.dp)
                                    .padding(end = 5.dp),
                                color = BlueApp,
                                strokeWidth = 2.dp
                            )
                        }
                    } else if (viewModel.documentStatus.value == Constants.DOCUMENT_UPLOAD_ERROR) {
                        viewModel.showDocumentLoader.value = false
                        Text(
                            text = stringResource(Res.string.try_another_document),
                            color = Color.Gray,
                            fontWeight = FontWeight.Normal
                        )
                    } else {
                        viewModel.showDocumentLoader.value = false
                        Image(
                            painter = painterResource(Res.drawable.baseline_file_present_24),
                            contentDescription = "",
                            modifier = Modifier.size(40.dp)
                        )
                        Text(
                            text = viewModel.fileName.value,
                            color = BlueApp,
                            fontWeight = FontWeight.Normal
                        )
                    }
                }
            }

            Text(
                text = stringResource(Res.string.only_support),
                fontSize = 12.sp,
                color = Color.LightGray,
                textAlign = TextAlign.Center
            )



            FilledButton(
                onClick = {
                    viewModel.showLoader.value = true
                    viewModel.addJob(
                        onSuccess = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpSuccess.value = true
                        },
                        onError = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpError.value = true
                        })
                },
                modifier = Modifier.padding(
                    start = 16.dp,
                    end = 16.dp,
                    top = 30.dp,
                    bottom = 20.dp
                ),
                text = stringResource(Res.string.add),
                isEnabled = true
            )
            if (viewModel.isTimePicker1Visible.value) {
                CustomTimePicker(
                    time = viewModel.startInputValue,
                    showTimePicker = viewModel.isTimePicker1Visible,
                    onTimeSelected = { viewModel.startInputValue.value = it })
            }
            if (viewModel.isTimePicker2Visible.value) {
                CustomTimePicker(
                    time = viewModel.endInputValue,
                    showTimePicker = viewModel.isTimePicker2Visible,
                    onTimeSelected = {
                        viewModel.endInputValue.value = it
                        viewModel.calculateDuration()
                        println("$$$$$" + viewModel.durationInMinutes.value)
                    })
            }
            val error = stringResource(Res.string.date_error)
            if (viewModel.isCalendar1Visible.value) {
                CustomCalendarView(
                    isAddEmployee = false,
                    onDismiss = { viewModel.isCalendar1Visible.value = false },
                    onDateSelect = {
                        viewModel.dateInputValue.value = it.toString()
                    },
                    showErrorMessage = {
                        viewModel.showPopUpError.value = true
                        viewModel.errorText.value = error
                    })
            }
            if (viewModel.isCalendar2Visible.value) {
                CustomCalendarView(
                    isAddEmployee = false,
                    onDismiss = { viewModel.isCalendar2Visible.value = false },
                    onDateSelect = {
                        viewModel.dueDateValue.value = it.toString()
                    },
                    showErrorMessage = {
                        viewModel.showPopUpError.value = true
                        viewModel.errorText.value = error
                    })
            }
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                        navHostController.popBackStack()
                    },
                    title = "SUCCESS",
                    message = "Job added successfully",
                    onPositiveBtnClicked = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                        navHostController.popBackStack()
                    },
                    positiveBtnText = "OK"
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = "ERROR",
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
        }

    }
}
