package com.hyperether.goodjob.scenes.landing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.onSizeChanged
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.toSize
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all_rights_reserved
import com.hyperether.goodjob.resources.and_we_will
import com.hyperether.goodjob.resources.effortless_efficiency
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.free_hundred
import com.hyperether.goodjob.resources.ic_check_white
import com.hyperether.goodjob.resources.its
import com.hyperether.goodjob.resources.landing_img_dark
import com.hyperether.goodjob.resources.optimize_your
import com.hyperether.goodjob.resources.pricing
import com.hyperether.goodjob.resources.privacy_policy
import com.hyperether.goodjob.resources.terms_of_service
import com.hyperether.goodjob.resources.transform
import com.hyperether.goodjob.resources.transparent_flexible
import com.hyperether.goodjob.resources.whether
import com.hyperether.goodjob.scenes.components.RoundedTextFieldWithButton
import com.hyperether.goodjob.scenes.components.SubscriptionToggle
import com.hyperether.goodjob.scenes.pricing.PricingBoxWebView
import com.hyperether.goodjob.theme.LandingPageBg
import com.hyperether.goodjob.theme.LandingPageMainTitle
import com.hyperether.goodjob.theme.LandingPageNavColor
import com.hyperether.goodjob.theme.LandingPageSmallTitle
import com.hyperether.goodjob.theme.LandingPageText
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun LandingPageWebView(
    selectedOption: MutableState<String>,
    containerSize: MutableState<Size>,
    priceBoxSize: MutableState<Size>,
    plans: List<Plan>,
    enteredEmail: MutableState<String>,
    savePlanLocally: (String) -> Unit,
    continueToRegister: () -> Unit
) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(LandingPageBg)
            .onSizeChanged { size ->
                containerSize.value = size.toSize()
            }
    ) {
        Column(
            modifier = Modifier
                .matchParentSize()
                .verticalScroll(rememberScrollState())
                .background(
                    Brush.radialGradient(
                        colors = listOf(
                            Color.White.copy(alpha = 0.2f),
                            Color.Transparent
                        ),
                        center = Offset(
                            x = 0f,
                            y = containerSize.value.height / 3f
                        ),
                        radius = containerSize.value.width / 4f
                    )
                ),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Column(
                modifier = Modifier
                    .fillMaxHeight()
                    .background(Color.Transparent),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                LandingHeader(
                    continueToRegister = continueToRegister
                )

                Spacer(modifier = Modifier.height(Dp(92f)))

                Row(
                    modifier = Modifier
                        .padding(
                            horizontal = Dp(100f)
                        )
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.Center
                ) {
                    Column(
                        modifier = Modifier
                            .weight(1f)
                            .width(Dp(663f)),
                        verticalArrangement = Arrangement.Center,
                    ) {
                        Text(
                            text = stringResource(Res.string.effortless_efficiency),
                            fontSize = Sp(16f),
                            lineHeight = Sp(18f),
                            color = LandingPageSmallTitle
                        )
                        Spacer(modifier = Modifier.height(Dp(4f)))
                        Text(
                            text = stringResource(Res.string.transform),
                            fontSize = Sp(50f),
                            fontWeight = FontWeight.SemiBold,
                            lineHeight = Sp(62f),
                            color = LandingPageMainTitle,
                            modifier = Modifier
                                .fillMaxWidth(),
                        )
                        Spacer(modifier = Modifier.height(Dp(12f)))
                        Text(
                            text = stringResource(Res.string.optimize_your),
                            fontSize = Sp(18f),
                            color = LandingPageText,
                            lineHeight = Sp(30f),
                            fontWeight = FontWeight.W400,
                        )
                        Spacer(modifier = Modifier.height(Dp(32f)))
                        RoundedTextFieldWithButton(
                            isMobileView = false,
                            input = enteredEmail,
                            placeHolder = stringResource(Res.string.enter_your_email),
                            modifier = Modifier
                                .width(Dp(610f))
                                .height(Dp(77f)),
                            onSubscribe = {
                                // TODO
                            }
                        )
                        Spacer(modifier = Modifier.height(Dp(25f)))
                        Row(
                            modifier = Modifier.fillMaxWidth(),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Image(
                                painter = painterResource(Res.drawable.ic_check_white),
                                contentDescription = "Feature icon",
                                modifier = Modifier
                                    .size(Dp(22f))
                            )
                            Text(
                                text = buildAnnotatedString {
                                    append(stringResource(Res.string.its))
                                    withStyle(style = SpanStyle(fontWeight = FontWeight.Bold)) {
                                        append(stringResource(Res.string.free_hundred))
                                    }
                                    append(stringResource(Res.string.and_we_will))
                                },
                                color = LandingPageText,
                                fontSize = Sp(16f),
                                lineHeight = Sp(18f),
                                modifier = Modifier
                                    .padding(start = Dp(25f))
                            )
                        }
                    }
                    Spacer(modifier = Modifier.width(Dp(120f)))
                    Image(
                        modifier = Modifier
                            .weight(1f)
                            .height(Dp(511f))
                            .width(Dp(457f)),
                        painter = painterResource(Res.drawable.landing_img_dark),
                        contentDescription = "Landing image"
                    )
                }

                Spacer(modifier = Modifier.height(Dp(120f)))

                Column(
                    modifier = Modifier
                        .padding(horizontal = Dp(100f))
                        .fillMaxWidth(),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        text = stringResource(Res.string.pricing),
                        fontSize = Sp(18f),
                        lineHeight = Sp(20f),
                        color = LandingPageSmallTitle,
                        textAlign = TextAlign.Center
                    )
                    Spacer(modifier = Modifier.height(Dp(4f)))

                    // Pricing Section Title
                    Text(
                        text = stringResource(Res.string.transparent_flexible),
                        fontSize = Sp(50f),
                        lineHeight = Sp(62f),
                        fontWeight = FontWeight.Bold,
                        textAlign = TextAlign.Center,
                        color = LandingPageMainTitle
                    )
                    Spacer(modifier = Modifier.height(Dp(12f)))
                    Text(
                        text = stringResource(Res.string.whether),
                        fontSize = Sp(18f),
                        lineHeight = Sp(30f),
                        color = LandingPageText,
                        textAlign = TextAlign.Center
                    )

                    Spacer(modifier = Modifier.height(Dp(32f)))

                    SubscriptionToggle(
                        isDark = true,
                        isMobile = false,
                        selectedOption = selectedOption.value,
                        onOptionSelected = {
                            // TODO: show corresponding prices
                            selectedOption.value = it
                        }
                    )

                    Spacer(modifier = Modifier.height(Dp(90f)))

                    // Pricing Cards Container
                    PricingBoxWebView(
                        isLanding = true,
                        priceBoxSize = priceBoxSize,
                        plans = plans,
                        savePlanLocally = { savePlanLocally(it) },
                        continueToRegister = { id, currency, amount ->
                        }
                    )
                }
                Spacer(modifier = Modifier.height(Dp(90f)))
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .background(LandingPageNavColor)
                        .height(Dp(48f)),
                    contentAlignment = Alignment.Center
                ) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(horizontal = Dp(100f))
                            .background(color = LandingPageNavColor),
                        verticalAlignment = Alignment.CenterVertically,
                    ) {
                        Text(
                            text = stringResource(Res.string.all_rights_reserved),
                            fontSize = Sp(15f),
                            lineHeight = Sp(18f),
                            color = LandingPageMainTitle,
                            fontWeight = FontWeight.W400,
                            textAlign = TextAlign.End,
                            modifier = Modifier.weight(3f)
                        )
                        Text(
                            text = stringResource(Res.string.privacy_policy),
                            fontSize = Sp(15f),
                            lineHeight = Sp(18f),
                            color = LandingPageMainTitle,
                            textAlign = TextAlign.End,
                            fontWeight = FontWeight.W900,
                            modifier = Modifier.weight(1f)
                        )
                        Text(
                            text = stringResource(Res.string.terms_of_service),
                            fontSize = Sp(15f),
                            lineHeight = Sp(18f),
                            color = LandingPageMainTitle,
                            textAlign = TextAlign.End,
                            fontWeight = FontWeight.W900,
                            modifier = Modifier
                                .weight(1f)
                                .padding(end = Dp(20f))
                        )
                    }
                }
            }
        }
    }
}