package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ErrorBody(
    @SerialName("code") var code: Int? = null,
    @SerialName("message") var message: String? = null,
    @SerialName("data") var data: DataModel? = null,
    @SerialName("internalCode") var internalCode: Int? = null
)