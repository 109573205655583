package com.hyperether.goodjob.scenes.landing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.login_register
import com.hyperether.goodjob.resources.logo_dark_landing
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.LandingPageNavColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun LandingHeader(
    continueToRegister: () -> Unit
) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dp(126f))
            .background(LandingPageNavColor)
            .padding(
                horizontal = Dp(100f)
            ),
        contentAlignment = Alignment.TopStart
    ) {
        Image(
            alignment = Alignment.CenterStart,
            painter = painterResource(Res.drawable.logo_dark_landing),
            contentDescription = null,
            modifier = Modifier
                .height(Dp(47f))
                .width(Dp(203f))
                .align(Alignment.CenterStart)
        )

        Button(
            onClick = {
                continueToRegister()
            },
            modifier = Modifier
                .align(Alignment.CenterEnd)
                .height(Dp(61f))
                .width(Dp(208f))
                .clip(RoundedCornerShape(Dp(56f))),
            colors = ButtonDefaults.buttonColors(Color.White),
        ) {
            Text(
                text = stringResource(Res.string.login_register),
                color = BlueApp,
                fontSize = Sp(18f),
                fontWeight = FontWeight.Bold
            )
        }
    }
}