package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChoosePlanRequest(
    @SerialName("amount") var amount: Double? = null,
    @SerialName("currency") var currency: String? = null,
    @SerialName("customerId") var customerId: String? = null,
)