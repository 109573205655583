import androidx.compose.material.MaterialTheme
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Container
import com.hyperether.goodjob.navigation.Screen
import org.jetbrains.compose.ui.tooling.preview.Preview
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.employees.EmployeeDetailsViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.jobs.JobViewModel
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel

@Composable
@Preview
fun App(
    navController: NavHostController,
    repository: Repository,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    addNewViewModel: AddNewViewModel,
    prefsManager: PrefsManager,
    currentRoute: String = Screen.Splash.route,
    onChoosePlanClick: (Double, String) -> Unit,
    onLogout: () -> Unit,
    onUploadDocumentClick: () -> Unit,
    onOpenPdf: (String) -> Unit
) {
    MaterialTheme {
        Container(
            navController,
            repository,
            workspaceViewModel,
            pricingViewModel,
            registerViewModel,
            employeesViewModel,
            employeeDetailsViewModel,
            addNewViewModel,
            prefsManager,
            currentRoute,
            onChoosePlanClick,
            onLogout,
            onUploadDocumentClick,
            onOpenPdf
        )
    }
}