package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.repository.Repository
import com.hyperether.planner.model.Event
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.minus
import kotlinx.datetime.plus
import kotlinx.datetime.todayIn

class SchedulerViewModel(val repository: Repository) : ViewModel() {
    var periodState = mutableStateOf(PeriodType.DAY)
    var currentPeriod = mutableStateOf("Today")
    private var selectedDay: LocalDate = Clock.System.todayIn(TimeZone.currentSystemDefault())
    private val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
    var listOfPlans = mutableStateListOf<Plan>()

    init {
//        viewModelScope.launch {
//            repository.getJobs().collect { jobs ->
//                repository.getUsers().collect { users ->
//                    val jobsForEvents =
//                        jobs.filter {
//                            it.employees?.contains(it.id.toString()) == true && LocalDateTime.parse(
//                                it.start_at!!
//                            ).date == selectedDay
//                        }
//                    users.forEach { user ->
//                        user.fullName?.let { username ->
//                            listOfPlans.add(
//                                Plan(
//                                    person = username,
//                                    events = jobsForEvents.map {
//                                        Event(
//                                            //todo change status when enum is finished from api side
//                                            EventStatus.PENDING,
//                                            LocalDateTime.parse(it.start_at!!),
//                                            LocalDateTime.parse(it.end_at!!),
//                                            it.jobTitle
//                                        )
//                                    }
//                                )
//                            )
//                        }
//                    }
//                }
//            }
//        }
    }

    fun previous() {
        if (periodState.value == PeriodType.DAY) {
            selectedDay = selectedDay.minus(1, DateTimeUnit.DAY)
            if (selectedDay == today) {
                currentPeriod.value = "Today"
            } else {
                currentPeriod.value = selectedDay.toString()
            }
        }
    }

    fun next() {
        if (periodState.value == PeriodType.DAY) {
            selectedDay = selectedDay.plus(1, DateTimeUnit.DAY)
            if (selectedDay == today) {
                currentPeriod.value = "Today"
            } else {
                currentPeriod.value = selectedDay.toString()
            }
        }
    }

    fun periodChanged() {
        when (periodState.value) {
            PeriodType.DAY -> {
                currentPeriod.value = "Today"
            }

            PeriodType.WEEK -> {
                currentPeriod.value = "September 1st-8th, 2024"
            }

            PeriodType.MONTH -> {
                currentPeriod.value = "November, 2024"
            }
        }
    }
}