package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Skills
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.friday
import com.hyperether.goodjob.resources.monday
import com.hyperether.goodjob.resources.saturday
import com.hyperether.goodjob.resources.sunday
import com.hyperether.goodjob.resources.thursday
import com.hyperether.goodjob.resources.tuesday
import com.hyperether.goodjob.resources.wednesday
import com.hyperether.goodjob.util.UploadManager
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.launch

class AddNewViewModel(val repository: Repository, uploadDocumentStatus: MutableState<String>, fileName: MutableState<String>) : ViewModel() {

    /*Add new employee*/
    var contactName: MutableState<String> = mutableStateOf("")
    var contactSite: MutableState<String> = mutableStateOf("")
    val fullNameInput: MutableState<String> = mutableStateOf("")
    val phoneInput: MutableState<String> = mutableStateOf("")
    val emailInput: MutableState<String> = mutableStateOf("")
    val zipInput: MutableState<String> = mutableStateOf("")
    val cityInput: MutableState<String> = mutableStateOf("")
    val countryInput: MutableState<String> = mutableStateOf("")
    val addressInput: MutableState<String> = mutableStateOf("")
    val status: MutableState<String> = mutableStateOf("")
    val roleSelected: MutableState<String> = mutableStateOf("")
    val _selectedSkills = mutableStateOf(emptyList<com.hyperether.goodjob.database.Skills>())

    private val _availableSkills =
        mutableStateOf<List<com.hyperether.goodjob.database.Skills>>(emptyList())

    val selectedSkillsNames = derivedStateOf { _selectedSkills.value.map { it.name ?: "" } }
    val availableSkillsNames: State<List<String>> = derivedStateOf {
        _availableSkills.value.map { it.name ?: "" }
    }

    val team: MutableState<String> = mutableStateOf("")
    val selectedTeam: MutableState<Team?> = mutableStateOf(null)
    val selectedSkill: MutableState<String?> = mutableStateOf(null)
    val upcomingLeaves: MutableState<String> = mutableStateOf("Upcoming leaves")
    val fileId: MutableState<String?> = mutableStateOf("")
    val documentStatus = uploadDocumentStatus
    val fileName = fileName

    val daysSchedule = listOf(
        DaySchedule(
            dayName = Res.string.monday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.tuesday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.wednesday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.thursday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.friday,
            isChecked = mutableStateOf(true),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.saturday,
            isChecked = mutableStateOf(false),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        ),
        DaySchedule(
            dayName = Res.string.sunday,
            isChecked = mutableStateOf(false),
            startTime = mutableStateOf("08:00"),
            endTime = mutableStateOf("16:00"),
            isStartTimePickerVisible = mutableStateOf(false),
            isEndTimePickerVisible = mutableStateOf(false)
        )
    )

    /*Add new job*/
    val jobTitleInput: MutableState<String> = mutableStateOf("")
    val jobStatusValue: MutableState<String> = mutableStateOf("")
    val assignEmployeeValue: MutableState<String> = mutableStateOf("")
    val dateInputValue: MutableState<String> = mutableStateOf("Date")
    val startInputValue: MutableState<String> = mutableStateOf("Start at")
    val endInputValue: MutableState<String> = mutableStateOf("End at")
    val repeatValue: MutableState<String> = mutableStateOf("")
    val dueDateValue: MutableState<String> = mutableStateOf("Due date")
    val jobDurationValue: MutableState<Int> = mutableStateOf(0)
    val notesValue: MutableState<String> = mutableStateOf("")
    val contactId: MutableState<String> = mutableStateOf("")
    val selectedSiteName: MutableState<String> = mutableStateOf("")
    val contactParentId: MutableState<String> = mutableStateOf("")
    val selectedEmployee: MutableState<User?> = mutableStateOf(null)
    val durationText: MutableState<String> = mutableStateOf("")
    val durationInMinutes: MutableState<Int> = mutableStateOf(0)


    /*Add new customer*/
    val isCheckedContactPerson: MutableState<Boolean> = mutableStateOf(false)
    val contactType: MutableState<String> = mutableStateOf("")
    val industry: MutableState<String> = mutableStateOf("")
    val contactAddress: MutableState<String> = mutableStateOf("")
    val contactNameNewContact: MutableState<String> = mutableStateOf("")
    val contactZip: MutableState<String> = mutableStateOf("")
    val contactCity: MutableState<String> = mutableStateOf("")
    val contactCountry: MutableState<String> = mutableStateOf("")
    val phoneNewContact: MutableState<String> = mutableStateOf("")
    val emailNewContact: MutableState<String> = mutableStateOf("")
    val contactPersonName: MutableState<String> = mutableStateOf("")
    val contactPersonEmail: MutableState<String> = mutableStateOf("")
    val contactPersonPhone: MutableState<String> = mutableStateOf("")
    val selectedContact: MutableState<String> = mutableStateOf("")


    val isTimePicker1Visible: MutableState<Boolean> = mutableStateOf(false)
    val isTimePicker2Visible: MutableState<Boolean> = mutableStateOf(false)
    val isCalendar1Visible: MutableState<Boolean> = mutableStateOf(false)
    val isCalendar2Visible: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val showDocumentLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")

    val steps = mutableStateListOf(StepData())

    val showProgressBar = mutableStateOf(false)
    val showErrorDialog = mutableStateOf(false)

    private val _contactSites = mutableStateOf<List<Site>>(emptyList())
    val contactSites: State<List<Site>> get() = _contactSites

    fun insertJob(job: Job) {
        viewModelScope.launch {
            repository.insertJob(job)
        }
    }

    val contacts: Flow<List<com.hyperether.goodjob.database.Contact>> =
        repository.getContactsFromLocal()
    val employees: Flow<List<com.hyperether.goodjob.database.User>> = repository.getUsers()


    fun addEmployee(onSuccess: () -> Unit, onError: () -> Unit) {
        // Validate inputs before proceeding
        val errorMessage = when {
            roleSelected.value.isBlank() -> "Role is required."
//            fullNameInput.value.isBlank() -> "Full Name is required."
//            phoneInput.value.isBlank() -> "Phone number is required."
            emailInput.value.isBlank() -> "Email is required."
//            cityInput.value.isBlank() -> "City is required."
//            countryInput.value.isBlank() -> "Country is required."
//            addressInput.value.isBlank() -> "Address is required."
//            zipInput.value.isBlank() -> "ZIP code is required."
            status.value.isBlank() -> "Status is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return
        }

        // If validation passes, create the employee object
        val user = com.hyperether.goodjob.models.User(
            fullName = fullNameInput.value,
            email = emailInput.value,
            address = addressInput.value,
            city = cityInput.value,
            zip = zipInput.value,
            country = countryInput.value,
            status = status.value,
            teams = listOf(selectedTeam.value),
            skills = _selectedSkills.value.map { it.name ?: "" },
            role = roleSelected.value,
            phone = phoneInput.value,
            workspaceId = repository.getWorkspaceId(),
            upcomingLeave = upcomingLeaves.value
        )

        viewModelScope.launch {
            val result = repository.addEmployee(user.toEmployeeRequest())

            when (result) {
                is Resource.Success -> {
                    println("User added successfully")
                    clearUserValues()
                    onSuccess()
                }

                is Resource.Error -> {
                    println("Error while adding user")
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }


    fun addJob(onSuccess: () -> Unit, onError: () -> Unit) {

        val errorMessage = when {
            jobTitleInput.value.isBlank() -> "Job title is required."
            jobStatusValue.value.isBlank() -> "Job status is required."
            selectedEmployee.value?._id?.isBlank() == true -> "Assign is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return
        }
        val stepsList: List<Step> = steps.map { stepData ->
            Step(
                stepTitle = stepData.title.value,
                stepDescription = stepData.description.value,
                estimatedDuration = stepData.estimation.value.toIntOrNull(),
                stepStatus = stepData.stepStatus.value
            )
        }
        val job = JobRequest(
            jobTitle = jobTitleInput.value,
            start_at = startInputValue.value,
            end_at = endInputValue.value,
            steps = stepsList,
            startDate = dateInputValue.value,
            dueDate = dueDateValue.value,
            employees = listOf(selectedEmployee.value?._id),
            status = jobStatusValue.value,
            repeat = repeatValue.value,
            jobDuration = durationInMinutes.value.toLong(),
            contactId = contactId.value,
            userId = getUser()?._id,
            workspaceId = getWorkspaceId(),
            contactSiteName = selectedSiteName.value,
            contactParentId = contactParentId.value,
            creatorId = "",
            notes = notesValue.value,
            fileId = fileId.value
        )
        viewModelScope.launch {
            val result = repository.createJob(job)

            when (result) {
                is Resource.Success -> {
                    clearJobValues()
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun uploadFile(fileName: String, byteArray: ByteArray, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.uploadFile(byteArray, fileName)

            when (result) {
                is Resource.Success -> {
                    fileId.value = result.data?.fileId
                    result?.data?.fileId?.let { UploadManager.setFileId(it) }
                    println(result)
                    onSuccess()
                }

                is Resource.Error -> {
                    println(result)
                    onError()
                }
            }
        }
    }

    fun getAllContacts() {
        viewModelScope.launch {
            val result = repository.getContactsByWorkspaceId()

            when (result) {
                is Resource.Success -> {
                    println(result)
                }

                is Resource.Error -> {
                    println(result)
                }
            }
        }
    }

    fun clearUserValues(){
        fullNameInput.value = ""
        emailInput.value = ""
        addressInput.value = ""
        zipInput.value = ""
        countryInput.value = ""
        cityInput.value = ""
        status.value = ""
        selectedTeam.value = null
        phoneInput.value = ""
        upcomingLeaves.value = ""
    }

    fun clearJobValues(){
        jobTitleInput.value = ""
        startInputValue.value = ""
        endInputValue.value = ""
        dueDateValue.value = ""
        selectedEmployee.value = null
        jobStatusValue.value = ""
        repeatValue.value = ""
        durationInMinutes.value = 0
        contactId.value = ""
        selectedSiteName.value = ""
        contactParentId.value = ""
        contactName.value = ""
        contactSite.value = ""
        notesValue.value = ""
        assignEmployeeValue.value = ""
        dateInputValue.value = ""
        fileName.value = ""
        documentStatus.value = ""
        fileId.value = ""
        steps.clear()
    }

    fun clearContactValues() {
        isCheckedContactPerson.value = false
        contactType.value = ""
        industry.value = ""
        contactAddress.value = ""
        contactNameNewContact.value = ""
        contactZip.value = ""
        contactCity.value = ""
        contactCountry.value = ""
        phoneNewContact.value = ""
        emailNewContact.value = ""
        contactPersonName.value = ""
        contactPersonEmail.value = ""
        contactPersonPhone.value = ""
        selectedContact.value = ""
    }

    fun getSitesUnderContact(siteRequest: SiteRequest) {
        viewModelScope.launch {
            val result = repository.getSitesUnderContact(siteRequest)
            if (result is Resource.Success) {
                println("Fetched sites: ${result.data}")
                _contactSites.value = result.data ?: emptyList()
            } else {
                println("Error fetching sites: ${result.text}")
            }
        }
    }

    fun addContact(onSuccess: () -> Unit, onError: () -> Unit) {

        val errorMessage = when {
            contactNameNewContact.value.isBlank() -> "Contact name is required."
            contactType.value.isBlank() -> "Contact type is required."
            industry.value.isBlank() -> "Industry is required."
            contactAddress.value.isBlank() -> "Address is required."
            emailNewContact.value.isEmpty() -> "Email is required."
            contactCity.value.isBlank() -> "City is required."
            contactCountry.value.isBlank() -> "Country is required."
            contactZip.value.isBlank() -> "Zip code is required."
            phoneNewContact.value.isBlank() -> "Phone is required."
            contactPersonName.value.isBlank() && !isCheckedContactPerson.value -> "Contact person name is required."
            contactPersonEmail.value.isBlank() && !isCheckedContactPerson.value -> "Contact person email is required."
            contactPersonPhone.value.isBlank() && !isCheckedContactPerson.value -> "Contact person phone is required."
            else -> null
        }

        if (errorMessage != null) {
            errorText.value = errorMessage
            onError()
            return
        }

        val contact = Contact(
            name = contactNameNewContact.value,
            contactType = contactType.value,
            type = contactType.value,
            industry = industry.value,
            address = contactAddress.value,
            email = emailNewContact.value,
            city = contactCity.value,
            country = contactCountry.value,
            zip = contactZip.value,
            phone = phoneNewContact.value,
            personName = if (isCheckedContactPerson.value) contactNameNewContact.value else contactPersonName.value,
            personAddress = if (isCheckedContactPerson.value) emailNewContact.value else contactPersonEmail.value,
            personPhone = if (isCheckedContactPerson.value) phoneNewContact.value else contactPersonPhone.value,
            workspaceId = repository.getWorkspaceId()
        )
        viewModelScope.launch {
            val result = repository.addContact(contact)

            when (result) {
                is Resource.Success -> {
                    clearContactValues()
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun getWorkspaceId(): String = repository.getWorkspaceId()

    fun getUser(): com.hyperether.goodjob.models.User? {
        return repository.getUser()
    }

    fun calculateDuration() {
        try {
            val (startHour, startMinute) = startInputValue.value.split(":").map { it.toInt() }
            val (endHour, endMinute) = endInputValue.value.split(":").map { it.toInt() }

            val startTotalMinutes = startHour * 60 + startMinute
            var endTotalMinutes = endHour * 60 + endMinute

            if (endTotalMinutes < startTotalMinutes) {
                endTotalMinutes += 1440
            }

            val totalMinutes = endTotalMinutes - startTotalMinutes

            durationInMinutes.value = totalMinutes
            durationText.value = "${totalMinutes / 60}h ${totalMinutes % 60}min"
        } catch (e: Exception) {
            durationText.value = "Invalid time format"
            durationInMinutes.value = 0
        }
    }

    fun addNewSkill(skillName: String) {
        viewModelScope.launch {
            repository.addSkill(Skills(name = skillName, workspaceId = repository.getWorkspaceId()))
        }
    }

    fun getAllSkillsRemote() {
        viewModelScope.launch {
            when (val result = repository.getSkillsByWorkspace()) {
                is Resource.Success -> {
                    result.data?.let { skillsList ->
                        _availableSkills.value = skillsList.map { Mapper().skillToSkill(it) }
                    }
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    fun removeSkills() {
        viewModelScope.launch {
            _availableSkills.value = emptyList()
            _selectedSkills.value = emptyList()
        }
    }
}