package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.contact_type
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.industry
import com.hyperether.goodjob.scenes.components.FilterSectionMobile
import com.hyperether.goodjob.scenes.components.toggleSelection
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun FilterContactsDialog(
    cities: List<String>,
    industries: List<String>,
    contactTypes: List<String>,
    onDismiss: () -> Unit,
    onApply: (cities: Set<String>, industries: Set<String>, contactTypes: Set<String>) -> Unit
) {
    var selectedCities by remember { mutableStateOf(setOf<String>()) }
    var selectedIndustries by remember { mutableStateOf(setOf<String>()) }
    var selectedContactTypes by remember { mutableStateOf(setOf<String>()) }

    Box(modifier = Modifier.fillMaxSize()) {

        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.Black.copy(alpha = 0.5f))
                .clickable(
                    onClick = {},
                    indication = null,
                    interactionSource = remember { MutableInteractionSource() })
        ) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .align(Alignment.Center)
                    .background(Color.White)
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    androidx.compose.material.Icon(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "Back icon",
                        modifier = Modifier
                            .weight(1f)
                            .offset(x = (-30).dp)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                onDismiss()
                            }
                    )

                    androidx.compose.material.Text(
                        text = stringResource(Res.string.filter),
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontSize = 20.sp,
                        modifier = Modifier
                            .weight(3f)
                            .align(Alignment.CenterVertically)
                    )

                    androidx.compose.material.Text(
                        text = "Clear all",
                        textAlign = TextAlign.End,
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier
                            .weight(1f)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                selectedCities = emptySet()
                                selectedIndustries = emptySet()
                                selectedContactTypes = emptySet()
                            }
                    )
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )

                Column(
                    modifier = Modifier.padding(start = 16.dp).fillMaxSize().verticalScroll(
                        rememberScrollState()
                    )
                ) {
                    Spacer(modifier = Modifier.height(15.dp))

                    FilterSectionMobile(
                        title = stringResource(Res.string.city),
                        options = cities,
                        selectedOptions = selectedCities,
                        onOptionSelected = { city ->
                            selectedCities = toggleSelection(selectedCities, city)
                        }
                    )

                    Spacer(modifier = Modifier.height(10.dp))

                    FilterSectionMobile(
                        title = stringResource(Res.string.industry),
                        options = industries,
                        selectedOptions = selectedIndustries,
                        onOptionSelected = { role ->
                            selectedIndustries = toggleSelection(selectedIndustries, role)
                        }
                    )

                    Spacer(modifier = Modifier.height(10.dp))

                    FilterSectionMobile(
                        title = stringResource(Res.string.contact_type),
                        options = contactTypes,
                        selectedOptions = selectedContactTypes,
                        onOptionSelected = { team ->
                            selectedContactTypes = toggleSelection(selectedContactTypes, team)
                        }
                    )

                }
            }
        }
    }

}
