package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoginResponse(
    @SerialName("id") var id: Long? = 0,
    @SerialName("_id") var _id: String? = null,
    @SerialName("fullName") var fullName: String? = null,
    @SerialName("email") var email: String?  = null,
    @SerialName("confirmStatus") var confirmStatus: String? = null,
    @SerialName("hash") var hash: String? = null,
    @SerialName("type") var type: String? = null,
    @SerialName("createdAt") var createdAt: Long? = 0L,
    @SerialName("updatedAt") var updatedAt: Long? = 0L,
    @SerialName("token") var token: String,
    @SerialName("refreshToken") var refreshToken: String,
    @SerialName("role") var role: String?  = null,
    @SerialName("customerId") var customerId: String?  = null,
    @SerialName("planId") var planId: String?  = null,
    @SerialName("subscriptionId") var subscriptionId: String?  = null
)