package com.hyperether.goodjob.scenes.addNew

import androidx.compose.runtime.MutableState
import org.jetbrains.compose.resources.StringResource

data class DaySchedule(
    val dayName: StringResource,
    val isChecked: MutableState<Boolean>,
    val startTime: MutableState<String>,
    val endTime: MutableState<String>,
    val isStartTimePickerVisible: MutableState<Boolean>,
    val isEndTimePickerVisible: MutableState<Boolean>
)