package com.hyperether.goodjob.scenes.onboarding

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.Divider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.back
import com.hyperether.goodjob.resources.next
import com.hyperether.goodjob.resources.onboarding_description1
import com.hyperether.goodjob.resources.onboarding_description2
import com.hyperether.goodjob.resources.onboarding_image1
import com.hyperether.goodjob.resources.onboarding_image2
import com.hyperether.goodjob.resources.powerful_tools
import com.hyperether.goodjob.resources.skip
import com.hyperether.goodjob.resources.welcome
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.LightBlue
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun OnboardingScreen(navHostController: NavHostController, prefsManager: PrefsManager) {
    prefsManager.onBoardingShown(true)

    val state = rememberPagerState(
        initialPage = 0, initialPageOffsetFraction = 0f
    ) {
        2
    }

    val coroutineScope = rememberCoroutineScope()
    var isSecondScreen = remember { mutableStateOf(true) }

    LaunchedEffect(state.currentPage) {
        isSecondScreen.value = state.currentPage == 1
    }


    Box(
        modifier = Modifier
            .fillMaxSize()
            .wrapContentSize(Alignment.BottomCenter)
    ) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {
            if (!isSecondScreen.value) {
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(top = 20.dp, end = 16.dp),
                    contentAlignment = Alignment.TopEnd
                ) {
                    Text(
                        text = stringResource(Res.string.skip),
                        color = Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier.padding(end = 8.dp).clickable {
                            navHostController.navigate(Screen.Register.route) {
                                popUpTo(Screen.Onboarding.route) {
                                    inclusive = true
                                }
                            }
                        }
                    )

                }
            } else {
                Spacer(modifier = Modifier.height(20.dp))
            }
            HorizontalPager(state = state, modifier = Modifier.weight(1f)) { page ->
                when (page) {
                    0 -> {
                        val title = stringResource(Res.string.welcome)
                        val description = stringResource(Res.string.onboarding_description1)
                        val painter = painterResource(Res.drawable.onboarding_image1)
                        ViewPagerContent(
                            painter,
                            title,
                            description
                        )
                    }

                    1 -> {
                        val title = stringResource(Res.string.powerful_tools)
                        val description = stringResource(Res.string.onboarding_description2)
                        val painter = painterResource(Res.drawable.onboarding_image2)
                        ViewPagerContent(
                            painter,
                            title,
                            description
                        )
                    }
                }
            }
            Divider(
                color = LightBlue,
                thickness = 1.dp,
                modifier = Modifier
                    .fillMaxWidth().offset(y = (-50).dp)
            )
            Row(
                modifier = Modifier
                    .fillMaxWidth().offset(y = (-20).dp)
                    //.align(Alignment.BottomCenter)
                    .padding(start = 16.dp, end = 16.dp),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                if (isSecondScreen.value) {
                    Text(
                        text = stringResource(Res.string.back),
                        color = BlueApp,
                        fontSize = 16.sp,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier
                            .align(Alignment.CenterVertically)
                            .clickable {
                                coroutineScope.launch {
                                    if (state.currentPage > 0) {
                                        state.animateScrollToPage(state.currentPage - 1)
                                    }
                                }
                            }
                    )
                } else {
                    Spacer(modifier = Modifier.width(48.dp))
                }
                Row(
                    modifier = Modifier
                        .padding(top = 16.dp),
                    horizontalArrangement = Arrangement.spacedBy(8.dp)
                ) {
                    repeat(2) { index ->
                        Box(
                            modifier = Modifier
                                .offset(y = (-5).dp)
                                .size(8.dp)
                                .background(
                                    if (state.currentPage == index) BlueApp else Color.Gray,
                                    shape = CircleShape
                                )
                        )
                    }
                }
                Text(
                    text = stringResource(Res.string.next),
                    color = BlueApp,
                    fontSize = 16.sp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.align(Alignment.CenterVertically)
                        .clickable {
                            coroutineScope.launch {
                                if (state.currentPage == 1) {
                                    navHostController.navigate(Screen.Register.route) {
                                        popUpTo(Screen.Onboarding.route) {
                                            inclusive = true
                                        }
                                    }
                                } else {
                                    state.animateScrollToPage(state.currentPage + 1)
                                }
                            }
                        }
                )
            }
        }
    }
}

@Composable
fun ViewPagerContent(
    painter: Painter,
    title: String,
    description: String,
) {

    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp)
    ) {
        Column(
            modifier = Modifier
                .align(Alignment.TopCenter)
                .padding(top = 8.dp),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Text(
                text = title,
                color = Color.Black,
                fontSize = 28.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(bottom = 16.dp, top = 25.dp)
            )

            Image(
                painter = painter,
                contentDescription = "Sample Image",
                modifier = Modifier
                    .size(470.dp)
                    .padding(bottom = 16.dp, top = 20.dp)
            )

            Text(
                text = description,
                color = Color.Gray,
                textAlign = TextAlign.Center,
                fontSize = 16.sp,
                modifier = Modifier.padding(top = 20.dp)
            )
        }

    }
}