package com.hyperether.goodjob.scenes.components

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Text
import androidx.compose.material.TextField
import androidx.compose.material.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.KeyboardType
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.subscribe
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.LandingPageTextField
import org.jetbrains.compose.resources.stringResource

@Composable
fun RoundedTextFieldWithButton(
    isMobileView: Boolean = false,
    input: MutableState<String>,
    placeHolder: String,
    modifier: Modifier = Modifier,
    keyboardOptions: KeyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Text),
    enabled: Boolean = true,
    onSubscribe: () -> Unit
) {
    Box(
        modifier = modifier
            .height(Dp(48f))
            .fillMaxWidth()
            .clip(RoundedCornerShape(Dp(60f)))
            .background(Color.White),
        contentAlignment = Alignment.Center
    ) {
        Row(
            modifier = Modifier
                .fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            TextField(
                value = input.value,
                onValueChange = { newValue ->
                    val filteredValue =
                        newValue.filter { it.isLetterOrDigit() || it.isWhitespace() || it in "!@#$%^&*()-_=+[]{}|;:'\",.<>?/`~" }
                    input.value = filteredValue
                },
                placeholder = {
                    Text(
                        placeHolder,
                        color = LandingPageTextField,
                        fontSize = Sp(16f),
                        lineHeight = Sp(19f)
                    )
                },
                enabled = enabled,
                modifier = Modifier
                    .weight(2f)
                    .padding(horizontal = Dp(15f)),
                keyboardOptions = keyboardOptions,
                colors = TextFieldDefaults.textFieldColors(
                    backgroundColor = Color.Transparent,
                    focusedIndicatorColor = Color.Transparent,
                    unfocusedIndicatorColor = Color.Transparent
                ),
                singleLine = true
            )
            if (!isMobileView) {
                Button(
                    onClick = {
                        onSubscribe()
                    },
                    modifier = Modifier
                        .padding(horizontal = Dp(23f), vertical = Dp(11f))
                        .weight(1f)
                        .height(Dp(55f))
                        .width(Dp(142f))
                        .clip(RoundedCornerShape(Dp(50f))),
                    colors = ButtonDefaults.buttonColors(BlueApp),
                ) {
                    Text(
                        text = stringResource(Res.string.subscribe),
                        color = Color.White,
                        fontSize = Sp(16f),
                        fontWeight = FontWeight.W400,
                        modifier = Modifier
                            .align(Alignment.CenterVertically)
                    )
                }
            }
        }
    }
}