package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
class AddCardRequest(
    @SerialName("customerId") var customerId: String? = null,
    @SerialName("paymentMethodId") var paymentMethodId: String? = null,
    @SerialName("paymentIntentId") var paymentIntentId: String? = null,
    @SerialName("planId") var planId: String? = null,
)