package com.hyperether.goodjob.scenes.dashboard

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.util.Constants
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class DashboardViewModel(
    val repository: Repository,
) : ViewModel() {

    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val jobList = mutableStateListOf<com.hyperether.goodjob.database.Job>()
    val contacts = mutableStateMapOf<String, Contact>()

    private val _contact = MutableStateFlow<com.hyperether.goodjob.database.Contact?>(null)
    val contact: StateFlow<com.hyperether.goodjob.database.Contact?> = _contact

    init {
        viewModelScope.launch {
            getAllUsers()
            getAllContacts()
            // todo check if employee
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }

        CoroutineScope(Dispatchers.Default).launch {
            repository.getJobs()?.collect {
                jobList.clear()
                it.forEach { job ->
                    job.contactParentId?.let {
                        val contact = getContactForJob(it)
                        contact?._id?.let { id -> contacts[id] = contact }
                    }
                }
                jobList.addAll(it)
            }
        }
    }

    suspend fun getContactForJob(id: String): Contact? {
        return repository.getContactById(id)
    }

    suspend fun getAllUsers() {
        val result = repository.getAllEmployees()

        when (result) {
            is Resource.Success -> {
                println(result)
            }

            is Resource.Error -> {
                println(result)
            }
        }
    }

    private suspend fun getAllContacts() {
        val result = repository.getContactsByWorkspaceId()

        when (result) {
            is Resource.Success -> {
                println(result)
            }

            is Resource.Error -> {
                println(result)
            }
        }
    }

    fun isEmployee(): Boolean {
        return repository.getFlow() == Constants.FLOW_EMPLOYEE
    }
}