package com.hyperether.goodjob.util

import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateOf

object UploadManager {
    private val _documentStatus = mutableStateOf("")
    private val _fileName = mutableStateOf("")
    private val _fileId = mutableStateOf("")

    val documentStatus: State<String> get() = _documentStatus
    val fileName: State<String> get() = _fileName
    val fileId: State<String> get() = _fileId

    fun setFileName(name: String) {
        _fileName.value = name
    }

    fun setDocumentStatus(status: String) {
        _documentStatus.value = status
    }

    fun setFileId(id: String) {
        _fileId.value = id
    }
}