package com.hyperether.goodjob.scenes.components

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.AlertDialog
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.CircularProgressIndicator
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedButton
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Add
import androidx.compose.material3.Checkbox
import androidx.compose.material3.CheckboxDefaults
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.ic_back
import com.hyperether.goodjob.resources.icon
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.map_pin
import com.hyperether.goodjob.resources.monthly
import com.hyperether.goodjob.resources.plus
import com.hyperether.goodjob.resources.yearly
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.FilterOption
import com.hyperether.goodjob.theme.PricingBoxBg
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.theme.ToggleBg
import com.hyperether.goodjob.util.Constants
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun Loader(modifier: Modifier = Modifier) {
    // Implement your Loader composable here
    Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
        CircularProgressIndicator()
    }

}


@Composable
fun MobileHeaderSearchAdd(
    title: String,
    drawerState: DrawerState,
    scope: CoroutineScope,
    add: () -> Unit,
    search: () -> Unit,
    image: DrawableResource = Res.drawable.baseline_person_24,
    searchOnMap: Boolean = false,
    flow: String
) {
    var searchEnabled by remember { mutableStateOf(false) }

    Column {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth().padding(horizontal = 16.dp, vertical = 10.dp)
        ) {
            Box(
                modifier = Modifier.weight(1f)
            ) {
                Image(
                    painter = painterResource(image),
                    contentDescription = "Avatar",
                    modifier = Modifier.size(48.dp).clip(CircleShape).background(Color.LightGray)
                        .clickable {
                            scope.launch {
                                drawerState.open()
                            }
                        }
                )
            }

            Text18Bold(
                text = title, modifier = Modifier.weight(2f)
            )

            Row(
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.weight(1f)
            ) {
                if (searchEnabled) {
                    TextButton(onClick = {
                        searchEnabled = false
                    }) {
                        Text(stringResource(Res.string.cancel))
                    }
                } else {

                    IconButton(onClick = {
                        if (!searchOnMap) {
                            searchEnabled = true
                        }
                        search()
                    }, modifier = Modifier.padding(end = 20.dp)) {
                        Image(
                            painter = if (searchOnMap) {
                                painterResource(Res.drawable.map_pin)
                            } else {
                                painterResource(Res.drawable.icon)
                            },
                            contentDescription = "Search icon"
                        )
                    }

                    if(flow == Constants.FLOW_ADMIN || flow == Constants.FLOW_MANAGER) {
                        IconButton(onClick = { add() }) {
                            Image(
                                painter = painterResource(Res.drawable.plus),
                                contentDescription = "Add icon",
                                modifier = Modifier.padding()
                            )
                        }
                    }
                }
            }
        }
        Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
    }
}


@Composable
fun MobileHeaderBackAndAction(
    title: String,
    back: () -> Unit,
    actionEnabled: Boolean = false,
    content: @Composable() (() -> Unit)? = null
) {
    Column {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth().padding(vertical = 10.dp)
                .height(48.dp)
        ) {
            Box(
                modifier = Modifier.weight(1f)
            ) {
                IconButton(onClick = { back() }) {
                    Image(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "",
                        modifier = Modifier
                    )
                }

            }

            Text18Bold(
                text = title, modifier = Modifier.weight(2f)
            )

            Box(
                modifier = Modifier.weight(1f), contentAlignment = Alignment.CenterEnd
            ) {
                if (actionEnabled) {
                    content?.let { it() }
                }
            }
        }
        Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
    }
}

@Composable
fun SubscriptionToggle(
    isDark: Boolean,
    isMobile: Boolean,
    selectedOption: String,
    onOptionSelected: (String) -> Unit
) {
    val options = listOf(stringResource(Res.string.monthly), stringResource(Res.string.yearly))
    Box(
        modifier = Modifier
            .height(Dp(48f))
            .then(
                if (isMobile) Modifier.fillMaxWidth()
                else Modifier.width(Dp(330f))
            )
            .background(
                if (!isDark) ToggleBg else PricingBoxBg,
                shape = RoundedCornerShape(Dp(15f))
            )
    ) {
        Row(
            modifier = Modifier.padding(horizontal = Dp(5f), vertical = Dp(5f))
        ) {
            options.forEach { option ->
                val isSelected = selectedOption == option
                Box(
                    modifier = Modifier
                        .weight(1f)
                        .width(Dp(167f))
                        .height(Dp(38f))
                        .background(
                            if (isSelected) BlueApp else Color.Transparent,
                            shape = RoundedCornerShape(Dp(16f))
                        )
                        .clickable { onOptionSelected(option) },
                    contentAlignment = Alignment.Center
                ) {
                    Text(
                        text = option,
                        color = if (isSelected) Color.White else if (!isDark) Color.Black else Color.White,
                        fontSize = Sp(16f),
                        lineHeight = Sp(19f),
                        fontWeight = FontWeight.W400
                    )
                }
            }
        }
    }
}

@Composable
fun WebViewHeader(
    isBackAvailable: Boolean,
    onBackClick: (() -> Unit)? = null,
    title: String,
    isImportAndAddVisible: Boolean,
    navHostController: NavHostController
) {
    Row(
        modifier = Modifier
            .background(Color.White)
            .fillMaxWidth()
            .height(Dp(86f))
            .drawBehind {
                val strokeWidth = 1.dp.toPx()
                drawLine(
                    color = BorderColor,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(horizontal = Dp(75f), vertical = Dp(25f)),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        if (isBackAvailable) {
            Image(
                painter = painterResource(Res.drawable.ic_back),
                contentDescription = null,
                modifier = Modifier
                    .padding(end = 30.dp)
                    .clickable {
                        onBackClick?.invoke()
                    }
            )
        }
        Text(
            text = title,
            fontSize = Sp(32f),
            fontWeight = FontWeight.Bold,
            lineHeight = Sp(38f),
            modifier = Modifier.weight(1f)
        )
        if (isImportAndAddVisible)
            Row(
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = stringResource(Res.string.import_list),
                    fontSize = Sp(16f),
                    lineHeight = Sp(19f)
                )
                Spacer(modifier = Modifier.width(Dp(32f)))
                Button(
                    onClick = {
                        navHostController.navigate(Screen.AddNew.route)
                    },
                    colors = ButtonDefaults.buttonColors(BlueApp),
                    shape = RoundedCornerShape(Dp(32f))
                ) {
                    Row(
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Icon(
                            imageVector = Icons.Default.Add,
                            contentDescription = stringResource(Res.string.add),
                            tint = Color.White,
                            modifier = Modifier.size(Dp(16f))
                        )
                        Spacer(modifier = Modifier.width(Dp(15f)))
                        Text(
                            text = stringResource(Res.string.add),
                            color = Color.White,
                            fontSize = Sp(16f),
                            fontWeight = FontWeight.W400
                        )
                    }
                }
            }
    }
}

@Composable
fun PopUpDialog(
    onDismiss: () -> Unit,
    onNegativeBtnClicked:  (() -> Unit)? = null,
    onPositiveBtnClicked: () -> Unit,
    title: String,
    message: String,
    negativeBtnText: String? = null,
    positiveBtnText: String
) {
    Dialog(onDismissRequest = onDismiss) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .clip(RoundedCornerShape(16.dp)) // Rounded dialog corners
                .background(Color.White)
        ) {
            Column(
                modifier = Modifier.padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    text = title,
                    style = MaterialTheme.typography.h6.copy(fontWeight = FontWeight.Bold),
                    modifier = Modifier.align(Alignment.CenterHorizontally).padding(top = 20.dp)
                )
                Spacer(modifier = Modifier.height(20.dp))

                // Message content
                Text(
                    text = message,
                    style = MaterialTheme.typography.body1,
                    textAlign = TextAlign.Center,
                )
                Spacer(modifier = Modifier.height(24.dp))

                // Action buttons
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    if (negativeBtnText != null && onNegativeBtnClicked != null) {
                        OutlinedButton(
                            onClick = onNegativeBtnClicked, // Pass the lambda directly
                            modifier = Modifier
                                .width(108.dp)
                                .height(45.dp)
                                .padding(start = 10.dp, bottom = 10.dp),
                            shape = RoundedCornerShape(8.dp), // Rounded corners
                            border = BorderStroke(1.dp, Color.Gray)
                        ) {
                            Text(
                                text = negativeBtnText,
                                style = MaterialTheme.typography.button.copy(color = Color.Gray)
                            )
                        }
                        Spacer(modifier = Modifier.width(24.dp))
                    }
                    Spacer(modifier = Modifier.width(8.dp))
                    Button(
                        onClick = onPositiveBtnClicked,
                        modifier = Modifier
                            .width(108.dp)
                            .height(45.dp)
                            .padding(end = 10.dp, bottom = 10.dp),
                        shape = RoundedCornerShape(8.dp), // Rounded corners
                        colors = ButtonDefaults.buttonColors(
                            backgroundColor = BlueApp,
                            contentColor = Color.White // White text
                        )
                    ) {
                        Text(
                            text = positiveBtnText,
                            style = MaterialTheme.typography.button
                        )
                    }
                }
            }
        }
    }
}


@Composable
fun ErrorDialog(onDismiss: () -> Unit, title: String, message: String) {
    AlertDialog(
        onDismissRequest = { onDismiss() },
        shape = RoundedCornerShape(8.dp),
        title = {
            androidx.compose.material3.Text(
                text = title,
                fontSize = 22.sp,
                color = BlueApp,
                fontWeight = FontWeight.W600,
                lineHeight = 24.sp
            )
        },
        text = {
            androidx.compose.material3.Text(
                text = message,
                fontWeight = FontWeight.W400,
                fontSize = 16.sp,
                lineHeight = 20.sp
            )
        },
        confirmButton = {
            TextButton(
                modifier = Modifier.padding(end = 15.dp, bottom = 15.dp),
                onClick = { onDismiss() },
                shape = RoundedCornerShape(8.dp),
                colors = ButtonDefaults.buttonColors(
                    backgroundColor = BlueApp,
                    contentColor = Color.White
                )
            ) {
                androidx.compose.material3.Text(
                    "OK",
                    fontSize = 18.sp,
                    fontWeight = FontWeight.W500,
                    color = Color.White
                )
            }
        }
    )
}

@Composable
fun FilterSection(
    title: String,
    options: List<String>,
    selectedOptions: Set<String>,
    onOptionSelected: (String) -> Unit
) {
    Column(modifier = Modifier.fillMaxWidth().padding(start = 20.dp)) {
        androidx.compose.material3.Text(
            text = title,
            fontSize = 20.sp,
            fontWeight = FontWeight.Bold
        )
        options.forEach { option ->
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Checkbox(
                    checked = selectedOptions.contains(option),
                    onCheckedChange = { onOptionSelected(option) },
                    colors = CheckboxDefaults.colors(
                        checkedColor = BlueApp
                    )
                )
                androidx.compose.material3.Text(
                    text = option,
                    fontSize = 16.sp,
                    color = FilterOption
                )
            }
        }
    }
}

@Composable
fun FilterSectionMobile(
    title: String,
    options: List<String>,
    selectedOptions: Set<String>,
    onOptionSelected: (String) -> Unit
) {
    Column(modifier = Modifier.fillMaxWidth()) {
        androidx.compose.material3.Text(
            text = title,
            fontSize = 18.sp,
            color = TextColor,
            fontWeight = FontWeight.SemiBold,
        )
        options.forEach { option ->
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {

                androidx.compose.material3.Text(
                    text = option,
                    fontSize = 14.sp,
                    color = FilterOption
                )

                Checkbox(
                    checked = selectedOptions.contains(option),
                    onCheckedChange = { onOptionSelected(option) },
                    colors = CheckboxDefaults.colors(
                        checkedColor = BlueApp
                    )
                )
            }
        }
    }
}