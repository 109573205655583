package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.OutlinedButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_new_employee
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.choose_team
import com.hyperether.goodjob.resources.email
import com.hyperether.goodjob.resources.employee_details
import com.hyperether.goodjob.resources.enter_full_name
import com.hyperether.goodjob.resources.enter_your_email
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.ic_calendar
import com.hyperether.goodjob.resources.ic_leave
import com.hyperether.goodjob.resources.ic_mail
import com.hyperether.goodjob.resources.ic_map_pin
import com.hyperether.goodjob.resources.ic_skills
import com.hyperether.goodjob.resources.ic_user
import com.hyperether.goodjob.resources.ic_users
import com.hyperether.goodjob.resources.ic_work_scheduler
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.profile_pic_placeholder
import com.hyperether.goodjob.resources.select_dates
import com.hyperether.goodjob.resources.set_weekly_hours
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.start_typing_address
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.upcoming_leaves
import com.hyperether.goodjob.resources.upload_profile_picture
import com.hyperether.goodjob.resources.work_schedule
import com.hyperether.goodjob.theme.AddEmployeeInputColor
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import com.hyperether.goodjob.theme.EmployeeDetailsText
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewEmployeeWeb(
    viewModel: AddNewViewModel,
    navHostController: NavHostController,
    itemsTeams: List<Team>,
    itemsSkills: List<String>,
    showProgressBar: MutableState<Boolean>,
    showErrorDialog: MutableState<Boolean>
) {

    val focusManager = LocalFocusManager.current
    val focusRequester = FocusRequester()

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
            .verticalScroll(rememberScrollState())
    ) {
        Row(
            modifier = Modifier
                .background(Color.White)
                .fillMaxWidth()
                .drawBehind {
                    val strokeWidth = 1.dp.toPx()
                    drawLine(
                        color = BorderColor,
                        start = Offset(0f, size.height - strokeWidth / 2),
                        end = Offset(size.width, size.height - strokeWidth / 2),
                        strokeWidth = strokeWidth
                    )
                }
                .padding(start = 70.dp, end = 70.dp, top = 20.dp, bottom = 20.dp),
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Text(
                text = stringResource(Res.string.add_new_employee),
                fontSize = 32.sp,
                fontWeight = FontWeight.Bold,
                lineHeight = 35.sp,
            )
        }
        Spacer(modifier = Modifier.height(30.dp))

        // Form container
        Box(
            modifier = Modifier
                .padding(horizontal = 40.dp)
                .background(Color.White)
                .fillMaxWidth()
                .border(1.dp, BorderColor)
                .padding(30.dp)
        ) {
            Column {
                Text(
                    text = stringResource(Res.string.employee_details),
                    fontSize = 27.sp,
                    lineHeight = 30.sp,
                    color = Color.Black,
                    fontWeight = FontWeight.Bold
                )
                Spacer(modifier = Modifier.height(40.dp))
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    // Form inputs
                    Column(
                        modifier = Modifier.weight(1f),
                        verticalArrangement = Arrangement.spacedBy(16.dp)
                    ) {
                        LaunchedEffect(true) {
                            focusRequester.requestFocus()
                        }
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.ic_user,
                            labelText = stringResource(Res.string.full_name),
                            placeholderText = stringResource(Res.string.enter_full_name),
                            inputValue = viewModel.fullNameInput.value,
                            onValueChange = {
                                viewModel.fullNameInput.value = ""
                                viewModel.fullNameInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.ic_map_pin,
                            labelText = stringResource(Res.string.address),
                            placeholderText = stringResource(Res.string.start_typing_address),
                            inputValue = viewModel.addressInput.value,
                            onValueChange = {
                                viewModel.addressInput.value = ""
                                viewModel.addressInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomNumberInputRowEmployee(
                            painterResource = Res.drawable.phone,
                            labelText = stringResource(Res.string.phone),
                            placeholderText = "+1 555 123 4567",
                            inputValue = viewModel.phoneInput.value,
                            onValueChange = {
                                viewModel.phoneInput.value = ""
                                viewModel.phoneInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        CustomInputRowEmployee(
                            painterResource = Res.drawable.ic_mail,
                            labelText = stringResource(Res.string.email),
                            placeholderText = stringResource(Res.string.enter_your_email),
                            inputValue = viewModel.emailInput.value,
                            onValueChange = {
                                viewModel.emailInput.value = ""
                                viewModel.emailInput.value = it
                            },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        val chooseTeam = stringResource(Res.string.choose_team)
                        CustomDropdownMenu(
                            iconRes = Res.drawable.ic_users,
                            labelText = stringResource(Res.string.teams),
                            items = itemsTeams,
                            selectedValue = viewModel.selectedTeam.value ?: Team(),
                            onValueSelected = { viewModel.selectedTeam.value = it },
                            displayText = { team -> team.value ?: chooseTeam },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        val skills = stringResource(Res.string.skills)
                        CustomDropdownMenu(
                            iconRes = Res.drawable.ic_skills,
                            labelText = stringResource(Res.string.skills),
                            items = itemsSkills,
                            selectedValue = viewModel.selectedSkill.value ?: skills,
                            onValueSelected = { viewModel.selectedSkill.value = it },
                            displayText = { it },
                            focusManager = focusManager,
                            modifier = Modifier.focusRequester(focusRequester)
                        )
                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 8.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Image(
                                    painter = painterResource(Res.drawable.ic_work_scheduler),
                                    contentDescription = null,
                                )
                                Text(
                                    text = stringResource(Res.string.work_schedule),
                                    color = EmployeeDetailsText,
                                    fontSize = 14.sp,
                                    modifier = Modifier.padding(start = 20.dp)
                                )
                            }

                            Row(
                                modifier = Modifier
                                    .weight(2f)
                                    .padding(end = 16.dp),
                                verticalAlignment = Alignment.CenterVertically
                            ) {
                                Text(
                                    text = stringResource(Res.string.set_weekly_hours),
                                    color = BlueApp,
                                    fontSize = 14.sp,
                                    fontWeight = FontWeight.Bold,
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .clickable { /* TODO: */ }
                                )
                            }
                        }
                        var expanded = remember { mutableStateOf(false) }

                        Row(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 8.dp),
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Row(
                                verticalAlignment = Alignment.CenterVertically,
                                modifier = Modifier.weight(1f)
                            ) {
                                Image(
                                    painter = painterResource(Res.drawable.ic_leave),
                                    contentDescription = null
                                )
                                Text(
                                    text = stringResource(Res.string.upcoming_leaves),
                                    color = EmployeeDetailsText,
                                    fontSize = 14.sp,
                                    modifier = Modifier.padding(start = 20.dp)
                                )
                            }

                            Box(
                                modifier = Modifier
                                    .weight(2f)
                                    .border(
                                        width = 1.dp,
                                        color = AddEmployeeInputColor,
                                        shape = RoundedCornerShape(4.dp)
                                    )
                                    .clickable { expanded.value = true }
                            ) {
                                Text(
                                    text = stringResource(Res.string.select_dates),
                                    color = EmployeeDetailsText,
                                    fontSize = 14.sp,
                                    modifier = Modifier.padding(12.dp)
                                )
                                Icon(
                                    painter = painterResource(Res.drawable.ic_calendar),
                                    contentDescription = null,
                                    tint = Color(0xFF676767),
                                    modifier = Modifier
                                        .align(Alignment.CenterEnd)
                                        .padding(end = 20.dp)
                                )

                                // TODO: add calendar picker
                            }
                        }
                        Box(
                            modifier = Modifier
                                .fillMaxWidth()
                                .padding(vertical = 16.dp) // Add some space before buttons
                        ) {
                            Column(
                                modifier = Modifier.fillMaxWidth(),
                                verticalArrangement = Arrangement.spacedBy(16.dp) // Space between buttons
                            ) {
                                Button(
                                    onClick = {
                                        showProgressBar.value = true
                                        viewModel.addEmployee(
                                            onSuccess = {
                                                showProgressBar.value = false
                                                navHostController.popBackStack()
                                            },
                                            onError = {
                                                showProgressBar.value = false
                                                showErrorDialog.value = true
                                            })
                                    },
                                    modifier = Modifier
                                        .fillMaxWidth(),
                                    shape = RoundedCornerShape(56.dp),
                                    colors = ButtonDefaults.buttonColors(BlueApp),
                                ) {
                                    Text(
                                        modifier = Modifier.padding(vertical = 8.dp),
                                        text = stringResource(Res.string.add_new_employee),
                                        color = Color.White,
                                    )
                                }
                                androidx.compose.material.OutlinedButton(
                                    onClick = {
                                        navHostController.popBackStack()
                                    },
                                    border = BorderStroke(1.dp, EmployeeDetailsText),
                                    modifier = Modifier
                                        .fillMaxWidth(),
                                    shape = RoundedCornerShape(25.dp),
                                    colors = ButtonDefaults.outlinedButtonColors(
                                        backgroundColor = Color.White,
                                        contentColor = Color.Black
                                    ),
                                ) {
                                    Text(
                                        modifier = Modifier.padding(vertical = 8.dp),
                                        text = stringResource(Res.string.cancel),
                                        color = Color.Black,
                                    )
                                }
                            }
                        }
                    }

                    Spacer(modifier = Modifier.width(30.dp))
                    // Profile picture
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier
                    ) {
                        Box(
                            modifier = Modifier
                                .padding(10.dp),
                            contentAlignment = Alignment.Center
                        ) {
                            Image(
                                painterResource(Res.drawable.profile_pic_placeholder),
                                contentDescription = null
                            )
                        }
                        Spacer(modifier = Modifier.height(10.dp))
                        OutlinedButton(
                            border = BorderStroke(1.dp, BlueApp),
                            shape = RoundedCornerShape(4.dp),
                            onClick = {
                                // TODO
                            }) {
                            Text(
                                text = stringResource(Res.string.upload_profile_picture),
                                color = BlueApp,
                                fontSize = 14.sp
                            )
                        }
                    }
                }
            }

            Spacer(modifier = Modifier.height(20.dp))
        }
    }
}