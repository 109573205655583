package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ConfirmationResponse(
    @SerialName("data") val data: ConfirmationData?,
    @SerialName("token") val token: String?,
    @SerialName("refreshToken") val refreshToken: String?
)

@Serializable
data class ConfirmationData(
    @SerialName("_id") val id: String?,
    @SerialName("fullName") val fullName: String?,
    @SerialName("email") val email: String?,
    @SerialName("password") val password: String?,
    @SerialName("confirmStatus") val confirmStatus: String?,
    @SerialName("hash") val hash: String?,
    @SerialName("type") val type: String?,
    @SerialName("createdAt") val createdAt: Long?,
    @SerialName("updatedAt") val updatedAt: Long?,
    @SerialName("role") var role: String?  = null,
    @SerialName("customerId") val customerId: String?
)