package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.database.Job
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar

@Composable
fun CompletionReportEmployee(
    viewModel: JobViewModel,
    navHostController: NavHostController,
    job: Job,
    onUploadDocumentClick: () -> Unit
) {
    val images = remember { mutableStateListOf<String>() }
    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        CompletionReportViewEmployee(viewModel,
            submit = {
                viewModel.showLoader.value = true
                job._id?.let {
                    //todo submit report
                    viewModel.finishJob(it, onSuccess = {
                        viewModel.showLoader.value = false
                        viewModel.showFinishedJobSuccess.value = true
                    }, onError = {
                        viewModel.showLoader.value = false
                        viewModel.showPopUpError.value = true
                    })
                }
            },
            images,
            onUploadDocumentClick = onUploadDocumentClick
            )
        if (viewModel.showFinishedJobSuccess.value) {
            PopUpDialog(
                onDismiss = {
                    viewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                },
                title = "SUCCESS",
                message = "Job finished successfully",
                onPositiveBtnClicked = {
                    viewModel.showPopUpSuccess.value = false
                    navHostController.popBackStack()
                },
                positiveBtnText = "OK"
            )
        }
        if (viewModel.showPopUpError.value) {
            ErrorDialog(
                onDismiss = { viewModel.showPopUpError.value = false },
                title = "ERROR",
                message = viewModel.errorText.value
            )
        }
        if (viewModel.showLoader.value) {
            FullScreenProgressBar()
        }
    }
}