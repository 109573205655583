package com.hyperether.goodjob.map


import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.layout.onGloballyPositioned
import androidx.compose.ui.layout.positionInRoot
import androidx.compose.ui.unit.IntSize
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement

@Composable
actual fun MapComponent(markers: List<Marker>) {
    var position by remember { mutableStateOf(Offset(0f, 0f)) }
    var size by remember { mutableStateOf(IntSize(0, 0)) }

    Box(
        modifier = Modifier
            .fillMaxSize()// Set the size of the composable
            .onGloballyPositioned { layoutCoordinates ->
                // Get the position of the composable on the screen
                position = layoutCoordinates.positionInRoot()
                size = layoutCoordinates.size
            }
    ) {

    }

    DisposableEffect(Unit) {
        onDispose {
            setPosition(0, 0, 0f, 0f)
        }
    }

    if (position.x != 0f && size.width != 0) {
        println("positioning $position with size $size")
        setPosition(size.width, size.height, position.x, position.y)
//        markers.forEach {
//            addMarker(it.lat, it.lng, it.title)
//        }
    }

}

private fun setPosition(w: Int, h: Int, posX: Float, posY: Float) {
    val mapDiv: HTMLDivElement = document.getElementById("map") as HTMLDivElement
    val devicePixelRatio = window.devicePixelRatio

    mapDiv.style.width = "${(w / devicePixelRatio).toInt()}px"
    mapDiv.style.height = "${(h / devicePixelRatio).toInt()}px"
    mapDiv.style.position = "absolute"
    mapDiv.style.top = "${(posY / devicePixelRatio).toInt()}px"
    mapDiv.style.left = "${(posX / devicePixelRatio).toInt()}px"
}

@JsName("addMarker")
external fun addMarker(lat: Double, lng: Double, title: String)