package com.hyperether.planner.ui

import androidx.compose.foundation.Canvas
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.gestures.detectDragGestures
import androidx.compose.foundation.horizontalScroll
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.shadow
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.input.pointer.pointerInput
import androidx.compose.ui.text.rememberTextMeasurer
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.planner.model.Event
import com.hyperether.planner.model.EventStatus
import com.hyperether.planner.model.Plan
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import kotlinx.datetime.isoDayNumber

@Composable
fun WeekPlanner(
    listOfPlans: MutableList<Plan>,
    completedColor: Color = Color(172, 229, 185),
    pendingColor: Color = Color(235, 231, 116),
    delayedColor: Color = Color(229, 172, 172),
    celWidth: Int = 150,
    lastDay: Int = 8,
    spacer: Int = 5
) {
    var celWidthDp by remember { mutableStateOf(celWidth) }
    val tableWidth by remember { mutableStateOf((7 * celWidthDp).dp) }

    if (listOfPlans.size < 10) {
        while (listOfPlans.size < 11)
            listOfPlans.addAll(listOf(Plan("", null)))
    }

    val horizontalScrollState = rememberScrollState()
    val verticalScrollState = rememberScrollState()

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .pointerInput(Unit) {

                detectDragGestures { change, dragAmount ->
                    change.consume()
                    CoroutineScope(Dispatchers.Default).launch {
                        println("Y drag = ${dragAmount.y.toInt()}")
                        println("Y state = ${verticalScrollState.value}")
                        println("X drag = ${dragAmount.x.toInt()}")
                        println("X state = ${horizontalScrollState.value}")

                        verticalScrollState.scrollTo(verticalScrollState.value - dragAmount.y.toInt())

                        horizontalScrollState.scrollTo(horizontalScrollState.value - dragAmount.x.toInt())
                    }
                }
            }
            .height(450.dp)
            .background(Color.White)
            .border(1.dp, color = Color.Black),
        contentAlignment = Alignment.TopStart
    ) {

        Row {
            //Names
            Column(
                modifier = Modifier.verticalScroll(verticalScrollState)
                    .background(Color.Transparent)
            ) {
                Box(
                    modifier = Modifier.size(height = 50.dp, width = 10.dp)
                        .background(Color.Transparent)
                )
                repeat(listOfPlans.size) { index ->
                    Box(
                        modifier = Modifier.height(50.dp).width(100.dp)
                            .background(Color.Transparent), contentAlignment = Alignment.Center
                    ) {
                        Text(listOfPlans[index].person, textAlign = TextAlign.Center)
                    }
                }
            }

            Column(
                modifier = Modifier
                    .background(Color.Transparent)
            ) {

                //days
                Row(
                    modifier = Modifier.border(1.dp, color = Color.Black)
                        .horizontalScroll(horizontalScrollState)
                ) {
                    repeat(8) { index ->
                        Text(
                            "month/${index + lastDay - 7}",
                            modifier = Modifier.size(width = celWidthDp.dp, height = 50.dp)
                                .border(1.dp, color = Color.Black),
                            textAlign = TextAlign.Center
                        )
                    }
                }

                //content
                Column(
                    modifier = Modifier
                        .horizontalScroll(horizontalScrollState)
                        .verticalScroll(verticalScrollState)
                ) {
                    val textMeasurer = rememberTextMeasurer()
                    repeat(listOfPlans.size) { rowIndex ->
                        Canvas(modifier = Modifier.size(width = tableWidth, height = 50.dp)) {
                            val pathEffect =
                                PathEffect.dashPathEffect(floatArrayOf(5f, 5f), phase = 0f)

                            //lines
                            for (i in 0..16) {
                                drawLine(
                                    Color.Black,
                                    start = Offset(celWidthDp.dp.toPx() * i, 0f),
                                    end = Offset(celWidthDp.dp.toPx() * i, 50.dp.toPx()),
                                    pathEffect = pathEffect
                                )
                            }

                            val mapOfEvents = mutableMapOf<Int, MutableList<Event>>()

                            listOfPlans[rowIndex].events?.forEach {
                                if (mapOfEvents[it.from.dayOfWeek.isoDayNumber] == null) {
                                    mapOfEvents[it.from.dayOfWeek.isoDayNumber] = mutableListOf()
                                }

                                mapOfEvents[it.from.dayOfWeek.isoDayNumber]?.add(it)
                            }

                            //plans
                            mapOfEvents.forEach {
                                val numOfEvents = it.value.size
                                var rectSpacingAdd = if (numOfEvents == 1) {
                                    2 * spacer.dp.toPx()
                                } else {
                                    2 * spacer.dp.toPx() + spacer.dp.toPx() / numOfEvents
                                }
                                val rectSize =
                                    celWidth.dp.toPx() / numOfEvents - (numOfEvents + 1) * (spacer.dp.toPx() / numOfEvents)

//                                for(blok 1…brojblokova)
//                                offset = spacing + (spaicn + blok_size) * (blok -1)
//                                blocksize = (total - (spaacing *( bloknum + 1)) ) / bloknum

                                val startX = tableWidth.toPx() / 7 * it.key + spacer.dp.toPx()
                                var topLeft = Offset(startX, 0.dp.toPx())
                                it.value.forEachIndexed() { index, event ->
                                    val color = when (event.status) {
                                        EventStatus.COMPLETED -> completedColor
                                        EventStatus.PENDING -> pendingColor
                                        EventStatus.DELAYED -> delayedColor
                                    }

                                    drawRoundRect(
                                        color = color,
                                        topLeft = topLeft,
                                        size = Size(rectSize, 50.dp.toPx()),
                                        cornerRadius = CornerRadius(10f, 10f)
                                    )

                                    topLeft =
                                        Offset(topLeft.x + rectSize + spacer.dp.toPx(), 0.dp.toPx())

                                }


//                                val minX1 = it.from.hour * 60 + it.from.minute
//                                val x1 =
//                                    (tableWidth * (minX1 - (startHour * 60))) / (1440 - (startHour * 60))
//
//                                val minX2 = it.to.hour * 60 + it.to.minute
//                                val x2 =
//                                    (tableWidth * (minX2 - (startHour * 60))) / (1440 - (startHour * 60))
//
//                                val topLeft = Offset(x1.toPx(), 0.dp.toPx())
//                                val size = Size(width = (x2 - x1).toPx(), height = 50.dp.toPx())
//
//                                drawRoundRect(
//                                    color = color,
//                                    topLeft = topLeft,
//                                    size = size,
//                                    cornerRadius = CornerRadius(10f, 10f)
//                                )
//                                val measuredText =
//                                    textMeasurer.measure(
//                                        AnnotatedString(it.title ?: ""),
//                                        constraints = Constraints.fixedWidth(size.width.toInt()),
//                                        overflow = TextOverflow.Ellipsis,
//                                        style = TextStyle(
//                                            fontSize = 18.sp,
//                                            color = Color.Black,
//                                            textAlign = TextAlign.Center
//                                        ),
//                                        maxLines = 1
//                                    )
//                                val textOffsetX =
//                                    topLeft.x + (size.width - measuredText.size.width) / 2
//                                val textOffsetY =
//                                    topLeft.y + 50.dp.toPx() / 2 - measuredText.size.height / 2
//                                drawText(
//                                    measuredText,
//                                    topLeft = Offset(
//                                        textOffsetX,
//                                        textOffsetY
//                                    )
//                                )
                            }
                        }
                    }
                }

            }
        }


        //TODO make font size screen depended
        Box(
            modifier = Modifier.fillMaxWidth().height(450.dp)
                .padding(horizontal = 20.dp, vertical = 12.dp),
            contentAlignment = Alignment.BottomCenter
        ) {
            Row(
                modifier = Modifier.shadow(elevation = 2.dp, shape = RoundedCornerShape(10.dp))
                    .clip(RoundedCornerShape(10.dp))
                    .background(Color.White).padding(20.dp),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Box(modifier = Modifier.size(20.dp).clip(CircleShape).background(completedColor))
                Text(
                    "Completed",
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(horizontal = 12.dp),
                    fontSize = 12.sp
                )

                Box(modifier = Modifier.size(20.dp).clip(CircleShape).background(pendingColor))
                Text(
                    "Pending",
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(horizontal = 12.dp),
                    fontSize = 12.sp
                )

                Box(modifier = Modifier.size(20.dp).clip(CircleShape).background(delayedColor))
                Text(
                    "Delayed",
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(horizontal = 12.dp),
                    fontSize = 12.sp
                )
            }
        }
    }
}