package com.hyperether.goodjob.repository.prefs

import kotlinx.browser.localStorage
import org.w3c.dom.get

actual class Preferences actual constructor() : Prefs {
    actual override fun putString(key: String, value: String) {
        localStorage.setItem(key, value)
    }

    actual override fun getString(key: String, defaultValue: String): String {
        return localStorage.get(key) ?: defaultValue
    }

    actual override fun putInt(key: String, value: Int) {
        localStorage.setItem(key, value.toString())
    }

    actual override fun getInt(key: String, defaultValue: Int): Int {
        return localStorage.getItem(key)?.toIntOrNull() ?: defaultValue
    }

    actual override fun putBool(key: String, value: Boolean) {
        localStorage.setItem(key, value.toString())
    }

    actual override fun getBool(key: String, defaultValue: Boolean): Boolean {
        return localStorage.getItem(key)?.toBooleanStrictOrNull() ?: defaultValue
    }

    actual override fun putLong(key: String, value: Long) {
        localStorage.setItem(key, value.toString())
    }

    actual override fun getLong(key: String, defaultValue: Long): Long {
        return localStorage.getItem(key)?.toLongOrNull() ?: defaultValue
    }

    actual override fun removeAll() {
        //todo implement
        localStorage.clear()
    }
}