package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_contact
import com.hyperether.goodjob.resources.add_new_job
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_site
import com.hyperether.goodjob.resources.next
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource


@Composable
fun AboutContact(viewModel: AddNewViewModel, navHostController: NavHostController) {

    val contacts = remember { mutableStateListOf<com.hyperether.goodjob.database.Contact>() }
    val contactSites = viewModel.contactSites.value

    LaunchedEffect(Unit) {
        viewModel.contacts.collect { employeeList ->
            contacts.clear()
            contacts.addAll(employeeList)
        }
    }
    println(contacts.toList())

    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(Color.White)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
        ) {
            Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
                MobileHeaderBackAndAction(
                    title = stringResource(Res.string.add_new_job),
                    back = {
                        navHostController.popBackStack()
                        viewModel.clearContactValues()
                    }
                )

                Spacer(modifier = Modifier.height(15.dp))

                Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.about_contact),
                        color = Color.Black,
                        fontSize = 24.sp,
                        fontWeight = FontWeight.Bold,
                        modifier = Modifier.padding(top = 10.dp)
                    )
                    AboutContactDropdown(
                        viewModel.contactName,
                        stringResource(Res.string.contact_name),
                        placeholder = stringResource(Res.string.contact_name),
                        dropdownItems = contacts,
                        painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                        onContactSelected = { contactId ->
                            viewModel.contactParentId.value = contactId
                            viewModel.getSitesUnderContact(SiteRequest(contactId))
                        }
                    )
                    AboutSiteDropdown(
                        viewModel.contactSite,
                        stringResource(Res.string.contact_site),
                        placeholder = stringResource(Res.string.site),
                        dropdownItems = contactSites,
                        painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                        onSiteSelected = { site ->
                            viewModel.selectedSiteName.value = site.siteName.toString()
                            viewModel.contactId.value = site.id.toString()
                        }
                    )
                    Spacer(modifier = Modifier.height(40.dp))
                    androidx.compose.material.OutlinedButton(
                        onClick = {
                            navHostController.navigate(Screen.AddNewJob.route)
                            viewModel.showPopUpSuccess.value = false
                        },
                        modifier = Modifier.fillMaxWidth().height(50.dp),
                        border = BorderStroke(1.dp, BlueApp),
                        shape = RoundedCornerShape(50),
                        colors = androidx.compose.material.ButtonDefaults.outlinedButtonColors(
                            contentColor = Color.White,
                            backgroundColor = Color.Transparent
                        ),
                    ) {
                        Text(
                            text = stringResource(Res.string.next),
                            maxLines = 1,
                            fontSize = 12.5.sp,
                            color = BlueApp,
                            letterSpacing = (-0.02).sp
                        )
                    }
                }
            }

        }
    }
}