package com.hyperether.goodjob.scenes.components

import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.sp

@Composable
fun Text18Bold(text: String, modifier: Modifier = Modifier, align: TextAlign = TextAlign.Center) {
    Text(
        text = text,
        fontSize = 18.sp,
        color = Color.Black,
        textAlign = align,
        modifier = modifier
    )
}