package com.hyperether.goodjob.scenes.dashboard

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.planListDashboard
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.chevron_left
import com.hyperether.goodjob.resources.chevron_right
import com.hyperether.goodjob.resources.dashboard
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.jobs_overview
import com.hyperether.goodjob.resources.today
import com.hyperether.goodjob.resources.today_schedule
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.jobs.JobsList
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.planner.ui.DashboardDayPlanner
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun DashboardScreen(
    isMobileView: Boolean,
    navHostController: NavHostController,
    dashboardViewModel: DashboardViewModel,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {
    var isJobsOverviewSelected = remember { mutableStateOf(true) }

    val selectedJobs = remember { mutableStateListOf<com.hyperether.goodjob.database.Job>() }

    if (isMobileView) {
        Column {

            MobileHeaderSearchAdd(
                title = stringResource(Res.string.dashboard),
                add = { navHostController.navigate(Screen.AddNew.route) },
                search = {},
                searchOnMap = true,
                scope = rememberCoroutineScope(),
                drawerState = drawerState,
                flow = prefsManager.getFlow()
            )

            Column(
                modifier = Modifier
                    .background(Color.LightGray.copy(alpha = 0.2f))
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(40.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.today_schedule),
                        color = if (isJobsOverviewSelected.value) Color.Gray else BlueApp,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(start = 30.dp)
                            .clickable {
                                isJobsOverviewSelected.value = false
                            }
                    )

                    Spacer(modifier = Modifier.weight(0.5f))

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.jobs_overview),
                        color = if (isJobsOverviewSelected.value) BlueApp else Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(end = 30.dp)
                            .clickable {
                                isJobsOverviewSelected.value = true
                            }
                    )
                }
            }
            Box(
                modifier = Modifier
                    .height(2.dp)
                    .fillMaxWidth(0.5f)
                    .background(if (isJobsOverviewSelected.value) BlueApp else BlueApp)
                    .align(if (isJobsOverviewSelected.value) Alignment.End else Alignment.Start)
            )

            if (isJobsOverviewSelected.value) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp, vertical = 16.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = stringResource(Res.string.edit),
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                    val leadingText = if (dashboardViewModel.jobList.size == 1) "job" else "jobs"
                    Text(
                        text = "Total: ${dashboardViewModel.jobList.size} $leadingText",
                        fontSize = 14.sp,
                        color = Color.Gray,
                        modifier = Modifier.weight(1f),
                        textAlign = TextAlign.Center
                    )
                    Row(verticalAlignment = Alignment.CenterVertically) {
                        Text(
                            text = stringResource(Res.string.filter),
                            fontSize = 14.sp,
                            color = Color.Gray
                        )
                        Icon(
                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                            contentDescription = "",
                            modifier = Modifier
                                .padding(start = 8.dp)
                                .size(20.dp)
                        )
                    }

                }
            }
            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
            if (isJobsOverviewSelected.value) {
                JobsList(
                    dashboardViewModel.contacts,
                    dashboardViewModel.jobList,
                    selectedJobs,
                    isInEditMode = dashboardViewModel.isInEditMode.value,
                    navHostController
                )
            } else {
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Center
                ) {
                    IconButton(onClick = {}) {
                        Icon(
                            painter = painterResource(Res.drawable.chevron_left),
                            contentDescription = "Left Icon"
                        )
                    }
                    Text(
                        text = stringResource(Res.string.today),
                        modifier = Modifier.padding(horizontal = 8.dp),
                        textAlign = TextAlign.Center,
                        style = TextStyle(fontSize = 16.sp, color = Color.DarkGray)
                    )
                    IconButton(onClick = {}) {
                        Icon(
                            painter = painterResource(Res.drawable.chevron_right),
                            contentDescription = "Right Icon"
                        )
                    }
                }
                DashboardDayPlanner(listOfPlans = planListDashboard.toMutableList())
                //SchedulerDayPlanner(listOfPlans = planList.toMutableList())
            }
        }
    } else {
        DashboardScreenWebView(navHostController)
    }

}