package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.daily_activities
import com.hyperether.goodjob.resources.details
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.error_message
import com.hyperether.goodjob.scenes.components.DetailsCards
import com.hyperether.goodjob.scenes.components.EditToggleButton
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DashboardBg
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun EmployeeDetailsWeb(
    employeeId: String,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    navController: NavHostController
) {
    val log = logging("EmployeeDetails")
    val selectedEmployee = remember { mutableStateOf(User()) }
    val showProgressBar = remember { mutableStateOf(false) }
    val showErrorDialog = remember { mutableStateOf(false) }

    val focusManager = LocalFocusManager.current
    val focusRequester = FocusRequester()

    val selectedCard = remember { mutableStateOf("Details") }
    val isEditView = remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        employeeDetailsViewModel.getEmployeeById(employeeId)
        employeeDetailsViewModel.selected_employee_remote.collect {
            log.d("EmployeeDetails") { "selected employee: ${it._id}" }
            selectedEmployee.value = it
            employeeDetailsViewModel.fullNameInput.value = it.fullName ?: ""
            employeeDetailsViewModel.addressInput.value = it.address ?: ""
            employeeDetailsViewModel.phoneInput.value = it.phone ?: ""
            employeeDetailsViewModel.emailInput.value = it.email ?: ""
            employeeDetailsViewModel.team.value = it.teams?.get(0)?.value ?: ""
        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
            .verticalScroll(rememberScrollState())
    ) {
        WebViewHeader(
            isBackAvailable = true,
            title = selectedEmployee.value.fullName ?: "",
            onBackClick = { navController.popBackStack() },
            isImportAndAddVisible = true,
            navHostController = navController
        )
        Spacer(modifier = Modifier.height(Dp(20f)))

        // Form container
        Row(
            modifier = Modifier
                .padding(horizontal = Dp(74f))
                .background(Color.White)
                .fillMaxSize()
                .border(Dp(1f), BorderColor)
        ) {
            DetailsCards(
                titles = listOf(
                    stringResource(Res.string.details),
                    stringResource(Res.string.daily_activities)
                ),
                modifier = Modifier.width(Dp(200f)),
                selectedCard = selectedCard
            )
            Column(
                modifier = Modifier
                    .fillMaxHeight()
                    .weight(5f)
                    .drawBehind {
                        drawLine(
                            color = BorderColor,
                            start = Offset(0f, 0f),
                            end = Offset(0f, size.height),
                            strokeWidth = 1f
                        )
                    }
                    .padding(horizontal = 40.dp, vertical = 40.dp)

            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    Text(
                        text = stringResource(Res.string.details),
                        fontSize = Sp(27f),
                        lineHeight = Sp(30f),
                        color = Color.Black,
                        fontWeight = FontWeight.W700
                    )
                    EditToggleButton(
                        isEditView = isEditView
                    )

                }
                Spacer(modifier = Modifier.height(Dp(16f)))
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    if (isEditView.value) {
                        EmployeeEditView(
                            navHostController = navController,
                            isEditView = isEditView,
                            modifier = Modifier.weight(1f),
                            focusRequester = focusRequester,
                            focusManager = focusManager,
                            fullNameInput = employeeDetailsViewModel.fullNameInput,
                            addressInput = employeeDetailsViewModel.addressInput,
                            phoneInput = employeeDetailsViewModel.phoneInput,
                            emailInput = employeeDetailsViewModel.emailInput,
                            selectedTeam = employeeDetailsViewModel.selectedTeam,
                            selectedSkill = employeeDetailsViewModel.selectedSkill,
                            updateEmployee = {
                                employeeDetailsViewModel.updateEmployeeById(
                                    employeeId,
                                    onSuccess = {
                                        navController.popBackStack()
                                    },
                                    onError = {
                                        // TODO
                                    })
                            }
                        )
                    } else {
                        EmployeeDetailsWebView(
                            focusRequester = focusRequester,
                            employeeDetailsViewModel = employeeDetailsViewModel,
                            modifier = Modifier.weight(1f)
                        )
                    }

                    Spacer(modifier = Modifier.width(30.dp))
                    ProfilePictureSection(
                        isEditView,
                        btnText = "Upload new image",
                    ) {
                        // TODO: upload new image
                    }
                }
            }
            Spacer(modifier = Modifier.height(20.dp))
        }
    }
    if (showErrorDialog.value) {
        val error = stringResource(Res.string.error)
        val message = stringResource(Res.string.error_message)
        ErrorDialog(
            onDismiss = {
                showErrorDialog.value = false
            },
            title = error,
            message = message
        )
    }
    if (showProgressBar.value) {
        FullScreenProgressBar()
    }
}