package com.hyperether.goodjob

import kotlinx.browser.window
import org.lighthousegames.logging.logging


class PlatformCheckerJs: PlatformChecker {

    val log = logging("PlatformCheckerJS")

    override fun isMobile(): Boolean {
        val userAgent = js("navigator.userAgent") as String
        return userAgent.contains("Mobi") || userAgent.contains("Android") || window.innerWidth <= 768
    }

    override fun isMobileWebView(): Boolean {
        val isMobileWebView = window.innerWidth <= 980
//        log.d("isWebMobileView: ") { "isMobileWebView: $isMobileWebView window.innerWidth: ${window.innerWidth}" }
        return isMobileWebView
    }
}

actual fun getPlatformChecker(): PlatformChecker {
    return PlatformCheckerJs()
}