package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.ArrowDropDown
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.OutlinedTextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Team
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.active
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_employee
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.admin
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.country
import com.hyperether.goodjob.resources.date_error
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.employee
import com.hyperether.goodjob.resources.employee_added_successfully
import com.hyperether.goodjob.resources.enter_employee_email
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.enter_surname
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.error_message
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.inactive
import com.hyperether.goodjob.resources.manager
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.photo_upload_area
import com.hyperether.goodjob.resources.role
import com.hyperether.goodjob.resources.select_skill
import com.hyperether.goodjob.resources.select_team
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.resources.tap_to_upload_photo
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.resources.working_hours
import com.hyperether.goodjob.resources.zip_employee
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeInputField
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeMultiSelectField
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewEmployee(
    isMobileView: Boolean,
    navHostController: NavHostController,
    viewModel: AddNewViewModel
) {
    val showProgressBar = remember { mutableStateOf(false) }
    val showErrorDialog = remember { mutableStateOf(false) }

    val selectedItemsState = remember { mutableStateOf(viewModel.selectedSkillsNames.value) }
    val dropdownItemsState = remember { mutableStateOf(viewModel.availableSkillsNames.value) }

    LaunchedEffect(viewModel.selectedSkillsNames.value, viewModel.availableSkillsNames.value) {
        selectedItemsState.value = viewModel.selectedSkillsNames.value
        dropdownItemsState.value = viewModel.availableSkillsNames.value
    }

    // TODO: get Teams
    val itemsTeams = listOf(
        Team(id = "1", value = "Sales Team"),
        Team(id = "2", value = "Support Team"),
        Team(id = "3", value = "Technical Team")
    )
    val itemsSkills = listOf("Skill1", "Skill2")

    val isEditView = remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        viewModel.getAllSkillsRemote()
    }

    if (isMobileView) {
        Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
            MobileHeaderBackAndAction(
                title = stringResource(Res.string.add_new_employee),
                back = {
                    viewModel.clearUserValues()
                    navHostController.popBackStack()
                }
            )

            Spacer(modifier = Modifier.height(15.dp))

            Image(
                painter = painterResource(Res.drawable.photo_upload_area),
                contentDescription = "Avatar",
                modifier = Modifier
                    .size(80.dp)
                    .clip(CircleShape)
                    .background(BlueApp)
                    .align(Alignment.CenterHorizontally)
            )

            Text(
                text = stringResource(Res.string.tap_to_upload_photo),
                fontSize = 14.sp,
                color = TextColor,
                textAlign = TextAlign.Center,
                fontStyle = FontStyle.Italic,
                modifier = Modifier.align(Alignment.CenterHorizontally)
            )

            Column(modifier = Modifier.padding(horizontal = 16.dp)) {
                val roles = listOf(stringResource(Res.string.admin),
                    stringResource(Res.string.manager), stringResource(Res.string.employee))
                AddEmployeeInputField(
                    viewModel.roleSelected,
                    stringResource(Res.string.role),
                    placeholder = "",
                    dropdownItems = roles,
                    keyboardInputEnabled = false
                )

                AppInputField(
                    viewModel.fullNameInput,
                    stringResource(Res.string.full_name),
                    placeholder = stringResource(Res.string.enter_surname)
                )
                PhoneInputField(
                    viewModel.phoneInput,
                    stringResource(Res.string.phone),
                    placeholder = "+134232525235"
                )
                AppInputField(
                    viewModel.emailInput,
                    stringResource(Res.string.email_address),
                    placeholder = stringResource(Res.string.enter_employee_email)
                )
                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    AppInputField(
                        viewModel.cityInput,
                        stringResource(Res.string.city),
                        placeholder = stringResource(Res.string.city),
                        modifier = Modifier.weight(1f)
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    AppInputField(
                        viewModel.countryInput,
                        stringResource(Res.string.country),
                        placeholder = stringResource(Res.string.country),
                        modifier = Modifier.weight(1f)
                    )
                }
                Row(
                    modifier = Modifier
                        .fillMaxWidth(),
                    horizontalArrangement = Arrangement.SpaceBetween
                ) {
                    AppInputField(
                        viewModel.addressInput,
                        stringResource(Res.string.address),
                        placeholder = stringResource(Res.string.enter_street),
                        modifier = Modifier.weight(3f)
                    )
                    Spacer(modifier = Modifier.width(8.dp))
                    AppInputField(
                        viewModel.zipInput,
                        stringResource(Res.string.zip_employee),
                        placeholder = stringResource(Res.string.zip_employee),
                        modifier = Modifier.weight(1f)
                    )
                }

                val items = listOf(
                    stringResource(Res.string.active),
                    stringResource(Res.string.inactive)
                )
                AddEmployeeInputField(
                    viewModel.status,
                    stringResource(Res.string.status),
                    placeholder = "",
                    dropdownItems = items,
                    keyboardInputEnabled = false
                )

                AddEmployeeMultiSelectField(
                    selectedItems = selectedItemsState,
                    stringResource(Res.string.skills),
                    placeholder = stringResource(Res.string.select_skill),
                    dropdownItems = dropdownItemsState,
                    onAddSkill = { name -> viewModel.addNewSkill(name) }
                )

                AddEmployeeTeamField(
                    text = viewModel.team,
                    label = stringResource(Res.string.team),
                    placeholder = stringResource(Res.string.select_team),
                    dropdownItems = itemsTeams,
                    onTeamSelected = { viewModel.selectedTeam.value = it }
                )

                OutlinedCalendarInput(
                    onClick = {
                        viewModel.isCalendar2Visible.value = true
                    },
                    viewModel.upcomingLeaves
                )

                Text(
                    text = stringResource(Res.string.working_hours),
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier.padding(top = 20.dp)
                )

                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(400.dp)
                ) {
                    WorkingHoursView(viewModel)
                }
            }


            FilledButton(
                onClick = {
                    viewModel.showLoader.value = true
                    viewModel.addEmployee(
                        onSuccess = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpSuccess.value = true
                            
                        },
                        onError = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpError.value = true
                        })
                },
                modifier = Modifier.padding(vertical = 30.dp, horizontal = 16.dp),
                text = stringResource(Res.string.add),
                isEnabled = true
            )
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.employee_added_successfully),
                    onPositiveBtnClicked = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    positiveBtnText = stringResource(Res.string.ok).uppercase()
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
            if (viewModel.isCalendar2Visible.value) {
                val error = stringResource(Res.string.date_error)
                CustomCalendarView(
                    isAddEmployee = true,
                    onDismiss = { viewModel.isCalendar2Visible.value = false },
                    onDateSelect = { selectedDates ->
                        if (selectedDates.isNotEmpty()) {
                            val sortedDates = selectedDates.sorted()
                            val start = sortedDates.first()
                            val end = sortedDates.last()

                            fun formatDate(date: LocalDate): String {
                                val monthAbbreviation = date.month.name.lowercase().take(3)
                                    .replaceFirstChar { it.uppercase() }
                                return "$monthAbbreviation ${date.dayOfMonth}"
                            }

                            val formattedRange =
                                if (start.month == end.month && start.year == end.year) {
                                    // If the month and year are the same
                                    "${formatDate(start)} - ${end.dayOfMonth}, ${end.year}"
                                } else {
                                    // Full range including months
                                    "${formatDate(start)} - ${formatDate(end)}, ${end.year}"
                                }

                            viewModel.upcomingLeaves.value = formattedRange
                        }
                    },
                   showErrorMessage = {
                       viewModel.showPopUpError.value = true
                       viewModel.errorText.value = error
                   }
                )
            }
        }

    } else {
        AddNewEmployeeWeb(
            viewModel,
            navHostController,
            itemsTeams,
            itemsSkills,
            showProgressBar,
            showErrorDialog
        )
    }

    if (showErrorDialog.value) {
        val error = stringResource(Res.string.error)
        val message = stringResource(Res.string.error_message)
        ErrorDialog(
            onDismiss = {
                showErrorDialog.value = false
            },
            title = error,
            message = message
        )
    }
    if (showProgressBar.value) {
        FullScreenProgressBar()
    }
}

@Composable
fun DropdownMenuComponent(label: String, selectedValue: String) {
    var expanded = remember { mutableStateOf(false) }
    var selectedOption = remember { mutableStateOf(selectedValue) }

    Box {
        OutlinedTextField(
            value = selectedOption.value,
            onValueChange = {},
            label = { Text(label) },
            trailingIcon = {
                Icon(
                    imageVector = Icons.Default.ArrowDropDown,
                    contentDescription = null,
                    modifier = Modifier.clickable { expanded.value = true }
                )
            },
            readOnly = true,
            modifier = Modifier.fillMaxWidth()
        )
        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            listOf("Option 1", "Option 2", "Option 3").forEach { option ->
                DropdownMenuItem(onClick = {
                    selectedOption.value = option
                    expanded.value = false
                }, text = {
                    Text(option)
                })
            }
        }
    }
}
