package com.hyperether.goodjob.scenes.addNew

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Checkbox
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.ContactType
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add
import com.hyperether.goodjob.resources.add_new_contact
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.contact_type
import com.hyperether.goodjob.resources.country
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.enter_contact_name
import com.hyperether.goodjob.resources.enter_email_address
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.industry
import com.hyperether.goodjob.resources.select_industry
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.photo_upload_area
import com.hyperether.goodjob.resources.tap_to_upload_photo
import com.hyperether.goodjob.resources.use_contact_name
import com.hyperether.goodjob.resources.zip_employee
import com.hyperether.goodjob.scenes.addNew.components.AddEmployeeInputField
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderBackAndAction
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewContact(addNewViewModel: AddNewViewModel, navHostController: NavHostController) {

    Column(modifier = Modifier.verticalScroll(rememberScrollState())) {
        MobileHeaderBackAndAction(
            title = stringResource(Res.string.add_new_contact),
            back = { navHostController.popBackStack() }
        )

        Spacer(modifier = Modifier.height(15.dp))

        Image(
            painter = painterResource(Res.drawable.photo_upload_area),
            contentDescription = "Avatar",
            modifier = Modifier
                .size(80.dp)
                .clip(CircleShape)
                .background(BlueApp)
                .align(Alignment.CenterHorizontally)
        )

        Text(
            text = stringResource(Res.string.tap_to_upload_photo),
            fontSize = 14.sp,
            color = TextColor,
            textAlign = TextAlign.Center,
            fontStyle = FontStyle.Italic,
            modifier = Modifier.align(Alignment.CenterHorizontally)
        )

        Column(modifier = Modifier.padding(horizontal = 16.dp)) {
            AppInputField(
                addNewViewModel.contactNameNewContact,
                stringResource(Res.string.contact_name),
                placeholder = stringResource(Res.string.enter_contact_name)
            )
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.offset(x = (-12).dp).padding(top = 5.dp)
            ) {
                Checkbox(
                    checked = addNewViewModel.isCheckedContactPerson.value,
                    onCheckedChange = {
                        addNewViewModel.isCheckedContactPerson.value = it
                    },
                    modifier = Modifier
                        .clip(RoundedCornerShape(50))
                )
                Spacer(modifier = Modifier.width(5.dp))
                Text(
                    text = stringResource(Res.string.use_contact_name),
                    color = TextColor,
                    fontSize = 12.sp
                )
            }
            val itemsType = listOf(
                ContactType.Customer.name,
                ContactType.Supplier.name,
                ContactType.Contractor.name
            )
            AddEmployeeInputField(
                addNewViewModel.contactType,
                stringResource(Res.string.contact_type),
                placeholder = "",
                dropdownItems = itemsType,
                keyboardInputEnabled = false
            )
            AddEmployeeInputField(
                addNewViewModel.industry,
                stringResource(Res.string.industry),
                placeholder = stringResource(Res.string.select_industry),
                dropdownItems = listOf(),
                keyboardInputEnabled = true
            )

            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                AppInputField(
                    addNewViewModel.contactCity,
                    stringResource(Res.string.city),
                    placeholder = stringResource(Res.string.city),
                    modifier = Modifier.weight(1f)
                )
                Spacer(modifier = Modifier.width(8.dp))
                AppInputField(
                    addNewViewModel.contactCountry,
                    stringResource(Res.string.country),
                    placeholder = stringResource(Res.string.country),
                    modifier = Modifier.weight(1f)
                )
            }
            Row(
                modifier = Modifier
                    .fillMaxWidth(),
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                AppInputField(
                    addNewViewModel.contactAddress,
                    stringResource(Res.string.address),
                    placeholder = stringResource(Res.string.enter_street),
                    modifier = Modifier.weight(3f)
                )
                Spacer(modifier = Modifier.width(8.dp))
                AppInputField(
                    addNewViewModel.contactZip,
                    stringResource(Res.string.zip_employee),
                    placeholder = stringResource(Res.string.zip_employee),
                    modifier = Modifier.weight(1f)
                )
            }
            PhoneInputField(
                addNewViewModel.phoneNewContact,
                stringResource(Res.string.phone),
                placeholder = "+134232525235"
            )
            AppInputField(
                addNewViewModel.emailNewContact,
                stringResource(Res.string.email_address),
                placeholder = stringResource(Res.string.enter_email_address)
            )
            Spacer(modifier = Modifier.height(15.dp))

            Text(
                text = "Contact person",
                fontSize = 18.sp,
                color = TextColor,
                fontWeight = FontWeight.SemiBold,
                textAlign = TextAlign.Center
            )
            AppInputField(
                if (addNewViewModel.isCheckedContactPerson.value) {
                    addNewViewModel.contactNameNewContact
                } else addNewViewModel.contactPersonName,
                stringResource(Res.string.contact_name),
                placeholder = stringResource(Res.string.enter_contact_name)
            )
            AppInputField(
                if (addNewViewModel.isCheckedContactPerson.value) {
                    addNewViewModel.emailNewContact
                } else addNewViewModel.contactPersonEmail,
                stringResource(Res.string.email_address),
                placeholder = stringResource(Res.string.enter_email_address)
            )
            PhoneInputField(
                if (addNewViewModel.isCheckedContactPerson.value) {
                    addNewViewModel.phoneNewContact
                } else addNewViewModel.contactPersonPhone,
                stringResource(Res.string.phone),
                placeholder = "+123456789"
            )
            Spacer(modifier = Modifier.height(25.dp))

            FilledButton(
                onClick = {
                    addNewViewModel.showLoader.value = true
                    addNewViewModel.addContact(
                        onSuccess = {
                            addNewViewModel.showLoader.value = false
                            addNewViewModel.showPopUpSuccess.value = true
                            navHostController.popBackStack()
                        }, onError = {
                            addNewViewModel.showLoader.value = false
                            addNewViewModel.showPopUpError.value = true
                        })

                },
                text = stringResource(Res.string.add),
                isEnabled = true
            )
            Spacer(modifier = Modifier.height(25.dp))
            if (addNewViewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        addNewViewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    title = "SUCCESS",
                    message = "Contact added successfully",
                    positiveBtnText = "OK",
                    onPositiveBtnClicked = {
                        addNewViewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    }
                )
            }
            if (addNewViewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { addNewViewModel.showPopUpError.value = false },
                    title = "ERROR",
                    message = addNewViewModel.errorText.value
                )
            }
            if (addNewViewModel.showLoader.value) {
                FullScreenProgressBar()
            }
        }
    }
}