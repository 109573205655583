package com.hyperether.goodjob.scenes.register

import androidx.compose.animation.core.Animatable
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.tween
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.material.Surface
import androidx.compose.material3.BasicAlertDialog
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.rotate
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.unit.dp
import com.hyperether.goodjob.theme.BlueApp

@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun FullScreenProgressBar() {
    val rotation = remember { Animatable(0f) }

    LaunchedEffect(Unit) {
        rotation.animateTo(
            targetValue = 360f,
            animationSpec = infiniteRepeatable(
                animation = tween(
                    durationMillis = 1500,
                    easing = LinearEasing
                ),
            )
        )
    }
    BasicAlertDialog(
        onDismissRequest = {  },
        content = {
            Surface(
                color = Color.Gray.copy(alpha = 0.5f),
                modifier = Modifier.fillMaxWidth().padding(16.dp)
            ) {
                Box(
                    modifier = Modifier.width(80.dp).height(80.dp),
                    contentAlignment = Alignment.Center
                ) {
                    CircularProgressIndicator(
                        progress = { 0.8f },
                        modifier = Modifier.rotate(rotation.value),
                        color = Color.Blue,
                        strokeWidth = 4.dp,
                        trackColor = BlueApp,
                        strokeCap = StrokeCap.Round,
                    )
                }
            }
        }
    )
}