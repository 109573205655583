package com.hyperether.goodjob.repository.remote

import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Skills
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.remote.model.AddCardRequest
import com.hyperether.goodjob.repository.remote.model.AddCardResponse
import com.hyperether.goodjob.repository.remote.model.AddPlanRequest
import com.hyperether.goodjob.repository.remote.model.AddPlanResponse
import com.hyperether.goodjob.repository.remote.model.AllSkillsRequest
import com.hyperether.goodjob.repository.remote.model.AllUsersByRoleRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanResponse
import com.hyperether.goodjob.repository.remote.model.ConfirmationRequest
import com.hyperether.goodjob.repository.remote.model.ConfirmationResponse
import com.hyperether.goodjob.repository.remote.model.DeleteFileDataRequest
import com.hyperether.goodjob.repository.remote.model.DeleteFileRequest
import com.hyperether.goodjob.repository.remote.model.EmployeeRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyResponse
import com.hyperether.goodjob.repository.remote.model.ErrorModel
import com.hyperether.goodjob.repository.remote.model.FinishJobRequest
import com.hyperether.goodjob.repository.remote.model.FinishJobResponse
import com.hyperether.goodjob.repository.remote.model.ForgotPasswordRequest
import com.hyperether.goodjob.repository.remote.model.ForgotPasswordResponse
import com.hyperether.goodjob.repository.remote.model.GetFileRequest
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.repository.remote.model.LoginRequest
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import com.hyperether.goodjob.repository.remote.model.RegisterRequest
import com.hyperether.goodjob.repository.remote.model.ResetPasswordRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.SetPasswordRequest
import com.hyperether.goodjob.repository.remote.model.SetPasswordResponse
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.repository.remote.model.SkillRequest
import com.hyperether.goodjob.repository.remote.model.UpdateWorkspaceRequest
import com.hyperether.goodjob.repository.remote.model.UploadFileResponse
import com.hyperether.goodjob.repository.remote.model.WorkspaceRequest
import io.ktor.client.call.body
import io.ktor.client.request.accept
import io.ktor.client.request.delete
import io.ktor.client.request.forms.MultiPartFormDataContent
import io.ktor.client.request.forms.formData
import io.ktor.client.request.get
import io.ktor.client.request.headers
import io.ktor.client.request.post
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.client.statement.HttpResponse
import io.ktor.client.statement.bodyAsBytes
import io.ktor.client.statement.bodyAsText
import io.ktor.http.ContentType
import io.ktor.http.Headers
import io.ktor.http.HttpHeaders
import io.ktor.http.HttpStatusCode
import io.ktor.http.contentType
import kotlinx.serialization.json.Json
import org.lighthousegames.logging.logging

class GoodJobApiImpl() : GoodJobApi {

    var baseURL: String = ApiConfig.baseURL
    val log = logging("GoodJobApiImpl")

    override suspend fun register(
        name: String,
        email: String,
        password: String
    ): Resource<User> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/register") {
                    contentType(ContentType.Application.Json)
                    setBody(RegisterRequest(name, email, password))
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to register body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to register exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to register: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun login(email: String, password: String): Resource<LoginResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/login") {
                    contentType(ContentType.Application.Json)
                    setBody(LoginRequest(email, password))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: LoginResponse = response.body()
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to login body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to login exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to login: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun confirmation(
        hash: String
    ): Resource<ConfirmationResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/confirmation") {
                    contentType(ContentType.Application.Json)
                    setBody(ConfirmationRequest(hash))
                }


            if (response.status == HttpStatusCode.OK) {
                val apiResponse: ConfirmationResponse = response.body()
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to confirm body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to confirm exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to confirm: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "Unknown error occurred")
        }
    }

    override suspend fun addWorkspace(
        name: String,
        workspaceType: String,
        token: String
    ): Resource<Workspace> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/workspace") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                        setBody(WorkspaceRequest(name, workspaceType))
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Workspace = response.body()
                log.d("add workspace") { "Add workspace SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to add workspace body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to add workspace exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to add workspace: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("add workspace") { "Failed to add workspace: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun getAllWorkspaces(token: String): Resource<List<Workspace>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/workspace/user-workspaces") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: List<Workspace> = response.body()
                log.d("getAllWorkspaces") { "getAllWorkspaces SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get workspaces body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get workspaces exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get workspaces: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getAllWorkspaces") { "Failed to add workspace: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    //todo this is workaround
    override suspend fun getAllWorkspacesId(token: String): Resource<List<Workspace>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/workspace/user-workspaceId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: List<Workspace> = response.body()
                log.d("getAllWorkspaces") { "getAllWorkspaces SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get workspaces body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get workspaces exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get workspaces: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getAllWorkspaces") { "Failed to add workspace: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun updateWorkspace(
        updateWorkspaceRequest: UpdateWorkspaceRequest,
        workspaceId: String,
        token: String
    ): Resource<Workspace> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.put("${baseURL}/api/v1/workspace/$workspaceId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(updateWorkspaceRequest)
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Workspace = response.body()
                log.d("updateWorkspace") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("updateWorkspace") { "Failed to update workspace body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("updateWorkspace") { "Failed to update workspace exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to update workspace: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("updateWorkspace") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllPlans(token: String): Resource<List<Plan>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/plan/all-plans") {
                    headers {
                        contentType(ContentType.Application.Json)
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: List<Plan> = response.body()
                log.d("getAllPlans") { "getAllPlans SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get all plans body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get all plans exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get all plans: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getAllPlans") { "Failed to getAllPlans: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun getPlanById(token: String, planId: String): Resource<Plan> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/plan/${planId}") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Plan = response.body()
                log.d("getPlanById") { "getPlanById SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("getPlanById") { "Failed to getPlanById: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("getPlanById") { "Failed to getPlanById exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to getPlanById: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getAllPlans") { "Failed to getPlanById: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun addPlan(
        token: String,
        addPlanRequest: AddPlanRequest
    ): Resource<AddPlanResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/add-plan") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                        setBody(addPlanRequest)
                    }
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: AddPlanResponse = response.body()
                log.d("addPlan") { "addPlan SUCCESS: ${(response.bodyAsText())}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to add plan body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to add plan exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to add plan: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("addPlan") { "Failed to addPlan: ${e.message}" }
            Resource.Error(text = e.message)
        }
    }

    override suspend fun addEmployee(
        token: String,
        addEmployeeRequest: EmployeeRequest
    ): Resource<User> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/add-user") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(addEmployeeRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                log.d("add employee") { "Add employee SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to add employee body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to add employee exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to add employee: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("add employee") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun addContact(token: String, customer: Contact): Resource<Contact> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/contact") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(customer)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Contact = response.body()
                log.d("Add contact") { "Add contact SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to add contact body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to add contact exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to add contact: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Add contact") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllContacts(token: String): Resource<List<Contact>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/contact") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val contacts = Json.decodeFromString<List<Contact>>(response.bodyAsText())
                log.d("Get all contacts") { "Get all contacts: ${response.bodyAsText()}" }
                Resource.Success(contacts)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get all contacts body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get all contacts exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get all contacts: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get all contacts") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getSitesUnderContacts(
        token: String,
        siteRequest: SiteRequest
    ): Resource<List<Site>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/contact/list-sites") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(siteRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val contacts = Json.decodeFromString<List<Site>>(response.bodyAsText())
                log.d("Get sites under contacts") { response.bodyAsText() }
                Resource.Success(contacts)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get sites body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get sites exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get sites: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get sites under contacts") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun createJob(token: String, job: JobRequest): Resource<Job> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/job") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(job)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Job = response.body()
                log.d("Add job") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to add job body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to add job exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to add job: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Add job") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun updateJob(token: String, jobId: String, job: Job): Resource<Job> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.put("${baseURL}/api/v1/job/${jobId}") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(job)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Job = response.body()
                log.d("Update job") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("Update job") { "Failed to update job body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("Update job") { "Failed to update job exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to update job: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Update job") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllEmployees(token: String): Resource<List<User>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/user") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            val json = Json {
                ignoreUnknownKeys = true
            }

            if (response.status == HttpStatusCode.OK) {
                val users = json.decodeFromString<List<User>>(response.bodyAsText())
                log.d("Get all employees") { "Success: ${response.bodyAsText()}" }
                Resource.Success(users)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get employees body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get employees exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get employees: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get all employees") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllJobsForSpecificWorkspace(
        token: String,
        workspaceId: String
    ): Resource<List<Job>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/job/all-workspace-jobs/$workspaceId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            val responseBody = response.bodyAsText().trim()
            log.d("Response Body") { responseBody }
            val json = Json {
                ignoreUnknownKeys = true
            }

            if (responseBody.isEmpty()) {
                return Resource.Success(emptyList())
            }

            if (response.status == HttpStatusCode.OK) {
                val jobs = json.decodeFromString<List<Job>>(response.bodyAsText())
                log.d("Get all jobs") { "Success: ${response.bodyAsText()}" }
                Resource.Success(jobs)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get all jobs for workspace body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get all jobs for workspace exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage = errorModel?.error?.message
                    ?: "Failed to get all jobs for workspace: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.e() { "Exception occurred: ${e::class.simpleName} - ${e.message}" }
            e.printStackTrace()
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllJobsForSpecificUser(
        token: String
    ): Resource<List<Job>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/user/find-all-user-jobs") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }
            val responseBody = response.bodyAsText().trim()
            log.d("Response Body") { responseBody }
            val json = Json {
                ignoreUnknownKeys = true
            }

            if (responseBody.isEmpty()) {
                return Resource.Success(emptyList())
            }

            if (response.status == HttpStatusCode.OK) {
                val jobs = json.decodeFromString<List<Job>>(response.bodyAsText())
                log.d("Get all jobs") { "Success: ${response.bodyAsText()}" }
                Resource.Success(jobs)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get all jobs for workspace body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get all jobs for workspace exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage = errorModel?.error?.message
                    ?: "Failed to get all jobs for workspace: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.e() { "Exception occurred: ${e::class.simpleName} - ${e.message}" }
            e.printStackTrace()
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun deleteJobById(token: String, id: String): Resource<String> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.delete("${baseURL}/api/v1/job/$id") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: String = response.body()
                log.d("Delete job") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to delete job body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to delete job exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to delete job: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Delete job") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun deleteEmployee(token: String, employeeId: String): Resource<String> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.delete("${baseURL}/api/v1/user/$employeeId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: String = response.body()
                log.d("Delete employee") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to delete employee body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to delete employee exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to delete employee: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Delete employee") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getEmployeeById(token: String, userId: String): Resource<User> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.get("${baseURL}/api/v1/user/$userId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                log.d("getEmployeeById") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get employee body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get employee exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get employee: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getEmployeeById") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun updateEmployeeById(
        token: String,
        userId: String,
        addEmployeeRequest: EmployeeRequest
    ): Resource<User> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.put("${baseURL}/api/v1/user/$userId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(addEmployeeRequest)
                }
            if (response.status == HttpStatusCode.OK) {
                val apiResponse: User = response.body()
                log.d("updateEmployeeById") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to update employee body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to update employee exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to update employee: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("updateEmployeeById") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun choosePlan(
        token: String,
        choosePlanRequest: ChoosePlanRequest
    ): Resource<ChoosePlanResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/choose-plan") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(choosePlanRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: ChoosePlanResponse = response.body()
                log.d("ChoosePlan") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to choose plan body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to choose plan exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to choose plan: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("ChoosePlan") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getEphemeralKey(
        token: String,
        ephemeralKeyRequest: EphemeralKeyRequest
    ): Resource<EphemeralKeyResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/payment/ephemeral-key") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(ephemeralKeyRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: EphemeralKeyResponse = response.body()
                log.d("getEphemeralKey") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get ephemeral key body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get ephemeral key exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get ephemeral key: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getEphemeralKey") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun addCard(
        token: String,
        addCardRequest: AddCardRequest
    ): Resource<AddCardResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/add-card") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(addCardRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: AddCardResponse = response.body()
                Resource.Success(apiResponse)

            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to add card body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to add card exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to add card: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("AddCard") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getAllUsersByRole(
        token: String,
        allUsersByRoleRequest: AllUsersByRoleRequest
    ): Resource<List<User>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/find-all-by-role") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(allUsersByRoleRequest)
                }
            val json = Json {
                ignoreUnknownKeys = true
            }

            if (response.status == HttpStatusCode.OK) {
                val users = json.decodeFromString<List<User>>(response.bodyAsText())
                log.d("Get all employees by role") { "Success: ${response.bodyAsText()}" }
                Resource.Success(users)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to get employees body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to get employees exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to get employees: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get all employees") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun forgotPassword(
        forgotPasswordRequest: ForgotPasswordRequest
    ): Resource<ForgotPasswordResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/forgot-password") {
                    headers {
                        contentType(ContentType.Application.Json)
                    }
                    setBody(forgotPasswordRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: ForgotPasswordResponse = response.body()
                log.d("ForgotPassword") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("ForgotPassword") { (response.bodyAsText()) }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("ForgotPassword") { "${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "ForgotPassword: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("ForgotPassword") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun resetPassword(resetPasswordRequest: ResetPasswordRequest): Resource<ForgotPasswordResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/reset-password") {
                    headers {
                        contentType(ContentType.Application.Json)
                    }
                    setBody(resetPasswordRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: ForgotPasswordResponse = response.body()
                log.d("ResetPassword") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("ResetPassword") { (response.bodyAsText()) }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("ResetPassword") { "${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage = errorModel?.error?.message ?: "ResetPassword: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("ResetPassword") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun setPassword(setPasswordRequest: SetPasswordRequest): Resource<SetPasswordResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/user/set-password") {
                    headers {
                        contentType(ContentType.Application.Json)
                    }
                    setBody(setPasswordRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: SetPasswordResponse = response.body()
                log.d("SetPassword") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("SetPassword") { (response.bodyAsText()) }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("SetPassword") { "${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage = errorModel?.error?.message ?: "SetPassword: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("SetPassword") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getContactsByWorkspaceId(
        token: String,
        allUsersByRoleRequest: AllUsersByRoleRequest
    ): Resource<List<Contact>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/contact/find-all-by-workspaceId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(allUsersByRoleRequest)
                }
            val json = Json {
                ignoreUnknownKeys = true
            }

            if (response.status == HttpStatusCode.OK) {
                val contacts = json.decodeFromString<List<Contact>>(response.bodyAsText())
                log.d("Get contacts by workspaceId") { "Success: ${response.bodyAsText()}" }
                Resource.Success(contacts)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("Get contacts by workspaceId") { (response.bodyAsText()) }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("Get contacts by workspaceId") { "${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Get contacts by workspaceId") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun addSite(token: String, site: Site): Resource<Site> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/contact/add-site") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(site)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Site = response.body()
                Resource.Success(apiResponse)

            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("addSite") { "Failed to add site body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("addSite") { "Failed to add card exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to add site: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("addSite") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun finishJob(
        token: String,
        finishJobRequest: FinishJobRequest
    ): Resource<FinishJobResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/job/finish-job") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(finishJobRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: FinishJobResponse = response.body()
                log.d("Finish job") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to finish job body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to finish job exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to finish job: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Finish job") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun deleteContact(token: String, contactId: String): Resource<String> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.delete("${baseURL}/api/v1/contact/$contactId") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: String = response.body()
                log.d("Delete contact") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("register") { "Failed to delete contact body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("register") { "Failed to delete contact exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to delete contact: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Delete contact") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getFile(fileId: String, token: String): Resource<ByteArray> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/file/get-file") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(GetFileRequest(fileId))
                }

            if (response.status == HttpStatusCode.OK) {
//                val localPath = "${context.cacheDir}/$imageName"
//                val inputStream = response.bodyAsChannel().toInputStream()
//                FileManager.getInstance(context).save(inputStream, localPath)
                log.d("getFile") { "Success: ${response.bodyAsBytes().size}" }
                Resource.Success(response.bodyAsBytes())
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("getFile") { (response.bodyAsText()) }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("getFile") { "${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("getFile") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun uploadFile(
        token: String,
        fileData: ByteArray,
        fileName: String
    ): Resource<UploadFileResponse> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/file/upload") {
                    headers {
                        append("x-access-token", token)
                        accept(ContentType.Application.Json)
                    }
                    setBody(
                        MultiPartFormDataContent(
                            formData {
                                append("file", fileData, Headers.build {
                                    append(
                                        HttpHeaders.ContentDisposition,
                                        "filename=\"$fileName\""
                                    )
                                    append(HttpHeaders.ContentType, "*/*")
                                })
                            }
                        )
                    )
                }

            if (response.status == HttpStatusCode.OK) {
                val uploadResponse =
                    Json.decodeFromString<UploadFileResponse>(response.bodyAsText())
                log.d("Upload file") { "Success: ${response.bodyAsText()}" }
                Resource.Success(uploadResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("Upload file") { errorBody }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("Upload file") { "${e.message}: $errorBody" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Upload file") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun deleteFile(token: String, fileId: String): Resource<String> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/file/delete") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(DeleteFileRequest(DeleteFileDataRequest(fileId)))
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: String = response.body()
                log.d("Delete file") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("Delete file") { "Failed to delete file body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("Delete file") { "Failed to delete contact exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to delete file: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("Delete file") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun addSkill(
        token: String,
        workspaceId: String,
        skillRequest: SkillRequest
    ): Resource<Skills> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/skill") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(skillRequest)
                }

            if (response.status == HttpStatusCode.OK) {
                val apiResponse: Skills = response.body()
                log.d("addSkill") { "SUCCESS: ${response.bodyAsText()}" }
                Resource.Success(apiResponse)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("addSkill") { "Failed to addSkill body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("addSkill") { "Failed to addSkill exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage =
                    errorModel?.error?.message ?: "Failed to addSkill: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.d("addSkill") { "Exception occurred: ${e.message}" }
            Resource.Error(e.message ?: "Unknown error")
        }
    }

    override suspend fun getSkillsByWorkspace(
        token: String,
        workspaceId: String
    ): Resource<List<Skills>> {
        return try {
            val response: HttpResponse =
                ClientProvider.client.post("${baseURL}/api/v1/skill/find-by-workspace") {
                    headers {
                        append("x-access-token", token)
                        contentType(ContentType.Application.Json)
                    }
                    setBody(AllSkillsRequest(workspaceId = workspaceId))
                }
            val responseBody = response.bodyAsText().trim()
            log.d("getSkillsByWorkspace") { "Response Body $responseBody" }
            val json = Json {
                ignoreUnknownKeys = true
            }
            if (responseBody.isEmpty()) {
                return Resource.Success(emptyList())
            }
            if (response.status == HttpStatusCode.OK) {
                val jobs = json.decodeFromString<List<Skills>>(response.bodyAsText())
                log.d("getSkillsByWorkspace") { "Success: ${response.bodyAsText()}" }
                Resource.Success(jobs)
            } else {
                val errorBody: String = response.bodyAsText()
                val errorModel: ErrorModel? = try {
                    log.d("getSkillsByWorkspace") { "Failed to getSkillsByWorkspace body: ${(response.bodyAsText())}" }
                    Json.decodeFromString(ErrorModel.serializer(), errorBody)
                } catch (e: Exception) {
                    log.d("getSkillsByWorkspace") { "Failed to getSkillsByWorkspace exception ${e.message}: ${(response.bodyAsText())}" }
                    null
                }
                val errorMessage = errorModel?.error?.message
                    ?: "Failed to getSkillsByWorkspace: ${response.status}"
                Resource.Error(errorMessage)
            }
        } catch (e: Exception) {
            log.e() { "Exception occurred: ${e::class.simpleName} - ${e.message}" }
            e.printStackTrace()
            Resource.Error(e.message ?: "Unknown error")
        }
    }
}