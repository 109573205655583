package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Card
import androidx.compose.material.Checkbox
import androidx.compose.material.CheckboxDefaults
import androidx.compose.material.Divider
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Text
import androidx.compose.material.TextButton
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.Paint
import androidx.compose.ui.graphics.PaintingStyle
import androidx.compose.ui.graphics.PathEffect
import androidx.compose.ui.graphics.drawscope.drawIntoCanvas
import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.window.Dialog
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_file
import com.hyperether.goodjob.resources.add_note
import com.hyperether.goodjob.resources.add_note_here
import com.hyperether.goodjob.resources.approve
import com.hyperether.goodjob.resources.assigned_to
import com.hyperether.goodjob.resources.baseline_file_present_24
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.contact_ic
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.document
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.images
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.max_size
import com.hyperether.goodjob.resources.name
import com.hyperether.goodjob.resources.notes
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.selected
import com.hyperether.goodjob.resources.submit
import com.hyperether.goodjob.resources.trash
import com.hyperether.goodjob.resources.try_another_document
import com.hyperether.goodjob.resources.upload_cloud
import com.hyperether.goodjob.resources.upload_documents
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.util.Constants
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EditJobsDialog(
    showDialog: Boolean, jobs: List<com.hyperether.goodjob.database.Job>,
    onDelete: () -> Unit,
    onExportList: () -> Unit,
    onImportList: () -> Unit,
    onEdit: () -> Unit
) {
    if (showDialog) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp))
            //.shadow(1.dp, RoundedCornerShape(8.dp))
        ) {
            Column() {
                Text(
                    text = "${jobs.size} " + stringResource(Res.string.selected),
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    Text(
                        text = stringResource(Res.string.delete),
                        fontSize = 14.sp,
                        color = Color.Red,
                        modifier = Modifier.clickable {
                            onDelete()
                        })
                    Text(
                        text = stringResource(Res.string.export_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onExportList()
                        })
                    Text(
                        text = stringResource(Res.string.import_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onImportList()
                        })
                    Text(text = stringResource(Res.string.edit), fontSize = 14.sp, color = BlueApp,
                        modifier = Modifier.clickable {
                            onEdit()
                        })
                }
            }
        }
    }
}

@Composable
fun CompletionReportViewAdmin(onApproveClick: () -> Unit, onRejectClick: () -> Unit) {
    val damageState = remember { mutableStateOf(true) }
    val inspectionState = remember { mutableStateOf(true) }
    val safetyState = remember { mutableStateOf(true) }
    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .align(Alignment.BottomCenter),
            verticalArrangement = Arrangement.spacedBy(16.dp)
        ) {
            // Title
//            Text(
//                text = "Adding Proof of Completed Work",
//                style = MaterialTheme.typography.h6,
//                modifier = Modifier.padding(bottom = 16.dp)
//            )
//
//            // Images Row
//            Row(
//                modifier = Modifier.fillMaxWidth(),
//                horizontalArrangement = Arrangement.spacedBy(16.dp)
//            ) {
//                ImageCard("135_adk.jpg")
//                ImageCard("136_adk.jpg")
//            }
//
//            Spacer(modifier = Modifier.height(16.dp))
//
//            // Status Check Section
//            SectionTitle("Status Check")
//            CheckBoxItem(
//                question = "Was any damage caused during the work?",
//                answer = "No damage",
//                checked = damageState.value,
//                onCheckedChange = { damageState.value = it }
//            )
//            CheckBoxItem(
//                question = "Was a final inspection completed and confirmed that everything is functioning correctly?",
//                answer = "Yes",
//                checked = inspectionState.value,
//                onCheckedChange = { inspectionState.value = it }
//            )
//
//            Spacer(modifier = Modifier.height(16.dp))
//
//            // Client Feedback Section
//            SectionTitle("Client Feedback")
//            Text("Client Feedback:", style = MaterialTheme.typography.subtitle1)
//            Text(
//                text = "\"Very satisfied with the installation. The technician was thorough and completed the job efficiently.\"",
//                style = MaterialTheme.typography.body1,
//                modifier = Modifier.padding(vertical = 8.dp)
//            )
//            Text("Client Signature:")
//            Spacer(modifier = Modifier.height(8.dp))
//            // Placeholder for Signature
//            Box(
//                modifier = Modifier
//                    .fillMaxWidth()
//                    .height(50.dp)
//                    .border(1.dp, Color.Gray)
//            ) {
//                Text(
//                    text = "Signature",
//                    modifier = Modifier.align(Alignment.Center),
//                    style = MaterialTheme.typography.caption,
//                    color = Color.Gray
//                )
//            }
//
//            Spacer(modifier = Modifier.height(16.dp))
//
//            // Additional Notes Section
//            SectionTitle("Additional Notes")
//            Text(
//                text = "Employee Comments:",
//                style = MaterialTheme.typography.subtitle1
//            )
//            Text(
//                text = "Installation was smooth. Tested all connections, and the inverter is operating within expected parameters. Client observed the process and confirmed satisfaction with the final setup.",
//                style = MaterialTheme.typography.body1,
//                modifier = Modifier.padding(vertical = 8.dp)
//            )
//
//            Spacer(modifier = Modifier.height(16.dp))
//
//            // Health and Safety Confirmation Section
//            SectionTitle("Health and Safety Confirmation")
//            CheckBoxItem(
//                question = "Were all health and safety requirements met?",
//                answer = "Yes",
//                checked = safetyState.value,
//                onCheckedChange = { safetyState.value = it }
//            )
            FilledButton(
                onClick = { onApproveClick() },
                modifier = Modifier.fillMaxWidth(),
                text = stringResource(Res.string.approve)
            )
//            androidx.compose.material.OutlinedButton(
//                onClick = {
//                    onRejectClick()
//                },
//                modifier = Modifier.fillMaxWidth().height(50.dp),
//                border = BorderStroke(1.dp, BlueApp),
//                shape = RoundedCornerShape(50),
//                colors = androidx.compose.material.ButtonDefaults.outlinedButtonColors(
//                    contentColor = Color.White,
//                    backgroundColor = Color.Transparent
//                ),
//            ) {
//                Text(
//                    text = stringResource(Res.string.reject),
//                    maxLines = 1,
//                    fontSize = 15.sp,
//                    color = BlueApp
//                )
//            }
        }
    }
}

@Composable
fun CompletionReportViewEmployee(viewModel: JobViewModel, submit: () -> Unit, images: SnapshotStateList<String>, onUploadDocumentClick: () -> Unit) {
    val damageState = remember { mutableStateOf(false) }
    val inspectionState = remember { mutableStateOf(false) }
    val safetyState = remember { mutableStateOf(false) }
    val commentText = remember { mutableStateOf("") }
    val feedbackText = remember { mutableStateOf("") }
    val pathEffectLine =
        PathEffect.dashPathEffect(floatArrayOf(10f, 10f), phase = 0f)
    Box(
        modifier = Modifier
            .fillMaxSize()
            .padding(16.dp)
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .align(Alignment.BottomCenter),
            verticalArrangement = Arrangement.spacedBy(16.dp)
        ) {
            // Title
            Text(
                text = "Upload photos of the completed job",
                style = MaterialTheme.typography.h6,
                fontSize = 16.sp,
                modifier = Modifier.padding(bottom = 8.dp)
            )

            // Images Row
            if (images.isEmpty()) {
                Box(
                    modifier = Modifier.fillMaxWidth().drawBehind {
                        val paint = Paint().apply {
                            color = BlueApp
                            style = PaintingStyle.Stroke
                            strokeWidth = 2.dp.toPx()
                            pathEffect = pathEffectLine
                        }

                        drawIntoCanvas { canvas ->
                            canvas.drawRoundRect(
                                0f,
                                0f,
                                size.width,
                                size.height,
                                radiusX = 20f,
                                radiusY = 20f,
                                paint
                            )
                        }
                    }.padding(24.dp),
                    contentAlignment = Alignment.Center
                ) {
                    Column(
                        horizontalAlignment = Alignment.CenterHorizontally,
                        modifier = Modifier.clickable {
                            onUploadDocumentClick()
                        }) {
                        if (viewModel.documentStatus.value == "") {
                            Image(
                                painter = painterResource(Res.drawable.upload_cloud),
                                contentDescription = "",
                                modifier = Modifier.size(40.dp)
                            )

                            Spacer(modifier = Modifier.height(8.dp))

                            Text(
                                text = stringResource(Res.string.upload_documents),
                                color = Color.Black,
                                fontWeight = FontWeight.Bold
                            )

                            Spacer(modifier = Modifier.height(4.dp))

                            Text(
                                text = stringResource(Res.string.max_size),
                                color = Color.LightGray
                            )
                        } else if (viewModel.documentStatus.value == Constants.DOCUMENT_LOADING) {
                            viewModel.showDocumentLoader.value = true
                            if(viewModel.showDocumentLoader.value) {
                                CircularProgressIndicator(
                                    modifier = Modifier
                                        .size(25.dp)
                                        .padding(end = 5.dp),
                                    color = BlueApp,
                                    strokeWidth = 2.dp
                                )
                            }
                        } else if (viewModel.documentStatus.value == Constants.DOCUMENT_UPLOAD_ERROR) {
                            viewModel.showDocumentLoader.value = false
                            Text(
                                text = stringResource(Res.string.try_another_document),
                                color = Color.Gray,
                                fontWeight = FontWeight.Normal
                            )
                        } else {
                            viewModel.showDocumentLoader.value = false
                            Image(
                                painter = painterResource(Res.drawable.baseline_file_present_24),
                                contentDescription = "",
                                modifier = Modifier.size(40.dp)
                            )
                            Text(
                                text = viewModel.uploadFileName.value,
                                color = BlueApp,
                                fontWeight = FontWeight.Normal
                            )
                        }
                    }
                }
            } else {
                //todo list images
                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.spacedBy(16.dp)
                ) {
                    ImageCard("135_adk.jpg")
                    ImageCard("136_adk.jpg")
                }
            }

            // Status Check Section
            CheckBoxItemBoldTitle(
                question = "Was any damage caused during the work?",
                answer = "No damage",
                checked = damageState.value,
                onCheckedChange = { damageState.value = it }
            )
            CheckBoxItemBoldTitle(
                question = "Was a final inspection completed and confirmed that everything is functioning correctly?",
                answer = "Yes",
                checked = inspectionState.value,
                onCheckedChange = { inspectionState.value = it }
            )


            // Client Feedback Section
            Text("Client Feedback:", style = MaterialTheme.typography.h6, fontSize = 16.sp)

            AppInputField(
                feedbackText,
                "Feedback",
                "Enter any feedback provided by the client"
            )

            Text("Client Signature", style = MaterialTheme.typography.h6, fontSize = 16.sp)
            // Placeholder for Signature
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(50.dp)
                    .border(1.dp, Color.Gray)
            ) {
                Text(
                    text = "Signature",
                    modifier = Modifier.align(Alignment.Center),
                    style = MaterialTheme.typography.caption,
                    color = Color.Gray
                )
            }

            Text("Your comment", style = MaterialTheme.typography.h6, fontSize = 16.sp)
            // Additional Notes Section
            AppInputField(
                commentText,
                "Comment",
                "Add any additional notes"
            )

            // Health and Safety Confirmation Section
            CheckBoxItemBoldTitle(
                question = "Were all health and safety requirements met?",
                answer = "Yes",
                checked = safetyState.value,
                onCheckedChange = { safetyState.value = it }
            )
            FilledButton(
                onClick = { submit() },
                modifier = Modifier.fillMaxWidth(),
                text = stringResource(Res.string.submit)
            )
        }
    }
}

@Composable
fun ImageCard(imageDescription: String) {
    Box(
        modifier = Modifier
            .height(150.dp)
            .clip(RoundedCornerShape(8.dp))
            .background(Color.LightGray),
        contentAlignment = Alignment.Center
    ) {
        Text(text = imageDescription, color = Color.Gray)
    }
}

@Composable
fun SectionTitle(title: String) {
    Text(
        text = title,
        style = MaterialTheme.typography.h6,
        modifier = Modifier.padding(vertical = 8.dp)
    )
}

@Composable
fun CheckBoxItem(
    question: String,
    answer: String,
    checked: Boolean,
    onCheckedChange: (Boolean) -> Unit
) {
    Column {
        Text(text = question, style = MaterialTheme.typography.subtitle1)
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(vertical = 4.dp)
        ) {
            Checkbox(
                checked = checked,
                onCheckedChange = onCheckedChange,
                colors = CheckboxDefaults.colors(checkedColor = Green)
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(text = answer, style = MaterialTheme.typography.body1, color = Color.Gray)
        }
    }
}

@Composable
fun CheckBoxItemBoldTitle(
    question: String,
    answer: String,
    checked: Boolean,
    onCheckedChange: (Boolean) -> Unit
) {
    Column {
        Text(text = question, style = MaterialTheme.typography.h6, fontSize = 16.sp)
        Row(
            verticalAlignment = Alignment.CenterVertically,
        ) {
            Checkbox(
                checked = checked,
                onCheckedChange = onCheckedChange,
                colors = CheckboxDefaults.colors(checkedColor = Green)
            )
            Spacer(modifier = Modifier.width(8.dp))
            Text(text = answer, style = MaterialTheme.typography.body1, color = Color.Gray)
        }
    }
}

@Composable
fun JobDetailsHeaderView(job: com.hyperether.goodjob.database.Job?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 16.dp, end = 16.dp, top = 16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
            ) {
                Text(
                    fontSize = 18.sp,
                    fontWeight = FontWeight.W700,
                    text = job?.jobTitle ?: stringResource(Res.string.name),
                )
                Spacer(modifier = Modifier.width(25.dp))
                Box(
                    modifier = Modifier
                        .background(
                            color = job?.status?.let { StatusColor().getStatusColor(it) } ?: Green,
                            shape = RoundedCornerShape(8.dp)
                        )
                        .padding(horizontal = 8.dp)
                ) {
                    Text(
                        text = job?.status?.capitalize() ?: "",
                        color = Color.White,
                        fontSize = 12.sp
                    )
                }
            }

            Spacer(modifier = Modifier.height(8.dp))

            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    fontSize = 14.sp,
                    text = stringResource(Res.string.assigned_to),
                    fontWeight = FontWeight.W500
                )
                Image(
                    painter = painterResource(Res.drawable.baseline_person_24),
                    contentDescription = "User Icon",
                    modifier = Modifier.size(24.dp).padding(start = 10.dp)
                )
                val employees = job?.employees?.let { Mapper().jsonToListEmployees(it) }
                employees?.get(0)?.let {
                    it.fullName?.let { it1 ->
                        Text(
                            text = it1,
                            modifier = Modifier.padding(start = 10.dp),
                            fontSize = 14.sp,
                            fontWeight = FontWeight.W700
                        )
                    }
                }
            }
        }
    }
}

@Composable
fun JobDetailsView(
    title: String,
    firstDescriptionText: String,
    firstDetailText: String,
    secondDetailText: String,
    secondDescriptionText: String,
    thirdDetailText: String,
    thirdDescriptionText: String
) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(start = 16.dp, end = 16.dp, top = 12.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = title,
                fontWeight = FontWeight.W700,
                fontSize = 16.sp
            )

            Text(
                text = buildAnnotatedString {
                    withStyle(style = SpanStyle(fontWeight = FontWeight.W400, color = DarkGrey)) {
                        append(firstDescriptionText)
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black
                        )
                    ) {
                        append(firstDetailText)
                    }
                },
                fontSize = 14.sp
            )

            Text(
                text = buildAnnotatedString {
                    withStyle(style = SpanStyle(fontWeight = FontWeight.W400, color = DarkGrey)) {
                        append(secondDescriptionText)
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black
                        )
                    ) {
                        append(secondDetailText)
                    }
                },
                fontSize = 14.sp,
            )

            Text(
                text = buildAnnotatedString {
                    withStyle(style = SpanStyle(fontWeight = FontWeight.W400, color = DarkGrey)) {
                        append(thirdDescriptionText)
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black
                        )
                    ) {
                        append(thirdDetailText)
                    }
                },
                fontSize = 14.sp,
            )
        }
    }
}

@Composable
fun NotesAndDocumentsView(
    image: BitmapPainter?,
    document: String?,
    job: com.hyperether.goodjob.database.Job,
    onAddNoteClick: () -> Unit,
    onOpenPdfClick: () -> Unit,
    onDeleteFileClick: (String) -> Unit,
    onDeleteImageClick: (String) -> Unit
) {
    Column(modifier = Modifier.padding(16.dp).verticalScroll(rememberScrollState())) {
        // Notes Section
        SectionHeader(
            title = stringResource(Res.string.notes),
            actionText = stringResource(Res.string.add_note),
            onAddNoteClick = {
                onAddNoteClick()
            }
        )
        job.notes?.let { notes ->
            if (notes.isNotEmpty()) {
                Spacer(modifier = Modifier.height(12.dp))
                Column(
                    modifier = Modifier.fillMaxWidth()
                        .border(1.dp, BorderColor, RoundedCornerShape(4.dp))
                        .padding(16.dp),
                    verticalArrangement = Arrangement.spacedBy(8.dp)
                ) {
                    val employees = job.employees?.let { Mapper().jsonToListEmployees(it) }
                    val employeeString = employees?.map { it.fullName }
                        ?.filter { it?.isNotEmpty() == true }
                        ?.joinToString(", ")
                    NoteRow(
                        noteText = notes,
                        author = employeeString ?: "",
                        date = job.dueDate ?: ""
                    )
                }
            }
        }
        Spacer(modifier = Modifier.height(16.dp))

        // Document Section
        if (document != null) {
            SectionHeader(
                title = stringResource(Res.string.document),
                actionText = stringResource(Res.string.add_file),
                onAddNoteClick = {}
            )
            Spacer(modifier = Modifier.height(24.dp))
            Column(
                modifier = Modifier.fillMaxWidth(),
                verticalArrangement = Arrangement.spacedBy(8.dp)
            ) {
                DocumentRow(
                    documentName = document.substringAfterLast("/"),
                    author = "",
                    date = "",
                    openPdf = onOpenPdfClick,
                    onDeleteFileClick
                )
            }
        }
        Spacer(modifier = Modifier.height(32.dp))

        // Images Section
        if (image != null && !job.fileId.isNullOrBlank()) {
            SectionHeader(
                title = stringResource(Res.string.images),
                actionText = "",
                onAddNoteClick = {})
            Spacer(modifier = Modifier.height(11.dp))
            ImageRow(
                imageName = job.fileId,
                imagePainter = image,
                onDeleteImageClick
            )
        }
    }
}

@Composable
fun SectionHeader(title: String, actionText: String, onAddNoteClick: () -> Unit) {
    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = title,
            style = MaterialTheme.typography.h6,
            fontWeight = FontWeight.Bold
        )
        if (actionText.isNotEmpty()) {
            Text(
                text = actionText,
                color = BlueApp,
                style = MaterialTheme.typography.body1,
                modifier = Modifier.border(1.dp, BlueApp, RoundedCornerShape(12.dp))
                    .padding(horizontal = 12.dp, vertical = 10.dp).clickable {
                        onAddNoteClick()
                    }
            )
        }
    }
}

@Composable
fun NoteRow(noteText: String, author: String, date: String) {
    Column {
        Text(
            text = noteText,
            style = MaterialTheme.typography.body1
        )
        Spacer(modifier = Modifier.height(4.dp))
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxWidth()
        ) {
            Row {
                Image(
                    contentDescription = "Contact image",
                    painter = painterResource(Res.drawable.contact_ic),
                    modifier = Modifier.size(20.dp).clip(CircleShape)
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(
                    text = author,
                    style = MaterialTheme.typography.body2,
                    color = Color.Black
                )
            }
            Text(
                text = date,
                style = MaterialTheme.typography.body2,
                color = Color.Gray
            )
        }
    }
}

@Composable
fun DocumentRow(
    documentName: String,
    author: String,
    date: String,
    openPdf: () -> Unit,
    onDeleteFileClick: (String) -> Unit
) {
    Column {
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = documentName,
                color = BlueApp,
                style = MaterialTheme.typography.body1,
                maxLines = 1,
                overflow = TextOverflow.Ellipsis,
                modifier = Modifier
                    .weight(1f)
                    .padding(end = 8.dp)
                    .clickable { openPdf() }
            )
            Image(
                painter = painterResource(Res.drawable.trash),
                contentDescription = null,
                modifier = Modifier
                    .size(20.dp)
                    .clickable {
                        onDeleteFileClick(documentName)
                    }
            )
        }
        Spacer(modifier = Modifier.height(4.dp))
        Row(
            horizontalArrangement = Arrangement.SpaceBetween,
            modifier = Modifier.fillMaxWidth()
        ) {
            Text(
                text = author,
                style = MaterialTheme.typography.body2,
                color = Color.Gray
            )
            Text(
                text = date,
                style = MaterialTheme.typography.body2,
                color = Color.Gray
            )
        }
    }
}

@Composable
fun ImageRow(imageName: String, imagePainter: Painter?, onDeleteImageClick: (String) -> Unit) {
    Column {
        if (imagePainter != null) {
            Image(
                painter = imagePainter,
                contentDescription = null,
                modifier = Modifier
                    .fillMaxWidth()
                    .height(150.dp)
                    .clip(RoundedCornerShape(8.dp))
            )
        }
        Spacer(modifier = Modifier.height(8.dp))
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = imageName,
                style = MaterialTheme.typography.body2,
                color = Color.Gray
            )
            Spacer(modifier = Modifier.width(14.dp))
            Image(
                painter = painterResource(Res.drawable.trash),
                contentDescription = null,
                modifier = Modifier
                    .size(20.dp)
                    .clickable {
                        onDeleteImageClick(imageName)
                    }
            )
        }
    }
}

@Composable
fun NoteInputDialog(
    initialText: MutableState<String>,
    onDismiss: () -> Unit,
    onConfirm: () -> Unit
) {

    Dialog(onDismissRequest = onDismiss) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .clip(RoundedCornerShape(16.dp))
                .background(Color.White)
        ) {
            Column(
                modifier = Modifier.padding(16.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Text(
                    text = stringResource(Res.string.add_note_here),
                    fontSize = 20.sp,
                    color = BlueApp,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 8.dp)
                )

                OutlinedTextField(
                    value = initialText.value,
                    onValueChange = { initialText.value = it },
                    label = { Text("Enter text") },
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(bottom = 16.dp)
                )

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    horizontalArrangement = Arrangement.End
                ) {
                    TextButton(onClick = onDismiss) {
                        Text(
                            stringResource(Res.string.cancel), color = Color.LightGray
                        )
                    }
                    Spacer(modifier = Modifier.width(8.dp))
                    TextButton(onClick = {
                        onConfirm()
                    }
                    ) {
                        Text(stringResource(Res.string.ok).uppercase(), color = BlueApp)
                    }
                }
            }
        }
    }
}