package com.hyperether.goodjob.scenes.workspace

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.State
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.util.Constants
import com.hyperether.goodjob.util.DateTimeUtil
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock

class WorkspaceViewModel(
    val repository: Repository
) : ViewModel() {

    val workspaces = mutableStateListOf<Workspace>()

    var workspaceId: MutableState<String> = mutableStateOf("")

    private val _currentPlan = mutableStateOf<Plan?>(null)
    val currentPlan: State<Plan?> = _currentPlan

    init {
        getAllWorkspaces()
    }

    fun addNewWorkspace(workspace: Workspace) {
        viewModelScope.launch {
            val result = workspace.name?.let {
                workspace.workspaceType?.let { it1 ->
                    repository.addWorkspace(
                        it,
                        it1
                    )
                }
            }
            if (result?.data != null && getPlatformChecker().isMobile()) {
                repository.insertWorkspace(result.data)
                getAllWorkspaces()
            }
        }
    }

    fun getAllWorkspaces() {
        viewModelScope.launch {
            when (repository.getAllWorkspaces()) {
                is Resource.Success -> {
                    getAllWorkspacesLocal()
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    private suspend fun getAllWorkspacesLocal() {
        repository.getWorkspacesFromLocal()?.collect {
            workspaces.clear()
            workspaces.addAll(it)
        }
    }

    fun getAllJobsForWorkspace() {
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                val result = repository.getAllJobsForWorkspace()

                when (result) {
                    is Resource.Success -> {
                        println(result)
                    }

                    is Resource.Error -> {
                        println(result)
                    }
                }
            }
        }
    }

    fun getUser(): User? {
        return repository.getUser()
    }

    fun fetchCurrentPlan() {
        val user = getUser()
        if (user == null) {
            _currentPlan.value = null
            return
        }

        viewModelScope.launch {
            // todo fix null
            user.planId?.let {
                val plan = repository.getPlanById(it)
                _currentPlan.value = plan
            }

        }
    }

    fun saveWorkspaceId(workspaceId: String) {
        return repository.saveWorkspaceId(workspaceId)
    }

    fun updateLastAccessed() {
        viewModelScope.launch {
            val lastAccessed =
                DateTimeUtil.formatTimestamp(Clock.System.now().toEpochMilliseconds())
            repository.updateWorkspaceLastAccessedById(lastAccessed, workspaceId.value)
        }
    }

    fun isEmployee(): Boolean {
        return repository.getFlow() == Constants.FLOW_EMPLOYEE
    }
}