package com.hyperether.goodjob.scenes.pricing

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.geometry.Size
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.AddCardRequest
import com.hyperether.goodjob.repository.remote.model.AddPlanRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanResponse
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyResponse
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class PricingViewModel(
    val repository: Repository,
    val onPlanAdded: () -> Unit
) : ViewModel() {

    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val selectedPlan: MutableState<String?> = mutableStateOf(null)
    val selectedPlanId: MutableState<String?> = mutableStateOf(null)
    val priceBoxSize: MutableState<Size> = mutableStateOf(Size.Zero)
    var selectedOption: MutableState<String> = mutableStateOf("Monthly")

    val error = mutableStateOf<Resource<Error>?>(null)

    private val _plans = MutableStateFlow<List<Plan>>(emptyList())
    val plans: StateFlow<List<Plan>> = _plans

    private val _plans_remote = MutableStateFlow<List<Plan>>(emptyList())
    val plans_remote: StateFlow<List<Plan>> = _plans_remote

    private val paymentIntentId = mutableStateOf<String?>(null)
    val amount = mutableStateOf<Double?>(null)
    val currency = mutableStateOf<String?>(null)
    val filteredPlans = mutableStateOf<List<Plan>>(emptyList())

    init {
        viewModelScope.launch {
            println("Get plans list ${_plans.value}")
            getAllPlans()
            if (plans.value.isNotEmpty()) {
                val popularPlan = plans.value.find { it.isPopular == true }
                println("Check plans list ${_plans.value}")
                if (_plans.value.isNotEmpty()) {
                    val popularPlan = _plans.value.find { it.isPopular == true }
                    selectedPlan.value = popularPlan?.name
                    selectedPlanId.value = popularPlan?._id
                    amount.value = popularPlan?.price
                    currency.value = popularPlan?.currency
                }
            }
        }
    }


    suspend fun getAllPlans() {
        showLoader.value = true
        val result = repository.getAllPlans()
        if (result.data != null) {
            showLoader.value = false
            val localPlaIds = plans.value.map { it._id }.toSet()

            val newPlans =
                result.data.filter { it._id != null && it._id !in localPlaIds }

            if (newPlans.isNotEmpty() && getPlatformChecker().isMobile()) {
                //newPlans.forEach { repository.insertPlan(it) }
                getAllPlansLocal()
            }
            _plans_remote.value = result.data
        } else {
            showLoader.value = false
        }
    }

    private fun getAllPlansLocal() {
        viewModelScope.launch {
            repository.getPlans().collect { planList ->
                _plans.value = planList
            }
        }
    }

    fun addPlan() {
        viewModelScope.launch {
            showLoader.value = true
            selectedPlanId.value?.let { planId ->
                val result = repository.addPlan(AddPlanRequest(planId, paymentIntentId.value))
                when (result) {
                    is Resource.Success -> {
                        savePlanLocally(planId)
                        onPlanAdded()
                    }

                    is Resource.Error -> {
                        error.value = Resource.Error(result.text)
                    }
                }
            } ?: kotlin.run {
                //todo add string res
                error.value = Resource.Error("No plan selected")
            }
            showLoader.value = false
        }

    }

    fun addCard(
        addCardRequest: AddCardRequest,
        onSuccess: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            showLoader.value = true
            when (val result = repository.addCard(addCardRequest)) {
                is Resource.Success -> {
                    onSuccess()
                    selectedPlanId.value?.let { savePlanLocally(it) }
                    showLoader.value = false
                    onPlanAdded()
                    println("Add card successful")
                }

                is Resource.Error -> {
                    onError(result.text ?: "An unknown error occurred")
                }
            }
        }
    }


    fun choosePlan(
        choosePlanRequest: ChoosePlanRequest,
        onSuccess: (ChoosePlanResponse) -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            when (val result = repository.choosePlan(choosePlanRequest)) {
                is Resource.Success -> {
                    paymentIntentId.value = result.data?.paymentIntent
                    val data = result.data
                    if (data != null) {
                        onSuccess(data)
                    } else {
                        onError("No data received")
                    }
                }

                is Resource.Error -> {
                    onError(result.text ?: "An unknown error occurred")
                }
            }
        }
    }

    fun getEphemeralKey(
        ephemeralKeyRequest: EphemeralKeyRequest,
        onSuccess: (EphemeralKeyResponse) -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            when (val result = repository.getEphemeralKey(ephemeralKeyRequest)) {
                is Resource.Success -> {
                    val data = result.data
                    if (data != null) {
                        onSuccess(data)
                    } else {
                        onError("No data received")
                    }
                }

                is Resource.Error -> {
                    onError(result.text ?: "An unknown error occurred")
                }
            }
        }
    }

    fun savePlanLocally(planId: String) {
        repository.savePlanIdLocally(planId)
        selectedPlanId.value = planId
    }
}