package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Plan(
@SerialName("id") var id: Long? = 0,
@SerialName("_id") var _id: String? = null,
@SerialName("name") var name: String? = null,
@SerialName("price") var price: Double? = 0.0,
@SerialName("description") var description: String? = null,
@SerialName("storage") var storage: String? = null,
@SerialName("planSupport") var planSupport: String? = null,
@SerialName("limits") var limits: String? = null,
@SerialName("isPopular") var isPopular: Boolean? = null,
@SerialName("currency") var currency: String? = null,
@SerialName("interval") var interval: String? = null,
@SerialName("stripeProductId") var stripeProductId: String? = null,
@SerialName("stripePriceId") var stripePriceId: String? = null,
@SerialName("type") var type: String? = null
)