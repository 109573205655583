package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.derivedStateOf
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all_employees
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.scenes.components.WebUserListView
import com.hyperether.goodjob.scenes.components.WebViewHeader
import com.hyperether.goodjob.theme.DashboardBg
import org.jetbrains.compose.resources.stringResource
import org.lighthousegames.logging.logging

@Composable
fun EmployeesWebView(
    employeesViewModel: EmployeesViewModel,
    navController: NavHostController
) {
    val log = logging("EmployeesWebView")
    val itemsPerPage = 5
    val currentPage = remember { mutableStateOf(1) }

    var searchText = remember { mutableStateOf("") }
    val sortBy = remember { mutableStateOf<String?>(null) }

    val showFilterDialog = remember { mutableStateOf(false) }
    // TODO get real data
    val cities = listOf("Basel", "Zurich", "Bern", "Houston", "Miami")
    val roles = listOf("Manager", "Technician", "Administrator")
    val teams = listOf("Sales Team", "Support Team", "Technical Team")
    val statuses = listOf("Active", "Inactive", "On Leave")
    val skills = listOf("Electrical Installation", "Solar Panel Installation", "Wiring and Cabling")

    val filteredEmployees = remember { mutableStateListOf<User>() }
    val currentEmployees = derivedStateOf {
        filteredEmployees.drop((currentPage.value - 1) * itemsPerPage).take(itemsPerPage)
    }

    LaunchedEffect(Unit) {
        employeesViewModel.getAllUsersRemote()
        employeesViewModel.employees_remote.collect { list ->
            filteredEmployees.clear()
            filteredEmployees.addAll(list)
        }
    }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .background(DashboardBg)
    ) {
        WebViewHeader(
            isBackAvailable = false,
            title = stringResource(Res.string.employees),
            isImportAndAddVisible = true,
            navHostController = navController
        )
        Spacer(modifier = Modifier.height(Dp(21f)))
        StatsSection()

        Spacer(modifier = Modifier.height(Dp(13f)))

        // Employee List
        WebUserListView(
            isEmployeeList = true,
            title = stringResource(Res.string.all_employees),
            allItems = filteredEmployees,
            filteredItems = currentEmployees.value,
            sortBy = sortBy,
            searchText = searchText,
            currentPage = currentPage,
            itemsPerPage = itemsPerPage,
            showFilterDialog = showFilterDialog,
            deleteItemById = { id -> employeesViewModel.deleteEmployeesById(id) },
            saveSelectedItem = { id ->
                log.d("EmployeesScreen") { "SELECTED EMPLOYEE: $id" }
                navController.navigate(Screen.EmployeeDetails.route + "?employeeId=${id}")
            }
        )
    }

    if (showFilterDialog.value) {
        FilterEmployeesDialog(
            cities,
            roles,
            teams,
            statuses,
            skills,
            onDismiss = { showFilterDialog.value = false },
            onApply = { selectedCities, selectedRoles, selectedTeams, selectedStatuses, selectedSkills ->
                log.d("Applied Filter") { "Applied Filters: Cities=$selectedCities, Roles=$selectedRoles, Teams=$selectedTeams, Statuses=$selectedStatuses, Skills=$selectedSkills" }
//                filteredEmployees.clear()
//                val list = employees.map { Mapper().mapDbUserToSerializableUser(it) }
//                filteredEmployees.addAll(
//                    list.filter { employee ->
//                        (selectedCities.isEmpty() || selectedCities.contains(employee.city)) &&
//                                (selectedRoles.isEmpty() || selectedRoles.contains(employee.role)) // &&
////                                (selectedTeams.isEmpty() || selectedTeams.contains(employee.teams)) &&
////                                (selectedStatuses.isEmpty() || selectedStatuses.contains(employee.status)) &&
////                                (selectedSkills.isEmpty() || selectedSkills.any { skill -> skill in employee.skills })
//                    }
//                )
                showFilterDialog.value = false
            }
        )
    }
}