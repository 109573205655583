package com.hyperether.goodjob.scenes.employees

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewModelScope
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.all
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.teams
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmployeesScreen(
    isMobileView: Boolean,
    employeesViewModel: EmployeesViewModel,
    navController: NavHostController,
    prefsManager: PrefsManager,
    drawerState: DrawerState
) {

    var isAllSelected = remember { mutableStateOf(true) }
    val isInEditMode = remember { mutableStateOf(false) }
    val employees = remember { mutableStateListOf<com.hyperether.goodjob.database.User>() }

    val selectedEmployees = remember { mutableStateListOf<com.hyperether.goodjob.database.User>() }
    var showPopup = remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        employeesViewModel.getAllUsersRemote()
        employeesViewModel.employees.collect { employeeList ->
            employees.clear()
            employees.addAll(employeeList)
        }
    }

    if (isMobileView) {
        Box(modifier = Modifier.fillMaxSize()) {
            Column {
                MobileHeaderSearchAdd(
                    title = stringResource(Res.string.employees),
                    add = { navController.navigate(Screen.AddNewEmployee.route) },
                    search = {},
                    drawerState = drawerState,
                    scope = rememberCoroutineScope(),
                    flow = prefsManager.getFlow()
                )

                Column(
                    modifier = Modifier
                        .background(Color.LightGray.copy(alpha = 0.2f))
                        .padding(8.dp),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Row(
                        modifier = Modifier.fillMaxWidth().height(40.dp),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.SpaceEvenly
                    ) {

                        Spacer(modifier = Modifier.weight(0.5f))
                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.all),
                            color = if (isAllSelected.value) BlueApp else Color.Gray,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .clickable {
                                    isAllSelected.value = true
                                }
                        )

                        Spacer(modifier = Modifier.weight(1f))

                        androidx.compose.material3.Text(
                            text = stringResource(Res.string.teams),
                            color = if (isAllSelected.value) Color.Gray else BlueApp,
                            fontSize = 16.sp,
                            modifier = Modifier
                                .clickable {
                                    isAllSelected.value = false
                                    selectedEmployees.clear()
                                }
                        )
                        Spacer(modifier = Modifier.weight(0.5f))

                    }
                }
                Box(
                    modifier = Modifier
                        .height(2.dp)
                        .fillMaxWidth(0.5f)
                        .background(if (isAllSelected.value) BlueApp else BlueApp)
                        .align(if (isAllSelected.value) Alignment.Start else Alignment.End)
                )
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp, vertical = 10.dp)
                ) {
                    if (!isInEditMode.value) {
                        Text(
                            text = stringResource(Res.string.edit),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier
                                .clickable {
                                    isInEditMode.value = true
                                    showPopup.value = true
                                }
                                .weight(1f),
                            textAlign = TextAlign.Start
                        )
                    } else {
                        Text(
                            text = stringResource(Res.string.cancel),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier
                                .clickable {
                                    isInEditMode.value = false
                                    showPopup.value = false
                                }
                                .weight(1f),
                            textAlign = TextAlign.Start
                        )
                    }

                    if (!isInEditMode.value) {
                        Text(
                            text = "${stringResource(Res.string.total)}:${employees.size} Employees",
                            fontSize = 14.sp,
                            color = Color.Black,
                            maxLines = 1,
                            fontStyle = FontStyle.Italic,
                            textAlign = TextAlign.Center
                        )
                    }

                    Row(
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.weight(1f).clickable {
                            employeesViewModel.showFilterDialog.value = true
                        }
                    ) {
                        Text(
                            text = stringResource(Res.string.filter),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier.padding(end = 8.dp),
                            textAlign = TextAlign.End
                        )
                        Icon(
                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                            contentDescription = null,
                            modifier = Modifier.size(20.dp)
                        )
                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )

                //change with employee id
                EmployeesList(
                    employees = employees,
                    isInEditMode = isInEditMode.value,
                    selectedEmployees = selectedEmployees,
                    navHostController = navController
                )
            }
            if (showPopup.value) {
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .background(Color.Black.copy(alpha = 0.05f))
                )

                Box(
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .fillMaxWidth()
                ) {
                    EditDialog(
                        showDialog = showPopup.value,
                        employees = selectedEmployees,
                        onDelete = {
                            if (selectedEmployees.isNotEmpty()) {
                                employeesViewModel.showLoader.value = true
                                employeesViewModel.deleteSelectedUsers(selectedEmployees,
                                    onAllDeleted = {
                                        employeesViewModel.showPopUpSuccess.value = true
                                        employeesViewModel.showLoader.value = false
                                        selectedEmployees.clear()

                                        employeesViewModel.viewModelScope.launch {
                                            employeesViewModel.employees.collect { employeeList ->
                                                employees.clear()
                                                employees.addAll(employeeList)
                                            }
                                        }
                                    },
                                    onError = {
                                        employeesViewModel.showPopUpError.value = true
                                        employeesViewModel.showLoader.value = false
                                    })
                            }
                        },
                        onEdit = {},
                        onExportList = {},
                        onImportList = {}
                    )
                }
            }
            if (employeesViewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { employeesViewModel.showPopUpSuccess.value = false },
                    title = "SUCCESS",
                    message = "Employee deleted successfully",
                    onPositiveBtnClicked = { employeesViewModel.showPopUpSuccess.value = false },
                    positiveBtnText = "OK"
                )
            }
            if (employeesViewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { employeesViewModel.showPopUpError.value = false },
                    title = "ERROR",
                    message = employeesViewModel.errorText.value
                )
            }
            if (employeesViewModel.showLoader.value) {
                FullScreenProgressBar()
            }
            if (employeesViewModel.showFilterDialog.value) {
                FilterEmployeesDialog(
                    { employeesViewModel.showFilterDialog.value = false },
                    employeesViewModel
                )
            }
        }
    } else {
        EmployeesWebView(
            employeesViewModel = employeesViewModel,
            navController = navController
        )
    }
}