package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Workspace(
    @SerialName("_id") var _id: String? = null,
    @SerialName("id") var id: Long? = 0,
    @SerialName("name") var name: String? = null,
    @SerialName("type") var type: String? = null,
    @SerialName("userId") var userId: String? = null,
    @SerialName("planId") var planId: String? = null,
    @SerialName("workspaceType") var workspaceType: String? = null,
    @SerialName("lastAccessed") var lastAccessed: String? = null
)
