package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.contacts_mail
import com.hyperether.goodjob.resources.contacts_map_pin
import com.hyperether.goodjob.resources.contacts_share
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.sites
import com.hyperether.goodjob.scenes.employees.RoundedCornerCheckbox
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.ContactDetailsText
import com.hyperether.goodjob.theme.EmployeeDetailsText
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ContactsItem(
    contact: com.hyperether.goodjob.database.Contact,
    contactSites: List<com.hyperether.goodjob.models.Site>,
    isInEditMode: Boolean,
    onContactSelected: (com.hyperether.goodjob.database.Contact, Boolean) -> Unit,
    onItemClick: () -> Unit
) {
    var isChecked = remember { mutableStateOf(false) }

    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp)
            .clickable {
                onItemClick()
            }
    ) {
        if (isInEditMode) {
            RoundedCornerCheckbox(
                isChecked = isChecked.value,
                onCheckedChange = { checked ->
                    isChecked.value = checked
                    onContactSelected(contact, checked)
                }
            )
        }

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 8.dp)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 4.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                contact.name?.let {
                    Text(
                        text = it,
                        fontSize = 16.sp,
                        color = BlueApp,
                        fontWeight = FontWeight.Bold
                    )
                }
                Spacer(modifier = Modifier.width(15.dp))
                contact.personName?.let {
                    Text(
                        text = it,
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.contacts_mail),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                Text(
                    text = contact.email ?: "",
                    fontSize = 14.sp,
                    color = ContactDetailsText
                )
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.contacts_map_pin),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                contact.address?.let {
                    Text(
                        text = it.toString(),
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.phone),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                contact.phone?.let {
                    Text(
                        text = it.toString(),
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.contacts_share),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                contact.industry?.let {
                    Text(
                        text = it.toString(),
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                }
            }
            Spacer(modifier = Modifier.height(4.dp))

            Text(
                text = contactSites.size.toString() + " " + stringResource(Res.string.sites).lowercase(),
                fontSize = 14.sp,
                color = BlueApp
            )
        }
    }
}

@Composable
fun ContactsList(
    contacts: List<com.hyperether.goodjob.database.Contact>,
    contactSites: List<com.hyperether.goodjob.models.Site>,
    selectedContacts: MutableList<com.hyperether.goodjob.database.Contact>,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize()
    ) {
        items(contacts) { contact ->
            ContactsItem(
                contact = contact,
                isInEditMode = isInEditMode,
                onContactSelected = { selectedContact, isSelected ->
                    if (isSelected) {
                        selectedContacts.add(selectedContact)
                    } else {
                        selectedContacts.remove(selectedContact)
                    }
                },
                onItemClick = {
                    navHostController.navigate(Screen.ContactDetails.route + "/${contact._id}")
                },
                contactSites = contactSites
            )
            Divider(
                //modifier = Modifier.padding(),
                color = Color.Gray,
                thickness = 0.5.dp
            )
        }
    }
}

@Composable
fun EditContactsDialog(
    showDialog: Boolean,
    employees: List<com.hyperether.goodjob.database.Contact>,
    onDelete: () -> Unit,
    onExportList: () -> Unit,
    onImportList: () -> Unit,
    onEdit: () -> Unit
) {
    if (showDialog) {
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp))
            //.shadow(1.dp, RoundedCornerShape(8.dp))
        ) {
            Column() {
                Text(
                    text = "${employees.size} selected",
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    Text(
                        text = stringResource(Res.string.delete),
                        fontSize = 14.sp,
                        color = Color.Red,
                        modifier = Modifier.clickable {
                            onDelete()
                        })
                    Text(
                        text = stringResource(Res.string.export_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onExportList()
                        })
                    Text(
                        text = stringResource(Res.string.import_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onImportList()
                        })
                    Text(text = stringResource(Res.string.edit), fontSize = 14.sp, color = BlueApp,
                        modifier = Modifier.clickable {
                            onEdit()
                        })
                }
            }
        }
    }
}

@Composable
fun DetailsTextItem(header: String, detailsText: String, color: Color = ContactDetailsText) {
    Column {
        Text(
            text = header,
            color = EmployeeDetailsText,
            fontWeight = FontWeight.W400,
            fontSize = 14.sp,
            modifier = Modifier.padding(start = 16.dp, end = 16.dp)

        )
        Text(
            text = detailsText,
            color = color,
            fontWeight = FontWeight.W500,
            fontSize = 14.sp,
            modifier = Modifier.padding(start = 16.dp, end = 16.dp)
        )
        Spacer(modifier = Modifier.height(12.dp))
    }
}

@Composable
fun SitesList(
    sites: List<com.hyperether.goodjob.models.Site>,
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize().padding(start = 16.dp, end = 16.dp)
    ) {
        items(sites) { site ->
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 15.dp)
                    .border(1.dp, Color.LightGray, RoundedCornerShape(8.dp))
            ) {
                Column(modifier = Modifier.padding(12.dp)) {
                    Row(
                        modifier = Modifier
                            .fillMaxWidth()
                            .padding(top = 4.dp),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        Text(
                            text = site.siteName ?: "",
                            fontSize = 16.sp,
                            fontWeight = FontWeight.Bold,
                            color = BlueApp
                        )
                        Spacer(modifier = Modifier.width(15.dp))

                        Text(
                            text = site.personName ?: "",
                            fontSize = 14.sp,
                            color = ContactDetailsText
                        )
                    }
                    Text(
                        text = site.personAddress ?: "",
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                    Text(
                        text = site.personEmail ?: "",
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                    Text(
                        text = site.personPhone ?: "",
                        fontSize = 14.sp,
                        color = ContactDetailsText
                    )
                }
            }
        }
    }
}

