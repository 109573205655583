package com.hyperether.goodjob.models

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Skills(
    @SerialName("_id") var _id: String? = null,
    @SerialName("id") var id: Long? = null,
    @SerialName("name") var name: String? = null,
    @SerialName("workspaceId") var workspaceId: String? = null
)