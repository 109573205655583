package com.hyperether.goodjob.scenes.contacts

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.lifecycle.viewModelScope
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.contacts
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun ContactsScreen(
    navHostController: NavHostController,
    viewModel: ContactsViewModel,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {

    val contacts = remember { mutableStateListOf<com.hyperether.goodjob.database.Contact>() }
    val selectedContacts =
        remember { mutableStateListOf<com.hyperether.goodjob.database.Contact>() }

    var cities = contacts.mapNotNull { it.city }
    var industries = contacts.mapNotNull { it.industry }
    var contactTypes = contacts.mapNotNull { it.contactType }


    LaunchedEffect(Unit) {
        viewModel.getContactsFromLocal()
        viewModel.contacts.collect { contactsList ->
            contacts.clear()
            contacts.addAll(contactsList)
            cities = contacts.mapNotNull { it.city }
            industries = contacts.mapNotNull { it.industry }
            contactTypes = contacts.mapNotNull { it.contactType }
        }
    }
    println(contacts.toList())

    Box(modifier = Modifier.fillMaxSize()) {

        Column {
            MobileHeaderSearchAdd(
                title = stringResource(Res.string.contacts),
                add = { navHostController.navigate(Screen.AddNewContact.route) },
                search = {},
                drawerState = drawerState,
                scope = rememberCoroutineScope(),
                flow = prefsManager.getFlow()
            )
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 10.dp)
            ) {
                if (!viewModel.isInEditMode.value) {
                    Text(
                        text = stringResource(Res.string.edit),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier
                            .clickable {
                                viewModel.isInEditMode.value = true
                                viewModel.showEditPopUp.value = true
                            }
                            .weight(1f),
                        textAlign = TextAlign.Start
                    )
                } else {
                    Text(
                        text = stringResource(Res.string.cancel),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier
                            .clickable {
                                viewModel.isInEditMode.value = false
                                viewModel.showEditPopUp.value = false
                                selectedContacts.clear()
                            }
                            .weight(1f),
                        textAlign = TextAlign.Start
                    )
                }

                if (!viewModel.isInEditMode.value) {
                    Text(
                        text = "${stringResource(Res.string.total)}: ${contacts.size}",
                        fontSize = 14.sp,
                        color = Color.Black,
                        maxLines = 1,
                        fontStyle = FontStyle.Italic,
                        textAlign = TextAlign.Center
                    )
                }

                Row(
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.weight(1f)
                        .clickable {
                            viewModel.showFilterDialog.value = true
                        }
                ) {
                    Text(
                        text = stringResource(Res.string.filter),
                        fontSize = 14.sp,
                        color = TextColor,
                        modifier = Modifier.padding(end = 8.dp),
                        textAlign = TextAlign.End
                    )
                    Icon(
                        painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                        contentDescription = null,
                        modifier = Modifier.size(20.dp)
                    )
                }
            }
            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))

            ContactsList(
                contacts = contacts,
                contactSites = viewModel.contactSites.value,
                selectedContacts = selectedContacts,
                viewModel.isInEditMode.value,
                navHostController
            )

        }
        if (viewModel.showEditPopUp.value) {
            Box(
                modifier = Modifier
                    .fillMaxSize()
                    .background(Color.Black.copy(alpha = 0.05f))
            )

            Box(
                modifier = Modifier
                    .align(Alignment.BottomCenter)
                    .fillMaxWidth()
            ) {
                EditContactsDialog(
                    showDialog = viewModel.showEditPopUp.value,
                    employees = selectedContacts,
                    onDelete = {
                        viewModel.showLoader.value = true
                        viewModel.deleteSelectedContacts(selectedContacts,
                            onAllDeleted = {
                                viewModel.isInEditMode.value = false
                                viewModel.showPopUpSuccess.value = true
                                viewModel.showEditPopUp.value = false
                                viewModel.showLoader.value = false
                                viewModel.getContactsFromLocal()
                            },
                            onError = {
                                viewModel.isInEditMode.value = false
                                viewModel.showPopUpError.value = true
                                viewModel.showLoader.value = false
                                viewModel.showEditPopUp.value = false
                            })
                    },
                    onEdit = {},
                    onExportList = {},
                    onImportList = {}
                )
            }
        }
        if (viewModel.showPopUpSuccess.value) {
            PopUpDialog(
                onDismiss = { viewModel.showPopUpSuccess.value = false },
                title = "SUCCESS",
                message = "Contact deleted successfully",
                onPositiveBtnClicked = { viewModel.showPopUpSuccess.value = false },
                positiveBtnText = "OK"
            )
        }
        if (viewModel.showPopUpError.value) {
            ErrorDialog(
                onDismiss = { viewModel.showPopUpError.value = false },
                title = "ERROR",
                message = viewModel.errorText.value
            )
        }
        if (viewModel.showLoader.value) {
            FullScreenProgressBar()
        }
    }

    if (viewModel.showFilterDialog.value) {
        FilterContactsDialog(
            cities,
            industries,
            contactTypes,
            onDismiss = { viewModel.showFilterDialog.value = false },
            onApply = { selectedCities, selectedIndustries, selectedContactTypes ->
                viewModel.showFilterDialog.value = false
            }
        )
    }
}