package com.hyperether.goodjob.scenes.register

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.text.ClickableText
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusRequester
import androidx.compose.ui.focus.focusRequester
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.style.TextDecoration
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.already_have_an_account
import com.hyperether.goodjob.resources.app_logo
import com.hyperether.goodjob.resources.confirm_password_reset
import com.hyperether.goodjob.resources.confirm_registration
import com.hyperether.goodjob.resources.contact_ic
import com.hyperether.goodjob.resources.create_one
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.email_ic
import com.hyperether.goodjob.resources.forgot_password
import com.hyperether.goodjob.resources.full_name
import com.hyperether.goodjob.resources.hello
import com.hyperether.goodjob.resources.join_good_job
import com.hyperether.goodjob.resources.join_us
import com.hyperether.goodjob.resources.manage_employees
import com.hyperether.goodjob.resources.need_an_account
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.pass_ic
import com.hyperether.goodjob.resources.password
import com.hyperether.goodjob.resources.ready_for
import com.hyperether.goodjob.resources.register
import com.hyperether.goodjob.resources.register_with
import com.hyperether.goodjob.resources.repeat_password
import com.hyperether.goodjob.resources.reset_link_sent
import com.hyperether.goodjob.resources.sign_in
import com.hyperether.goodjob.resources.sign_in_1
import com.hyperether.goodjob.resources.sign_in_with
import com.hyperether.goodjob.resources.start_organizing
import com.hyperether.goodjob.resources.thank_you_for_registering
import com.hyperether.goodjob.resources.web_left_content
import com.hyperether.goodjob.resources.welcome_back
import com.hyperether.goodjob.resources.your_email_address
import com.hyperether.goodjob.resources.your_full_name
import com.hyperether.goodjob.resources.your_password
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PasswordInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.RegisterSubTitle
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun RegisterScreen(
    isMobileView: Boolean,
    registerViewModel: RegisterViewModel,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    navHostController: NavHostController
) {

    val isRegisterSelected = remember { mutableStateOf(false) }

    if (isMobileView) {
        Column(
            modifier = Modifier.fillMaxSize()
        ) {

            Box(
                modifier = Modifier
                    .fillMaxWidth()
            ) {
                Image(
                    painter = painterResource(Res.drawable.app_logo),
                    contentDescription = "app logo",
                    modifier = Modifier
                        .align(Alignment.TopCenter)
                        .padding(bottom = 16.dp, top = 30.dp)
                )

            }
            Column(
                modifier = Modifier
                    .padding(top = 20.dp, start = 16.dp, end = 16.dp)
                    .background(Color.LightGray.copy(alpha = 0.2f))
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(40.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    Text(
                        text = stringResource(Res.string.register),
                        color = if (isRegisterSelected.value) BlueApp else Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(start = 40.dp)
                            .clickable {
                                isRegisterSelected.value = true
                            }
                    )

                    Spacer(modifier = Modifier.weight(0.5f))

                    Text(
                        text = stringResource(Res.string.sign_in),
                        color = if (isRegisterSelected.value) Color.Gray else BlueApp,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(end = 40.dp)
                            .clickable {
                                isRegisterSelected.value = false
                            }
                    )
                }


            }
            val nameInput = remember { mutableStateOf("") }
            val mailInput = remember { mutableStateOf("") }
            val passwordInput = remember { mutableStateOf("") }
            val repeatPasswordInput = remember { mutableStateOf("") }

            Box(
                modifier = Modifier
                    .height(2.dp)
                    .padding(start = 16.dp, end = 16.dp)
                    .fillMaxWidth(0.5f)
                    .background(if (isRegisterSelected.value) BlueApp else BlueApp)
                    .align(if (isRegisterSelected.value) Alignment.Start else Alignment.End)
            )

            if (isRegisterSelected.value) {
                RegisterSignInContent(
                    stringResource(Res.string.join_good_job),
                    stringResource(Res.string.start_organizing),
                    nameInput,
                    mailInput,
                    passwordInput,
                    repeatPasswordInput,
                    stringResource(Res.string.register),
                    stringResource(Res.string.register_with),
                    isRegisterSelected = isRegisterSelected.value,
                    registerViewModel,
                    navHostController = navHostController,
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel
                ) {
                    isRegisterSelected.value = false
                }
            } else {
                RegisterSignInContent(
                    stringResource(Res.string.welcome_back),
                    stringResource(Res.string.manage_employees),
                    nameInput,
                    mailInput,
                    passwordInput,
                    repeatPasswordInput = repeatPasswordInput,
                    stringResource(Res.string.sign_in),
                    stringResource(Res.string.sign_in_with),
                    isRegisterSelected = isRegisterSelected.value,
                    registerViewModel,
                    navHostController = navHostController,
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel
                ) {
                    isRegisterSelected.value = false
                }
            }
//            IconButtonRow(
//                onFbClick = { },
//                onGoogleClick = { },
//                onAppleClick = { }
//            )
        }
    } else {
        val nameInput = remember { mutableStateOf("") }
        val mailInput = remember { mutableStateOf("") }
        val passwordInput = remember { mutableStateOf("") }
        Row(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(rememberScrollState())
        ) {
            Image(
                modifier = Modifier.weight(2.5f),
                painter = painterResource(Res.drawable.web_left_content),
                contentDescription = null,
                contentScale = ContentScale.Crop,
                alignment = Alignment.CenterStart
            )
            Column(
                modifier = Modifier
                    .fillMaxHeight()
                    .weight(1.5f)
            ) {
                RegisterSignInContentWeb(
                    title = if (isRegisterSelected.value) stringResource(Res.string.hello) else stringResource(
                        Res.string.welcome_back
                    ),
                    description = if (isRegisterSelected.value) stringResource(Res.string.join_us) else stringResource(
                        Res.string.ready_for
                    ),
                    firstInputText = nameInput,
                    secondInputText = mailInput,
                    thirdInputText = passwordInput,
                    btnText = if (isRegisterSelected.value) stringResource(Res.string.register) else stringResource(
                        Res.string.sign_in
                    ),
                    dividerText = if (isRegisterSelected.value) stringResource(Res.string.register_with) else stringResource(
                        Res.string.sign_in_with
                    ),
                    isRegisterSelected = isRegisterSelected,
                    viewModel = registerViewModel,
                    navHostController = navHostController
                )
            }
        }
    }
}

@Composable
fun RegisterSignInContent(
    title: String,
    description: String,
    firstInputText: MutableState<String>,
    secondInputText: MutableState<String>,
    thirdInputText: MutableState<String>,
    repeatPasswordInput: MutableState<String>,
    btnText: String,
    dividerText: String,
    isRegisterSelected: Boolean,
    viewModel: RegisterViewModel,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    navHostController: NavHostController,
    selectLogin: () -> Unit
) {

    var showSuccessDialog = remember { mutableStateOf(false) }
    var showErrorDialog = remember { mutableStateOf(false) }
    val showProgressBar = remember { mutableStateOf(false) }
    val showForgotPasswordView = remember { mutableStateOf(false) }


    Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
        Text(
            text = title,
            color = Color.Black,
            fontSize = 24.sp,
            fontWeight = FontWeight.Bold,
            modifier = Modifier.padding(top = 25.dp)
        )
        Text(
            text = description,
            color = Color.Gray,
            textAlign = TextAlign.Left,
            fontSize = 16.sp,
            modifier = Modifier.padding(top = 20.dp, bottom = 15.dp)
        )
        if (isRegisterSelected) {
            AppInputField(
                firstInputText,
                stringResource(Res.string.full_name),
                placeholder = stringResource(Res.string.your_full_name)
            )
        }
        SignInInputField(
            secondInputText,
            stringResource(Res.string.email_address),
            placeholder = stringResource(Res.string.your_email_address)
        )
        PasswordInputField(
            thirdInputText,
            stringResource(Res.string.password),
            placeholder = stringResource(Res.string.your_password)
        )
        if (isRegisterSelected) {
            PasswordInputField(
                repeatPasswordInput,
                stringResource(Res.string.repeat_password),
                placeholder = stringResource(Res.string.repeat_password)
            )
        }

        if (!isRegisterSelected) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.End,
                verticalAlignment = Alignment.CenterVertically
            ) {
                Text(
                    text = stringResource(Res.string.forgot_password),
                    color = BlueApp,
                    modifier = Modifier
                        .padding(top = 15.dp)
                        .clickable {
                            showForgotPasswordView.value = true
                        }
                )
            }
        }

        FilledButton(
            onClick = {
                showProgressBar.value = true
                if (isRegisterSelected) {
                    viewModel.register(firstInputText.value,
                        secondInputText.value,
                        thirdInputText.value,
                        onSuccess = {
                            showProgressBar.value = false
                            showSuccessDialog.value = true
                        },
                        onError = {
                            showProgressBar.value = false
                            showErrorDialog.value = true
                        })
                } else {
                    viewModel.login(secondInputText.value, thirdInputText.value,
                        onSuccess = {
                            showProgressBar.value = false
                            workspaceViewModel.getAllWorkspaces()
                            CoroutineScope(Dispatchers.Default).launch {
                                pricingViewModel.getAllPlans()
                            }
                            if (viewModel.isEmployee()) {
                                //todo uncomment if you want to go directly to dashboard
//                                navHostController.navigate(Screen.Dashboard.route) {
//                                    popUpTo(Screen.Register.route) {
//                                        inclusive = true
//                                    }
//                                }
                                navHostController.navigate(Screen.Workspace.route) {
                                    popUpTo(Screen.Register.route) {
                                        inclusive = true
                                    }
                                }
                            } else {
                                if (viewModel.getUser() != null && viewModel.getUser()?.planId?.isNotEmpty() == true) {
                                    navHostController.navigate(Screen.Workspace.route) {
                                        popUpTo(Screen.Register.route) {
                                            inclusive = true
                                        }
                                    }
                                } else {
                                    navHostController.navigate(Screen.Pricing.route)
                                }
                            }
                        },
                        onError = {
                            showProgressBar.value = false
                            showErrorDialog.value = true
                        })
                }
            },
            text = btnText,
            modifier = Modifier.padding(top = 30.dp),
            isEnabled = if (isRegisterSelected) {
                firstInputText.value.isNotEmpty() && secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty() && repeatPasswordInput.value.isNotEmpty() &&
                        (thirdInputText.value == repeatPasswordInput.value)
            } else {
                secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty()
            }
        )
        Row(
            modifier = Modifier.fillMaxWidth().padding(top = 25.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
//            Box(
//                modifier = Modifier
//                    .weight(1f)
//                    .height(1.dp)
//                    .background(Color.Gray)
//            )
//
//            Text(
//                text = dividerText,
//                modifier = Modifier
//                    .padding(horizontal = 16.dp)
//                    .align(Alignment.CenterVertically),
//                color = Color.Gray,
//                fontSize = 16.sp
//            )
//
//            Box(
//                modifier = Modifier
//                    .weight(1f)
//                    .height(1.dp)
//                    .background(Color.Gray)
//            )
            if (showSuccessDialog.value) {
                PopUpDialog(
                    onDismiss = {
                        showSuccessDialog.value = false
                        selectLogin()
                    },
                    title = stringResource(Res.string.confirm_registration),
                    message = stringResource(Res.string.thank_you_for_registering),
                    onPositiveBtnClicked = {
                        showSuccessDialog.value = false
                        selectLogin()
                    },
                    positiveBtnText = stringResource(Res.string.ok)
                )
            }

            if (viewModel.showSendResetLinkMessage.value) {
                PopUpDialog(
                    onDismiss = { viewModel.showSendResetLinkMessage.value = false },
                    title = stringResource(Res.string.reset_link_sent),
                    message = stringResource(Res.string.confirm_password_reset),
                    onPositiveBtnClicked = { viewModel.showSendResetLinkMessage.value = false },
                    positiveBtnText = stringResource(Res.string.ok)
                )
            }

            if (showForgotPasswordView.value) {
                ForgotPasswordView(
                    onDismiss = {
                        showForgotPasswordView.value = false
                    },
                    emailInput = viewModel.emailInput,
                    onSendResetLink = {
                        showForgotPasswordView.value = false
                        viewModel.forgotPassword()

                    }
                )
            }

            if (showErrorDialog.value) {
                ErrorDialog(
                    onDismiss = {
                        showErrorDialog.value = false
                    },
                    title = "Error",
                    message = viewModel.errorText.value
                )
            }
            if (showProgressBar.value) {
                FullScreenProgressBar()
            }
        }

    }
}

@Composable
fun RegisterSignInContentWeb(
    title: String,
    description: String,
    firstInputText: MutableState<String>,
    secondInputText: MutableState<String>,
    thirdInputText: MutableState<String>,
    btnText: String,
    dividerText: String,
    isRegisterSelected: MutableState<Boolean>,
    viewModel: RegisterViewModel,
    navHostController: NavHostController
) {

    var showSuccessDialog = remember { mutableStateOf(false) }
    var showErrorDialog = remember { mutableStateOf(false) }
    val showProgressBar = remember { mutableStateOf(false) }

    val focusManager = LocalFocusManager.current
    val focusRequester = FocusRequester()

    Column(
        modifier = Modifier.fillMaxSize()
            .padding(bottom = Dp(92f), top = Dp(32f), start = Dp(110f), end = Dp(110f)),
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
        ) {
            Text(
                text = title,
                color = Color.Black,
                fontSize = Sp(32f),
                lineHeight = Sp(38f),
                fontWeight = FontWeight.W700,
                modifier = Modifier.align(Alignment.CenterHorizontally)
            )
            Spacer(modifier = Modifier.height(Dp(11f)))
            Text(
                text = description,
                color = RegisterSubTitle,
                textAlign = TextAlign.Center,
                fontSize = Sp(16f),
                lineHeight = Sp(20f),
                modifier = Modifier.align(Alignment.CenterHorizontally)
            )
            Spacer(modifier = Modifier.height(Dp(41f)))
            if (isRegisterSelected.value) {
                RegisterInputFieldWeb(
                    firstInputText,
                    stringResource(Res.string.full_name),
                    Res.drawable.contact_ic,
                    focusManager,
                    Modifier.focusRequester(focusRequester)
                )
                LaunchedEffect(true) {
                    focusRequester.requestFocus()
                }
            }
            RegisterInputFieldWeb(
                secondInputText,
                stringResource(Res.string.email_address),
                Res.drawable.email_ic,
                focusManager
            )
            RegisterPasswordInputFieldWeb(
                thirdInputText,
                stringResource(Res.string.password),
                Res.drawable.pass_ic,
                focusManager
            )
            if (!isRegisterSelected.value) {
                Spacer(modifier = Modifier.height(Dp(11f)))
                Text(
                    text = stringResource(Res.string.forgot_password),
                    color = BlueApp.copy(alpha = 0.7f),
                    modifier = Modifier
                        .align(Alignment.End)
                        .padding(top = 15.dp)
                        .clickable {
                        }
                )
            }
            Spacer(modifier = Modifier.height(Dp(24f)))
            FilledButton(
                onClick = {
                    showProgressBar.value = true
                    if (isRegisterSelected.value) {
                        viewModel.register(firstInputText.value,
                            secondInputText.value,
                            thirdInputText.value,
                            onSuccess = {
                                showProgressBar.value = false
                                showSuccessDialog.value = true
                            },
                            onError = {
                                showProgressBar.value = false
                                showErrorDialog.value = true
                            })
                    } else {
                        viewModel.login(secondInputText.value, thirdInputText.value,
                            onSuccess = {
                                showProgressBar.value = false
                                if (viewModel.isEmployee()) {
                                    //todo uncomment if you want to go directly to dashboard
//                                    navHostController.navigate(Screen.Dashboard.route) {
//                                        popUpTo(Screen.Register.route) {
//                                            inclusive = true
//                                        }
//                                    }
                                    navHostController.navigate(Screen.Workspace.route) {
                                        popUpTo(Screen.Register.route) {
                                            inclusive = true
                                        }
                                    }
                                } else {
                                    if (viewModel.getUser() != null && viewModel.getUser()?.planId?.isNotEmpty() == true) {
                                        navHostController.navigate(Screen.Workspace.route) {
                                            popUpTo(Screen.Register.route) {
                                                inclusive = true
                                            }
                                        }
                                    } else {
                                        navHostController.navigate(Screen.Pricing.route)
                                    }
                                }
                            },
                            onError = {
                                showProgressBar.value = false
                                showErrorDialog.value = true
                            })
                    }
                },
                text = btnText,
                modifier = Modifier.padding(top = 30.dp),
                isEnabled = if (isRegisterSelected.value) {
                    firstInputText.value.isNotEmpty() && secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty()
                } else {
                    secondInputText.value.isNotEmpty() && thirdInputText.value.isNotEmpty()
                }
            )
//            Row(
//                modifier = Modifier.fillMaxWidth().padding(top = 25.dp),
//                verticalAlignment = Alignment.CenterVertically
//            ) {
//                Box(
//                    modifier = Modifier
//                        .weight(1f)
//                        .height(1.dp)
//                        .background(RegisterBorder)
//                )
//
//                Text(
//                    text = dividerText,
//                    modifier = Modifier
//                        .padding(horizontal = 16.dp)
//                        .align(Alignment.CenterVertically),
//                    color = Color(0xFF6E6E6E),
//                    fontSize = 16.sp
//                )
//
//                Box(
//                    modifier = Modifier
//                        .weight(1f)
//                        .height(1.dp)
//                        .background(RegisterBorder)
//                )
//            }
//            Spacer(modifier = Modifier.height(Dp(13f)))
//            RegisterWithButton(
//                onClick = {
//                    // TODO
//                },
//                text = stringResource(Res.string.continue_with_google),
//                isEnabled = true,
//                drawableResource = Res.drawable.google
//            )
//            Spacer(modifier = Modifier.height(Dp(13f)))
//            RegisterWithButton(
//                onClick = {
//                    // TODO
//                },
//                text = stringResource(Res.string.continue_with_microsoft),
//                isEnabled = true,
//                drawableResource = Res.drawable.ic_microsoft
//            )
//            Spacer(modifier = Modifier.height(Dp(13f)))
//            RegisterWithButton(
//                onClick = {
//                    // TODO
//                },
//                text = stringResource(Res.string.continue_with_fb),
//                isEnabled = true,
//                drawableResource = Res.drawable.fb
//            )
//            Spacer(modifier = Modifier.height(Dp(13f)))
//            RegisterWithButton(
//                onClick = {
//                    // TODO
//                },
//                text = stringResource(Res.string.continue_with_apple),
//                isEnabled = true,
//                drawableResource = Res.drawable.apple
//            )
//            Spacer(modifier = Modifier.height(Dp(13f)))

        }
        Spacer(modifier = Modifier.height(50.dp))
        Box(
            modifier = Modifier
                .fillMaxWidth()
                .padding(vertical = 16.dp),
            contentAlignment = Alignment.Center
        ) {
            if (isRegisterSelected.value) {
                TextWithClickablePart(
                    nonClickableText = stringResource(Res.string.already_have_an_account),
                    clickableText = stringResource(Res.string.sign_in_1),
                    onClick = { isRegisterSelected.value = false }
                )
            } else {
                TextWithClickablePart(
                    nonClickableText = stringResource(Res.string.need_an_account),
                    clickableText = stringResource(Res.string.create_one),
                    onClick = { isRegisterSelected.value = true }
                )
            }
        }


//        Box(
//            modifier = Modifier
//                .align(Alignment.BottomCenter)
//                .padding(16.dp)
//        ) {
//            if (isRegisterSelected.value) {
//                TextWithClickablePart(
//                    nonClickableText = "Already have an account?",
//                    clickableText = "Sign in",
//                    onClick = { isRegisterSelected.value = false }
//                )
//            } else {
//                TextWithClickablePart(
//                    nonClickableText = "Need an account?",
//                    clickableText = "Create one",
//                    onClick = { isRegisterSelected.value = true }
//                )
//            }
//        }

    }
    if (showSuccessDialog.value) {
        PopUpDialog(
            onDismiss = {
                showSuccessDialog.value = false
            },
            title = "Confirm registration",
            message = "Thank you for registering! We’ve sent a confirmation link to your email. Please check your inbox and click the link to complete your registration.",
            onPositiveBtnClicked = { showSuccessDialog.value = false },
            positiveBtnText = "OK"
        )
    }

    if (showErrorDialog.value) {
        ErrorDialog(
            onDismiss = {
                showErrorDialog.value = false
            },
            title = "Error",
            message = viewModel.errorText.value
        )
    }
    if (showProgressBar.value) {
        FullScreenProgressBar()
    }

}

@Composable
fun TextWithClickablePart(
    nonClickableText: String,
    clickableText: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier
) {
    val annotatedText = buildAnnotatedString {
        withStyle(
            style = SpanStyle(
                color = Color(0xFF797676),
                fontSize = 16.sp
            )
        ) {
            append(nonClickableText)
        }
        append(" ")

        pushStringAnnotation(tag = "CLICKABLE", annotation = "clickable")
        withStyle(
            style = SpanStyle(
                color = BlueApp,
                textDecoration = TextDecoration.Underline,
                fontSize = 16.sp
            )
        ) {
            append(clickableText)
        }
        pop()
    }

    ClickableText(
        text = annotatedText,
        onClick = { offset ->
            annotatedText.getStringAnnotations(tag = "CLICKABLE", start = offset, end = offset)
                .firstOrNull()?.let {
                    onClick()
                }
        },
        modifier = modifier.padding(bottom = 16.dp)
    )
}