package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.chevron_left
import com.hyperether.goodjob.resources.chevron_right
import com.hyperether.goodjob.scenes.components.Text18Bold
import com.hyperether.goodjob.theme.GrayBackground
import com.hyperether.goodjob.theme.GrayBackgroundDivider
import org.jetbrains.compose.resources.painterResource


@Composable
fun PeriodChooser(state: MutableState<PeriodType>, changed: () -> Unit) {
    Row(modifier = Modifier.height(40.dp)) {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier
                .fillMaxHeight()
                .background(
                    GrayBackground,
                    shape = RoundedCornerShape(topStart = 10.dp, bottomStart = 10.dp)
                )
                .padding(horizontal = 20.dp)
                .clickable {
                    state.value = PeriodType.DAY
                    changed()
                }
        ) {
            Text("Day", color = if (state.value == PeriodType.DAY) Color.Blue else Color.Black)
        }
        Box(
            modifier = Modifier.fillMaxHeight().width(1.dp).background(GrayBackgroundDivider)
        )

        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxHeight().background(
                GrayBackground
            ).padding(horizontal = 20.dp).clickable {
                state.value = PeriodType.WEEK
                changed()
            }
        ) {
            Text("Week", color = if (state.value == PeriodType.WEEK) Color.Blue else Color.Black)
        }
        Box(
            modifier = Modifier.fillMaxHeight().width(1.dp).background(GrayBackgroundDivider)
        )

        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxHeight().background(
                GrayBackground,
                shape = RoundedCornerShape(topEnd = 10.dp, bottomEnd = 10.dp)
            ).padding(horizontal = 20.dp).clickable {
                state.value = PeriodType.MONTH
                changed()
            }
        ) {
            Text("Month", color = if (state.value == PeriodType.MONTH) Color.Blue else Color.Black)
        }
    }
}

@Composable
fun PeriodSwitcher(text: String, prev: () -> Unit, next: () -> Unit) {
    Row(
        Modifier.fillMaxWidth().padding(horizontal = 20.dp, vertical = 8.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        IconButton(
            onClick = {
                prev()
            }
        ) {
            Icon(
                painter = painterResource(Res.drawable.chevron_left),
                contentDescription = "Previous date or week"
            )
        }
        Text18Bold(text, modifier = Modifier.weight(1f))
        IconButton(
            onClick = {
                next()
            }
        ) {
            Icon(
                painter = painterResource(Res.drawable.chevron_right),
                contentDescription = "Next date or week"
            )
        }
    }
}

