package com.hyperether.goodjob.util

class FileUtil {

    fun getFileType(fileName: String): FileType {
        return when {
            fileName.endsWith(".jpg", ignoreCase = true) ||
                    fileName.endsWith(".png", ignoreCase = true) -> FileType.Image
            fileName.endsWith(".pdf", ignoreCase = true) -> FileType.PDF
            else -> FileType.Unknown
        }
    }
}