package com.hyperether.goodjob

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import org.lighthousegames.logging.logging

val log = logging("Sizes")

fun dpToSizeInPx(maxWidth: Dp, maxHeight: Dp, density: Density): Size {
    val widthPx = with(density) { maxWidth.toPx() }
    val heightPx = with(density) { maxHeight.toPx() }
    return Size(widthPx, heightPx)
}

val containerSize: MutableState<Size> = mutableStateOf(Size.Zero)
fun Sp(size: Float): TextUnit = when {
    isWebMobileView() -> (size * 3).sp
    is4K() -> (size * 2).sp
    else -> size.sp
}

fun Dp(size: Float): Dp = when {
    isWebMobileView() -> (size * 3).dp
    is4K() -> (size * 2).dp
    else -> size.dp
}


fun Sp10() = GetSize(heightOrWidth = containerSize.value.height, 70f, 10f).sp
fun Sp16() = GetSize(heightOrWidth = containerSize.value.height, 65f, 16f).sp
fun Sp18() = GetSize(heightOrWidth = containerSize.value.height, 50f, 18f).sp
fun Sp25() = GetSize(heightOrWidth = containerSize.value.height, 35f, 25f).sp
fun Sp30() = GetSize(heightOrWidth = containerSize.value.height, 28f, 30f).sp
fun Sp50() = GetSize(heightOrWidth = containerSize.value.height, 17f, 50f).sp

fun Dp5Height() = GetSize(heightOrWidth = containerSize.value.height, 320f, 5f).dp
fun Dp10Height() = GetSize(heightOrWidth = containerSize.value.height, 160f, 10f).dp
fun Dp15Height() = GetSize(heightOrWidth = containerSize.value.height, 120f, 15f).dp
fun Dp20Height() = GetSize(heightOrWidth = containerSize.value.height, 80f, 20f).dp
fun Dp30Height() = GetSize(heightOrWidth = containerSize.value.height, 40f, 30f).dp
fun Dp40Height() = GetSize(heightOrWidth = containerSize.value.height, 20f, 40f).dp
fun Dp50Height() = GetSize(heightOrWidth = containerSize.value.height, 17f, 50f).dp
fun Dp70Height() = GetSize(heightOrWidth = containerSize.value.height, 14.5f, 70f).dp
fun Dp100Height() = GetSize(heightOrWidth = containerSize.value.height, 13f, 100f).dp
fun Dp120Height() = GetSize(heightOrWidth = containerSize.value.height, 12.25f, 120f).dp
fun Dp150Height() = GetSize(heightOrWidth = containerSize.value.height, 11.5f, 150f).dp
fun Dp200Height() = GetSize(heightOrWidth = containerSize.value.height, 10f, 200f).dp
fun Dp300Height() = GetSize(heightOrWidth = containerSize.value.height, 8f, 300f).dp
fun Dp400Height() = GetSize(heightOrWidth = containerSize.value.height, 2.5f, 400f).dp
fun Dp500Height() = GetSize(heightOrWidth = containerSize.value.height, 3.5f, 500f).dp
fun Dp600Height() = GetSize(heightOrWidth = containerSize.value.height, 3f, 600f).dp
fun Dp700Height() = GetSize(heightOrWidth = containerSize.value.height, 2.5f, 700f).dp

fun Dp5Width() = GetSize(heightOrWidth = containerSize.value.width, 200f, 5f).dp
fun Dp10Width() = GetSize(heightOrWidth = containerSize.value.width, 100f, 10f).dp
fun Dp15Width() = GetSize(heightOrWidth = containerSize.value.width, 75f, 15f).dp
fun Dp20Width() = GetSize(heightOrWidth = containerSize.value.width, 50f, 20f).dp
fun Dp50Width() = GetSize(heightOrWidth = containerSize.value.width, 17f, 50f).dp
fun Dp70Width() = GetSize(heightOrWidth = containerSize.value.width, 15f, 70f).dp
fun Dp100Width() = GetSize(heightOrWidth = containerSize.value.width, 10f, 100f).dp
fun Dp150Width() = GetSize(heightOrWidth = containerSize.value.width, 7f, 150f).dp
fun Dp200Width() = GetSize(heightOrWidth = containerSize.value.width, 6f, 200f).dp
fun Dp300Width() = GetSize(heightOrWidth = containerSize.value.width, 5f, 300f).dp
fun Dp330Width() = GetSize(heightOrWidth = containerSize.value.width, 4.8f, 350f).dp
fun Dp400Width() = GetSize(heightOrWidth = containerSize.value.width, 2f, 400f).dp
fun Dp500Width() = GetSize(heightOrWidth = containerSize.value.width, 4f, 500f).dp
fun Dp550Width() = GetSize(heightOrWidth = containerSize.value.width, 3f, 550f).dp
fun Dp600Width() = GetSize(heightOrWidth = containerSize.value.width, 1.9f, 600f).dp

fun GetSize(
    heightOrWidth: Float,
    screenPercentage: Float,
    defaultSize: Float
): Float {
    return if (is4K()) {
        val size = heightOrWidth.takeIf { it > 0 }?.div(screenPercentage)
//        log.d("SIZE RESOLUTION: ") { "4K calculation:  width=${containerSize.value.width}, height=${containerSize.value.height}, screenPercentage=$screenPercentage, calculatedSize=$size, defaultSize=$defaultSize" }
        size ?: defaultSize
    } else {
//        log.d("SIZE RESOLUTION: ") { "NOT 4K width=${containerSize.value.width}, height=${containerSize.value.height}, screenPercentage=$screenPercentage, defaultSize=$defaultSize" }
        defaultSize
    }
}

fun is4K(): Boolean {
//    log.d("SIZE RESOLUTION: ") { "IS 4K:  width=${containerSize.value.width}, height=${containerSize.value.height}" }
    return containerSize.value.width >= 3700f && containerSize.value.height >= 2090f
}

fun isWebMobileView(): Boolean {
    val isWebMobileView = getPlatformChecker().isMobileWebView()
//    log.d("isWebMobileView: ") { "isWebMobileView: $isWebMobileView, getPlatformChecker().isMobileWebView(): ${getPlatformChecker().isMobileWebView()}" }
    return isWebMobileView
}