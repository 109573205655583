@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package com.hyperether.goodjob.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.InternalResourceApi
import org.jetbrains.compose.resources.StringResource

private object CommonMainString0 {
  public val about: StringResource by 
      lazy { init_about() }

  public val about_contact: StringResource by 
      lazy { init_about_contact() }

  public val about_job: StringResource by 
      lazy { init_about_job() }

  public val access_dashboard: StringResource by 
      lazy { init_access_dashboard() }

  public val active: StringResource by 
      lazy { init_active() }

  public val add: StringResource by 
      lazy { init_add() }

  public val add_client: StringResource by 
      lazy { init_add_client() }

  public val add_file: StringResource by 
      lazy { init_add_file() }

  public val add_new: StringResource by 
      lazy { init_add_new() }

  public val add_new_button: StringResource by 
      lazy { init_add_new_button() }

  public val add_new_contact: StringResource by 
      lazy { init_add_new_contact() }

  public val add_new_employee: StringResource by 
      lazy { init_add_new_employee() }

  public val add_new_job: StringResource by 
      lazy { init_add_new_job() }

  public val add_new_site: StringResource by 
      lazy { init_add_new_site() }

  public val add_new_workspace: StringResource by 
      lazy { init_add_new_workspace() }

  public val add_note: StringResource by 
      lazy { init_add_note() }

  public val add_note_here: StringResource by 
      lazy { init_add_note_here() }

  public val add_notes: StringResource by 
      lazy { init_add_notes() }

  public val add_site: StringResource by 
      lazy { init_add_site() }

  public val add_steps: StringResource by 
      lazy { init_add_steps() }

  public val add_your_note_here: StringResource by 
      lazy { init_add_your_note_here() }

  public val address: StringResource by 
      lazy { init_address() }

  public val address_required: StringResource by 
      lazy { init_address_required() }

  public val admin: StringResource by 
      lazy { init_admin() }

  public val all: StringResource by 
      lazy { init_all() }

  public val all_employees: StringResource by 
      lazy { init_all_employees() }

  public val all_rights_reserved: StringResource by 
      lazy { init_all_rights_reserved() }

  public val already_have_an_account: StringResource by 
      lazy { init_already_have_an_account() }

  public val already_have_workspace: StringResource by 
      lazy { init_already_have_workspace() }

  public val and_we_will: StringResource by 
      lazy { init_and_we_will() }

  public val app_name: StringResource by 
      lazy { init_app_name() }

  public val approve: StringResource by 
      lazy { init_approve() }

  public val assign_employee: StringResource by 
      lazy { init_assign_employee() }

  public val assigned_to: StringResource by 
      lazy { init_assigned_to() }

  public val back: StringResource by 
      lazy { init_back() }

  public val built_for: StringResource by 
      lazy { init_built_for() }

  public val cancel: StringResource by 
      lazy { init_cancel() }

  public val choose_category: StringResource by 
      lazy { init_choose_category() }

  public val choose_plan: StringResource by 
      lazy { init_choose_plan() }

  public val choose_team: StringResource by 
      lazy { init_choose_team() }

  public val choose_the_plan: StringResource by 
      lazy { init_choose_the_plan() }

  public val choose_the_right_plan: StringResource by 
      lazy { init_choose_the_right_plan() }

  public val choose_your_workspace: StringResource by 
      lazy { init_choose_your_workspace() }

  public val city: StringResource by 
      lazy { init_city() }

  public val city_required: StringResource by 
      lazy { init_city_required() }

  public val clear_all: StringResource by 
      lazy { init_clear_all() }

  public val click_here: StringResource by 
      lazy { init_click_here() }

  public val company: StringResource by 
      lazy { init_company() }

  public val completed: StringResource by 
      lazy { init_completed() }

  public val completion_report: StringResource by 
      lazy { init_completion_report() }

  public val confirm_password: StringResource by 
      lazy { init_confirm_password() }

  public val confirm_password_reset: StringResource by 
      lazy { init_confirm_password_reset() }

  public val confirm_registration: StringResource by 
      lazy { init_confirm_registration() }

  public val contact_information: StringResource by 
      lazy { init_contact_information() }

  public val contact_name: StringResource by 
      lazy { init_contact_name() }

  public val contact_person: StringResource by 
      lazy { init_contact_person() }

  public val contact_site: StringResource by 
      lazy { init_contact_site() }

  public val contact_type: StringResource by 
      lazy { init_contact_type() }

  public val contact_us: StringResource by 
      lazy { init_contact_us() }

  public val contacts: StringResource by 
      lazy { init_contacts() }

  public val continue_with_apple: StringResource by 
      lazy { init_continue_with_apple() }

  public val continue_with_fb: StringResource by 
      lazy { init_continue_with_fb() }

  public val continue_with_google: StringResource by 
      lazy { init_continue_with_google() }

  public val continue_with_microsoft: StringResource by 
      lazy { init_continue_with_microsoft() }

  public val continue_with_next_step: StringResource by 
      lazy { init_continue_with_next_step() }

  public val country: StringResource by 
      lazy { init_country() }

  public val country_required: StringResource by 
      lazy { init_country_required() }

  public val create: StringResource by 
      lazy { init_create() }

  public val create_new_employee: StringResource by 
      lazy { init_create_new_employee() }

  public val create_new_task: StringResource by 
      lazy { init_create_new_task() }

  public val create_one: StringResource by 
      lazy { init_create_one() }

  public val create_strong_password: StringResource by 
      lazy { init_create_strong_password() }

  public val create_workspace: StringResource by 
      lazy { init_create_workspace() }

  public val custom: StringResource by 
      lazy { init_custom() }

  public val daily_activities: StringResource by 
      lazy { init_daily_activities() }

  public val dashboard: StringResource by 
      lazy { init_dashboard() }

  public val date: StringResource by 
      lazy { init_date() }

  public val date_error: StringResource by 
      lazy { init_date_error() }

  public val day_free_trial: StringResource by 
      lazy { init_day_free_trial() }

  public val dedicated_support: StringResource by 
      lazy { init_dedicated_support() }

  public val delete: StringResource by 
      lazy { init_delete() }

  public val describe_the_process: StringResource by 
      lazy { init_describe_the_process() }

  public val details: StringResource by 
      lazy { init_details() }

  public val document: StringResource by 
      lazy { init_document() }

  public val done_selecting_dates: StringResource by 
      lazy { init_done_selecting_dates() }

  public val due_date: StringResource by 
      lazy { init_due_date() }

  public val edit: StringResource by 
      lazy { init_edit() }

  public val effortless_efficiency: StringResource by 
      lazy { init_effortless_efficiency() }

  public val email: StringResource by 
      lazy { init_email() }

  public val email_address: StringResource by 
      lazy { init_email_address() }

  public val email_capital: StringResource by 
      lazy { init_email_capital() }

  public val email_required: StringResource by 
      lazy { init_email_required() }

  public val employee: StringResource by 
      lazy { init_employee() }

  public val employee_added_successfully: StringResource by 
      lazy { init_employee_added_successfully() }

  public val employee_details: StringResource by 
      lazy { init_employee_details() }

  public val employees: StringResource by 
      lazy { init_employees() }

  public val employment_details: StringResource by 
      lazy { init_employment_details() }

  public val end_at: StringResource by 
      lazy { init_end_at() }

  public val end_time: StringResource by 
      lazy { init_end_time() }

  public val enter_building_number: StringResource by 
      lazy { init_enter_building_number() }

  public val enter_contact_name: StringResource by 
      lazy { init_enter_contact_name() }

  public val enter_email_address: StringResource by 
      lazy { init_enter_email_address() }

  public val enter_employee_email: StringResource by 
      lazy { init_enter_employee_email() }

  public val enter_full_name: StringResource by 
      lazy { init_enter_full_name() }

  public val enter_job_title: StringResource by 
      lazy { init_enter_job_title() }

  public val enter_site_name: StringResource by 
      lazy { init_enter_site_name() }

  public val enter_step_title: StringResource by 
      lazy { init_enter_step_title() }

  public val enter_street: StringResource by 
      lazy { init_enter_street() }

  public val enter_surname: StringResource by 
      lazy { init_enter_surname() }

  public val enter_text: StringResource by 
      lazy { init_enter_text() }

  public val enter_time: StringResource by 
      lazy { init_enter_time() }

  public val enter_workspace_name: StringResource by 
      lazy { init_enter_workspace_name() }

  public val enter_your_email: StringResource by 
      lazy { init_enter_your_email() }

  public val enter_zip: StringResource by 
      lazy { init_enter_zip() }

  public val error: StringResource by 
      lazy { init_error() }

  public val error_message: StringResource by 
      lazy { init_error_message() }

  public val estimated_duration: StringResource by 
      lazy { init_estimated_duration() }

  public val every_2_weeks: StringResource by 
      lazy { init_every_2_weeks() }

  public val every_day: StringResource by 
      lazy { init_every_day() }

  public val every_month: StringResource by 
      lazy { init_every_month() }

  public val every_week: StringResource by 
      lazy { init_every_week() }

  public val expand_your_team: StringResource by 
      lazy { init_expand_your_team() }

  public val export_list: StringResource by 
      lazy { init_export_list() }

  public val fast_track: StringResource by 
      lazy { init_fast_track() }

  public val fast_track_support: StringResource by 
      lazy { init_fast_track_support() }

  public val file_deleted: StringResource by 
      lazy { init_file_deleted() }

  public val files: StringResource by 
      lazy { init_files() }

  public val files_notes: StringResource by 
      lazy { init_files_notes() }

  public val filter: StringResource by 
      lazy { init_filter() }

  public val fly_high: StringResource by 
      lazy { init_fly_high() }

  public val fly_high_description: StringResource by 
      lazy { init_fly_high_description() }

  public val fly_high_feature_1: StringResource by 
      lazy { init_fly_high_feature_1() }

  public val fly_high_feature_2: StringResource by 
      lazy { init_fly_high_feature_2() }

  public val fly_high_plan_description: StringResource by 
      lazy { init_fly_high_plan_description() }

  public val fly_high_price: StringResource by 
      lazy { init_fly_high_price() }

  public val forgot_password: StringResource by 
      lazy { init_forgot_password() }

  public val forgot_your_password: StringResource by 
      lazy { init_forgot_your_password() }

  public val free: StringResource by 
      lazy { init_free() }

  public val free_100: StringResource by 
      lazy { init_free_100() }

  public val free_hundred: StringResource by 
      lazy { init_free_hundred() }

  public val freebie: StringResource by 
      lazy { init_freebie() }

  public val freebie_description: StringResource by 
      lazy { init_freebie_description() }

  public val freebie_feature_1: StringResource by 
      lazy { init_freebie_feature_1() }

  public val freebie_feature_2: StringResource by 
      lazy { init_freebie_feature_2() }

  public val freebie_plan_description: StringResource by 
      lazy { init_freebie_plan_description() }

  public val freebie_price: StringResource by 
      lazy { init_freebie_price() }

  public val friday: StringResource by 
      lazy { init_friday() }

  public val from_small_teams: StringResource by 
      lazy { init_from_small_teams() }

  public val full_name: StringResource by 
      lazy { init_full_name() }

  public val full_name_contact_person: StringResource by 
      lazy { init_full_name_contact_person() }

  public val full_name_required: StringResource by 
      lazy { init_full_name_required() }

  public val galactic: StringResource by 
      lazy { init_galactic() }

  public val galactic_description: StringResource by 
      lazy { init_galactic_description() }

  public val galactic_feature_1: StringResource by 
      lazy { init_galactic_feature_1() }

  public val galactic_feature_2: StringResource by 
      lazy { init_galactic_feature_2() }

  public val galactic_plan_description: StringResource by 
      lazy { init_galactic_plan_description() }

  public val galactic_price: StringResource by 
      lazy { init_galactic_price() }

  public val goodjob_stripe_merchant_name: StringResource by 
      lazy { init_goodjob_stripe_merchant_name() }

  public val hello: StringResource by 
      lazy { init_hello() }

  public val high_five: StringResource by 
      lazy { init_high_five() }

  public val high_five_description: StringResource by 
      lazy { init_high_five_description() }

  public val high_five_feature_1: StringResource by 
      lazy { init_high_five_feature_1() }

  public val high_five_feature_2: StringResource by 
      lazy { init_high_five_feature_2() }

  public val high_five_plan_description: StringResource by 
      lazy { init_high_five_plan_description() }

  public val high_five_price: StringResource by 
      lazy { init_high_five_price() }

  public val hour: StringResource by 
      lazy { init_hour() }

  public val images: StringResource by 
      lazy { init_images() }

  public val import_list: StringResource by 
      lazy { init_import_list() }

  public val inactive: StringResource by 
      lazy { init_inactive() }

  public val industry: StringResource by 
      lazy { init_industry() }

  public val its: StringResource by 
      lazy { init_its() }

  public val job_details: StringResource by 
      lazy { init_job_details() }

  public val job_duration: StringResource by 
      lazy { init_job_duration() }

  public val job_finished: StringResource by 
      lazy { init_job_finished() }

  public val job_status: StringResource by 
      lazy { init_job_status() }

  public val job_title: StringResource by 
      lazy { init_job_title() }

  public val jobs: StringResource by 
      lazy { init_jobs() }

  public val jobs_overview: StringResource by 
      lazy { init_jobs_overview() }

  public val join_good_job: StringResource by 
      lazy { init_join_good_job() }

  public val join_us: StringResource by 
      lazy { init_join_us() }

  public val last_accessed: StringResource by 
      lazy { init_last_accessed() }

  public val leave_details: StringResource by 
      lazy { init_leave_details() }

  public val location_details: StringResource by 
      lazy { init_location_details() }

  public val log_out: StringResource by 
      lazy { init_log_out() }

  public val login_register: StringResource by 
      lazy { init_login_register() }

  public val logout: StringResource by 
      lazy { init_logout() }

  public val manage_employees: StringResource by 
      lazy { init_manage_employees() }

  public val manager: StringResource by 
      lazy { init_manager() }

  public val map: StringResource by 
      lazy { init_map() }

  public val max_size: StringResource by 
      lazy { init_max_size() }

  public val minute: StringResource by 
      lazy { init_minute() }

  public val minutes: StringResource by 
      lazy { init_minutes() }

  public val monday: StringResource by 
      lazy { init_monday() }

  public val month: StringResource by 
      lazy { init_month() }

  public val monthly: StringResource by 
      lazy { init_monthly() }

  public val most_popular: StringResource by 
      lazy { init_most_popular() }

  public val name: StringResource by 
      lazy { init_name() }

  public val need_an_account: StringResource by 
      lazy { init_need_an_account() }

  public val never: StringResource by 
      lazy { init_never() }

  public val new_password: StringResource by 
      lazy { init_new_password() }

  public val new_workspace: StringResource by 
      lazy { init_new_workspace() }

  public val next: StringResource by 
      lazy { init_next() }

  public val no_worries: StringResource by 
      lazy { init_no_worries() }

  public val note_added_successfully: StringResource by 
      lazy { init_note_added_successfully() }

  public val notes: StringResource by 
      lazy { init_notes() }

  public val notifications: StringResource by 
      lazy { init_notifications() }

  public val ok: StringResource by 
      lazy { init_ok() }

  public val onboarding_description1: StringResource by 
      lazy { init_onboarding_description1() }

  public val onboarding_description2: StringResource by 
      lazy { init_onboarding_description2() }

  public val only_support: StringResource by 
      lazy { init_only_support() }

  public val optimize_your: StringResource by 
      lazy { init_optimize_your() }

  public val password: StringResource by 
      lazy { init_password() }

  public val password_set: StringResource by 
      lazy { init_password_set() }

  public val password_updated: StringResource by 
      lazy { init_password_updated() }

  public val phone: StringResource by 
      lazy { init_phone() }

  public val phone_number: StringResource by 
      lazy { init_phone_number() }

  public val phone_number_required: StringResource by 
      lazy { init_phone_number_required() }

  public val places: StringResource by 
      lazy { init_places() }

  public val plan: StringResource by 
      lazy { init_plan() }

  public val plan_and_assign: StringResource by 
      lazy { init_plan_and_assign() }

  public val powerful_tools: StringResource by 
      lazy { init_powerful_tools() }

  public val pricing: StringResource by 
      lazy { init_pricing() }

  public val priority_support: StringResource by 
      lazy { init_priority_support() }

  public val privacy_policy: StringResource by 
      lazy { init_privacy_policy() }

  public val profile_settings: StringResource by 
      lazy { init_profile_settings() }

  public val provide_information: StringResource by 
      lazy { init_provide_information() }

  public val publish_key: StringResource by 
      lazy { init_publish_key() }

  public val ready_for: StringResource by 
      lazy { init_ready_for() }

  public val register: StringResource by 
      lazy { init_register() }

  public val register_with: StringResource by 
      lazy { init_register_with() }

  public val reject: StringResource by 
      lazy { init_reject() }

  public val repeat: StringResource by 
      lazy { init_repeat() }

  public val repeat_password: StringResource by 
      lazy { init_repeat_password() }

  public val reset_link_sent: StringResource by 
      lazy { init_reset_link_sent() }

  public val role: StringResource by 
      lazy { init_role() }

  public val roles_permission: StringResource by 
      lazy { init_roles_permission() }

  public val saturday: StringResource by 
      lazy { init_saturday() }

  public val save_password: StringResource by 
      lazy { init_save_password() }

  public val schedule: StringResource by 
      lazy { init_schedule() }

  public val scheduler: StringResource by 
      lazy { init_scheduler() }

  public val search: StringResource by 
      lazy { init_search() }

  public val select_dates: StringResource by 
      lazy { init_select_dates() }

  public val select_employee: StringResource by 
      lazy { init_select_employee() }

  public val select_industry: StringResource by 
      lazy { init_select_industry() }

  public val select_job_status: StringResource by 
      lazy { init_select_job_status() }

  public val select_skill: StringResource by 
      lazy { init_select_skill() }

  public val select_team: StringResource by 
      lazy { init_select_team() }

  public val select_your_workspace: StringResource by 
      lazy { init_select_your_workspace() }

  public val selected: StringResource by 
      lazy { init_selected() }

  public val send_reset_link: StringResource by 
      lazy { init_send_reset_link() }

  public val set_new_password: StringResource by 
      lazy { init_set_new_password() }

  public val set_up_workspace: StringResource by 
      lazy { init_set_up_workspace() }

  public val set_weekly_hours: StringResource by 
      lazy { init_set_weekly_hours() }

  public val settings: StringResource by 
      lazy { init_settings() }

  public val sign_in: StringResource by 
      lazy { init_sign_in() }

  public val sign_in_1: StringResource by 
      lazy { init_sign_in_1() }

  public val sign_in_with: StringResource by 
      lazy { init_sign_in_with() }

  public val site: StringResource by 
      lazy { init_site() }

  public val site_added: StringResource by 
      lazy { init_site_added() }

  public val site_name: StringResource by 
      lazy { init_site_name() }

  public val sites: StringResource by 
      lazy { init_sites() }

  public val skill: StringResource by 
      lazy { init_skill() }

  public val skill_required: StringResource by 
      lazy { init_skill_required() }

  public val skills: StringResource by 
      lazy { init_skills() }

  public val skip: StringResource by 
      lazy { init_skip() }

  public val sort_by: StringResource by 
      lazy { init_sort_by() }

  public val start_at: StringResource by 
      lazy { init_start_at() }

  public val start_organizing: StringResource by 
      lazy { init_start_organizing() }

  public val start_time: StringResource by 
      lazy { init_start_time() }

  public val start_typing_address: StringResource by 
      lazy { init_start_typing_address() }

  public val status: StringResource by 
      lazy { init_status() }

  public val status_required: StringResource by 
      lazy { init_status_required() }

  public val step: StringResource by 
      lazy { init_step() }

  public val step_description: StringResource by 
      lazy { init_step_description() }

  public val step_title: StringResource by 
      lazy { init_step_title() }

  public val steps: StringResource by 
      lazy { init_steps() }

  public val submit: StringResource by 
      lazy { init_submit() }

  public val subscribe: StringResource by 
      lazy { init_subscribe() }

  public val subscription: StringResource by 
      lazy { init_subscription() }

  public val success: StringResource by 
      lazy { init_success() }

  public val sunday: StringResource by 
      lazy { init_sunday() }

  public val support: StringResource by 
      lazy { init_support() }

  public val tap_to_upload_photo: StringResource by 
      lazy { init_tap_to_upload_photo() }

  public val team: StringResource by 
      lazy { init_team() }

  public val team_required: StringResource by 
      lazy { init_team_required() }

  public val teams: StringResource by 
      lazy { init_teams() }

  public val terms_of_service: StringResource by 
      lazy { init_terms_of_service() }

  public val thank_you_for_registering: StringResource by 
      lazy { init_thank_you_for_registering() }

  public val thursday: StringResource by 
      lazy { init_thursday() }

  public val today: StringResource by 
      lazy { init_today() }

  public val today_schedule: StringResource by 
      lazy { init_today_schedule() }

  public val total: StringResource by 
      lazy { init_total() }

  public val transform: StringResource by 
      lazy { init_transform() }

  public val transparent_flexible: StringResource by 
      lazy { init_transparent_flexible() }

  public val try_another_document: StringResource by 
      lazy { init_try_another_document() }

  public val tuesday: StringResource by 
      lazy { init_tuesday() }

  public val unlimited_storage: StringResource by 
      lazy { init_unlimited_storage() }

  public val unselect_all: StringResource by 
      lazy { init_unselect_all() }

  public val upcoming_leaves: StringResource by 
      lazy { init_upcoming_leaves() }

  public val upload_documents: StringResource by 
      lazy { init_upload_documents() }

  public val upload_profile_picture: StringResource by 
      lazy { init_upload_profile_picture() }

  public val use_contact_name: StringResource by 
      lazy { init_use_contact_name() }

  public val user: StringResource by 
      lazy { init_user() }

  public val users: StringResource by 
      lazy { init_users() }

  public val wednesday: StringResource by 
      lazy { init_wednesday() }

  public val welcome: StringResource by 
      lazy { init_welcome() }

  public val welcome_back: StringResource by 
      lazy { init_welcome_back() }

  public val whether: StringResource by 
      lazy { init_whether() }

  public val work_schedule: StringResource by 
      lazy { init_work_schedule() }

  public val working_hours: StringResource by 
      lazy { init_working_hours() }

  public val workspace_name: StringResource by 
      lazy { init_workspace_name() }

  public val workspace_settings: StringResource by 
      lazy { init_workspace_settings() }

  public val workspace_type: StringResource by 
      lazy { init_workspace_type() }

  public val yearly: StringResource by 
      lazy { init_yearly() }

  public val your_email_address: StringResource by 
      lazy { init_your_email_address() }

  public val your_full_name: StringResource by 
      lazy { init_your_full_name() }

  public val your_password: StringResource by 
      lazy { init_your_password() }

  public val zip: StringResource by 
      lazy { init_zip() }

  public val zip_employee: StringResource by 
      lazy { init_zip_employee() }

  public val zip_required: StringResource by 
      lazy { init_zip_required() }
}

@InternalResourceApi
internal fun _collectCommonMainString0Resources(map: MutableMap<String, StringResource>) {
  map.put("about", CommonMainString0.about)
  map.put("about_contact", CommonMainString0.about_contact)
  map.put("about_job", CommonMainString0.about_job)
  map.put("access_dashboard", CommonMainString0.access_dashboard)
  map.put("active", CommonMainString0.active)
  map.put("add", CommonMainString0.add)
  map.put("add_client", CommonMainString0.add_client)
  map.put("add_file", CommonMainString0.add_file)
  map.put("add_new", CommonMainString0.add_new)
  map.put("add_new_button", CommonMainString0.add_new_button)
  map.put("add_new_contact", CommonMainString0.add_new_contact)
  map.put("add_new_employee", CommonMainString0.add_new_employee)
  map.put("add_new_job", CommonMainString0.add_new_job)
  map.put("add_new_site", CommonMainString0.add_new_site)
  map.put("add_new_workspace", CommonMainString0.add_new_workspace)
  map.put("add_note", CommonMainString0.add_note)
  map.put("add_note_here", CommonMainString0.add_note_here)
  map.put("add_notes", CommonMainString0.add_notes)
  map.put("add_site", CommonMainString0.add_site)
  map.put("add_steps", CommonMainString0.add_steps)
  map.put("add_your_note_here", CommonMainString0.add_your_note_here)
  map.put("address", CommonMainString0.address)
  map.put("address_required", CommonMainString0.address_required)
  map.put("admin", CommonMainString0.admin)
  map.put("all", CommonMainString0.all)
  map.put("all_employees", CommonMainString0.all_employees)
  map.put("all_rights_reserved", CommonMainString0.all_rights_reserved)
  map.put("already_have_an_account", CommonMainString0.already_have_an_account)
  map.put("already_have_workspace", CommonMainString0.already_have_workspace)
  map.put("and_we_will", CommonMainString0.and_we_will)
  map.put("app_name", CommonMainString0.app_name)
  map.put("approve", CommonMainString0.approve)
  map.put("assign_employee", CommonMainString0.assign_employee)
  map.put("assigned_to", CommonMainString0.assigned_to)
  map.put("back", CommonMainString0.back)
  map.put("built_for", CommonMainString0.built_for)
  map.put("cancel", CommonMainString0.cancel)
  map.put("choose_category", CommonMainString0.choose_category)
  map.put("choose_plan", CommonMainString0.choose_plan)
  map.put("choose_team", CommonMainString0.choose_team)
  map.put("choose_the_plan", CommonMainString0.choose_the_plan)
  map.put("choose_the_right_plan", CommonMainString0.choose_the_right_plan)
  map.put("choose_your_workspace", CommonMainString0.choose_your_workspace)
  map.put("city", CommonMainString0.city)
  map.put("city_required", CommonMainString0.city_required)
  map.put("clear_all", CommonMainString0.clear_all)
  map.put("click_here", CommonMainString0.click_here)
  map.put("company", CommonMainString0.company)
  map.put("completed", CommonMainString0.completed)
  map.put("completion_report", CommonMainString0.completion_report)
  map.put("confirm_password", CommonMainString0.confirm_password)
  map.put("confirm_password_reset", CommonMainString0.confirm_password_reset)
  map.put("confirm_registration", CommonMainString0.confirm_registration)
  map.put("contact_information", CommonMainString0.contact_information)
  map.put("contact_name", CommonMainString0.contact_name)
  map.put("contact_person", CommonMainString0.contact_person)
  map.put("contact_site", CommonMainString0.contact_site)
  map.put("contact_type", CommonMainString0.contact_type)
  map.put("contact_us", CommonMainString0.contact_us)
  map.put("contacts", CommonMainString0.contacts)
  map.put("continue_with_apple", CommonMainString0.continue_with_apple)
  map.put("continue_with_fb", CommonMainString0.continue_with_fb)
  map.put("continue_with_google", CommonMainString0.continue_with_google)
  map.put("continue_with_microsoft", CommonMainString0.continue_with_microsoft)
  map.put("continue_with_next_step", CommonMainString0.continue_with_next_step)
  map.put("country", CommonMainString0.country)
  map.put("country_required", CommonMainString0.country_required)
  map.put("create", CommonMainString0.create)
  map.put("create_new_employee", CommonMainString0.create_new_employee)
  map.put("create_new_task", CommonMainString0.create_new_task)
  map.put("create_one", CommonMainString0.create_one)
  map.put("create_strong_password", CommonMainString0.create_strong_password)
  map.put("create_workspace", CommonMainString0.create_workspace)
  map.put("custom", CommonMainString0.custom)
  map.put("daily_activities", CommonMainString0.daily_activities)
  map.put("dashboard", CommonMainString0.dashboard)
  map.put("date", CommonMainString0.date)
  map.put("date_error", CommonMainString0.date_error)
  map.put("day_free_trial", CommonMainString0.day_free_trial)
  map.put("dedicated_support", CommonMainString0.dedicated_support)
  map.put("delete", CommonMainString0.delete)
  map.put("describe_the_process", CommonMainString0.describe_the_process)
  map.put("details", CommonMainString0.details)
  map.put("document", CommonMainString0.document)
  map.put("done_selecting_dates", CommonMainString0.done_selecting_dates)
  map.put("due_date", CommonMainString0.due_date)
  map.put("edit", CommonMainString0.edit)
  map.put("effortless_efficiency", CommonMainString0.effortless_efficiency)
  map.put("email", CommonMainString0.email)
  map.put("email_address", CommonMainString0.email_address)
  map.put("email_capital", CommonMainString0.email_capital)
  map.put("email_required", CommonMainString0.email_required)
  map.put("employee", CommonMainString0.employee)
  map.put("employee_added_successfully", CommonMainString0.employee_added_successfully)
  map.put("employee_details", CommonMainString0.employee_details)
  map.put("employees", CommonMainString0.employees)
  map.put("employment_details", CommonMainString0.employment_details)
  map.put("end_at", CommonMainString0.end_at)
  map.put("end_time", CommonMainString0.end_time)
  map.put("enter_building_number", CommonMainString0.enter_building_number)
  map.put("enter_contact_name", CommonMainString0.enter_contact_name)
  map.put("enter_email_address", CommonMainString0.enter_email_address)
  map.put("enter_employee_email", CommonMainString0.enter_employee_email)
  map.put("enter_full_name", CommonMainString0.enter_full_name)
  map.put("enter_job_title", CommonMainString0.enter_job_title)
  map.put("enter_site_name", CommonMainString0.enter_site_name)
  map.put("enter_step_title", CommonMainString0.enter_step_title)
  map.put("enter_street", CommonMainString0.enter_street)
  map.put("enter_surname", CommonMainString0.enter_surname)
  map.put("enter_text", CommonMainString0.enter_text)
  map.put("enter_time", CommonMainString0.enter_time)
  map.put("enter_workspace_name", CommonMainString0.enter_workspace_name)
  map.put("enter_your_email", CommonMainString0.enter_your_email)
  map.put("enter_zip", CommonMainString0.enter_zip)
  map.put("error", CommonMainString0.error)
  map.put("error_message", CommonMainString0.error_message)
  map.put("estimated_duration", CommonMainString0.estimated_duration)
  map.put("every_2_weeks", CommonMainString0.every_2_weeks)
  map.put("every_day", CommonMainString0.every_day)
  map.put("every_month", CommonMainString0.every_month)
  map.put("every_week", CommonMainString0.every_week)
  map.put("expand_your_team", CommonMainString0.expand_your_team)
  map.put("export_list", CommonMainString0.export_list)
  map.put("fast_track", CommonMainString0.fast_track)
  map.put("fast_track_support", CommonMainString0.fast_track_support)
  map.put("file_deleted", CommonMainString0.file_deleted)
  map.put("files", CommonMainString0.files)
  map.put("files_notes", CommonMainString0.files_notes)
  map.put("filter", CommonMainString0.filter)
  map.put("fly_high", CommonMainString0.fly_high)
  map.put("fly_high_description", CommonMainString0.fly_high_description)
  map.put("fly_high_feature_1", CommonMainString0.fly_high_feature_1)
  map.put("fly_high_feature_2", CommonMainString0.fly_high_feature_2)
  map.put("fly_high_plan_description", CommonMainString0.fly_high_plan_description)
  map.put("fly_high_price", CommonMainString0.fly_high_price)
  map.put("forgot_password", CommonMainString0.forgot_password)
  map.put("forgot_your_password", CommonMainString0.forgot_your_password)
  map.put("free", CommonMainString0.free)
  map.put("free_100", CommonMainString0.free_100)
  map.put("free_hundred", CommonMainString0.free_hundred)
  map.put("freebie", CommonMainString0.freebie)
  map.put("freebie_description", CommonMainString0.freebie_description)
  map.put("freebie_feature_1", CommonMainString0.freebie_feature_1)
  map.put("freebie_feature_2", CommonMainString0.freebie_feature_2)
  map.put("freebie_plan_description", CommonMainString0.freebie_plan_description)
  map.put("freebie_price", CommonMainString0.freebie_price)
  map.put("friday", CommonMainString0.friday)
  map.put("from_small_teams", CommonMainString0.from_small_teams)
  map.put("full_name", CommonMainString0.full_name)
  map.put("full_name_contact_person", CommonMainString0.full_name_contact_person)
  map.put("full_name_required", CommonMainString0.full_name_required)
  map.put("galactic", CommonMainString0.galactic)
  map.put("galactic_description", CommonMainString0.galactic_description)
  map.put("galactic_feature_1", CommonMainString0.galactic_feature_1)
  map.put("galactic_feature_2", CommonMainString0.galactic_feature_2)
  map.put("galactic_plan_description", CommonMainString0.galactic_plan_description)
  map.put("galactic_price", CommonMainString0.galactic_price)
  map.put("goodjob_stripe_merchant_name", CommonMainString0.goodjob_stripe_merchant_name)
  map.put("hello", CommonMainString0.hello)
  map.put("high_five", CommonMainString0.high_five)
  map.put("high_five_description", CommonMainString0.high_five_description)
  map.put("high_five_feature_1", CommonMainString0.high_five_feature_1)
  map.put("high_five_feature_2", CommonMainString0.high_five_feature_2)
  map.put("high_five_plan_description", CommonMainString0.high_five_plan_description)
  map.put("high_five_price", CommonMainString0.high_five_price)
  map.put("hour", CommonMainString0.hour)
  map.put("images", CommonMainString0.images)
  map.put("import_list", CommonMainString0.import_list)
  map.put("inactive", CommonMainString0.inactive)
  map.put("industry", CommonMainString0.industry)
  map.put("its", CommonMainString0.its)
  map.put("job_details", CommonMainString0.job_details)
  map.put("job_duration", CommonMainString0.job_duration)
  map.put("job_finished", CommonMainString0.job_finished)
  map.put("job_status", CommonMainString0.job_status)
  map.put("job_title", CommonMainString0.job_title)
  map.put("jobs", CommonMainString0.jobs)
  map.put("jobs_overview", CommonMainString0.jobs_overview)
  map.put("join_good_job", CommonMainString0.join_good_job)
  map.put("join_us", CommonMainString0.join_us)
  map.put("last_accessed", CommonMainString0.last_accessed)
  map.put("leave_details", CommonMainString0.leave_details)
  map.put("location_details", CommonMainString0.location_details)
  map.put("log_out", CommonMainString0.log_out)
  map.put("login_register", CommonMainString0.login_register)
  map.put("logout", CommonMainString0.logout)
  map.put("manage_employees", CommonMainString0.manage_employees)
  map.put("manager", CommonMainString0.manager)
  map.put("map", CommonMainString0.map)
  map.put("max_size", CommonMainString0.max_size)
  map.put("minute", CommonMainString0.minute)
  map.put("minutes", CommonMainString0.minutes)
  map.put("monday", CommonMainString0.monday)
  map.put("month", CommonMainString0.month)
  map.put("monthly", CommonMainString0.monthly)
  map.put("most_popular", CommonMainString0.most_popular)
  map.put("name", CommonMainString0.name)
  map.put("need_an_account", CommonMainString0.need_an_account)
  map.put("never", CommonMainString0.never)
  map.put("new_password", CommonMainString0.new_password)
  map.put("new_workspace", CommonMainString0.new_workspace)
  map.put("next", CommonMainString0.next)
  map.put("no_worries", CommonMainString0.no_worries)
  map.put("note_added_successfully", CommonMainString0.note_added_successfully)
  map.put("notes", CommonMainString0.notes)
  map.put("notifications", CommonMainString0.notifications)
  map.put("ok", CommonMainString0.ok)
  map.put("onboarding_description1", CommonMainString0.onboarding_description1)
  map.put("onboarding_description2", CommonMainString0.onboarding_description2)
  map.put("only_support", CommonMainString0.only_support)
  map.put("optimize_your", CommonMainString0.optimize_your)
  map.put("password", CommonMainString0.password)
  map.put("password_set", CommonMainString0.password_set)
  map.put("password_updated", CommonMainString0.password_updated)
  map.put("phone", CommonMainString0.phone)
  map.put("phone_number", CommonMainString0.phone_number)
  map.put("phone_number_required", CommonMainString0.phone_number_required)
  map.put("places", CommonMainString0.places)
  map.put("plan", CommonMainString0.plan)
  map.put("plan_and_assign", CommonMainString0.plan_and_assign)
  map.put("powerful_tools", CommonMainString0.powerful_tools)
  map.put("pricing", CommonMainString0.pricing)
  map.put("priority_support", CommonMainString0.priority_support)
  map.put("privacy_policy", CommonMainString0.privacy_policy)
  map.put("profile_settings", CommonMainString0.profile_settings)
  map.put("provide_information", CommonMainString0.provide_information)
  map.put("publish_key", CommonMainString0.publish_key)
  map.put("ready_for", CommonMainString0.ready_for)
  map.put("register", CommonMainString0.register)
  map.put("register_with", CommonMainString0.register_with)
  map.put("reject", CommonMainString0.reject)
  map.put("repeat", CommonMainString0.repeat)
  map.put("repeat_password", CommonMainString0.repeat_password)
  map.put("reset_link_sent", CommonMainString0.reset_link_sent)
  map.put("role", CommonMainString0.role)
  map.put("roles_permission", CommonMainString0.roles_permission)
  map.put("saturday", CommonMainString0.saturday)
  map.put("save_password", CommonMainString0.save_password)
  map.put("schedule", CommonMainString0.schedule)
  map.put("scheduler", CommonMainString0.scheduler)
  map.put("search", CommonMainString0.search)
  map.put("select_dates", CommonMainString0.select_dates)
  map.put("select_employee", CommonMainString0.select_employee)
  map.put("select_industry", CommonMainString0.select_industry)
  map.put("select_job_status", CommonMainString0.select_job_status)
  map.put("select_skill", CommonMainString0.select_skill)
  map.put("select_team", CommonMainString0.select_team)
  map.put("select_your_workspace", CommonMainString0.select_your_workspace)
  map.put("selected", CommonMainString0.selected)
  map.put("send_reset_link", CommonMainString0.send_reset_link)
  map.put("set_new_password", CommonMainString0.set_new_password)
  map.put("set_up_workspace", CommonMainString0.set_up_workspace)
  map.put("set_weekly_hours", CommonMainString0.set_weekly_hours)
  map.put("settings", CommonMainString0.settings)
  map.put("sign_in", CommonMainString0.sign_in)
  map.put("sign_in_1", CommonMainString0.sign_in_1)
  map.put("sign_in_with", CommonMainString0.sign_in_with)
  map.put("site", CommonMainString0.site)
  map.put("site_added", CommonMainString0.site_added)
  map.put("site_name", CommonMainString0.site_name)
  map.put("sites", CommonMainString0.sites)
  map.put("skill", CommonMainString0.skill)
  map.put("skill_required", CommonMainString0.skill_required)
  map.put("skills", CommonMainString0.skills)
  map.put("skip", CommonMainString0.skip)
  map.put("sort_by", CommonMainString0.sort_by)
  map.put("start_at", CommonMainString0.start_at)
  map.put("start_organizing", CommonMainString0.start_organizing)
  map.put("start_time", CommonMainString0.start_time)
  map.put("start_typing_address", CommonMainString0.start_typing_address)
  map.put("status", CommonMainString0.status)
  map.put("status_required", CommonMainString0.status_required)
  map.put("step", CommonMainString0.step)
  map.put("step_description", CommonMainString0.step_description)
  map.put("step_title", CommonMainString0.step_title)
  map.put("steps", CommonMainString0.steps)
  map.put("submit", CommonMainString0.submit)
  map.put("subscribe", CommonMainString0.subscribe)
  map.put("subscription", CommonMainString0.subscription)
  map.put("success", CommonMainString0.success)
  map.put("sunday", CommonMainString0.sunday)
  map.put("support", CommonMainString0.support)
  map.put("tap_to_upload_photo", CommonMainString0.tap_to_upload_photo)
  map.put("team", CommonMainString0.team)
  map.put("team_required", CommonMainString0.team_required)
  map.put("teams", CommonMainString0.teams)
  map.put("terms_of_service", CommonMainString0.terms_of_service)
  map.put("thank_you_for_registering", CommonMainString0.thank_you_for_registering)
  map.put("thursday", CommonMainString0.thursday)
  map.put("today", CommonMainString0.today)
  map.put("today_schedule", CommonMainString0.today_schedule)
  map.put("total", CommonMainString0.total)
  map.put("transform", CommonMainString0.transform)
  map.put("transparent_flexible", CommonMainString0.transparent_flexible)
  map.put("try_another_document", CommonMainString0.try_another_document)
  map.put("tuesday", CommonMainString0.tuesday)
  map.put("unlimited_storage", CommonMainString0.unlimited_storage)
  map.put("unselect_all", CommonMainString0.unselect_all)
  map.put("upcoming_leaves", CommonMainString0.upcoming_leaves)
  map.put("upload_documents", CommonMainString0.upload_documents)
  map.put("upload_profile_picture", CommonMainString0.upload_profile_picture)
  map.put("use_contact_name", CommonMainString0.use_contact_name)
  map.put("user", CommonMainString0.user)
  map.put("users", CommonMainString0.users)
  map.put("wednesday", CommonMainString0.wednesday)
  map.put("welcome", CommonMainString0.welcome)
  map.put("welcome_back", CommonMainString0.welcome_back)
  map.put("whether", CommonMainString0.whether)
  map.put("work_schedule", CommonMainString0.work_schedule)
  map.put("working_hours", CommonMainString0.working_hours)
  map.put("workspace_name", CommonMainString0.workspace_name)
  map.put("workspace_settings", CommonMainString0.workspace_settings)
  map.put("workspace_type", CommonMainString0.workspace_type)
  map.put("yearly", CommonMainString0.yearly)
  map.put("your_email_address", CommonMainString0.your_email_address)
  map.put("your_full_name", CommonMainString0.your_full_name)
  map.put("your_password", CommonMainString0.your_password)
  map.put("zip", CommonMainString0.zip)
  map.put("zip_employee", CommonMainString0.zip_employee)
  map.put("zip_required", CommonMainString0.zip_required)
}

public val Res.string.about: StringResource
  get() = CommonMainString0.about

private fun init_about(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about", "about",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 82, 21),
    )
)

public val Res.string.about_contact: StringResource
  get() = CommonMainString0.about_contact

private fun init_about_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about_contact", "about_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10, 41),
    )
)

public val Res.string.about_job: StringResource
  get() = CommonMainString0.about_job

private fun init_about_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:about_job", "about_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 52, 29),
    )
)

public val Res.string.access_dashboard: StringResource
  get() = CommonMainString0.access_dashboard

private fun init_access_dashboard(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:access_dashboard", "access_dashboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 104, 48),
    )
)

public val Res.string.active: StringResource
  get() = CommonMainString0.active

private fun init_active(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:active", "active",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 153, 22),
    )
)

public val Res.string.add: StringResource
  get() = CommonMainString0.add

private fun init_add(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add", "add",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 879, 15),
    )
)

public val Res.string.add_client: StringResource
  get() = CommonMainString0.add_client

private fun init_add_client(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_client", "add_client",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 176, 98),
    )
)

public val Res.string.add_file: StringResource
  get() = CommonMainString0.add_file

private fun init_add_file(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_file", "add_file",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 275, 28),
    )
)

public val Res.string.add_new: StringResource
  get() = CommonMainString0.add_new

private fun init_add_new(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new", "add_new",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 555, 27),
    )
)

public val Res.string.add_new_button: StringResource
  get() = CommonMainString0.add_new_button

private fun init_add_new_button(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new_button", "add_new_button",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 304, 34),
    )
)

public val Res.string.add_new_contact: StringResource
  get() = CommonMainString0.add_new_contact

private fun init_add_new_contact(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new_contact", "add_new_contact",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 339, 43),
    )
)

public val Res.string.add_new_employee: StringResource
  get() = CommonMainString0.add_new_employee

private fun init_add_new_employee(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_new_employee", "add_new_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 383, 48),
    )
)

public val Res.string.add_new_job: StringResource
  get() = CommonMainString0.add_new_job

private fun init_add_new_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new_job", "add_new_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 432, 35),
    )
)

public val Res.string.add_new_site: StringResource
  get() = CommonMainString0.add_new_site

private fun init_add_new_site(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_new_site", "add_new_site",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 468, 36),
    )
)

public val Res.string.add_new_workspace: StringResource
  get() = CommonMainString0.add_new_workspace

private fun init_add_new_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_new_workspace", "add_new_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 505, 49),
    )
)

public val Res.string.add_note: StringResource
  get() = CommonMainString0.add_note

private fun init_add_note(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_note", "add_note",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 659, 28),
    )
)

public val Res.string.add_note_here: StringResource
  get() = CommonMainString0.add_note_here

private fun init_add_note_here(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_note_here", "add_note_here",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 583, 45),
    )
)

public val Res.string.add_notes: StringResource
  get() = CommonMainString0.add_notes

private fun init_add_notes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_notes", "add_notes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 629, 29),
    )
)

public val Res.string.add_site: StringResource
  get() = CommonMainString0.add_site

private fun init_add_site(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_site", "add_site",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 688, 28),
    )
)

public val Res.string.add_steps: StringResource
  get() = CommonMainString0.add_steps

private fun init_add_steps(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:add_steps", "add_steps",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 717, 29),
    )
)

public val Res.string.add_your_note_here: StringResource
  get() = CommonMainString0.add_your_note_here

private fun init_add_your_note_here(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:add_your_note_here", "add_your_note_here",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 747, 50),
    )
)

public val Res.string.address: StringResource
  get() = CommonMainString0.address

private fun init_address(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:address", "address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 851, 27),
    )
)

public val Res.string.address_required: StringResource
  get() = CommonMainString0.address_required

private fun init_address_required(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:address_required", "address_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 798, 52),
    )
)

public val Res.string.admin: StringResource
  get() = CommonMainString0.admin

private fun init_admin(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:admin", "admin",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 895, 33),
    )
)

public val Res.string.all: StringResource
  get() = CommonMainString0.all

private fun init_all(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:all", "all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1087, 15),
    )
)

public val Res.string.all_employees: StringResource
  get() = CommonMainString0.all_employees

private fun init_all_employees(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:all_employees", "all_employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 929, 41),
    )
)

public val Res.string.all_rights_reserved: StringResource
  get() = CommonMainString0.all_rights_reserved

private fun init_all_rights_reserved(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:all_rights_reserved", "all_rights_reserved",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 971, 115),
    )
)

public val Res.string.already_have_an_account: StringResource
  get() = CommonMainString0.already_have_an_account

private fun init_already_have_an_account(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_an_account", "already_have_an_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1103, 67),
    )
)

public val Res.string.already_have_workspace: StringResource
  get() = CommonMainString0.already_have_workspace

private fun init_already_have_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:already_have_workspace", "already_have_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1171, 66),
    )
)

public val Res.string.and_we_will: StringResource
  get() = CommonMainString0.and_we_will

private fun init_and_we_will(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:and_we_will", "and_we_will",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1238, 91),
    )
)

public val Res.string.app_name: StringResource
  get() = CommonMainString0.app_name

private fun init_app_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:app_name", "app_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1330, 28),
    )
)

public val Res.string.approve: StringResource
  get() = CommonMainString0.approve

private fun init_approve(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:approve", "approve",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1359, 55),
    )
)

public val Res.string.assign_employee: StringResource
  get() = CommonMainString0.assign_employee

private fun init_assign_employee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:assign_employee", "assign_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1415, 43),
    )
)

public val Res.string.assigned_to: StringResource
  get() = CommonMainString0.assigned_to

private fun init_assigned_to(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:assigned_to", "assigned_to",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1459, 39),
    )
)

public val Res.string.back: StringResource
  get() = CommonMainString0.back

private fun init_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:back", "back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1499, 20),
    )
)

public val Res.string.built_for: StringResource
  get() = CommonMainString0.built_for

private fun init_built_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:built_for", "built_for",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1520, 125),
    )
)

public val Res.string.cancel: StringResource
  get() = CommonMainString0.cancel

private fun init_cancel(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:cancel", "cancel",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1646, 22),
    )
)

public val Res.string.choose_category: StringResource
  get() = CommonMainString0.choose_category

private fun init_choose_category(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_category", "choose_category",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1669, 51),
    )
)

public val Res.string.choose_plan: StringResource
  get() = CommonMainString0.choose_plan

private fun init_choose_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_plan", "choose_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1721, 35),
    )
)

public val Res.string.choose_team: StringResource
  get() = CommonMainString0.choose_team

private fun init_choose_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_team", "choose_team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1757, 39),
    )
)

public val Res.string.choose_the_plan: StringResource
  get() = CommonMainString0.choose_the_plan

private fun init_choose_the_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:choose_the_plan", "choose_the_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1797, 87),
    )
)

public val Res.string.choose_the_right_plan: StringResource
  get() = CommonMainString0.choose_the_right_plan

private fun init_choose_the_right_plan(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:choose_the_right_plan", "choose_the_right_plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1885, 69),
    )
)

public val Res.string.choose_your_workspace: StringResource
  get() = CommonMainString0.choose_your_workspace

private fun init_choose_your_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:choose_your_workspace", "choose_your_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 1955, 89),
    )
)

public val Res.string.city: StringResource
  get() = CommonMainString0.city

private fun init_city(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:city", "city",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2091, 20),
    )
)

public val Res.string.city_required: StringResource
  get() = CommonMainString0.city_required

private fun init_city_required(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:city_required", "city_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2045, 45),
    )
)

public val Res.string.clear_all: StringResource
  get() = CommonMainString0.clear_all

private fun init_clear_all(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:clear_all", "clear_all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2112, 29),
    )
)

public val Res.string.click_here: StringResource
  get() = CommonMainString0.click_here

private fun init_click_here(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:click_here", "click_here",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2142, 50),
    )
)

public val Res.string.company: StringResource
  get() = CommonMainString0.company

private fun init_company(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:company", "company",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2193, 27),
    )
)

public val Res.string.completed: StringResource
  get() = CommonMainString0.completed

private fun init_completed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:completed", "completed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2221, 29),
    )
)

public val Res.string.completion_report: StringResource
  get() = CommonMainString0.completion_report

private fun init_completion_report(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:completion_report", "completion_report",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2251, 49),
    )
)

public val Res.string.confirm_password: StringResource
  get() = CommonMainString0.confirm_password

private fun init_confirm_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_password", "confirm_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2488, 48),
    )
)

public val Res.string.confirm_password_reset: StringResource
  get() = CommonMainString0.confirm_password_reset

private fun init_confirm_password_reset(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_password_reset", "confirm_password_reset",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2301, 186),
    )
)

public val Res.string.confirm_registration: StringResource
  get() = CommonMainString0.confirm_registration

private fun init_confirm_registration(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:confirm_registration", "confirm_registration",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2537, 56),
    )
)

public val Res.string.contact_information: StringResource
  get() = CommonMainString0.contact_information

private fun init_contact_information(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:contact_information", "contact_information",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2594, 55),
    )
)

public val Res.string.contact_name: StringResource
  get() = CommonMainString0.contact_name

private fun init_contact_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_name", "contact_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2650, 36),
    )
)

public val Res.string.contact_person: StringResource
  get() = CommonMainString0.contact_person

private fun init_contact_person(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_person", "contact_person",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2687, 42),
    )
)

public val Res.string.contact_site: StringResource
  get() = CommonMainString0.contact_site

private fun init_contact_site(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_site", "contact_site",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2730, 36),
    )
)

public val Res.string.contact_type: StringResource
  get() = CommonMainString0.contact_type

private fun init_contact_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_type", "contact_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2767, 36),
    )
)

public val Res.string.contact_us: StringResource
  get() = CommonMainString0.contact_us

private fun init_contact_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contact_us", "contact_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2804, 34),
    )
)

public val Res.string.contacts: StringResource
  get() = CommonMainString0.contacts

private fun init_contacts(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:contacts", "contacts",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2839, 28),
    )
)

public val Res.string.continue_with_apple: StringResource
  get() = CommonMainString0.continue_with_apple

private fun init_continue_with_apple(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_apple", "continue_with_apple",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2868, 55),
    )
)

public val Res.string.continue_with_fb: StringResource
  get() = CommonMainString0.continue_with_fb

private fun init_continue_with_fb(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_fb", "continue_with_fb",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2924, 56),
    )
)

public val Res.string.continue_with_google: StringResource
  get() = CommonMainString0.continue_with_google

private fun init_continue_with_google(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_google", "continue_with_google",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 2981, 56),
    )
)

public val Res.string.continue_with_microsoft: StringResource
  get() = CommonMainString0.continue_with_microsoft

private fun init_continue_with_microsoft(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_microsoft", "continue_with_microsoft",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3038, 63),
    )
)

public val Res.string.continue_with_next_step: StringResource
  get() = CommonMainString0.continue_with_next_step

private fun init_continue_with_next_step(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:continue_with_next_step", "continue_with_next_step",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3102, 63),
    )
)

public val Res.string.country: StringResource
  get() = CommonMainString0.country

private fun init_country(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:country", "country",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3219, 27),
    )
)

public val Res.string.country_required: StringResource
  get() = CommonMainString0.country_required

private fun init_country_required(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:country_required", "country_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3166, 52),
    )
)

public val Res.string.create: StringResource
  get() = CommonMainString0.create

private fun init_create(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create", "create",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3682, 22),
    )
)

public val Res.string.create_new_employee: StringResource
  get() = CommonMainString0.create_new_employee

private fun init_create_new_employee(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_new_employee", "create_new_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3247, 119),
    )
)

public val Res.string.create_new_task: StringResource
  get() = CommonMainString0.create_new_task

private fun init_create_new_task(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create_new_task", "create_new_task",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3367, 127),
    )
)

public val Res.string.create_one: StringResource
  get() = CommonMainString0.create_one

private fun init_create_one(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:create_one", "create_one",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3495, 34),
    )
)

public val Res.string.create_strong_password: StringResource
  get() = CommonMainString0.create_strong_password

private fun init_create_strong_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_strong_password", "create_strong_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3530, 94),
    )
)

public val Res.string.create_workspace: StringResource
  get() = CommonMainString0.create_workspace

private fun init_create_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:create_workspace", "create_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3625, 56),
    )
)

public val Res.string.custom: StringResource
  get() = CommonMainString0.custom

private fun init_custom(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:custom", "custom",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3705, 22),
    )
)

public val Res.string.daily_activities: StringResource
  get() = CommonMainString0.daily_activities

private fun init_daily_activities(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:daily_activities", "daily_activities",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3728, 48),
    )
)

public val Res.string.dashboard: StringResource
  get() = CommonMainString0.dashboard

private fun init_dashboard(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:dashboard", "dashboard",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3777, 29),
    )
)

public val Res.string.date: StringResource
  get() = CommonMainString0.date

private fun init_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:date", "date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3866, 20),
    )
)

public val Res.string.date_error: StringResource
  get() = CommonMainString0.date_error

private fun init_date_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:date_error", "date_error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3807, 58),
    )
)

public val Res.string.day_free_trial: StringResource
  get() = CommonMainString0.day_free_trial

private fun init_day_free_trial(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:day_free_trial", "day_free_trial",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3887, 66),
    )
)

public val Res.string.dedicated_support: StringResource
  get() = CommonMainString0.dedicated_support

private fun init_dedicated_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:dedicated_support", "dedicated_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 3954, 69),
    )
)

public val Res.string.delete: StringResource
  get() = CommonMainString0.delete

private fun init_delete(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:delete", "delete",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4024, 22),
    )
)

public val Res.string.describe_the_process: StringResource
  get() = CommonMainString0.describe_the_process

private fun init_describe_the_process(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:describe_the_process", "describe_the_process",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4047, 56),
    )
)

public val Res.string.details: StringResource
  get() = CommonMainString0.details

private fun init_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:details", "details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4104, 27),
    )
)

public val Res.string.document: StringResource
  get() = CommonMainString0.document

private fun init_document(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:document", "document",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4132, 28),
    )
)

public val Res.string.done_selecting_dates: StringResource
  get() = CommonMainString0.done_selecting_dates

private fun init_done_selecting_dates(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:done_selecting_dates", "done_selecting_dates",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4161, 36),
    )
)

public val Res.string.due_date: StringResource
  get() = CommonMainString0.due_date

private fun init_due_date(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:due_date", "due_date",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4198, 28),
    )
)

public val Res.string.edit: StringResource
  get() = CommonMainString0.edit

private fun init_edit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:edit", "edit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4227, 20),
    )
)

public val Res.string.effortless_efficiency: StringResource
  get() = CommonMainString0.effortless_efficiency

private fun init_effortless_efficiency(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:effortless_efficiency", "effortless_efficiency",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4248, 57),
    )
)

public val Res.string.email: StringResource
  get() = CommonMainString0.email

private fun init_email(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email", "email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4425, 21),
    )
)

public val Res.string.email_address: StringResource
  get() = CommonMainString0.email_address

private fun init_email_address(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email_address", "email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4306, 41),
    )
)

public val Res.string.email_capital: StringResource
  get() = CommonMainString0.email_capital

private fun init_email_capital(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email_capital", "email_capital",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4348, 29),
    )
)

public val Res.string.email_required: StringResource
  get() = CommonMainString0.email_required

private fun init_email_required(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:email_required", "email_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4378, 46),
    )
)

public val Res.string.employee: StringResource
  get() = CommonMainString0.employee

private fun init_employee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:employee", "employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4598, 28),
    )
)

public val Res.string.employee_added_successfully: StringResource
  get() = CommonMainString0.employee_added_successfully

private fun init_employee_added_successfully(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:employee_added_successfully", "employee_added_successfully",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4447, 71),
    )
)

public val Res.string.employee_details: StringResource
  get() = CommonMainString0.employee_details

private fun init_employee_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:employee_details", "employee_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4519, 48),
    )
)

public val Res.string.employees: StringResource
  get() = CommonMainString0.employees

private fun init_employees(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:employees", "employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4568, 29),
    )
)

public val Res.string.employment_details: StringResource
  get() = CommonMainString0.employment_details

private fun init_employment_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:employment_details", "employment_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4627, 50),
    )
)

public val Res.string.end_at: StringResource
  get() = CommonMainString0.end_at

private fun init_end_at(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:end_at", "end_at",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4678, 22),
    )
)

public val Res.string.end_time: StringResource
  get() = CommonMainString0.end_time

private fun init_end_time(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:end_time", "end_time",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4701, 28),
    )
)

public val Res.string.enter_building_number: StringResource
  get() = CommonMainString0.enter_building_number

private fun init_enter_building_number(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_building_number", "enter_building_number",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4730, 81),
    )
)

public val Res.string.enter_contact_name: StringResource
  get() = CommonMainString0.enter_contact_name

private fun init_enter_contact_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_contact_name", "enter_contact_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4812, 50),
    )
)

public val Res.string.enter_email_address: StringResource
  get() = CommonMainString0.enter_email_address

private fun init_enter_email_address(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_email_address", "enter_email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4863, 55),
    )
)

public val Res.string.enter_employee_email: StringResource
  get() = CommonMainString0.enter_employee_email

private fun init_enter_employee_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_employee_email", "enter_employee_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4919, 68),
    )
)

public val Res.string.enter_full_name: StringResource
  get() = CommonMainString0.enter_full_name

private fun init_enter_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_full_name", "enter_full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 4988, 43),
    )
)

public val Res.string.enter_job_title: StringResource
  get() = CommonMainString0.enter_job_title

private fun init_enter_job_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_job_title", "enter_job_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5032, 43),
    )
)

public val Res.string.enter_site_name: StringResource
  get() = CommonMainString0.enter_site_name

private fun init_enter_site_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_site_name", "enter_site_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5076, 43),
    )
)

public val Res.string.enter_step_title: StringResource
  get() = CommonMainString0.enter_step_title

private fun init_enter_step_title(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_step_title", "enter_step_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5120, 48),
    )
)

public val Res.string.enter_street: StringResource
  get() = CommonMainString0.enter_street

private fun init_enter_street(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_street", "enter_street",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5169, 60),
    )
)

public val Res.string.enter_surname: StringResource
  get() = CommonMainString0.enter_surname

private fun init_enter_surname(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_surname", "enter_surname",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5230, 53),
    )
)

public val Res.string.enter_text: StringResource
  get() = CommonMainString0.enter_text

private fun init_enter_text(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_text", "enter_text",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5284, 34),
    )
)

public val Res.string.enter_time: StringResource
  get() = CommonMainString0.enter_time

private fun init_enter_time(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_time", "enter_time",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5319, 34),
    )
)

public val Res.string.enter_workspace_name: StringResource
  get() = CommonMainString0.enter_workspace_name

private fun init_enter_workspace_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_workspace_name", "enter_workspace_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5354, 72),
    )
)

public val Res.string.enter_your_email: StringResource
  get() = CommonMainString0.enter_your_email

private fun init_enter_your_email(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:enter_your_email", "enter_your_email",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5427, 48),
    )
)

public val Res.string.enter_zip: StringResource
  get() = CommonMainString0.enter_zip

private fun init_enter_zip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:enter_zip", "enter_zip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5476, 49),
    )
)

public val Res.string.error: StringResource
  get() = CommonMainString0.error

private fun init_error(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error", "error",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5596, 21),
    )
)

public val Res.string.error_message: StringResource
  get() = CommonMainString0.error_message

private fun init_error_message(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:error_message", "error_message",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5526, 69),
    )
)

public val Res.string.estimated_duration: StringResource
  get() = CommonMainString0.estimated_duration

private fun init_estimated_duration(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:estimated_duration", "estimated_duration",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5618, 50),
    )
)

public val Res.string.every_2_weeks: StringResource
  get() = CommonMainString0.every_2_weeks

private fun init_every_2_weeks(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_2_weeks", "every_2_weeks",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5669, 41),
    )
)

public val Res.string.every_day: StringResource
  get() = CommonMainString0.every_day

private fun init_every_day(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_day", "every_day",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5711, 29),
    )
)

public val Res.string.every_month: StringResource
  get() = CommonMainString0.every_month

private fun init_every_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_month", "every_month",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5741, 35),
    )
)

public val Res.string.every_week: StringResource
  get() = CommonMainString0.every_week

private fun init_every_week(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:every_week", "every_week",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5777, 34),
    )
)

public val Res.string.expand_your_team: StringResource
  get() = CommonMainString0.expand_your_team

private fun init_expand_your_team(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:expand_your_team", "expand_your_team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5812, 116),
    )
)

public val Res.string.export_list: StringResource
  get() = CommonMainString0.export_list

private fun init_export_list(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:export_list", "export_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5929, 35),
    )
)

public val Res.string.fast_track: StringResource
  get() = CommonMainString0.fast_track

private fun init_fast_track(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fast_track", "fast_track",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6036, 62),
    )
)

public val Res.string.fast_track_support: StringResource
  get() = CommonMainString0.fast_track_support

private fun init_fast_track_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fast_track_support", "fast_track_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 5965, 70),
    )
)

public val Res.string.file_deleted: StringResource
  get() = CommonMainString0.file_deleted

private fun init_file_deleted(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:file_deleted", "file_deleted",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6099, 56),
    )
)

public val Res.string.files: StringResource
  get() = CommonMainString0.files

private fun init_files(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:files", "files",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6196, 21),
    )
)

public val Res.string.files_notes: StringResource
  get() = CommonMainString0.files_notes

private fun init_files_notes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:files_notes", "files_notes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6156, 39),
    )
)

public val Res.string.filter: StringResource
  get() = CommonMainString0.filter

private fun init_filter(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:filter", "filter",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6218, 22),
    )
)

public val Res.string.fly_high: StringResource
  get() = CommonMainString0.fly_high

private fun init_fly_high(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fly_high", "fly_high",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6685, 28),
    )
)

public val Res.string.fly_high_description: StringResource
  get() = CommonMainString0.fly_high_description

private fun init_fly_high_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_description", "fly_high_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6241, 136),
    )
)

public val Res.string.fly_high_feature_1: StringResource
  get() = CommonMainString0.fly_high_feature_1

private fun init_fly_high_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_feature_1", "fly_high_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6378, 54),
    )
)

public val Res.string.fly_high_feature_2: StringResource
  get() = CommonMainString0.fly_high_feature_2

private fun init_fly_high_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_feature_2", "fly_high_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6433, 70),
    )
)

public val Res.string.fly_high_plan_description: StringResource
  get() = CommonMainString0.fly_high_plan_description

private fun init_fly_high_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:fly_high_plan_description", "fly_high_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6504, 141),
    )
)

public val Res.string.fly_high_price: StringResource
  get() = CommonMainString0.fly_high_price

private fun init_fly_high_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:fly_high_price", "fly_high_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6646, 38),
    )
)

public val Res.string.forgot_password: StringResource
  get() = CommonMainString0.forgot_password

private fun init_forgot_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:forgot_password", "forgot_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6714, 47),
    )
)

public val Res.string.forgot_your_password: StringResource
  get() = CommonMainString0.forgot_your_password

private fun init_forgot_your_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:forgot_your_password", "forgot_your_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6762, 56),
    )
)

public val Res.string.free: StringResource
  get() = CommonMainString0.free

private fun init_free(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free", "free",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7408, 20),
    )
)

public val Res.string.free_100: StringResource
  get() = CommonMainString0.free_100

private fun init_free_100(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free_100", "free_100",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6819, 108),
    )
)

public val Res.string.free_hundred: StringResource
  get() = CommonMainString0.free_hundred

private fun init_free_hundred(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:free_hundred", "free_hundred",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6928, 32),
    )
)

public val Res.string.freebie: StringResource
  get() = CommonMainString0.freebie

private fun init_freebie(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:freebie", "freebie",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7380, 27),
    )
)

public val Res.string.freebie_description: StringResource
  get() = CommonMainString0.freebie_description

private fun init_freebie_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_description", "freebie_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 6961, 135),
    )
)

public val Res.string.freebie_feature_1: StringResource
  get() = CommonMainString0.freebie_feature_1

private fun init_freebie_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_feature_1", "freebie_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7097, 49),
    )
)

public val Res.string.freebie_feature_2: StringResource
  get() = CommonMainString0.freebie_feature_2

private fun init_freebie_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_feature_2", "freebie_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7147, 57),
    )
)

public val Res.string.freebie_plan_description: StringResource
  get() = CommonMainString0.freebie_plan_description

private fun init_freebie_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:freebie_plan_description", "freebie_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7205, 140),
    )
)

public val Res.string.freebie_price: StringResource
  get() = CommonMainString0.freebie_price

private fun init_freebie_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:freebie_price", "freebie_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7346, 33),
    )
)

public val Res.string.friday: StringResource
  get() = CommonMainString0.friday

private fun init_friday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:friday", "friday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7429, 22),
    )
)

public val Res.string.from_small_teams: StringResource
  get() = CommonMainString0.from_small_teams

private fun init_from_small_teams(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:from_small_teams", "from_small_teams",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7452, 232),
    )
)

public val Res.string.full_name: StringResource
  get() = CommonMainString0.full_name

private fun init_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:full_name", "full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7813, 29),
    )
)

public val Res.string.full_name_contact_person: StringResource
  get() = CommonMainString0.full_name_contact_person

private fun init_full_name_contact_person(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:full_name_contact_person", "full_name_contact_person",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7685, 68),
    )
)

public val Res.string.full_name_required: StringResource
  get() = CommonMainString0.full_name_required

private fun init_full_name_required(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:full_name_required", "full_name_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7754, 58),
    )
)

public val Res.string.galactic: StringResource
  get() = CommonMainString0.galactic

private fun init_galactic(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:galactic", "galactic",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8287, 28),
    )
)

public val Res.string.galactic_description: StringResource
  get() = CommonMainString0.galactic_description

private fun init_galactic_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_description", "galactic_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7843, 136),
    )
)

public val Res.string.galactic_feature_1: StringResource
  get() = CommonMainString0.galactic_feature_1

private fun init_galactic_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_feature_1", "galactic_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 7980, 54),
    )
)

public val Res.string.galactic_feature_2: StringResource
  get() = CommonMainString0.galactic_feature_2

private fun init_galactic_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_feature_2", "galactic_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8035, 70),
    )
)

public val Res.string.galactic_plan_description: StringResource
  get() = CommonMainString0.galactic_plan_description

private fun init_galactic_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:galactic_plan_description", "galactic_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8106, 141),
    )
)

public val Res.string.galactic_price: StringResource
  get() = CommonMainString0.galactic_price

private fun init_galactic_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:galactic_price", "galactic_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8248, 38),
    )
)

public val Res.string.goodjob_stripe_merchant_name: StringResource
  get() = CommonMainString0.goodjob_stripe_merchant_name

private fun init_goodjob_stripe_merchant_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:goodjob_stripe_merchant_name", "goodjob_stripe_merchant_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8316, 48),
    )
)

public val Res.string.hello: StringResource
  get() = CommonMainString0.hello

private fun init_hello(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hello", "hello",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8365, 21),
    )
)

public val Res.string.high_five: StringResource
  get() = CommonMainString0.high_five

private fun init_high_five(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:high_five", "high_five",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8808, 33),
    )
)

public val Res.string.high_five_description: StringResource
  get() = CommonMainString0.high_five_description

private fun init_high_five_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_description", "high_five_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8387, 125),
    )
)

public val Res.string.high_five_feature_1: StringResource
  get() = CommonMainString0.high_five_feature_1

private fun init_high_five_feature_1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_feature_1", "high_five_feature_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8513, 55),
    )
)

public val Res.string.high_five_feature_2: StringResource
  get() = CommonMainString0.high_five_feature_2

private fun init_high_five_feature_2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_feature_2", "high_five_feature_2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8569, 71),
    )
)

public val Res.string.high_five_plan_description: StringResource
  get() = CommonMainString0.high_five_plan_description

private fun init_high_five_plan_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:high_five_plan_description", "high_five_plan_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8641, 126),
    )
)

public val Res.string.high_five_price: StringResource
  get() = CommonMainString0.high_five_price

private fun init_high_five_price(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:high_five_price", "high_five_price",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8768, 39),
    )
)

public val Res.string.hour: StringResource
  get() = CommonMainString0.hour

private fun init_hour(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:hour", "hour",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8842, 20),
    )
)

public val Res.string.images: StringResource
  get() = CommonMainString0.images

private fun init_images(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:images", "images",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8863, 22),
    )
)

public val Res.string.import_list: StringResource
  get() = CommonMainString0.import_list

private fun init_import_list(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:import_list", "import_list",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8886, 35),
    )
)

public val Res.string.inactive: StringResource
  get() = CommonMainString0.inactive

private fun init_inactive(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:inactive", "inactive",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8922, 28),
    )
)

public val Res.string.industry: StringResource
  get() = CommonMainString0.industry

private fun init_industry(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:industry", "industry",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8951, 28),
    )
)

public val Res.string.its: StringResource
  get() = CommonMainString0.its

private fun init_its(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:its", "its",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 8980, 19),
    )
)

public val Res.string.job_details: StringResource
  get() = CommonMainString0.job_details

private fun init_job_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_details", "job_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9000, 35),
    )
)

public val Res.string.job_duration: StringResource
  get() = CommonMainString0.job_duration

private fun init_job_duration(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_duration", "job_duration",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9036, 36),
    )
)

public val Res.string.job_finished: StringResource
  get() = CommonMainString0.job_finished

private fun init_job_finished(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_finished", "job_finished",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9073, 56),
    )
)

public val Res.string.job_status: StringResource
  get() = CommonMainString0.job_status

private fun init_job_status(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_status", "job_status",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9130, 34),
    )
)

public val Res.string.job_title: StringResource
  get() = CommonMainString0.job_title

private fun init_job_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:job_title", "job_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9165, 29),
    )
)

public val Res.string.jobs: StringResource
  get() = CommonMainString0.jobs

private fun init_jobs(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:jobs", "jobs",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9237, 20),
    )
)

public val Res.string.jobs_overview: StringResource
  get() = CommonMainString0.jobs_overview

private fun init_jobs_overview(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:jobs_overview", "jobs_overview",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9195, 41),
    )
)

public val Res.string.join_good_job: StringResource
  get() = CommonMainString0.join_good_job

private fun init_join_good_job(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_good_job", "join_good_job",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9258, 41),
    )
)

public val Res.string.join_us: StringResource
  get() = CommonMainString0.join_us

private fun init_join_us(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:join_us", "join_us",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9300, 55),
    )
)

public val Res.string.last_accessed: StringResource
  get() = CommonMainString0.last_accessed

private fun init_last_accessed(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:last_accessed", "last_accessed",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9356, 41),
    )
)

public val Res.string.leave_details: StringResource
  get() = CommonMainString0.leave_details

private fun init_leave_details(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:leave_details", "leave_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9398, 41),
    )
)

public val Res.string.location_details: StringResource
  get() = CommonMainString0.location_details

private fun init_location_details(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:location_details", "location_details",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9440, 48),
    )
)

public val Res.string.log_out: StringResource
  get() = CommonMainString0.log_out

private fun init_log_out(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:log_out", "log_out",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9489, 27),
    )
)

public val Res.string.login_register: StringResource
  get() = CommonMainString0.login_register

private fun init_login_register(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:login_register", "login_register",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9517, 42),
    )
)

public val Res.string.logout: StringResource
  get() = CommonMainString0.logout

private fun init_logout(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:logout", "logout",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9560, 22),
    )
)

public val Res.string.manage_employees: StringResource
  get() = CommonMainString0.manage_employees

private fun init_manage_employees(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:manage_employees", "manage_employees",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9583, 100),
    )
)

public val Res.string.manager: StringResource
  get() = CommonMainString0.manager

private fun init_manager(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:manager", "manager",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9684, 27),
    )
)

public val Res.string.map: StringResource
  get() = CommonMainString0.map

private fun init_map(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:map", "map",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9712, 15),
    )
)

public val Res.string.max_size: StringResource
  get() = CommonMainString0.max_size

private fun init_max_size(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:max_size", "max_size",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9728, 52),
    )
)

public val Res.string.minute: StringResource
  get() = CommonMainString0.minute

private fun init_minute(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:minute", "minute",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9809, 22),
    )
)

public val Res.string.minutes: StringResource
  get() = CommonMainString0.minutes

private fun init_minutes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:minutes", "minutes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9781, 27),
    )
)

public val Res.string.monday: StringResource
  get() = CommonMainString0.monday

private fun init_monday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:monday", "monday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9832, 22),
    )
)

public val Res.string.month: StringResource
  get() = CommonMainString0.month

private fun init_month(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:month", "month",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9883, 21),
    )
)

public val Res.string.monthly: StringResource
  get() = CommonMainString0.monthly

private fun init_monthly(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:monthly", "monthly",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9855, 27),
    )
)

public val Res.string.most_popular: StringResource
  get() = CommonMainString0.most_popular

private fun init_most_popular(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:most_popular", "most_popular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9905, 36),
    )
)

public val Res.string.name: StringResource
  get() = CommonMainString0.name

private fun init_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:name", "name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9942, 20),
    )
)

public val Res.string.need_an_account: StringResource
  get() = CommonMainString0.need_an_account

private fun init_need_an_account(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:need_an_account", "need_an_account",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 9963, 47),
    )
)

public val Res.string.never: StringResource
  get() = CommonMainString0.never

private fun init_never(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:never", "never",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10011, 21),
    )
)

public val Res.string.new_password: StringResource
  get() = CommonMainString0.new_password

private fun init_new_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_password", "new_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10033, 36),
    )
)

public val Res.string.new_workspace: StringResource
  get() = CommonMainString0.new_workspace

private fun init_new_workspace(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:new_workspace", "new_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10070, 41),
    )
)

public val Res.string.next: StringResource
  get() = CommonMainString0.next

private fun init_next(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:next", "next",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10112, 20),
    )
)

public val Res.string.no_worries: StringResource
  get() = CommonMainString0.no_worries

private fun init_no_worries(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:no_worries", "no_worries",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10133, 66),
    )
)

public val Res.string.note_added_successfully: StringResource
  get() = CommonMainString0.note_added_successfully

private fun init_note_added_successfully(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:note_added_successfully", "note_added_successfully",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10200, 63),
    )
)

public val Res.string.notes: StringResource
  get() = CommonMainString0.notes

private fun init_notes(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:notes", "notes",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10264, 21),
    )
)

public val Res.string.notifications: StringResource
  get() = CommonMainString0.notifications

private fun init_notifications(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:notifications", "notifications",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10286, 41),
    )
)

public val Res.string.ok: StringResource
  get() = CommonMainString0.ok

private fun init_ok(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ok", "ok",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10328, 14),
    )
)

public val Res.string.onboarding_description1: StringResource
  get() = CommonMainString0.onboarding_description1

private fun init_onboarding_description1(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onboarding_description1", "onboarding_description1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10343, 139),
    )
)

public val Res.string.onboarding_description2: StringResource
  get() = CommonMainString0.onboarding_description2

private fun init_onboarding_description2(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:onboarding_description2", "onboarding_description2",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10483, 167),
    )
)

public val Res.string.only_support: StringResource
  get() = CommonMainString0.only_support

private fun init_only_support(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:only_support", "only_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10651, 72),
    )
)

public val Res.string.optimize_your: StringResource
  get() = CommonMainString0.optimize_your

private fun init_optimize_your(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:optimize_your", "optimize_your",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10724, 169),
    )
)

public val Res.string.password: StringResource
  get() = CommonMainString0.password

private fun init_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password", "password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11120, 28),
    )
)

public val Res.string.password_set: StringResource
  get() = CommonMainString0.password_set

private fun init_password_set(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:password_set", "password_set",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10894, 104),
    )
)

public val Res.string.password_updated: StringResource
  get() = CommonMainString0.password_updated

private fun init_password_updated(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:password_updated", "password_updated",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 10999, 120),
    )
)

public val Res.string.phone: StringResource
  get() = CommonMainString0.phone

private fun init_phone(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:phone", "phone",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11252, 21),
    )
)

public val Res.string.phone_number: StringResource
  get() = CommonMainString0.phone_number

private fun init_phone_number(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:phone_number", "phone_number",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11215, 36),
    )
)

public val Res.string.phone_number_required: StringResource
  get() = CommonMainString0.phone_number_required

private fun init_phone_number_required(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:phone_number_required", "phone_number_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11149, 65),
    )
)

public val Res.string.places: StringResource
  get() = CommonMainString0.places

private fun init_places(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:places", "places",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11274, 22),
    )
)

public val Res.string.plan: StringResource
  get() = CommonMainString0.plan

private fun init_plan(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plan", "plan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11425, 20),
    )
)

public val Res.string.plan_and_assign: StringResource
  get() = CommonMainString0.plan_and_assign

private fun init_plan_and_assign(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:plan_and_assign", "plan_and_assign",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11297, 127),
    )
)

public val Res.string.powerful_tools: StringResource
  get() = CommonMainString0.powerful_tools

private fun init_powerful_tools(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:powerful_tools", "powerful_tools",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11446, 42),
    )
)

public val Res.string.pricing: StringResource
  get() = CommonMainString0.pricing

private fun init_pricing(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:pricing", "pricing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11489, 27),
    )
)

public val Res.string.priority_support: StringResource
  get() = CommonMainString0.priority_support

private fun init_priority_support(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:priority_support", "priority_support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11517, 68),
    )
)

public val Res.string.privacy_policy: StringResource
  get() = CommonMainString0.privacy_policy

private fun init_privacy_policy(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:privacy_policy", "privacy_policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11586, 42),
    )
)

public val Res.string.profile_settings: StringResource
  get() = CommonMainString0.profile_settings

private fun init_profile_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:profile_settings", "profile_settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11629, 48),
    )
)

public val Res.string.provide_information: StringResource
  get() = CommonMainString0.provide_information

private fun init_provide_information(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:provide_information", "provide_information",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11678, 67),
    )
)

public val Res.string.publish_key: StringResource
  get() = CommonMainString0.publish_key

private fun init_publish_key(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:publish_key", "publish_key",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11746, 163),
    )
)

public val Res.string.ready_for: StringResource
  get() = CommonMainString0.ready_for

private fun init_ready_for(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:ready_for", "ready_for",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11910, 61),
    )
)

public val Res.string.register: StringResource
  get() = CommonMainString0.register

private fun init_register(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register", "register",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11998, 28),
    )
)

public val Res.string.register_with: StringResource
  get() = CommonMainString0.register_with

private fun init_register_with(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:register_with", "register_with",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 11972, 25),
    )
)

public val Res.string.reject: StringResource
  get() = CommonMainString0.reject

private fun init_reject(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:reject", "reject",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12027, 58),
    )
)

public val Res.string.repeat: StringResource
  get() = CommonMainString0.repeat

private fun init_repeat(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:repeat", "repeat",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12130, 22),
    )
)

public val Res.string.repeat_password: StringResource
  get() = CommonMainString0.repeat_password

private fun init_repeat_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:repeat_password", "repeat_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12086, 43),
    )
)

public val Res.string.reset_link_sent: StringResource
  get() = CommonMainString0.reset_link_sent

private fun init_reset_link_sent(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:reset_link_sent", "reset_link_sent",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12153, 47),
    )
)

public val Res.string.role: StringResource
  get() = CommonMainString0.role

private fun init_role(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:role", "role",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12250, 20),
    )
)

public val Res.string.roles_permission: StringResource
  get() = CommonMainString0.roles_permission

private fun init_roles_permission(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:roles_permission", "roles_permission",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12201, 48),
    )
)

public val Res.string.saturday: StringResource
  get() = CommonMainString0.saturday

private fun init_saturday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:saturday", "saturday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12271, 28),
    )
)

public val Res.string.save_password: StringResource
  get() = CommonMainString0.save_password

private fun init_save_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:save_password", "save_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12300, 41),
    )
)

public val Res.string.schedule: StringResource
  get() = CommonMainString0.schedule

private fun init_schedule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:schedule", "schedule",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12372, 28),
    )
)

public val Res.string.scheduler: StringResource
  get() = CommonMainString0.scheduler

private fun init_scheduler(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:scheduler", "scheduler",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12342, 29),
    )
)

public val Res.string.search: StringResource
  get() = CommonMainString0.search

private fun init_search(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:search", "search",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12401, 22),
    )
)

public val Res.string.select_dates: StringResource
  get() = CommonMainString0.select_dates

private fun init_select_dates(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_dates", "select_dates",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12424, 36),
    )
)

public val Res.string.select_employee: StringResource
  get() = CommonMainString0.select_employee

private fun init_select_employee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_employee", "select_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12461, 43),
    )
)

public val Res.string.select_industry: StringResource
  get() = CommonMainString0.select_industry

private fun init_select_industry(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_industry", "select_industry",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12505, 59),
    )
)

public val Res.string.select_job_status: StringResource
  get() = CommonMainString0.select_job_status

private fun init_select_job_status(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_job_status", "select_job_status",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12565, 49),
    )
)

public val Res.string.select_skill: StringResource
  get() = CommonMainString0.select_skill

private fun init_select_skill(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_skill", "select_skill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12615, 52),
    )
)

public val Res.string.select_team: StringResource
  get() = CommonMainString0.select_team

private fun init_select_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:select_team", "select_team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12668, 47),
    )
)

public val Res.string.select_your_workspace: StringResource
  get() = CommonMainString0.select_your_workspace

private fun init_select_your_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:select_your_workspace", "select_your_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12716, 57),
    )
)

public val Res.string.selected: StringResource
  get() = CommonMainString0.selected

private fun init_selected(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:selected", "selected",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12774, 28),
    )
)

public val Res.string.send_reset_link: StringResource
  get() = CommonMainString0.send_reset_link

private fun init_send_reset_link(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:send_reset_link", "send_reset_link",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12803, 43),
    )
)

public val Res.string.set_new_password: StringResource
  get() = CommonMainString0.set_new_password

private fun init_set_new_password(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:set_new_password", "set_new_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12847, 52),
    )
)

public val Res.string.set_up_workspace: StringResource
  get() = CommonMainString0.set_up_workspace

private fun init_set_up_workspace(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:set_up_workspace", "set_up_workspace",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 12900, 104),
    )
)

public val Res.string.set_weekly_hours: StringResource
  get() = CommonMainString0.set_weekly_hours

private fun init_set_weekly_hours(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:set_weekly_hours", "set_weekly_hours",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13005, 48),
    )
)

public val Res.string.settings: StringResource
  get() = CommonMainString0.settings

private fun init_settings(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:settings", "settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13054, 28),
    )
)

public val Res.string.sign_in: StringResource
  get() = CommonMainString0.sign_in

private fun init_sign_in(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in", "sign_in",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13138, 27),
    )
)

public val Res.string.sign_in_1: StringResource
  get() = CommonMainString0.sign_in_1

private fun init_sign_in_1(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in_1", "sign_in_1",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13083, 29),
    )
)

public val Res.string.sign_in_with: StringResource
  get() = CommonMainString0.sign_in_with

private fun init_sign_in_with(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sign_in_with", "sign_in_with",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13113, 24),
    )
)

public val Res.string.site: StringResource
  get() = CommonMainString0.site

private fun init_site(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:site", "site",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13269, 20),
    )
)

public val Res.string.site_added: StringResource
  get() = CommonMainString0.site_added

private fun init_site_added(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:site_added", "site_added",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13166, 50),
    )
)

public val Res.string.site_name: StringResource
  get() = CommonMainString0.site_name

private fun init_site_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:site_name", "site_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13217, 29),
    )
)

public val Res.string.sites: StringResource
  get() = CommonMainString0.sites

private fun init_sites(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sites", "sites",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13247, 21),
    )
)

public val Res.string.skill: StringResource
  get() = CommonMainString0.skill

private fun init_skill(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skill", "skill",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13372, 21),
    )
)

public val Res.string.skill_required: StringResource
  get() = CommonMainString0.skill_required

private fun init_skill_required(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skill_required", "skill_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13290, 58),
    )
)

public val Res.string.skills: StringResource
  get() = CommonMainString0.skills

private fun init_skills(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skills", "skills",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13349, 22),
    )
)

public val Res.string.skip: StringResource
  get() = CommonMainString0.skip

private fun init_skip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:skip", "skip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13394, 20),
    )
)

public val Res.string.sort_by: StringResource
  get() = CommonMainString0.sort_by

private fun init_sort_by(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sort_by", "sort_by",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13415, 27),
    )
)

public val Res.string.start_at: StringResource
  get() = CommonMainString0.start_at

private fun init_start_at(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:start_at", "start_at",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13443, 28),
    )
)

public val Res.string.start_organizing: StringResource
  get() = CommonMainString0.start_organizing

private fun init_start_organizing(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_organizing", "start_organizing",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13472, 68),
    )
)

public val Res.string.start_time: StringResource
  get() = CommonMainString0.start_time

private fun init_start_time(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:start_time", "start_time",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13541, 34),
    )
)

public val Res.string.start_typing_address: StringResource
  get() = CommonMainString0.start_typing_address

private fun init_start_typing_address(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:start_typing_address", "start_typing_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13576, 84),
    )
)

public val Res.string.status: StringResource
  get() = CommonMainString0.status

private fun init_status(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:status", "status",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13713, 22),
    )
)

public val Res.string.status_required: StringResource
  get() = CommonMainString0.status_required

private fun init_status_required(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:status_required", "status_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13661, 51),
    )
)

public val Res.string.step: StringResource
  get() = CommonMainString0.step

private fun init_step(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:step", "step",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13842, 20),
    )
)

public val Res.string.step_description: StringResource
  get() = CommonMainString0.step_description

private fun init_step_description(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:step_description", "step_description",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13736, 48),
    )
)

public val Res.string.step_title: StringResource
  get() = CommonMainString0.step_title

private fun init_step_title(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:step_title", "step_title",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13785, 34),
    )
)

public val Res.string.steps: StringResource
  get() = CommonMainString0.steps

private fun init_steps(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:steps", "steps",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13820, 21),
    )
)

public val Res.string.submit: StringResource
  get() = CommonMainString0.submit

private fun init_submit(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:submit", "submit",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13863, 22),
    )
)

public val Res.string.subscribe: StringResource
  get() = CommonMainString0.subscribe

private fun init_subscribe(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscribe", "subscribe",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13886, 29),
    )
)

public val Res.string.subscription: StringResource
  get() = CommonMainString0.subscription

private fun init_subscription(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:subscription", "subscription",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13916, 36),
    )
)

public val Res.string.success: StringResource
  get() = CommonMainString0.success

private fun init_success(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:success", "success",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13953, 27),
    )
)

public val Res.string.sunday: StringResource
  get() = CommonMainString0.sunday

private fun init_sunday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:sunday", "sunday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 13981, 22),
    )
)

public val Res.string.support: StringResource
  get() = CommonMainString0.support

private fun init_support(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:support", "support",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14004, 47),
    )
)

public val Res.string.tap_to_upload_photo: StringResource
  get() = CommonMainString0.tap_to_upload_photo

private fun init_tap_to_upload_photo(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:tap_to_upload_photo", "tap_to_upload_photo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14052, 55),
    )
)

public val Res.string.team: StringResource
  get() = CommonMainString0.team

private fun init_team(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:team", "team",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14184, 20),
    )
)

public val Res.string.team_required: StringResource
  get() = CommonMainString0.team_required

private fun init_team_required(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:team_required", "team_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14108, 53),
    )
)

public val Res.string.teams: StringResource
  get() = CommonMainString0.teams

private fun init_teams(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:teams", "teams",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14162, 21),
    )
)

public val Res.string.terms_of_service: StringResource
  get() = CommonMainString0.terms_of_service

private fun init_terms_of_service(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:terms_of_service", "terms_of_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14205, 48),
    )
)

public val Res.string.thank_you_for_registering: StringResource
  get() = CommonMainString0.thank_you_for_registering

private fun init_thank_you_for_registering(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:thank_you_for_registering", "thank_you_for_registering",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14254, 233),
    )
)

public val Res.string.thursday: StringResource
  get() = CommonMainString0.thursday

private fun init_thursday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:thursday", "thursday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14488, 28),
    )
)

public val Res.string.today: StringResource
  get() = CommonMainString0.today

private fun init_today(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:today", "today",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14564, 21),
    )
)

public val Res.string.today_schedule: StringResource
  get() = CommonMainString0.today_schedule

private fun init_today_schedule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:today_schedule", "today_schedule",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14517, 46),
    )
)

public val Res.string.total: StringResource
  get() = CommonMainString0.total

private fun init_total(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:total", "total",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14586, 21),
    )
)

public val Res.string.transform: StringResource
  get() = CommonMainString0.transform

private fun init_transform(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:transform", "transform",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14608, 73),
    )
)

public val Res.string.transparent_flexible: StringResource
  get() = CommonMainString0.transparent_flexible

private fun init_transparent_flexible(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:transparent_flexible", "transparent_flexible",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14682, 96),
    )
)

public val Res.string.try_another_document: StringResource
  get() = CommonMainString0.try_another_document

private fun init_try_another_document(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:try_another_document", "try_another_document",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14779, 84),
    )
)

public val Res.string.tuesday: StringResource
  get() = CommonMainString0.tuesday

private fun init_tuesday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:tuesday", "tuesday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14864, 27),
    )
)

public val Res.string.unlimited_storage: StringResource
  get() = CommonMainString0.unlimited_storage

private fun init_unlimited_storage(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:unlimited_storage", "unlimited_storage",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14892, 49),
    )
)

public val Res.string.unselect_all: StringResource
  get() = CommonMainString0.unselect_all

private fun init_unselect_all(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:unselect_all", "unselect_all",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14942, 36),
    )
)

public val Res.string.upcoming_leaves: StringResource
  get() = CommonMainString0.upcoming_leaves

private fun init_upcoming_leaves(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:upcoming_leaves", "upcoming_leaves",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 14979, 43),
    )
)

public val Res.string.upload_documents: StringResource
  get() = CommonMainString0.upload_documents

private fun init_upload_documents(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:upload_documents", "upload_documents",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15023, 60),
    )
)

public val Res.string.upload_profile_picture: StringResource
  get() = CommonMainString0.upload_profile_picture

private fun init_upload_profile_picture(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:upload_profile_picture", "upload_profile_picture",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15084, 62),
    )
)

public val Res.string.use_contact_name: StringResource
  get() = CommonMainString0.use_contact_name

private fun init_use_contact_name(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:use_contact_name", "use_contact_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15147, 72),
    )
)

public val Res.string.user: StringResource
  get() = CommonMainString0.user

private fun init_user(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:user", "user",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15242, 20),
    )
)

public val Res.string.users: StringResource
  get() = CommonMainString0.users

private fun init_users(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:users", "users",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15220, 21),
    )
)

public val Res.string.wednesday: StringResource
  get() = CommonMainString0.wednesday

private fun init_wednesday(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:wednesday", "wednesday",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15263, 29),
    )
)

public val Res.string.welcome: StringResource
  get() = CommonMainString0.welcome

private fun init_welcome(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome", "welcome",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15334, 39),
    )
)

public val Res.string.welcome_back: StringResource
  get() = CommonMainString0.welcome_back

private fun init_welcome_back(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:welcome_back", "welcome_back",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15293, 40),
    )
)

public val Res.string.whether: StringResource
  get() = CommonMainString0.whether

private fun init_whether(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:whether", "whether",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15374, 195),
    )
)

public val Res.string.work_schedule: StringResource
  get() = CommonMainString0.work_schedule

private fun init_work_schedule(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:work_schedule", "work_schedule",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15570, 41),
    )
)

public val Res.string.working_hours: StringResource
  get() = CommonMainString0.working_hours

private fun init_working_hours(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:working_hours", "working_hours",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15612, 41),
    )
)

public val Res.string.workspace_name: StringResource
  get() = CommonMainString0.workspace_name

private fun init_workspace_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:workspace_name", "workspace_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15654, 42),
    )
)

public val Res.string.workspace_settings: StringResource
  get() = CommonMainString0.workspace_settings

private fun init_workspace_settings(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:workspace_settings", "workspace_settings",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15697, 50),
    )
)

public val Res.string.workspace_type: StringResource
  get() = CommonMainString0.workspace_type

private fun init_workspace_type(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:workspace_type", "workspace_type",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15748, 42),
    )
)

public val Res.string.yearly: StringResource
  get() = CommonMainString0.yearly

private fun init_yearly(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:yearly", "yearly",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15791, 30),
    )
)

public val Res.string.your_email_address: StringResource
  get() = CommonMainString0.your_email_address

private fun init_your_email_address(): StringResource =
    org.jetbrains.compose.resources.StringResource(
  "string:your_email_address", "your_email_address",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15822, 50),
    )
)

public val Res.string.your_full_name: StringResource
  get() = CommonMainString0.your_full_name

private fun init_your_full_name(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_full_name", "your_full_name",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15873, 42),
    )
)

public val Res.string.your_password: StringResource
  get() = CommonMainString0.your_password

private fun init_your_password(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:your_password", "your_password",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15916, 41),
    )
)

public val Res.string.zip: StringResource
  get() = CommonMainString0.zip

private fun init_zip(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zip", "zip",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 16032, 35),
    )
)

public val Res.string.zip_employee: StringResource
  get() = CommonMainString0.zip_employee

private fun init_zip_employee(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zip_employee", "zip_employee",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15958, 24),
    )
)

public val Res.string.zip_required: StringResource
  get() = CommonMainString0.zip_required

private fun init_zip_required(): StringResource = org.jetbrains.compose.resources.StringResource(
  "string:zip_required", "zip_required",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/com.hyperether.goodjob.resources/values/strings.commonMain.cvr", 15983, 48),
    )
)
