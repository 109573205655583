package com.hyperether.goodjob.navigation

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.material.IconButton
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.painter.Painter
import androidx.navigation.NavHostController
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.briefcase
import com.hyperether.goodjob.resources.briefcase_unselect
import com.hyperether.goodjob.resources.calendar
import com.hyperether.goodjob.resources.calendar_unselect
import com.hyperether.goodjob.resources.dahboard
import com.hyperether.goodjob.resources.dahboard_unselect
import com.hyperether.goodjob.resources.employees
import com.hyperether.goodjob.resources.employees_unselect
import com.hyperether.goodjob.resources.map
import com.hyperether.goodjob.resources.map_unselect
import com.hyperether.goodjob.resources.notification
import com.hyperether.goodjob.resources.notification_unselected
import com.hyperether.goodjob.resources.user_check
import com.hyperether.goodjob.resources.user_check_unselect
import com.hyperether.goodjob.util.Constants
import org.jetbrains.compose.resources.painterResource

@Composable
fun AndroidNav(navController: NavHostController, prefsManager: PrefsManager) {
    var selectedTabIndex = remember { mutableStateOf(0) }

    val navItems = if (prefsManager.getFlow() == Constants.FLOW_ADMIN) listOf(
        NavItem(
            iconResId = painterResource(Res.drawable.dahboard),
            unselectedIconResId = painterResource(Res.drawable.dahboard_unselect),
            route = Screen.Dashboard.route
        ),
        NavItem(
            iconResId = painterResource(Res.drawable.employees),
            unselectedIconResId = painterResource(Res.drawable.employees_unselect),
            route = Screen.Employees.route
        ),
        NavItem(
            iconResId = painterResource(Res.drawable.briefcase),
            unselectedIconResId = painterResource(Res.drawable.briefcase_unselect),
            route = Screen.Jobs.route
        ),
        NavItem(
            iconResId = painterResource(Res.drawable.calendar),
            unselectedIconResId = painterResource(Res.drawable.calendar_unselect),
            route = Screen.Scheduler.route
        ),
        NavItem(
            iconResId = painterResource(Res.drawable.user_check),
            unselectedIconResId = painterResource(Res.drawable.user_check_unselect),
            route = Screen.Contacts.route
        ),
        NavItem(
            iconResId = painterResource(Res.drawable.map),
            unselectedIconResId = painterResource(Res.drawable.map_unselect),
            route = Screen.Map.route
        )
    ) else {
        listOf(
            NavItem(
                iconResId = painterResource(Res.drawable.dahboard),
                unselectedIconResId = painterResource(Res.drawable.dahboard_unselect),
                route = Screen.Dashboard.route
            ),
            NavItem(
                iconResId = painterResource(Res.drawable.briefcase),
                unselectedIconResId = painterResource(Res.drawable.briefcase_unselect),
                route = Screen.Jobs.route
            ),
            NavItem(
                iconResId = painterResource(Res.drawable.calendar),
                unselectedIconResId = painterResource(Res.drawable.calendar_unselect),
                route = Screen.Scheduler.route
            ),
            NavItem(
                iconResId = painterResource(Res.drawable.map),
                unselectedIconResId = painterResource(Res.drawable.map_unselect),
                route = Screen.Map.route
            ),
            NavItem(
                iconResId = painterResource(Res.drawable.notification),
                unselectedIconResId = painterResource(Res.drawable.notification_unselected),
                route = Screen.Notifications.route
            )
        )
    }


    Row(
        modifier = Modifier.fillMaxWidth(),
        horizontalArrangement = Arrangement.SpaceAround
    ) {
        navItems.forEachIndexed { index, item ->
            IconButton(onClick = {
                selectedTabIndex.value = index
                navController.safeNavigate(item.route)
            }) {
                Column(
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    val icon =
                        if (selectedTabIndex.value == index) item.iconResId else item.unselectedIconResId
                    Image(
                        painter = icon,
                        contentDescription = null,
                    )
                }
            }
        }
    }

    LaunchedEffect(navController) {
        navController.currentBackStackEntryFlow.collect { backStackEntry ->
            val currentRoute = backStackEntry.destination.route
            val tabIndex = navItems.indexOfFirst { it.route == currentRoute }
            if (tabIndex != -1) {
                selectedTabIndex.value = tabIndex
            }
        }
    }
}

data class NavItem(val iconResId: Painter, val unselectedIconResId: Painter, val route: String)


