package com.hyperether.goodjob.scenes.components

import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.Icon
import androidx.compose.material.IconButton
import androidx.compose.material3.OutlinedTextField
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.password_not_visible
import com.hyperether.goodjob.resources.password_visible
import com.hyperether.goodjob.theme.InputIconColor
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.painterResource

@Composable
fun PasswordInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        var passVisible by remember { mutableStateOf(false) }
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text(placeholder, fontSize = 14.sp, color = TextColor)
            },
            visualTransformation = if (passVisible) VisualTransformation.None else PasswordVisualTransformation(),
            keyboardOptions = KeyboardOptions(keyboardType = KeyboardType.Password),
            trailingIcon = {
                val image =
                    if (passVisible) Res.drawable.password_visible else Res.drawable.password_not_visible
                IconButton(onClick = { passVisible = !passVisible }) {
                    Icon(
                        painter = painterResource(image),
                        "Show/Hide password",
                        tint = InputIconColor
                    )
                }
            }
        )

    }
}

@Composable
fun PhoneInputField(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    modifier: Modifier = Modifier
) {
    Box(
        modifier = modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        OutlinedTextField(
            modifier = Modifier.fillMaxWidth(),
            value = text.value,
            onValueChange = { newValue ->
                if (newValue.isEmpty() || newValue.all { it.isDigit() } || (newValue.startsWith("+") && newValue.drop(1).all { it.isDigit() })) {
                    text.value = if (newValue.isNotEmpty()) newValue else ""
                }
            },
            label = {
                Text(label, fontSize = 14.sp, color = TextColor)
            },
            placeholder = {
                Text(placeholder, fontSize = 14.sp, color = TextColor)
            },
            keyboardOptions = KeyboardOptions.Default.copy(keyboardType = KeyboardType.Phone)
        )
    }
}