package com.hyperether.goodjob.scenes.components

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.requiredHeight
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Icon
import androidx.compose.material.MaterialTheme
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.Text
import androidx.compose.material.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshots.SnapshotStateList
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.TextStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.em
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.models.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.ic_search
import com.hyperether.goodjob.resources.search
import com.hyperether.goodjob.resources.unselect_all
import com.hyperether.goodjob.scenes.addNew.DropdownSortBy
import com.hyperether.goodjob.scenes.employees.EmployeeRow
import com.hyperether.goodjob.scenes.employees.EmployeeRowHeader
import com.hyperether.goodjob.scenes.employees.Pagination
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.BorderColor
import com.hyperether.goodjob.theme.EmployeesBorder
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.SelectedCardBg
import com.hyperether.goodjob.util.DateTimeUtil
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun <T> WebUserListView(
    isEmployeeList: Boolean,
    title: String,
    allItems: List<T>,
    filteredItems: List<T>,
    sortBy: MutableState<String?>,
    searchText: MutableState<String>,
    currentPage: MutableState<Int>,
    itemsPerPage: Int,
    showFilterDialog: MutableState<Boolean>,
    deleteItemById: (String) -> Unit,
    saveSelectedItem: (String) -> Unit,
) {
    val selectedEmployees = remember { mutableStateListOf<String>() }

    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(start = Dp(75f), end = Dp(75f), bottom = Dp(27f))
            .background(Color.White)
            .border(width = Dp(1f), color = EmployeesBorder, shape = RoundedCornerShape(Dp(4f)))
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(
                top = Dp(24f),
                end = Dp(40f),
                start = Dp(24f),
                bottom = Dp(27f)
            )
        ) {
            Row {
                Column(modifier = Modifier.height(Dp(90f)).weight(2f)) {
                    Text(
                        text = title,
                        fontSize = Sp(24f),
                        fontWeight = FontWeight.W700
                    )
                    if (selectedEmployees.isNotEmpty()) {
                        Row(
                            modifier = Modifier.padding(top = Dp(16f)),
                            horizontalArrangement = Arrangement.SpaceBetween,
                            verticalAlignment = Alignment.CenterVertically
                        ) {
                            Text(
                                text = stringResource(Res.string.unselect_all),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        selectedEmployees.clear()
                                    }
                            )
                            Text(
                                text = stringResource(Res.string.edit),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        // Handle edit logic
                                    }
                            )
                            Text(
                                text = stringResource(Res.string.export_list),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        // Handle export logic
                                    }
                            )
                            Text(
                                text = stringResource(Res.string.delete),
                                fontSize = Sp(14f),
                                fontWeight = FontWeight.W400,
                                color = Color.Red,
                                modifier = Modifier
                                    .padding(end = Dp(24f))
                                    .clickable {
                                        for (id in selectedEmployees) {
                                            deleteItemById(id)
                                        }
                                    }
                            )
                        }
                    } else {
                        Spacer(modifier = Modifier.height(Dp(32f)))
                    }
                }

                OutlinedTextField(
                    textStyle = TextStyle(fontSize = Sp(12f)),
                    value = searchText.value,
                    onValueChange = { searchText.value = it },
                    placeholder = {
                        Text(
                            text = stringResource(Res.string.search),
                            fontSize = Sp(12f),
                            fontWeight = FontWeight.W400
                        )
                    },
                    modifier = Modifier
                        .width(Dp(226f))
                        .height(Dp(53f))
                        .border(
                            width = Dp(1f),
                            color = BorderColor,
                            shape = RoundedCornerShape(Dp(8f))
                        ),
                    singleLine = true,
                    leadingIcon = {
                        Image(
                            modifier = Modifier
                                .height(Dp(24f))
                                .width(Dp(24f)),
                            painter = painterResource(Res.drawable.ic_search),
                            contentDescription = "Search"
                        )
                    },
                    colors = TextFieldDefaults.textFieldColors(
                        backgroundColor = Color.White,
                        focusedIndicatorColor = Color.Transparent,
                        unfocusedIndicatorColor = Color.Transparent
                    )
                )
                Spacer(modifier = Modifier.width(Dp(16f)))

                Row(
                    modifier = Modifier
                        .height(Dp(55f)).width(Dp(100f))
                        .border(
                            width = Dp(1f),
                            color = BorderColor,
                            shape = RoundedCornerShape(Dp(8f))
                        )
                        .clickable { showFilterDialog.value = true },
                    horizontalArrangement = Arrangement.Center,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Text(
                        text = stringResource(Res.string.filter),
                        fontSize = Sp(14f),
                        fontWeight = FontWeight.W400,
                    )
                    Image(
                        painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                        contentDescription = "Filter",
                        modifier = Modifier.width(Dp(24f)).height(Dp(24f))
                    )
                }
                Spacer(modifier = Modifier.width(Dp(16f)))

                // TODO: get options
                DropdownSortBy(
                    shouldAppendSortBy = true,
                    items = listOf("Newest", "Oldest"),
                    selectedValue = sortBy.value ?: "Newest",
                    onValueSelected = { sortBy.value = it },
                    displayText = { it },
                    placeholderColor = BorderColor,
                    textColor = Color.Black,
                    modifier = Modifier.height(Dp(55f))
                )
            }
        }

        if (isEmployeeList) {
            GenericListScreen(
                allItems = allItems,
                items = filteredItems,
                currentPage = currentPage,
                itemsPerPage = itemsPerPage,
                selectedItems = selectedEmployees,
                saveSelectedItem = { itemId -> saveSelectedItem(itemId) },
                getItemId = { (it as? User)?._id },
                ItemRowHeader = { allSelected, onSelectAllChange ->
                    EmployeeRowHeader(
                        allSelected = allSelected,
                        onSelectAllChange = onSelectAllChange
                    )
                },
                ItemRow = { item, isSelected, onSelectionChange, modifier ->
                    EmployeeRow(
                        employee = item as User,
                        isSelected = isSelected,
                        onSelectionChange = onSelectionChange,
                        modifier = modifier
                    )
                },
                PaginationControls = { allItems, currentPage, itemsPerPage, onPageChange ->
                    Pagination(
                        list = allItems,
                        currentPage = currentPage,
                        itemsPerPage = itemsPerPage,
                        onPageChange = onPageChange
                    )
                }
            )
        } else {
            // TODO: FOR CONTACTS
            GenericListScreen(
                allItems = allItems,
                items = filteredItems,
                currentPage = currentPage,
                itemsPerPage = itemsPerPage,
                selectedItems = selectedEmployees,
                saveSelectedItem = { itemId -> saveSelectedItem(itemId) },
                getItemId = { (it as? Contact)?.id },
                ItemRowHeader = { allSelected, onSelectAllChange ->
                    EmployeeRowHeader(
                        allSelected = allSelected,
                        onSelectAllChange = onSelectAllChange
                    )
                },
                ItemRow = { item, isSelected, onSelectionChange, modifier ->
//                    EmployeeRow(
//                        employee = item as Contact,
//                        isSelected = isSelected,
//                        onSelectionChange = onSelectionChange,
//                        modifier = modifier
//                    )
                },
                PaginationControls = { allItems, currentPage, itemsPerPage, onPageChange ->
                    Pagination(
                        list = allItems,
                        currentPage = currentPage,
                        itemsPerPage = itemsPerPage,
                        onPageChange = onPageChange
                    )
                }
            )
        }
    }
}

@Composable
fun <T> GenericListScreen(
    allItems: List<T>,
    items: List<T>,
    currentPage: MutableState<Int>,
    itemsPerPage: Int,
    selectedItems: SnapshotStateList<String>,
    saveSelectedItem: (String) -> Unit,
    getItemId: (T) -> String?,
    ItemRowHeader: @Composable (Boolean, (Boolean) -> Unit) -> Unit,
    ItemRow: @Composable (T, Boolean, (Boolean) -> Unit, Modifier) -> Unit,
    PaginationControls: @Composable (List<T>, Int, Int, (Int) -> Unit) -> Unit
) {
    val allSelected = items.all { getItemId(it)?.let { id -> selectedItems.contains(id) } == true }

    Column(modifier = Modifier.fillMaxSize()) {
        // Item list with LazyColumn
        LazyColumn(
            modifier = Modifier.weight(1f)
        ) {
            // Add the header as the first item
            item {
                ItemRowHeader(
                    allSelected
                ) { selectAll ->
                    if (selectAll) {
                        selectedItems.addAll(items.mapNotNull { getItemId(it) })
                    } else {
                        selectedItems.removeAll(items.mapNotNull { getItemId(it) })
                    }
                }
            }

            // Add item rows
            items(items) { item ->
                val itemId = getItemId(item)
                if (itemId != null) {
                    ItemRow(
                        item,
                        selectedItems.contains(itemId),
                        { isSelected ->
                            if (isSelected) {
                                selectedItems.add(itemId)
                            } else {
                                selectedItems.remove(itemId)
                            }
                        },
                        Modifier.clickable {
                            saveSelectedItem(itemId)
                        }
                    )
                }
            }
        }

        // Pagination controls
        PaginationControls(
            allItems,
            currentPage.value,
            itemsPerPage
        ) { page -> currentPage.value = page }
    }
}

@Composable
fun DetailsCards(
    titles: List<String>,
    modifier: Modifier,
    selectedCard: MutableState<String>
) {
    Column(
        modifier = modifier
            .padding(top = Dp(10f))
    ) {
        for (title in titles) {
            Text(
                text = title,
                fontSize = Sp(16f),
                fontWeight = if (selectedCard.value == title) FontWeight.W700 else FontWeight.W400,
                color = if (selectedCard.value == title) BlueApp else Color.Gray,
                modifier = modifier
                    .background(if (selectedCard.value == title) SelectedCardBg else Color.White)
                    .clickable { selectedCard.value = title }
                    .drawBehind {
                        if (selectedCard.value == title) {
                            drawLine(
                                color = BlueApp,
                                start = Offset(0f, 0f),
                                end = Offset(0f, size.height),
                                strokeWidth = 2f
                            )
                        }
                    }
                    .padding(Dp(14f))
            )
        }
    }
}

@Composable
fun EditToggleButton(
    isEditView: MutableState<Boolean>
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.clickable {
            isEditView.value = !isEditView.value
        }
    ) {
        if (!isEditView.value) {
            Icon(
                painter = painterResource(Res.drawable.edit),
                contentDescription = stringResource(Res.string.edit),
                tint = Color(0xFF515151),
                modifier = Modifier.size(Dp(24f))
            )
            Spacer(modifier = Modifier.width(Dp(4f)))
        }
        Text(
            text = if (isEditView.value) stringResource(Res.string.cancel) else stringResource(
                Res.string.edit
            ),
            color = if (isEditView.value) Color.Red else Color(0xFF515151),
            fontSize = Sp(18f)
        )
    }
}

@Composable
fun JobHeader(createdAt: String) {
    Box(
        modifier = Modifier
            .fillMaxWidth()
            .requiredHeight(Dp(42f))
            .background(color = Color.White)
            .drawBehind {
                val strokeWidth = Dp(1f).toPx()
                drawLine(
                    color = RegisterBorder,
                    start = Offset(0f, size.height - strokeWidth / 2),
                    end = Offset(size.width, size.height - strokeWidth / 2),
                    strokeWidth = strokeWidth
                )
            }
            .padding(horizontal = Dp(23f), vertical = Dp(10f))
    ) {
        Text(
            text = createdAt,
            color = Color(0xff323232),
            lineHeight = 1.71.em,
            style = TextStyle(
                fontSize = Sp(14f),
                fontWeight = FontWeight.Bold
            ),
            modifier = Modifier
                .align(alignment = Alignment.TopStart)
                .wrapContentHeight(align = Alignment.Bottom)
                .fillMaxWidth()
        )
    }
    Spacer(modifier = Modifier.height(Dp(8f)))
}

@Composable
fun JobListGroupedByDate(jobs: List<Job>) {
    // Group jobs by their date (ignoring the time)
    val groupedJobs = jobs.groupBy { job ->
        job.createdAt?.let { DateTimeUtil.extractDate(it) } ?: "Unknown Date"
    }

    Column(modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState())) {
        groupedJobs.forEach { (date, jobsForDate) ->
            val formattedDate = if (date is LocalDate) {
                DateTimeUtil.formatDateForDisplay(date)
            } else {
                date.toString()
            }

            // Display the header for this date
            JobHeader(createdAt = formattedDate)

            // Display all jobs under the same date header
            jobsForDate.forEachIndexed { index, rowItem ->
                JobListRow(rowItem = rowItem, isEvenRow = index % 2 == 0)
            }

            // Add spacing between groups
            Spacer(modifier = Modifier.height(Dp(16f)))
        }
    }
}


@Composable
fun JobListRow(
    rowItem: Job,
    isEvenRow: Boolean // Use to alternate row colors
) {
    val backgroundColor = if (isEvenRow) Color(0xFFF9F9F9) else Color.White
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dp(48f))
            .padding(horizontal = Dp(22f))
            .background(backgroundColor)
            .clip(MaterialTheme.shapes.medium),
        verticalArrangement = Arrangement.Center
    ) {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier.padding(horizontal = Dp(24f))
        ) {
            // Row Details
            Text(
                text = rowItem.jobTitle ?: " ",
                color = Color(0xFF34618E),
                fontSize = Sp(16f),
                fontWeight = FontWeight.Bold,
                modifier = Modifier
                    .weight(0.9f)
            )
            Text(
                text = rowItem.contactSiteName ?: " ",
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.9f)
            )
            Text(
                text = rowItem.contactSiteName ?: " ", // TODO what is location?
                color = Color(0xFF555555),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(1f)
            )
            Text(
                text = rowItem.start_at?.let { start ->
                    rowItem.end_at?.let { end ->
                        "$start - $end"
                    }
                } ?: "",
                color = Color(0xFF555555),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.7f)
            )
            Text(
                text = rowItem.employees
                    .mapNotNull { it?.fullName }
                    .joinToString(", "),
                color = Color(0xFF323232),
                fontSize = Sp(14f),
                modifier = Modifier
                    .weight(0.7f)
            )
            Column(
                modifier = Modifier
                    .weight(0.5f)
            ) {
                Box(
                    modifier = Modifier
                        .size(Dp(16f))
                        .clip(CircleShape)
                        .align(Alignment.CenterHorizontally)
                        .background(getStatusColor(rowItem.status ?: "On hold"))
                )
            }
        }
    }
}

fun getStatusColor(status: String): Color {
    return when (status) {
        "Pending" -> Color(0xFF8B4513) // Brown
        "Scheduled" -> Color(0xFFFFA500) // Orange
        "In progress" -> Color(0xFFD32F7E) // Magenta/Pink
        "Completed" -> Color(0xFF4CAF50) // Green
        "On hold" -> Color(0xFF9E9E9E) // Gray
        "Cancelled" -> Color(0xFFE53935) // Red
        else -> Color.LightGray // Default color
    }
}