package com.hyperether.goodjob.scenes.contacts.site

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_new_site
import com.hyperether.goodjob.resources.add_site
import com.hyperether.goodjob.resources.address
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.contact_name
import com.hyperether.goodjob.resources.email_address
import com.hyperether.goodjob.resources.enter_contact_name
import com.hyperether.goodjob.resources.enter_email_address
import com.hyperether.goodjob.resources.enter_site_name
import com.hyperether.goodjob.resources.enter_street
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.site_added
import com.hyperether.goodjob.resources.site_name
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PhoneInputField
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.contacts.ContactsViewModel
import com.hyperether.goodjob.scenes.register.AppInputField
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewSite(
    contactId: String,
    viewModel: ContactsViewModel,
    navHostController: NavHostController
) {
    val contacts by viewModel.contacts.collectAsState(emptyList())
    val contact = contacts.find { it._id == contactId }

    Column {
        Row(
            verticalAlignment = Alignment.CenterVertically,
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 20.dp)
        ) {
            Image(
                painter = painterResource(Res.drawable.back_arrow),
                contentDescription = "",
                modifier = Modifier
                    .clickable {
                        navHostController.popBackStack()
                    }
            )

            Text(
                text = stringResource(Res.string.add_new_site),
                fontSize = 18.sp,
                color = Color.Black,
                textAlign = TextAlign.Center,
                modifier = Modifier
                    .weight(1f)
                //.padding(start = 16.dp)
            )
        }

        Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))

        Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
            AppInputField(
                viewModel.siteNameInput,
                stringResource(Res.string.site_name),
                placeholder = stringResource(Res.string.enter_site_name),
            )

            AppInputField(
                viewModel.siteContactNameInput,
                stringResource(Res.string.contact_name),
                placeholder = stringResource(Res.string.enter_contact_name),
            )

            AppInputField(
                viewModel.siteEmailInput,
                stringResource(Res.string.email_address),
                placeholder = stringResource(Res.string.enter_email_address)
            )

            AppInputField(
                viewModel.siteAddressInput,
                stringResource(Res.string.address),
                placeholder = stringResource(Res.string.enter_street),
            )

            PhoneInputField(
                viewModel.sitePhoneInput,
                stringResource(Res.string.phone),
                placeholder = "+1 345 2525 252",
            )

            Spacer(modifier = Modifier.height(30.dp))
            val site = Site(
                siteName = viewModel.siteNameInput.value,
                parentName = contact?.name,
                parentId = contactId,
                personAddress = viewModel.siteAddressInput.value,
                personName = viewModel.siteContactNameInput.value,
                personPhone = viewModel.sitePhoneInput.value,
                personEmail = viewModel.siteEmailInput.value
            )
            FilledButton(
                onClick = {
                    viewModel.showLoader.value = true
                    viewModel.addSite(site,
                        onSuccess = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpSuccess.value = true
                        },
                        onError = {
                            viewModel.showLoader.value = false
                            viewModel.showPopUpError.value = true

                        })
                },
                text = stringResource(Res.string.add_site),
                isEnabled = true
            )

            Spacer(modifier = Modifier.height(15.dp))

            androidx.compose.material.OutlinedButton(
                onClick = {
                    navHostController.popBackStack()
                },
                modifier = Modifier.fillMaxWidth().height(50.dp),
                border = BorderStroke(1.dp, BlueApp),
                shape = RoundedCornerShape(50),
                colors = androidx.compose.material.ButtonDefaults.outlinedButtonColors(
                    contentColor = Color.White,
                    backgroundColor = Color.Transparent
                ),
            ) {
                Text(
                    text = stringResource(Res.string.cancel),
                    maxLines = 1,
                    fontSize = 12.5.sp,
                    color = BlueApp,
                    letterSpacing = (-0.02).sp
                )
            }
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.site_added),
                    positiveBtnText = stringResource(Res.string.ok).uppercase(),
                    onPositiveBtnClicked = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    }
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
        }
    }
}