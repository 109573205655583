package com.hyperether.goodjob.theme

import androidx.compose.ui.graphics.Color

val BlueApp = Color(0xFF34618E)
val LightBlue = Color(0xFFC9E2F8)
val LightGrey = Color(0xFFD8DADC)
val DarkGrey = Color(0xFF878787 )
val TextColor = Color(0xFF313030 )
val Green = Color(0xFF45AE2F )

val SplashBg = Color(0xFF10283F)

val LandingPageBg = Color(0xFF1D425E)
val LandingPageNavColor = Color(0xFF18364D)
val LandingPageSmallTitle = Color(0xFFC8D7E6)
val LandingSmallTitleMobile = Color(0xFFCDE3F8)
val LandingPageMainTitle = Color(0xFFFFFFFF)
val LandingPageText = Color(0xFFF4F4F4)
val LandingPageMobileText = Color(0xFFEBEBEB)
val LandingPageTextField = Color(0xFF181818)

val PricingBoxBg = Color(0xFF102433)
val PopularPricingBoxBg = Color(0xFFFFFFFF)
val PricingText = Color(0xFFFFFFFF)
val PopularPricingText = Color(0xFF303030)
val PricingBtnColor = Color(0xFF273947)

val SelectedIconTint = Color(0xFFFFFFFF)
val UnselectedIconTint = Color(0xFFD9D9D9)

val DashboardBg = Color(0xFFFCFCFC)
val BorderColor = Color(0xFFDDDDDD)

val AddEmployeeInputColor = Color(0xFFC9C6C5)
val EmployeeDetailsText = Color(0xFF605E5E)
val ContactDetailsText = Color(0xFF313030)
val EmployeesBorder = Color(0xFFEDEDED)
val HeaderText = Color(0xFF797676)
val Divider = Color(0xFFEEEEEE)
val GreenActive = Color(0xFF008767)
val GreenActiveBorder = Color(0xFF00B087)
val RedInactive = Color(0xFFDF0404)
val FooterText = Color(0xFFB5B7C0)

val UnselectedPage = Color(0xFFF5F5F5)
val FilterOption = Color(0xFF46474A)
val SelectedCardBg = Color(0xFFF5FAFF)
val InputIconColor = Color(0xFF777676)
val InputBorderColor = Color(0xFFAEAEAE)

val ToggleBg = Color(0xFFF2F2F2)
val CardBg = Color(0xFF102434)
val RegisterSubTitle = Color(0xFF676767)
val RegisterBorder = Color(0xFFE5E2E1)

val StatusColorActive = Color(0xFF44AA30)

val GrayBackground = Color(0xFFE3E3E3)
val GrayBackgroundDivider = Color(0xFFF8F8F80)