package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontStyle
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.database.Job
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.cancel
import com.hyperether.goodjob.resources.clock_small
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employees_job_item
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.jobs
import com.hyperether.goodjob.resources.map_job_item
import com.hyperether.goodjob.resources.total
import com.hyperether.goodjob.resources.user_check_job_item
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.dashboard.JobsWeb
import com.hyperether.goodjob.scenes.employees.RoundedCornerCheckbox
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobsScreen(
    isMobileView: Boolean,
    viewModel: JobViewModel,
    navHostController: NavHostController,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {

    if (isMobileView) {
        Box(modifier = Modifier.fillMaxSize()) {

            Column {
                MobileHeaderSearchAdd(
                    title = stringResource(Res.string.jobs),
                    add = { navHostController.navigate(Screen.AboutContact.route) },
                    search = {},
                    drawerState = drawerState,
                    scope = rememberCoroutineScope(),
                    flow = prefsManager.getFlow()
                )
                Row(
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(horizontal = 16.dp, vertical = 10.dp)
                ) {
                    if (!viewModel.isInEditMode.value) {
                        Text(
                            text = stringResource(Res.string.edit),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier
                                .clickable {
                                    viewModel.isInEditMode.value = true
                                    viewModel.showEditPopUp.value = true
                                }
                                .weight(1f),
                            textAlign = TextAlign.Start
                        )
                    } else {
                        Text(
                            text = stringResource(Res.string.cancel),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier
                                .clickable {
                                    viewModel.isInEditMode.value = false
                                    viewModel.showEditPopUp.value = false
                                    viewModel.selectedJobs.clear()
                                }
                                .weight(1f),
                            textAlign = TextAlign.Start
                        )
                    }

                    if (!viewModel.isInEditMode.value) {
                        Text(
                            text = "${stringResource(Res.string.total)}: ${viewModel.jobList.size}",
                            fontSize = 14.sp,
                            color = Color.Black,
                            maxLines = 1,
                            fontStyle = FontStyle.Italic,
                            textAlign = TextAlign.Center
                        )
                    }

                    Row(
                        horizontalArrangement = Arrangement.End,
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.weight(1f)
                    ) {
                        Text(
                            text = stringResource(Res.string.filter),
                            fontSize = 14.sp,
                            color = TextColor,
                            modifier = Modifier.padding(end = 8.dp),
                            textAlign = TextAlign.End
                        )
                        Icon(
                            painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                            contentDescription = null,
                            modifier = Modifier.size(20.dp)
                        )
                    }
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )

                JobsList(
                    viewModel.contacts,
                    viewModel.jobList,
                    viewModel.selectedJobs,
                    isInEditMode = viewModel.isInEditMode.value,
                    navHostController
                )
            }
            if (viewModel.showEditPopUp.value) {
                Box(
                    modifier = Modifier
                        .fillMaxSize()
                        .background(Color.Black.copy(alpha = 0.05f))
                )

                Box(
                    modifier = Modifier
                        .align(Alignment.BottomCenter)
                        .fillMaxWidth()
                ) {
                    EditJobsDialog(
                        showDialog = viewModel.showEditPopUp.value,
                        jobs = viewModel.selectedJobs,
                        onDelete = {
                            viewModel.showLoader.value = true
                            viewModel.deleteSelectedJobs(
                                onAllDeleted = {
                                    viewModel.showLoader.value = false
                                    viewModel.showPopUpSuccess.value = true
                                },
                                onError = {
                                    viewModel.showLoader.value = false
                                    viewModel.showPopUpError.value = true
                                })
                        },
                        onEdit = {},
                        onExportList = {},
                        onImportList = {}
                    )
                }
            }
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = { viewModel.showPopUpSuccess.value = false },
                    title = "SUCCESS",
                    message = "Job deleted successfully",
                    onPositiveBtnClicked = { viewModel.showPopUpSuccess.value = false },
                    positiveBtnText = "OK"
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = "ERROR",
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
        }
    } else {
        JobsWeb(
            navController = navHostController,
            jobViewModel = viewModel
        )
    }
}

@Composable
fun JobsList(
    contacts: Map<String, Contact>,
    jobs: List<Job>,
    selectedJobs: MutableList<Job>,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {

    LazyColumn(
        modifier = Modifier.fillMaxSize().padding(start = 8.dp, end = 16.dp)
    ) {
        items(jobs) { job ->
            JobsItem(
                contacts,
                job = job,
                isInEditMode = isInEditMode,
                selectedJobs,
                onJobSelected = { selectedJob, isSelected ->
                    if (isSelected) {
                        selectedJobs.add(selectedJob)
                    } else {
                        selectedJobs.remove(selectedJob)
                    }
                },
                onItemClick = {
                    navHostController.navigate(Screen.JobDetails.route + "/${job._id}")
                },
            )
        }
    }
}

@Composable
fun JobsItem(
    contacts: Map<String, Contact>,
    job: Job,
    isInEditMode: Boolean,
    selectedJobs: MutableList<Job>,
    onJobSelected: (Job, Boolean) -> Unit,
    onItemClick: () -> Unit
) {

    Column(modifier = Modifier.fillMaxWidth().padding(top = 15.dp).clickable { onItemClick() }) {
        job.startDate?.let {
            Text(
                text = DateTimeUtil.jobListDateFormatter(it),
                color = Color.Gray,
                fontSize = 12.sp,
                fontWeight = FontWeight.Bold,
                modifier = Modifier.padding(bottom = 8.dp, start = 8.dp, top = 10.dp)
            )
        }
        Row {
            if (isInEditMode) {
                RoundedCornerCheckbox(
                    isChecked = selectedJobs.contains(job),
                    onCheckedChange = { checked ->
                        onJobSelected(job, checked)
                    }
                )
            }
            Spacer(modifier = Modifier.width(10.dp))

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .border(1.dp, Color.LightGray, RoundedCornerShape(8.dp))
            ) {
                Column(modifier = Modifier.padding(12.dp)) {
                    Row(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalArrangement = Arrangement.SpaceBetween,
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        job.jobTitle?.let {
                            Text(
                                text = it,
                                color = Color.Black,
                                fontWeight = FontWeight.Bold,
                                fontSize = 18.sp,
                                modifier = Modifier.padding(bottom = 8.dp)
                            )
                        }
                        Box(
                            modifier = Modifier
                                .size(16.dp)
                                .background(color = job.status?.let {
                                    StatusColor().getStatusColor(
                                        it
                                    )
                                } ?: Green, shape = CircleShape)
                        )
                    }

                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.user_check_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.contactId?.let { contactId ->
                            contacts[contactId]?.let {
                                Text(
                                    text = it.name ?: it.personName ?: "",
                                    color = BlueApp,
                                )
                            }
                        } ?: run {
                            job.contactParentId?.let { contactId ->
                                contacts[contactId]?.let {
                                    Text(
                                        text = it.name ?: it.personName ?: "",
                                        color = BlueApp,
                                    )
                                }
                            }
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.map_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.contactId?.let {
                            Text(
                                text = contacts[job.contactParentId]?.address ?: "",
                                color = BlueApp,
                            )
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.clock_small),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        job.start_at?.let { start ->
                            job.end_at?.let { end ->
                                Text(
                                    text = "$start - $end",
                                    color = Color.Black,
                                    fontWeight = FontWeight.Bold
                                )
                            }
                        }
                    }
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier.padding(bottom = 4.dp)
                    ) {
                        Image(
                            painter = painterResource(Res.drawable.employees_job_item),
                            contentDescription = null,
                        )
                        Spacer(modifier = Modifier.width(8.dp))
                        val list = job.employees?.let { Mapper().jsonToListEmployees(it) }
                        list?.get(0)?.fullName.let {
                            if (it != null) {
                                Text(
                                    text = it,
                                    color = BlueApp,
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}