package com.hyperether.goodjob.repository.remote.model

import com.hyperether.goodjob.models.AssociatedObject
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class EphemeralKey(
    @SerialName("id") var id: String,
    @SerialName("object") var `object`: String,
    @SerialName("associated_objects") var associated_objects: List<AssociatedObject>,
    @SerialName("created") var created: Long,
    @SerialName("expires") var expires: Long,
    @SerialName("livemode") var livemode: Boolean,
    @SerialName("secret") var secret: String
)