package com.hyperether.goodjob.repository

import androidx.compose.ui.graphics.painter.BitmapPainter
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Employee
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.models.Site
import com.hyperether.goodjob.models.Skills
import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.repository.remote.GoodJobApiImpl
import com.hyperether.goodjob.repository.remote.model.AddCardRequest
import com.hyperether.goodjob.repository.remote.model.AddCardResponse
import com.hyperether.goodjob.repository.remote.model.AddPlanRequest
import com.hyperether.goodjob.repository.remote.model.AddPlanResponse
import com.hyperether.goodjob.repository.remote.model.ChoosePlanRequest
import com.hyperether.goodjob.repository.remote.model.ChoosePlanResponse
import com.hyperether.goodjob.repository.remote.model.ConfirmationResponse
import com.hyperether.goodjob.repository.remote.model.EmployeeRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyRequest
import com.hyperether.goodjob.repository.remote.model.EphemeralKeyResponse
import com.hyperether.goodjob.repository.remote.model.FileResult
import com.hyperether.goodjob.repository.remote.model.FinishJobRequest
import com.hyperether.goodjob.repository.remote.model.FinishJobResponse
import com.hyperether.goodjob.repository.remote.model.ForgotPasswordRequest
import com.hyperether.goodjob.repository.remote.model.ForgotPasswordResponse
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.repository.remote.model.LoginResponse
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.repository.remote.model.ResetPasswordRequest
import com.hyperether.goodjob.repository.remote.model.SetPasswordRequest
import com.hyperether.goodjob.repository.remote.model.SetPasswordResponse
import com.hyperether.goodjob.repository.remote.model.SiteRequest
import com.hyperether.goodjob.repository.remote.model.UploadFileResponse
import com.hyperether.goodjob.util.FileUtil
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.catch
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOf
import org.lighthousegames.logging.logging

class WebRepositoryImpl(
    private val prefs: PrefsManager,
    private val goodJobApiImpl: GoodJobApiImpl
) : Repository {
    val log = logging("WebRepositoryImpl")

    override fun getFlow(): String {
        return prefs.getFlow()
    }

    //TODO Use for web, dont use database here, we will show data only from API

    override suspend fun getTestData(): String {
        //   TODO("Not yet implemented")
        return ""
    }

    override suspend fun deleteAllWorkspaces() {
        //  TODO("Not yet implemented")
    }

    override suspend fun insertWorkspace(workspace: Workspace) {

    }

    override suspend fun getWorkspacesFromLocal(): Flow<List<Workspace>> {
        return flow {
            val resource = getAllWorkspaces()
            if (resource is Resource.Success && resource.data != null) {
                emit(resource.data)
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            emit(emptyList())
        }
    }

    override suspend fun getWorkspaceById(id: String): Workspace? {
        var workspace: Workspace? = null
        getWorkspacesFromLocal().collect { workspaceList ->
            workspace = workspaceList.find { it._id == id }
        }
        return workspace
    }

    override suspend fun deleteWorkspaceById(id: Long) {
        // TODO("Not yet implemented")
    }

    override suspend fun addWorkspace(name: String, workspaceType: String): Resource<Workspace> {
        return goodJobApiImpl.addWorkspace(name, workspaceType, getToken())
    }

    override suspend fun getAllWorkspaces(): Resource<List<Workspace>> {
        return goodJobApiImpl.getAllWorkspaces(getToken())
    }

    override suspend fun getAllPlans(): Resource<List<Plan>> {
        return goodJobApiImpl.getAllPlans(getToken())
    }

    override suspend fun addPlan(addPlanRequest: AddPlanRequest): Resource<AddPlanResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.addPlan(getToken(), addPlanRequest)) {
                is Resource.Success -> {
                    val data = apiResponse.data
                    if (data != null) {
                        log.d("addPlan") { "addPlan SUCCESS ${data.user.planId}" }
                        val currentUser = prefs.getUser()
                        currentUser?.planId = data.user.planId
                        currentUser?.let { saveUser(it) }
                        Resource.Success(data)
                    } else {
                        log.d("addPlan") { "addPlan ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "addPlan An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "addPlan Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

//    override suspend fun addPlan(planId: String): Resource<AddPlanResponse> {
//        return try {
//            when (val apiResponse = goodJobApiImpl.addPlan(getToken(), planId)) {
//                is Resource.Success -> {
//                    val data = apiResponse.data
//
//                    if (data != null) {
//                        log.d("addPlan") { "addPlan SUCCESS ${data.user.planId}" }
//                        val currentUser = prefs.getUser()
//                        currentUser?.planId = data.user.planId
//                        currentUser?.let { saveUser(it) }
//                        Resource.Success(data)
//                    } else {
//                        log.d("addPlan") { "addPlan ERROR No data received" }
//                        Resource.Error("No data received")
//                    }
//                }
//
//                is Resource.Error -> {
//                    log.e { "addPlan An error occurred" }
//                    Resource.Error(apiResponse.text?.toString() ?: "An error occurred")
//                }
//            }
//        } catch (e: Exception) {
//            log.e { "addPlan Unexpected error occurred: ${e.printStackTrace()}" }
//            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
//        }
//    }

    override suspend fun choosePlan(choosePlanRequest: ChoosePlanRequest): Resource<ChoosePlanResponse> {
        return goodJobApiImpl.choosePlan(getToken(), choosePlanRequest)
    }

    override suspend fun getPlan(id: String): Resource<Plan> {
        return goodJobApiImpl.getPlanById(getToken(), id)
    }

    override suspend fun confirmation(hash: String): Resource<ConfirmationResponse> {
        val result = goodJobApiImpl.confirmation(hash)
        if (result is Resource.Success) {
            result.data?.let {
                saveToken(it.token ?: "")
                saveRefreshToken(it.refreshToken ?: "")
                val user = Mapper().confirmationResponseToUser(it)
                saveUser(user)
//                if (user.planId?.isEmpty() == true && getSavedPlanId().isNotEmpty()) {
//                    addPlan(AddPlanRequest(getSavedPlanId(), ""))
//                }
            }
        }
        return result
    }

    override fun saveUser(user: User) {
        prefs.saveUser(user)
    }

    override fun getUser(): User? {
        return prefs.getUser()
    }

    override suspend fun logoutUser() {
        prefs.saveEmail("")
        prefs.saveToken("")
        prefs.saveRefreshToken("")
        prefs.saveUser(null)
        prefs.savePlanIdLocally("")
        prefs.saveWorkspaceId("")
        prefs.removeAll()
    }

    override suspend fun forgotPassword(forgotPasswordRequest: ForgotPasswordRequest): Resource<ForgotPasswordResponse> {
        TODO("Not yet implemented")
    }

    override suspend fun resetPassword(resetPasswordRequest: ResetPasswordRequest): Resource<ForgotPasswordResponse> {
        TODO("Not yet implemented")
    }

    override suspend fun setPassword(setPasswordRequest: SetPasswordRequest): Resource<SetPasswordResponse> {
        TODO("Not yet implemented")
    }

    override suspend fun getEphemeralKey(ephemeralKeyRequest: EphemeralKeyRequest): Resource<EphemeralKeyResponse> {
        return goodJobApiImpl.getEphemeralKey(
            getToken(),
            EphemeralKeyRequest(getUser()?.customerId)
        )
    }

    override suspend fun addCard(addCardRequest: AddCardRequest): Resource<AddCardResponse> {
        return goodJobApiImpl.addCard(getToken(), addCardRequest)
    }

    override fun savePlanIdLocally(planId: String) {
        return prefs.savePlanIdLocally(planId)
    }

    override fun getSavedPlanId(): String {
        return prefs.getSavedPlanId()
    }

    override suspend fun insertPlan(plan: Plan) {

    }

    override fun getPlans(): Flow<List<Plan>> {
        return flow {
            val resource = getAllPlans()
            if (resource is Resource.Success && resource.data != null) {
                emit(resource.data)
            } else {
                emit(emptyList())
            }
        }.catch { exception ->
            emit(emptyList())
        }
    }

    override suspend fun getPlanById(id: String): Plan? {
        return when (val result = getPlan(id)) {
            is Resource.Success -> result.data
            is Resource.Error -> {
                log.d("getPlanById") { "Error fetching plan: ${result.text}" }
                null
            }
        }
    }

    override suspend fun deletePlanById(id: String) {
        TODO("Not yet implemented")
    }

    override suspend fun insertUser(user: User) {

    }

    override fun getUsers(): Flow<List<com.hyperether.goodjob.database.User>> {
        // TODO
        return flowOf(emptyList())
    }

    override suspend fun deleteUsersByIds(ids: List<Long>) {
        TODO("Not yet implemented")
    }

    override suspend fun deleteEmployee(employeeId: String): Resource<String> {
        return goodJobApiImpl.deleteEmployee(getToken(), employeeId)
    }

    override suspend fun getEmployeeById(userId: String): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.getEmployeeById(getToken(), userId)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("getEmployeeById") { "getEmployeeById SUCCESS $user" }
                    Resource.Success(user)
                }

                is Resource.Error -> {
                    log.d("getEmployeeById") { "getEmployeeById ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("getEmployeeById") { "getEmployeeById EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun addEmployee(employeeRequest: EmployeeRequest): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.addEmployee(getToken(), employeeRequest)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("addEmployee") { "addEmployee SUCCESS $user" }
                    user?.let { insertUser(it) }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    log.d("addEmployee") { "addEmployee ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("addEmployee") { "addEmployee EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun updateEmployee(
        userId: String,
        employeeRequest: EmployeeRequest
    ): Resource<User> {
        return try {
            when (val apiResponse =
                goodJobApiImpl.updateEmployeeById(getToken(), userId, employeeRequest)) {
                is Resource.Success -> {
                    val user = apiResponse.data
                    log.d("addEmployee") { "addEmployee SUCCESS $user" }
                    user?.let { insertUser(it) }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    log.d("addEmployee") { "addEmployee ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("addEmployee") { "addEmployee EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllEmployees(): Resource<List<User>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllEmployees(getToken())

            when (apiResponse) {
                is Resource.Success -> {
                    val employeesResponse = apiResponse.data
                    if (employeesResponse?.isNotEmpty() == true) {

                        employeesResponse.forEach { employee ->
                            try {
                                val remoteId = employee._id
                                if (remoteId.isNullOrBlank()) {
                                    log.d("Users Sync Error") { "Skipping contact with null or blank ID: ${employee.fullName}" }
                                    return@forEach
                                }
                            } catch (e: Exception) {
                                log.d("Users Sync Error") {
                                    "Failed to sync users: ${employee._id}, error: ${e.message}"
                                }
                            }
                        }
                    }
                    Resource.Success(employeesResponse)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override fun getContactsFromLocal(): Flow<List<Contact>> {
        return flowOf(emptyList())
    }

    override suspend fun getAllContacts(): Resource<List<com.hyperether.goodjob.models.Contact>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllContacts(getToken())

            when (apiResponse) {
                is Resource.Success -> {
                    val contactResponse = apiResponse.data
                    if (contactResponse?.isNotEmpty() == true) {
                        contactResponse.forEach { contact ->
                            try {
                                val remoteId = contact.id
                                if (remoteId.isNullOrBlank()) {
                                    log.d("Contact Sync Error") { "Skipping contact with null or blank ID: ${contact.name}" }
                                    return@forEach
                                }
                            } catch (e: Exception) {
                                log.d("Contact Sync Error") {
                                    "Failed to sync contact: ${contact.id}, error: ${e.message}"
                                }
                            }
                        }
                    }
                    Resource.Success(contactResponse)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun deleteContactById(id: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteContact(getToken(), id)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getContactById(id: String): Contact? {
        TODO("Not yet implemented")
    }

    override suspend fun getContactsByWorkspaceId(): Resource<List<com.hyperether.goodjob.models.Contact>> {
        TODO("Not yet implemented")
    }

    override suspend fun addSite(site: Site): Resource<Site> {
        TODO("Not yet implemented")
    }

    override suspend fun getSitesFromLocal(): List<com.hyperether.goodjob.database.Site> {
        TODO("Not yet implemented")
    }

    override suspend fun addContact(contact: com.hyperether.goodjob.models.Contact): Resource<com.hyperether.goodjob.models.Contact> {
        return try {
            val apiResponse = goodJobApiImpl.addContact(getToken(), contact)

            when (apiResponse) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun insertJob(job: Job) {

    }

    override suspend fun getJobs(): Flow<List<com.hyperether.goodjob.database.Job>> {
        // TODO("Not yet implemented")
        return flowOf(emptyList())
    }

    override suspend fun getSitesUnderContact(siteRequest: SiteRequest): Resource<List<Site>> {
        // TODO("Not yet implemented")
        return Resource.Success(emptyList())

    }

    override suspend fun getJobsByParentId(id: String): List<com.hyperether.goodjob.database.Job> {
        TODO("Not yet implemented")
    }

    override suspend fun createJob(job: JobRequest): Resource<Job> {
        return try {
            when (val apiResponse = goodJobApiImpl.createJob(getToken(), job)) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    if (response != null) {
                        log.d("createJob") { "Create job SUCCESS ${response.jobTitle}" }
                    }
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    log.d("createJob") { "Create job ERROR ${apiResponse.text}" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("createJob") { "Create job  EXCEPTION ${e.message}" }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllJobsForWorkspace(): Resource<List<Job>> {
        return try {
            val apiResponse = goodJobApiImpl.getAllJobsForSpecificWorkspace(
                getToken(),
                getWorkspaceId()
            )

            when (apiResponse) {
                is Resource.Success -> {
                    val response = apiResponse.data
                    if (response?.isNotEmpty() == true) {
                        response.forEach { job ->
                            try {
                                val remoteId = job.id
                                if (remoteId.isNullOrBlank()) {
                                    log.d("Contact Sync Error") { "Skipping contact with null or blank ID: ${job.jobTitle}" }
                                    return@forEach
                                }
                            } catch (e: Exception) {
                                log.d("Contact Sync Error") {
                                    "Failed to sync contact: ${job.id}, error: ${e.message}"
                                }
                            }
                        }
                    }
                    Resource.Success(response)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun getAllJobsForUser(): Resource<List<Job>> {
        // todo implement this
        return Resource.Success(emptyList())
    }

    override suspend fun deleteJobById(id: String): Resource<String> {
        return try {
            when (val apiResponse = goodJobApiImpl.deleteJobById(getToken(), id)) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun finishJob(finishJobRequest: FinishJobRequest): Resource<FinishJobResponse> {
        return try {
            val apiResponse = goodJobApiImpl.finishJob(getToken(), finishJobRequest)

            when (apiResponse) {
                is Resource.Success -> {
                    Resource.Success(apiResponse.data)
                }

                is Resource.Error -> {
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun updateJob(jobId: String, job: Job): Resource<Job> {
        TODO("Not yet implemented")
    }

    override suspend fun insertSkill(skills: Skills) {
        TODO("Not yet implemented")
    }

    override suspend fun getSkillsFromLocal(): Flow<List<com.hyperether.goodjob.database.Skills>> {
        TODO("Not yet implemented")
    }

    override suspend fun deleteAllSkills() {
        // TODO("Not yet implemented")
    }

    override suspend fun addSkill(skill: Skills): Resource<Skills> {
        TODO("Not yet implemented")
    }

    override suspend fun getSkillsByWorkspace(): Resource<List<Skills>> {
        TODO("Not yet implemented")
    }

    //LOCAL STORAGE
    // TODO for token we should save it in cookie for web, it is safer
    override fun saveToken(token: String) {
        log.d("saveToken") { "saveToken" }
        prefs.saveToken(token)
    }

    override fun getToken() = prefs.getToken()

    override fun saveRefreshToken(refreshToken: String) {
        prefs.saveRefreshToken(refreshToken)
    }

    override fun getRefreshToken(): String {
        return prefs.getRefreshToken()
    }

    override fun saveEmail(email: String) {
        prefs.saveEmail(email)
    }

    override fun getEmail(): String {
        return prefs.getEmail()
    }

    override fun saveWorkspaceId(workspaceId: String) {
        prefs.saveWorkspaceId(workspaceId)
    }

    override fun getWorkspaceId(): String {
        return prefs.getWorkspaceId()
    }

    override suspend fun updateWorkspaceLastAccessedById(
        lastAccessed: String,
        workspaceId: String
    ): Resource<Workspace> {
        return try {
            val workspace = getWorkspaceById(workspaceId)
            workspace?.let {
                workspace.lastAccessed = lastAccessed
                val request = Mapper().toUpdateWorkspaceRequest(workspace)
                val apiResponse = goodJobApiImpl.updateWorkspace(request, workspaceId, getToken())
                when (apiResponse) {
                    is Resource.Success -> {
                        val workspaceResponse = apiResponse.data
                        Resource.Success(workspaceResponse)
                    }

                    is Resource.Error -> {
                        log.d("updateWorkspaceLastAccessedById") {
                            "Failed to update workspace: $workspaceId, error: ${apiResponse.text}"
                        }
                        Resource.Error("${apiResponse.text}")
                    }
                }
            } ?: run {
                log.d("updateWorkspaceLastAccessedById") { "Workspace with ID $workspaceId not found" }
                Resource.Error("Workspace with ID $workspaceId not found")
            }
        } catch (e: Exception) {
            log.d("updateWorkspaceLastAccessedById") {
                "An unexpected error occurred: ${e.message}"
            }
            Resource.Error(e.message ?: "An unexpected error occurred")
        }
    }

    override suspend fun register(
        name: String,
        email: String,
        password: String
    ): Resource<User> {
        return try {
            when (val apiResponse = goodJobApiImpl.register(name, email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("register") { "Register SUCCESS ${data.email}" }
                        saveUser(data)
                        Resource.Success(data)
                    } else {
                        log.d("register") { "Register ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.d("register") { "Register An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.d("register") { "Register Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun login(email: String, password: String): Resource<LoginResponse> {
        return try {
            when (val apiResponse = goodJobApiImpl.login(email, password)) {
                is Resource.Success -> {
                    val data = apiResponse.data

                    if (data != null) {
                        log.d("login") { "Login SUCCESS ${data.email}" }
                        saveToken(data.token)
                        saveRefreshToken(data.refreshToken)
                        val user = Mapper().loginResponseToUser(data)
                        saveUser(user)
                        if (user.planId?.isEmpty() == true && getSavedPlanId().isNotEmpty()) {
                            addPlan(AddPlanRequest(getSavedPlanId(), ""))
                        }
                        Resource.Success(data)
                    } else {
                        log.d("login") { "Login ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "Login ERROR An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "Login Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override suspend fun getFile(fileId: String): Resource<FileResult> {
        return try {
            when (val apiResponse = goodJobApiImpl.getFile(fileId, getToken())) {
                is Resource.Success -> {
                    val data = apiResponse.data
                    if (data != null) {
                        log.d("getFile") { "getFile SUCCESS ${data.size}" }
                        val fileType = FileUtil().getFileType(fileId)
                        // TODO: FILE MANAGER SAVE
                        Resource.Success(FileResult(data, fileType))
                    } else {
                        log.e { "getFile ERROR No data received" }
                        Resource.Error("No data received")
                    }
                }

                is Resource.Error -> {
                    log.e { "getFile ERROR An error occurred" }
                    Resource.Error(apiResponse.text)
                }
            }
        } catch (e: Exception) {
            log.e { "getFile Unexpected error occurred: ${e.printStackTrace()}" }
            Resource.Error("Unexpected error occurred: ${e.printStackTrace()}")
        }
    }

    override fun getImage(imageName: String): BitmapPainter? {
        TODO("Not yet implemented")
    }

    override fun getSavedFilePath(fileId: String): String? {
        TODO("Not yet implemented")
    }

    override suspend fun uploadFile(
        fileData: ByteArray,
        fileName: String
    ): Resource<UploadFileResponse> {
        return goodJobApiImpl.uploadFile(getToken(), fileData, fileName)
    }

    override suspend fun deleteFile(jobId: String, fileId: String): Resource<String> {
        TODO("Not yet implemented")
    }
}