package com.hyperether.goodjob.scenes.employees

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.repository.remote.model.EmployeeRequest
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.Resource
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch

class EmployeesViewModel(
    val repository: Repository,
) : ViewModel() {

    private val _employees_remote =
        MutableStateFlow<List<com.hyperether.goodjob.models.User>>(emptyList())
    val employees_remote: StateFlow<List<com.hyperether.goodjob.models.User>> = _employees_remote
    val isDetailsView: MutableState<Boolean> = mutableStateOf(true)
    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val showFilterDialog: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")


    /*filter states*/
    val managerChecked: MutableState<Boolean> = mutableStateOf(false)
    val employeeChecked: MutableState<Boolean> = mutableStateOf(false)
    val administratorChecked: MutableState<Boolean> = mutableStateOf(false)
    val activeChecked: MutableState<Boolean> = mutableStateOf(false)
    val inactiveChecked: MutableState<Boolean> = mutableStateOf(false)
    val onLeaveChecked: MutableState<Boolean> = mutableStateOf(false)
    val salesTeamChecked: MutableState<Boolean> = mutableStateOf(false)
    val supportTeamChecked: MutableState<Boolean> = mutableStateOf(false)
    val technicalTeamChecked: MutableState<Boolean> = mutableStateOf(false)
    val electricalInstallationChecked: MutableState<Boolean> = mutableStateOf(false)
    val solarPanelCheckedChecked: MutableState<Boolean> = mutableStateOf(false)
    val wiringCheckedChecked: MutableState<Boolean> = mutableStateOf(false)
    val cityCheckedValue: MutableState<Boolean> = mutableStateOf(false)


    fun getAllUsersRemote() {
        viewModelScope.launch {
            when (val result = repository.getAllEmployees()) {
                is Resource.Success -> {
                    result.data?.let { _employees_remote.value = it }
                }

                is Resource.Error -> {
                    // Handle error state if needed
                }
            }
        }
    }

    fun deleteSelectedUsers(selectedEmployees: List<com.hyperether.goodjob.database.User>, onAllDeleted: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch {
            try {
                selectedEmployees.forEachIndexed { index, job ->
                    val isLastJob = index == selectedEmployees.size - 1

                    job._id?.let { id ->
                        val result = repository.deleteEmployee(id)
                        if (result is Resource.Success && isLastJob) {
                            onAllDeleted()
                        } else if (result is Resource.Error) {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError(id)
                            return@launch
                        }
                    }
                }
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }



    val employees: Flow<List<com.hyperether.goodjob.database.User>> = repository.getUsers()

    fun addEmployee(user: EmployeeRequest, onSuccess: () -> Unit, onError: (String) -> Unit) {
        viewModelScope.launch {
            val result = repository.addEmployee(user)

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                    println("User added successfully")
                }

                is Resource.Error -> {
                    onError("Error while adding user")
                    println("Error while adding user")
                }
            }
        }
    }

    fun deleteEmployeesById(id: String) {
        viewModelScope.launch {
            val result = repository.deleteEmployee(id)
            when (result) {
                is Resource.Success -> {
                    getAllUsersRemote()
                    println("Employee deleted successfully")
                }

                is Resource.Error -> {
                    println("Error while deleting employee")
                }
            }
        }
    }

    fun clearFilterValues() {
         managerChecked.value = false
         employeeChecked.value = false
         administratorChecked.value = false
         activeChecked.value = false
         inactiveChecked.value = false
         onLeaveChecked.value = false
         salesTeamChecked.value = false
         supportTeamChecked.value = false
         technicalTeamChecked.value = false
         electricalInstallationChecked.value = false
         solarPanelCheckedChecked.value = false
         wiringCheckedChecked.value = false
         cityCheckedValue.value = false
    }
}