package com.hyperether.goodjob.scenes.employees

import androidx.compose.animation.core.tween
import androidx.compose.animation.expandHorizontally
import androidx.compose.animation.fadeOut
import androidx.compose.animation.slideInHorizontally
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.interaction.MutableInteractionSource
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.heightIn
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Card
import androidx.compose.material.Checkbox
import androidx.compose.material.CheckboxDefaults
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalDensity
import androidx.compose.ui.text.SpanStyle
import androidx.compose.ui.text.buildAnnotatedString
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.text.withStyle
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.database.User
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.baseline_person_24
import com.hyperether.goodjob.resources.city
import com.hyperether.goodjob.resources.contact_information
import com.hyperether.goodjob.resources.delete
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employment_details
import com.hyperether.goodjob.resources.export_list
import com.hyperether.goodjob.resources.filter
import com.hyperether.goodjob.resources.import_list
import com.hyperether.goodjob.resources.leave_details
import com.hyperether.goodjob.resources.phone
import com.hyperether.goodjob.resources.role
import com.hyperether.goodjob.resources.skill
import com.hyperether.goodjob.resources.skills
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.team
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun EmployeesItem(
    isChecked: Boolean,
    employee: User,
    isInEditMode: Boolean,
    onEmployeeSelected: (User, Boolean) -> Unit,
    onItemClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 16.dp, bottom = 16.dp, end = 7.dp)
            .clickable {
                onItemClick()
            }
    ) {
        if (isInEditMode) {
            RoundedCornerCheckbox(
                modifier = Modifier.padding(start = 16.dp),
                isChecked = isChecked,
                onCheckedChange = { checked ->
                    onEmployeeSelected(employee, checked)
                }
            )
        }

        Spacer(modifier = Modifier.width(15.dp))
        Image(
            painter = painterResource(Res.drawable.baseline_person_24),
            contentDescription = null,
            modifier = Modifier
                .size(40.dp)
                .clip(CircleShape)
                .background(Color.LightGray.copy(alpha = 0.3f))
                .align(Alignment.Top)
        )

        Spacer(modifier = Modifier.width(8.dp))

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .padding(start = 8.dp)
        ) {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(top = 4.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {
                employee.fullName?.let {
                    Text(
                        text = it,
                        fontSize = 16.sp,
                        fontWeight = FontWeight.Bold
                    )
                }
                Spacer(modifier = Modifier.width(15.dp))
                employee.email?.let {
                    Text(
                        text = it,
                        fontSize = 12.sp,
                        color = Color.LightGray
                    )
                }
            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.team),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                val team = employee.teams?.let { Mapper().extractValues(it) }
                team?.firstOrNull()?.let {
                    Text(
                        text = it,
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                }

            }

            Spacer(modifier = Modifier.height(4.dp))

            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier.fillMaxWidth()
            ) {
                Icon(
                    painter = painterResource(Res.drawable.phone),
                    contentDescription = null,
                    modifier = Modifier.size(20.dp),
                    tint = Color.Gray
                )
                Spacer(modifier = Modifier.width(8.dp))
                employee.phone?.let {
                    Text(
                        text = it.toString(),
                        fontSize = 14.sp,
                        color = Color.Gray
                    )
                }
            }
        }
    }
}

@Composable
fun EditDialog(
    showDialog: Boolean,
    employees: List<User>,
    onDelete: () -> Unit,
    onExportList: () -> Unit,
    onImportList: () -> Unit,
    onEdit: () -> Unit
) {
    if (showDialog) {
        Card(
            modifier = Modifier
                .fillMaxWidth()
                .padding(horizontal = 16.dp, vertical = 28.dp)
                .background(Color.White, shape = RoundedCornerShape(8.dp)),
            elevation = 4.dp
        ) {
            Column() {
                Text(
                    text = "${employees.size} selected",
                    fontWeight = FontWeight.W400,
                    color = Color.Gray,
                    fontSize = 16.sp,
                    modifier = Modifier.padding(start = 16.dp, end = 16.dp, top = 16.dp)
                )

                Spacer(modifier = Modifier.height(8.dp))

                Divider(
                    color = Color.LightGray,
                    thickness = 1.dp
                )

                Spacer(modifier = Modifier.height(8.dp))

                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    modifier = Modifier.fillMaxWidth()
                        .padding(start = 16.dp, end = 16.dp, bottom = 16.dp)

                ) {
                    Text(
                        text = stringResource(Res.string.delete),
                        fontSize = 14.sp,
                        color = Color.Red,
                        modifier = Modifier.clickable {
                            onDelete()
                        })
                    Text(
                        text = stringResource(Res.string.export_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onExportList()
                        })
                    Text(
                        text = stringResource(Res.string.import_list),
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier.clickable {
                            onImportList()
                        })
                    Text(text = stringResource(Res.string.edit), fontSize = 14.sp, color = BlueApp,
                        modifier = Modifier.clickable {
                            onEdit()
                        })
                }
            }
        }
    }
}

@Composable
fun RoundedCornerCheckbox(
    isChecked: Boolean,
    onCheckedChange: (Boolean) -> Unit,
    modifier: Modifier = Modifier,
    size: Float = 24f,
    checkedColor: Color = BlueApp,
    uncheckedColor: Color = Color.Gray,
    checkmarkColor: Color = Color.White,
) {
    val density = LocalDensity.current
    val duration = 200

    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = modifier
            .heightIn(48.dp)
            .clickable { onCheckedChange(!isChecked) }
    ) {
        Box(
            modifier = Modifier
                .size(size.dp)
                .border(
                    width = 1.5.dp,
                    color = if (isChecked) checkedColor else uncheckedColor,
                    shape = CircleShape
                ),
            contentAlignment = Alignment.Center
        ) {
            androidx.compose.animation.AnimatedVisibility(
                visible = isChecked,
                enter = slideInHorizontally(animationSpec = tween(duration)) {
                    with(density) { (size * -0.5).dp.roundToPx() }
                } + expandHorizontally(
                    expandFrom = Alignment.Start,
                    animationSpec = tween(duration)
                ),
                exit = fadeOut()
            ) {
                Box(
                    modifier = Modifier
                        .size(size.dp)
                        .background(color = checkedColor, shape = CircleShape),
                    contentAlignment = Alignment.Center
                ) {
                    androidx.compose.material3.Icon(
                        Icons.Default.Check,
                        contentDescription = null,
                        tint = checkmarkColor
                    )
                }
            }
        }
    }
}

@Composable
fun EmployeesList(
    employees: List<com.hyperether.goodjob.database.User>,
    selectedEmployees: MutableList<com.hyperether.goodjob.database.User>,
    isInEditMode: Boolean,
    navHostController: NavHostController
) {
    val checkedStates = remember { mutableStateMapOf<Long, Boolean>() }

    LazyColumn(
        modifier = Modifier.fillMaxSize()
    ) {
        items(employees) { employee ->
            EmployeesItem(
                employee = employee,
                isInEditMode = isInEditMode,
                isChecked = checkedStates[employee.id] ?: false,
                onEmployeeSelected = { selectedEmployee, isSelected ->
                    checkedStates[employee.id] = isSelected
                    if (isSelected) {
                        selectedEmployees.add(selectedEmployee)
                    } else {
                        selectedEmployees.remove(selectedEmployee)
                    }
                },
                onItemClick = {
                    navHostController.navigate(Screen.EmployeeDetails.route + "/${employee.id}")
                }
            )
            Divider(
                //modifier = Modifier.padding(),
                color = Color.Gray,
                thickness = 0.5.dp
            )
        }
    }
    LaunchedEffect(selectedEmployees.isEmpty()) {
        if (selectedEmployees.isEmpty()) {
            checkedStates.clear()
        }
    }
}

@Composable
fun EmployeeHeaderView(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(16.dp),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                painter = painterResource(Res.drawable.baseline_person_24),
                contentDescription = "Rounded Image",
                modifier = Modifier
                    .size(50.dp)
                    .clip(CircleShape)
                    .background(Color.LightGray)

            )

            Spacer(modifier = Modifier.width(16.dp))

            Column(
                modifier = Modifier.weight(1f)
            ) {
                user?.fullName?.let {
                    Text(
                        text = it,
                        fontWeight = FontWeight.Bold,
                        fontSize = 14.sp
                    )
                }
                user?.role?.let {
                    Text(
                        text = it,
                        color = Color.Gray,
                        fontSize = 14.sp
                    )
                }
            }

            Text(
                text = "",
                modifier = Modifier.align(Alignment.CenterVertically)
            )
        }
    }
}

@Composable
fun EmployeeDetailsView(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.employment_details),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            Text(
                text = "",
                fontSize = 14.sp,
                color = Color.Gray,
            )

            Text(
                text = buildAnnotatedString {

                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Gray,
                            fontSize = 14.sp
                        )
                    ) {
                        append(stringResource(Res.string.team) + ": ")
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black,
                            fontSize = 14.sp
                        )
                    ) {
                        val teams = user?.teams?.let { Mapper().getTeamsFromString(it) } ?: emptyList()
                        for ((index, team) in teams.withIndex()) {
                            append(team.value)
                            if (index != teams.size - 1) {
                                append(", ")
                            }
                        }
                    }
                }
            )
            Text(
                text = buildAnnotatedString {
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Gray,
                            fontSize = 14.sp
                        )
                    ) {
                        append(stringResource(Res.string.skills) + ": ")
                    }
                    withStyle(
                        style = SpanStyle(
                            fontWeight = FontWeight.W400,
                            color = Color.Black,
                            fontSize = 14.sp
                        )
                    ) {
                        val skills =
                            user?.skills?.split(",")?.filter { it.isNotEmpty() }?.joinToString(", ")
                                ?: " "
                        append(skills)
                    }
                }
            )
        }
    }
}

@Composable
fun EmployeeContactsView(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.contact_information),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            user?.email?.let {
                Text(
                    text = it,
                    fontSize = 14.sp,
                    color = Color.Gray,
                )
            }
            user?.phone?.let {
                Text(
                    text = it,
                    color = Color.Gray,
                    fontSize = 14.sp
                )
            }
            user?.address?.let {
                Text(
                    text = it,
                    color = Color.Gray,
                    fontSize = 14.sp
                )
            }

        }
    }
}

@Composable
fun EmployeeLeaveDetails(user: com.hyperether.goodjob.database.User?) {
    Card(
        modifier = Modifier
            .fillMaxWidth()
            .padding(16.dp),
        shape = RoundedCornerShape(8.dp),
        elevation = 8.dp
    ) {
        Column(modifier = Modifier.padding(16.dp)) {
            Text(
                text = stringResource(Res.string.leave_details),
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp
            )
            Text(
                text = user?.upcomingLeave ?: "",
                fontSize = 14.sp,
                color = Color.Gray,
            )

        }
    }
}

@Composable
fun FilterEmployeesDialog(onDismiss: () -> Unit, viewModel: EmployeesViewModel) {
    Box(modifier = Modifier.fillMaxSize()) {

        Box(
            modifier = Modifier
                .fillMaxSize()
                .background(Color.Black.copy(alpha = 0.5f))
                .clickable(
                    onClick = {},
                    indication = null,
                    interactionSource = remember { MutableInteractionSource() })
        ) {
            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .align(Alignment.Center)
                    .background(Color.White)
                    .verticalScroll(rememberScrollState())
            ) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(16.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    Icon(
                        painter = painterResource(Res.drawable.back_arrow),
                        contentDescription = "Back icon",
                        modifier = Modifier
                            .weight(1f)
                            .offset(x = (-30).dp)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                onDismiss()
                            }
                    )

                    Text(
                        text = stringResource(Res.string.filter),
                        textAlign = TextAlign.Center,
                        color = Color.Black,
                        fontSize = 20.sp,
                        modifier = Modifier
                            .weight(3f)
                            .align(Alignment.CenterVertically)
                    )

                    Text(
                        text = "Clear all",
                        textAlign = TextAlign.End,
                        fontSize = 14.sp,
                        color = BlueApp,
                        modifier = Modifier
                            .weight(1f)
                            .align(Alignment.CenterVertically)
                            .clickable {
                                viewModel.clearFilterValues()
                            }
                    )
                }
                Divider(
                    modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f))
                )

                Column(modifier = Modifier.padding(start = 16.dp)) {
                    Spacer(modifier = Modifier.height(15.dp))
                    Text(
                        text = stringResource(Res.string.role).substring(0, 4),
                        fontSize = 18.sp,
                        color = TextColor,
                        fontWeight = FontWeight.SemiBold,
                    )
                    RowWithTextAndCheckbox("Manager", viewModel.managerChecked)
                    RowWithTextAndCheckbox("Employee", viewModel.employeeChecked)
                    RowWithTextAndCheckbox("Administrator", viewModel.administratorChecked)

                    Spacer(modifier = Modifier.height(10.dp))

                    Text(
                        text = stringResource(Res.string.status),
                        fontSize = 18.sp,
                        color = TextColor,
                        fontWeight = FontWeight.SemiBold,
                    )
                    RowWithTextAndCheckbox("Active", viewModel.activeChecked)
                    RowWithTextAndCheckbox("Inactive", viewModel.inactiveChecked)
                    RowWithTextAndCheckbox("On Leave", viewModel.onLeaveChecked)

                    Spacer(modifier = Modifier.height(10.dp))

                    Text(
                        text = stringResource(Res.string.city),
                        fontSize = 18.sp,
                        color = TextColor,
                        fontWeight = FontWeight.SemiBold,
                    )

                    RowWithTextAndCheckbox("New York", viewModel.cityCheckedValue)
                    RowWithTextAndCheckbox("Chicago", viewModel.cityCheckedValue)
                    RowWithTextAndCheckbox("Houston", viewModel.cityCheckedValue)

                    Spacer(modifier = Modifier.height(10.dp))

                    Text(
                        text = stringResource(Res.string.team),
                        fontSize = 18.sp,
                        color = TextColor,
                        fontWeight = FontWeight.SemiBold,
                    )

                    RowWithTextAndCheckbox("Sales Team", viewModel.salesTeamChecked)
                    RowWithTextAndCheckbox("Support Team", viewModel.supportTeamChecked)
                    RowWithTextAndCheckbox("Technical Team", viewModel.technicalTeamChecked)

                    Spacer(modifier = Modifier.height(10.dp))

                    Text(
                        text = stringResource(Res.string.skill),
                        fontSize = 18.sp,
                        color = TextColor,
                        fontWeight = FontWeight.SemiBold,
                    )

                    RowWithTextAndCheckbox(
                        "Electrical Installation",
                        viewModel.electricalInstallationChecked
                    )
                    RowWithTextAndCheckbox(
                        "Solar Panel Installation",
                        viewModel.solarPanelCheckedChecked
                    )
                    RowWithTextAndCheckbox("Wiring and Cabling", viewModel.wiringCheckedChecked)
                }
            }
        }
    }
}

@Composable
fun RowWithTextAndCheckbox(text: String, checkedValue: MutableState<Boolean>) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .height(35.dp),
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = text,
            fontSize = 14.sp,
            modifier = Modifier
                .weight(1f)
                .align(Alignment.CenterVertically)
        )

        Checkbox(
            checked = checkedValue.value,
            onCheckedChange = {
                checkedValue.value = it
            },
            colors = CheckboxDefaults.colors(
                checkedColor = BlueApp,
                uncheckedColor = Color.Gray,
                checkmarkColor = Color.White
            ),
            modifier = Modifier.align(Alignment.CenterVertically)
        )
    }
}

