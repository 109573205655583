package com.hyperether.goodjob.scenes.jobs

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentWidth
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.itemsIndexed
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material.Text
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.models.Step
import com.hyperether.goodjob.repository.remote.model.JobRequest
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.about_job
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.company
import com.hyperether.goodjob.resources.completed
import com.hyperether.goodjob.resources.completion_report
import com.hyperether.goodjob.resources.contact_information
import com.hyperether.goodjob.resources.date
import com.hyperether.goodjob.resources.details
import com.hyperether.goodjob.resources.edit
import com.hyperether.goodjob.resources.employee_added_successfully
import com.hyperether.goodjob.resources.end_time
import com.hyperether.goodjob.resources.error
import com.hyperether.goodjob.resources.estimated_duration
import com.hyperether.goodjob.resources.file_deleted
import com.hyperether.goodjob.resources.files
import com.hyperether.goodjob.resources.job_details
import com.hyperether.goodjob.resources.job_finished
import com.hyperether.goodjob.resources.location_details
import com.hyperether.goodjob.resources.minutes
import com.hyperether.goodjob.resources.note_added_successfully
import com.hyperether.goodjob.resources.ok
import com.hyperether.goodjob.resources.schedule
import com.hyperether.goodjob.resources.site
import com.hyperether.goodjob.resources.start_time
import com.hyperether.goodjob.resources.status
import com.hyperether.goodjob.resources.steps
import com.hyperether.goodjob.resources.success
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.PopUpDialog
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.Green
import com.hyperether.goodjob.theme.TextColor
import com.hyperether.goodjob.util.DateTimeUtil
import com.hyperether.goodjob.util.StatusColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun JobDetails(
    jobId: String,
    viewModel: JobViewModel,
    navHostController: NavHostController,
    onOpenPdf: (String) -> Unit,
    onUploadDocumentClick: () -> Unit
) {

    var isAboutJobView = remember { mutableStateOf(true) }
    var selectedButtonIndex by remember { mutableStateOf(0) }
    var percentCompleted by remember { mutableStateOf(0) }

    val job = viewModel.jobList.find { it._id == jobId }
    val steps = job?.steps?.let { Mapper().jsonToListSteps(it) } ?: emptyList()
    val contact by viewModel.contact.collectAsState()

    LaunchedEffect(job) {
        job?.contactParentId?.let { contactId ->
            if (contact == null || contact?._id != contactId) {
                viewModel.fetchContactById(contactId)
            }
        }
        job?.fileId?.let {
            if (it.isNotEmpty()) {
                viewModel.downloadFile(it)
            }
        }
    }

    println(job.toString())
    println(contact.toString())

    Box(modifier = Modifier.fillMaxSize()) {

        Column {
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(horizontal = 16.dp, vertical = 20.dp)
            ) {
                Image(
                    painter = painterResource(Res.drawable.back_arrow),
                    contentDescription = "",
                    modifier = Modifier
                        .clickable {
                            navHostController.popBackStack()
                        }
                )

                Text(
                    text = stringResource(Res.string.job_details),
                    fontSize = 18.sp,
                    color = Color.Black,
                    textAlign = TextAlign.Center,
                    modifier = Modifier
                        .weight(1f)
                        .padding(start = 16.dp)
                )

                Row(
                    horizontalArrangement = Arrangement.End,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier
                        .wrapContentWidth()
                        .padding(start = 8.dp)
                ) {
                    Image(
                        painter = painterResource(Res.drawable.edit),
                        contentDescription = "",
                        modifier = Modifier
                            .padding()
                            .clickable {
                            }
                    )
                }
            }
            Divider(modifier = Modifier.height(2.dp).background(Color.LightGray.copy(alpha = 0.1f)))
            Column(
                modifier = Modifier
                    .background(Color.LightGray.copy(alpha = 0.2f))
                    .padding(8.dp),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                Row(
                    modifier = Modifier.fillMaxWidth().height(40.dp),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.about_job),
                        color = if (isAboutJobView.value) BlueApp else Color.Gray,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(start = 40.dp)
                            .clickable {
                                isAboutJobView.value = true
                            }
                    )

                    Spacer(modifier = Modifier.weight(0.5f))

                    androidx.compose.material3.Text(
                        text = stringResource(Res.string.completion_report),
                        color = if (isAboutJobView.value) Color.Gray else BlueApp,
                        fontSize = 16.sp,
                        modifier = Modifier
                            .padding(end = 20.dp)
                            .clickable {
                                isAboutJobView.value = false
                            }
                    )
                }
            }
            Box(
                modifier = Modifier
                    .height(2.dp)
                    .fillMaxWidth(0.5f)
                    .background(if (isAboutJobView.value) BlueApp else BlueApp)
                    .align(if (isAboutJobView.value) Alignment.Start else Alignment.End)
            )

            if (isAboutJobView.value) {
                Row(
                    modifier = Modifier
                        .fillMaxWidth()
                        .padding(start = 11.dp, end = 11.dp, top = 10.dp),
                    horizontalArrangement = Arrangement.SpaceEvenly,
                    verticalAlignment = Alignment.CenterVertically
                ) {
                    val buttons = listOf(
                        stringResource(Res.string.details),
                        stringResource(Res.string.steps),
                        stringResource(Res.string.files)
                    )

                    buttons.forEachIndexed { index, label ->
                        Box(
                            modifier = Modifier
                                .weight(1f)
                                .padding(5.dp)
                                .border(1.dp, Color.LightGray, RoundedCornerShape(20))
                                .background(
                                    color = if (selectedButtonIndex == index) BlueApp else Color.Transparent,
                                    shape = RoundedCornerShape(20)
                                )
                                .clickable { selectedButtonIndex = index },
                            contentAlignment = Alignment.Center
                        ) {
                            Text(
                                text = label,
                                color = if (selectedButtonIndex == index) Color.White else Color.LightGray,
                                modifier = Modifier.padding(vertical = 12.dp)
                            )
                        }
                    }
                }
                if (selectedButtonIndex == 0) {
                    Column(
                        modifier = Modifier.fillMaxSize().verticalScroll(rememberScrollState())
                    ) {
                        JobDetailsHeaderView(job)
                        JobDetailsView(
                            stringResource(Res.string.contact_information),
                            firstDetailText = contact?.personName ?: "",
                            firstDescriptionText = "",
                            secondDetailText = contact?.phone ?: "",
                            secondDescriptionText = "",
                            thirdDetailText = contact?.email ?: "",
                            thirdDescriptionText = ""
                        )
                        JobDetailsView(
                            stringResource(Res.string.location_details),
                            firstDetailText = contact?.address ?: "",
                            firstDescriptionText = "",
                            secondDescriptionText = stringResource(Res.string.site) + ": ",
                            secondDetailText = job?.contactSiteName ?: "",
                            thirdDescriptionText = stringResource(Res.string.company) + ": ",
                            thirdDetailText = contact?.name ?: ""
                        )
                        JobDetailsView(
                            stringResource(Res.string.schedule),
                            firstDescriptionText = stringResource(Res.string.date) + ": ",
                            firstDetailText = DateTimeUtil.formatJobDetailsDate(job?.dueDate ?: ""),
                            secondDetailText = job?.start_at ?: "",
                            secondDescriptionText = stringResource(Res.string.start_time) + ": ",
                            thirdDetailText = job?.end_at ?: "",
                            thirdDescriptionText = stringResource(Res.string.end_time) + ": "
                        )
                    }
                    Spacer(modifier = Modifier.height(20.dp))
                } else if (selectedButtonIndex == 1) {
                    if (steps.isNotEmpty()) {
                        if (steps[0].stepTitle?.isNotEmpty() == true) {
                            Column {
                                Spacer(modifier = Modifier.height(15.dp))
                                Text(
                                    text = "${percentCompleted}% " + stringResource(Res.string.completed),
                                    color = job?.status?.let { StatusColor().getStatusColor(it) }
                                        ?: Green,
                                    fontWeight = FontWeight.W900,
                                    fontSize = 16.sp,
                                    modifier = Modifier.padding(start = 16.dp)

                                )
                                Spacer(modifier = Modifier.height(15.dp))

                                LazyColumn(modifier = Modifier.fillMaxSize()) {
                                    itemsIndexed(steps) { index, step ->
                                        val stepCounter = remember { mutableStateOf(index + 1) }
                                        StepsItem(step = step, number = stepCounter)
                                    }
                                }
                            }
                        }
                    }
                } else if (selectedButtonIndex == 2) {
                    job?.let {
                        NotesAndDocumentsView(
                            image = viewModel.imagePainter.value,
                            document = viewModel.pdfFilePath.value,
                            job = it,
                            onAddNoteClick = {
                                viewModel.showNotePopUp.value = true
                            },
                            onOpenPdfClick = {
                                viewModel.pdfFilePath.value?.let { fileName ->
                                    onOpenPdf(
                                        fileName
                                    )
                                }
                            },
                            onDeleteFileClick = { fileId ->
                                job._id?.let { jobId -> viewModel.deleteFile(jobId, fileId) }
                            },
                            onDeleteImageClick = { imageName ->
                                job._id?.let { jobId -> viewModel.deleteImage(jobId, imageName) }
                            }
                        )
                    }
                }
            } else {
                if (viewModel.isEmployee()) {
                    job?.let {
                        CompletionReportEmployee(viewModel, navHostController, it, onUploadDocumentClick)
                    }
                } else {
                    job?.let {
                        CompletionReportAdmin(viewModel, navHostController, it)
                    }
                }
            }
            if (viewModel.showFinishedJobSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.job_finished),
                    onPositiveBtnClicked = {
                        viewModel.showPopUpSuccess.value = false
                        navHostController.popBackStack()
                    },
                    positiveBtnText = stringResource(Res.string.ok).uppercase()
                )
            }
            if (viewModel.showNotePopUp.value) {
                NoteInputDialog(
                    initialText = viewModel.noteInput,
                    onDismiss = {
                        viewModel.showNotePopUp.value = false
                    },
                    onConfirm = {
                        viewModel.showLoader.value = true
                        val employees = job?.employees?.let { Mapper().jsonToListEmployees(it) }
                        val employeesIds = employees?.map { it._id }
                        val jobModel =
                            employeesIds?.let {
                                Job(
                                    jobTitle = job.jobTitle,
                                    status = job.status,
                                    employees = Mapper().jsonToListEmployees(
                                        job.employees ?: ""
                                    ),
                                    creatorId = job.contactId,
                                    workspaceId = job.workspaceId,
                                    start_at = job.start_at,
                                    end_at = job.end_at,
                                    repeat = job.repeat,
                                    dueDate = job.dueDate,
                                    jobDuration = job.jobDuration,
                                    steps = Mapper().jsonToListSteps(job.steps ?: ""),
                                    userId = job.userId,
                                    contactId = job.contactId,
                                    contactParentId = job.contactParentId,
                                    contactSiteName = job.contactSiteName,
                                    notes = viewModel.noteInput.value,
                                    fileId = job.fileId
                                )
                            }


                        if (jobModel != null) {
                            viewModel.updateJob(jobId, jobModel, onSuccess = {
                                viewModel.showNotePopUp.value = false
                                viewModel.showLoader.value = false
                                viewModel.showPopUpSuccess.value = true
                            },
                                onError = {
                                    viewModel.showNotePopUp.value = false
                                    viewModel.showLoader.value = false
                                    viewModel.showPopUpError.value = false
                                })
                        }
                    }
                )
            }
            if (viewModel.showPopUpError.value) {
                ErrorDialog(
                    onDismiss = { viewModel.showPopUpError.value = false },
                    title = stringResource(Res.string.error).uppercase(),
                    message = viewModel.errorText.value
                )
            }
            if (viewModel.showLoader.value) {
                FullScreenProgressBar()
            }
            if (viewModel.showPopUpSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        viewModel.showPopUpSuccess.value = false
                    },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.note_added_successfully),
                    onPositiveBtnClicked = {
                        viewModel.showPopUpSuccess.value = false
                    },
                    positiveBtnText = stringResource(Res.string.ok).uppercase()
                )
            }
            if (viewModel.showFileDeleteSuccess.value) {
                PopUpDialog(
                    onDismiss = {
                        viewModel.showFileDeleteSuccess.value = false
                    },
                    title = stringResource(Res.string.success).uppercase(),
                    message = stringResource(Res.string.file_deleted),
                    onPositiveBtnClicked = {
                        viewModel.showFileDeleteSuccess.value = false
                    },
                    positiveBtnText = stringResource(Res.string.ok).uppercase()
                )
            }
        }
    }
}


@Composable
fun StepsItem(step: Step, number: MutableState<Int>) {
    Column(modifier = Modifier.padding(start = 16.dp, end = 16.dp)) {
        step.stepTitle?.let {
            Row {
                NumberCircle(
                    step = step,
                    number = number.value,
                    onNumberChange = { newNumber ->
                        number.value = newNumber
                    }
                )
                Spacer(modifier = Modifier.width(15.dp))
                Text(
                    text = it,
                    color = Color.DarkGray,
                    fontWeight = FontWeight.Bold,
                    fontSize = 16.sp,

                    )
            }
        }
        step.stepDescription?.let {
            Text(
                text = it,
                color = TextColor,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp,

                )
        }
        Row {
            Text(
                text = "${stringResource(Res.string.estimated_duration)}:",
                color = Color.LightGray,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp
            )
            Spacer(modifier = Modifier.width(5.dp))
            step.estimatedDuration?.let {
                Text(
                    text = it.toString() + " " + stringResource(Res.string.minutes),
                    color = TextColor,
                    fontWeight = FontWeight.Bold,
                    fontSize = 14.sp,

                    )
            }
        }
        Row(
            verticalAlignment = Alignment.CenterVertically
        ) {
            Text(
                text = "${stringResource(Res.string.status)}:",
                color = Color.LightGray,
                fontWeight = FontWeight.Normal,
                fontSize = 14.sp
            )
            Spacer(modifier = Modifier.width(5.dp))
            if (step.stepStatus == "completed") { // TODO add enum for step status
                Icon(
                    imageVector = Icons.Default.Check,
                    contentDescription = "Checkmark",
                    modifier = Modifier.size(16.dp),
                    tint = step.stepStatus?.let { StatusColor().getStatusColor(it) } ?: Green
                )
            }

            Spacer(modifier = Modifier.width(5.dp))
            Text(
                text = step.stepStatus?.capitalize()
                    ?: stringResource(Res.string.completed).capitalize(),
                color = step.stepStatus?.let { StatusColor().getStatusColor(it) } ?: Green,
                fontWeight = FontWeight.Bold,
                fontSize = 14.sp,
            )
            // }
        }
        Spacer(modifier = Modifier.height(15.dp))

    }
}

@Composable
fun NumberCircle(
    step: Step,
    number: Int,
    onNumberChange: (Int) -> Unit
) {
    var currentNumber by remember { mutableStateOf(number) }

    Box(
        contentAlignment = Alignment.Center,
        modifier = Modifier
            .size(25.dp)
            .background(color = step.stepStatus?.let { StatusColor().getStatusColor(it) }
                ?: Green,
                shape = CircleShape)
            .clickable {
                currentNumber += 1
                onNumberChange(currentNumber)
            }
    ) {
        Text(
            text = currentNumber.toString().padStart(2, '0'),
            color = Color.White,
            fontSize = 12.sp,
            fontWeight = FontWeight.Bold
        )
    }
}