package com.hyperether.goodjob.map

import androidx.compose.runtime.Composable

@Composable
expect fun MapComponent(markers: List<Marker>)


data class Marker(
    val lat: Double,
    val lng: Double,
    val title: String
)