package com.hyperether.goodjob.scenes.scheduler

import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.Divider
import androidx.compose.material.Icon
import androidx.compose.material3.DrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.runtime.rememberCoroutineScope
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.Screen
import com.hyperether.goodjob.planList
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.filter_svgrepo_com_1
import com.hyperether.goodjob.resources.scheduler
import com.hyperether.goodjob.scenes.components.MobileHeaderSearchAdd
import com.hyperether.planner.ui.SchedulerDayPlanner
import com.hyperether.planner.ui.WeekPlanner
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun SchedulerScreen(
    viewModel: SchedulerViewModel,
    navHostController: NavHostController,
    drawerState: DrawerState,
    prefsManager: PrefsManager
) {
    Column {
        MobileHeaderSearchAdd(
            title = stringResource(Res.string.scheduler),
            add = { navHostController.navigate(Screen.AddNew.route) },
            search = {},
            drawerState = drawerState,
            scope = rememberCoroutineScope(),
            flow = prefsManager.getFlow()
        )

        Row(
            modifier = Modifier.fillMaxWidth().padding(horizontal = 20.dp, vertical = 12.dp),
            horizontalArrangement = Arrangement.SpaceBetween
        ) {
            PeriodChooser(viewModel.periodState) { viewModel.periodChanged() }
            Icon(
                painter = painterResource(Res.drawable.filter_svgrepo_com_1),
                contentDescription = null,
                modifier = Modifier.size(40.dp)
            )
        }

        Divider(modifier = Modifier.height(1.dp).background(Color.LightGray.copy(alpha = 0.1f)))

        PeriodSwitcher(
            text = viewModel.currentPeriod.value,
            prev = { viewModel.previous() },
            next = { viewModel.next() }
        )

        when (viewModel.periodState.value) {
            PeriodType.DAY -> SchedulerDayPlanner(listOfPlans = viewModel.listOfPlans)
            PeriodType.WEEK -> WeekPlanner(listOfPlans = planList.toMutableList())
            PeriodType.MONTH -> SchedulerDayPlanner(listOfPlans = planList.toMutableList())
        }

    }

}