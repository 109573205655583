package com.hyperether.goodjob.scenes.pricing

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.rememberScrollState
import androidx.compose.foundation.verticalScroll
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.getPlatformChecker
import com.hyperether.goodjob.log
import com.hyperether.goodjob.models.Plan
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.choose_plan
import com.hyperether.goodjob.resources.choose_the_plan
import com.hyperether.goodjob.resources.choose_the_right_plan
import com.hyperether.goodjob.resources.day_free_trial
import com.hyperether.goodjob.resources.free
import com.hyperether.goodjob.resources.from_small_teams
import com.hyperether.goodjob.resources.logo_dark_landing
import com.hyperether.goodjob.scenes.components.ErrorDialog
import com.hyperether.goodjob.scenes.components.SubscriptionToggle
import com.hyperether.goodjob.scenes.register.FilledButton
import com.hyperether.goodjob.scenes.register.FullScreenProgressBar
import com.hyperether.goodjob.theme.LandingPageBg
import com.hyperether.goodjob.theme.LandingPageMainTitle
import com.hyperether.goodjob.theme.LandingPageText
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun PricingScreen(
    isMobileView: Boolean,
    pricingViewModel: PricingViewModel,
    onChoosePlanClick: (Double, String) -> Unit
) {
    val plans = if (getPlatformChecker().isMobile()) {
        pricingViewModel.plans.collectAsState(initial = emptyList()).value
    } else {
        pricingViewModel.plans_remote.collectAsState(initial = emptyList()).value.sortedBy { it.price }
    }


    fun updateFilteredPlans() {
        pricingViewModel.filteredPlans.value = emptyList()
        val newFilteredPlans = mutableListOf<Plan>()

        // Always add the "freebie" plan if it exists
        val freebiePlan = plans.find {
            it.price == 0.0
        }
        if (freebiePlan != null) {
            newFilteredPlans.add(freebiePlan)
        }

        // Filter plans based on the selected interval ("month" or "year")
        val selectedInterval = when {
            pricingViewModel.selectedOption.value.startsWith("year", ignoreCase = true) -> "year"
            pricingViewModel.selectedOption.value.startsWith("month", ignoreCase = true) -> "month"
            else -> null
        }

        if (selectedInterval != null) {
            val matchingPlans = plans.filter {
                it.interval.equals(selectedInterval, ignoreCase = true) && it.price != 0.0
            }

            // Add up to 3 plans matching the interval
            newFilteredPlans.addAll(matchingPlans)
        }

        // Update the filtered plans observable state
        pricingViewModel.filteredPlans.value = newFilteredPlans
        log.d("Pricing") { "Filtered plans: ${pricingViewModel.filteredPlans.value}" }
    }

    LaunchedEffect(plans) {
        if (plans.isNotEmpty()) {
            val popularPlan = plans.find { it.isPopular == true }
            pricingViewModel.selectedPlan.value = popularPlan?.name
            pricingViewModel.selectedPlanId.value = popularPlan?._id
            pricingViewModel.showLoader.value = false
        }
    }

    LaunchedEffect(isMobileView) {
        pricingViewModel.getAllPlans()
    }

    LaunchedEffect(pricingViewModel.selectedOption.value, plans) {
        updateFilteredPlans()
    }

    if (isMobileView) {
        Column(
            modifier = Modifier
                .fillMaxSize()
                .verticalScroll(rememberScrollState())
                .background(
                    brush = Brush.verticalGradient(
                        colors = listOf(
                            Color(0xFFc7e4fc),
                            Color(0xFFFFFFFF)
                        )
                    )
                )
                .padding(16.dp)
        ) {
            Column(
                modifier = Modifier.fillMaxSize(),
            ) {
                Text(
                    text = stringResource(Res.string.choose_the_right_plan),
                    color = Color.Black,
                    fontSize = 28.sp,
                    textAlign = TextAlign.Center,
                    fontWeight = FontWeight.Bold,
                    modifier = Modifier.padding(bottom = 24.dp, top = 30.dp)
                )

                SubscriptionToggle(
                    isDark = false,
                    isMobile = true,
                    selectedOption = pricingViewModel.selectedOption.value,
                    onOptionSelected = {
                        pricingViewModel.selectedOption.value = it
                        updateFilteredPlans()
                    })

                pricingViewModel.filteredPlans.value.forEach { plan ->
                    PricingItem(
                        isChecked = pricingViewModel.selectedPlan.value == plan.name,
                        onClick = {
                            pricingViewModel.selectedPlan.value = plan.name
                            pricingViewModel.selectedPlanId.value = plan._id
                            pricingViewModel.amount.value = plan.price
                            pricingViewModel.currency.value = plan.currency
                        },
                        modifier = Modifier.padding(top = 10.dp),
                        planPrice = if (plan.price == 0.0) stringResource(Res.string.free) else "$${plan.price}",
                        planName = plan.name.orEmpty()
                    )
                }
            }
            Column(
                horizontalAlignment = Alignment.CenterHorizontally,
                modifier = Modifier
                    //.align(Alignment.BottomCenter)
                    .fillMaxWidth()
                    .padding(bottom = 10.dp)
            ) {

                FilledButton(
                    onClick = {
                        if (pricingViewModel.amount.value == 0.0) {
                            pricingViewModel.selectedPlanId.value?.let {
                                pricingViewModel.addPlan()
                            }
                        } else {
                            pricingViewModel.amount.value?.let { amount ->
                                pricingViewModel.currency.value?.let { currency ->
                                    onChoosePlanClick(amount, currency)
                                } ?: run {
                                    pricingViewModel.error.value =
                                        com.hyperether.goodjob.repository.remote.model.Resource.Error(
                                            "At the moment, it is not possible to buy a plan."
                                        )
                                }
                            } ?: run {
                                pricingViewModel.error.value =
                                    com.hyperether.goodjob.repository.remote.model.Resource.Error(
                                        "At the moment, it is not possible to buy a plan."
                                    )
                            }
                        }
                    },
                    modifier = Modifier.padding(top = 20.dp),
                    text = stringResource(Res.string.choose_plan),
                    isEnabled = true
                )
                Spacer(modifier = Modifier.height(10.dp))
                Text(
                    text = stringResource(Res.string.day_free_trial),
                    color = Color.Gray,
                    textAlign = TextAlign.Center,
                    fontSize = 14.sp,
                    modifier = Modifier.padding(top = 8.dp)
                )
            }
        }
    } else {
        Spacer(modifier = Modifier.height(60.dp))

        Column(
            modifier = Modifier
                .fillMaxSize()
                .background(LandingPageBg)
                .verticalScroll(rememberScrollState()),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(start = Dp(100f), top = Dp(38f)),
                contentAlignment = Alignment.TopStart
            ) {
                Image(
                    alignment = Alignment.TopStart,
                    painter = painterResource(Res.drawable.logo_dark_landing),
                    contentDescription = null,
                    modifier = Modifier
                        .height(Dp(47f))
                        .width(Dp(203f))
                )
            }
            Spacer(modifier = Modifier.height(Dp(48f)))

            androidx.compose.material.Text(
                text = stringResource(Res.string.choose_the_plan),
                fontSize = Sp(50f),
                lineHeight = Sp(62f),
                color = LandingPageMainTitle,
                fontWeight = FontWeight.W700,
                textAlign = TextAlign.Center,
                modifier = Modifier.padding(vertical = 16.dp)
            )
            Spacer(modifier = Modifier.height(Dp(12f)))
            androidx.compose.material.Text(
                text = stringResource(Res.string.from_small_teams),
                fontSize = Sp(18f),
                lineHeight = Sp(30f),
                fontWeight = FontWeight.W400,
                color = LandingPageText,
                textAlign = TextAlign.Center
            )

            Spacer(modifier = Modifier.height(Dp(40f)))
            SubscriptionToggle(
                isDark = true,
                isMobile = false,
                selectedOption = pricingViewModel.selectedOption.value,
                onOptionSelected = {
                    pricingViewModel.selectedOption.value = it
                    updateFilteredPlans()
                })
            Spacer(modifier = Modifier.height(Dp(90f)))
            Box(modifier = Modifier.fillMaxWidth().padding(horizontal = Dp(135f))) {
               // TODO: FIX WEB
                PricingBoxWebView(
                    isLanding = false,
                    priceBoxSize = pricingViewModel.priceBoxSize,
                    plans = pricingViewModel.filteredPlans.value,
                    savePlanLocally = { pricingViewModel.savePlanLocally(it) },
                    continueToRegister = { id, currency, amount ->
                        if (amount == 0.0) {
                            pricingViewModel.selectedPlanId.value?.let {
                                pricingViewModel.addPlan()
                            }
                        } else {
                            onChoosePlanClick(
                                amount,
                                currency
                            )
                        }
                    }
                )
            }
            Spacer(modifier = Modifier.height(Dp(78f)))
        }
    }

    pricingViewModel.error.value?.let {
        ErrorDialog(
            onDismiss = {
                pricingViewModel.error.value = null
            },
            title = "Error",
            message = it.text ?: ""
        )
    }
    if (pricingViewModel.showLoader.value) {
        FullScreenProgressBar()
    }
}