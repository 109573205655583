package com.hyperether.goodjob.database

import kotlin.Long
import kotlin.String

public data class Skills(
  public val id: Long,
  public val _id: String?,
  public val name: String?,
  public val workspaceId: String?,
)
