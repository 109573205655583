package com.hyperether.goodjob.util

import androidx.compose.ui.text.intl.Locale
import kotlinx.datetime.Clock
import kotlinx.datetime.DatePeriod
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atTime
import kotlinx.datetime.minus
import kotlinx.datetime.toLocalDateTime

object DateTimeUtil {

    fun formatTimestamp(unixTimestamp: Long): String {
        // Handle possible millisecond timestamp by converting to seconds
        val adjustedTimestamp =
            if (unixTimestamp > 9999999999L) unixTimestamp / 1000 else unixTimestamp

        // Convert the Unix timestamp to a LocalDateTime
        val dateTime = Instant.fromEpochSeconds(adjustedTimestamp)
            .toLocalDateTime(TimeZone.currentSystemDefault())

        // Get today's and yesterday's date in the system's current time zone
        val currentDate = Clock.System.now()
            .toLocalDateTime(TimeZone.currentSystemDefault())
            .date
        val yesterdayDate = currentDate.minus(DatePeriod(days = 1))

        // Format the time as HH:mm
        val timeFormatted = "${dateTime.hour.toString().padStart(2, '0')}:${
            dateTime.minute.toString().padStart(2, '0')
        }"
        // Format the date as YYYY-MM-DD
        val dateFormatted = "${dateTime.year}-${
            dateTime.monthNumber.toString().padStart(2, '0')
        }-${dateTime.dayOfMonth.toString().padStart(2, '0')}"

        return when (dateTime.date) {
            currentDate -> "Today, $timeFormatted"
            yesterdayDate -> "Yesterday, $timeFormatted"
            else -> "$dateFormatted, $timeFormatted"
        }
    }

    fun jobListDateFormatter(dateString: String): String {
        try {
            val parsedString = dateString.replace("[", "").replace("]", "")
            val date = parseDate(parsedString)
            val dayOfWeek = date?.dayOfWeek?.name?.lowercase()?.replaceFirstChar { it.uppercase() }
            val day = date?.dayOfMonth.toString().padStart(2, '0')
            val month = date?.month?.name?.lowercase()?.replaceFirstChar { it.uppercase() }
            val year = date?.year

            return "$dayOfWeek, $day $month $year"
        } catch (e: Exception) {
            println("Cant parse date: $dateString")
            return dateString
        }

    }

    fun formatJobDetailsDate(inputDate: String): String {
        return try {
            val cleanedDate = inputDate.trim().removeSurrounding("[", "]")

            val instant = if (cleanedDate.contains(" ")) {
                Instant.parse(cleanedDate.replace(" ", "T"))
            } else {
                Instant.parse("${cleanedDate}T00:00:00Z")
            }

            val localDateTime = instant.toLocalDateTime(TimeZone.currentSystemDefault())

            val day = localDateTime.dayOfMonth.toString().padStart(2, '0')
            val month = localDateTime.month.name.lowercase().replaceFirstChar { it.uppercase() }
            val year = localDateTime.year

            "$day $month $year"
        } catch (e: Exception) {
            "Invalid date format"
        }
    }


    private fun parseDate(input: String): LocalDateTime? {
        return try {
            when {
                input.matches(Regex("\\d{4}-\\d{2}-\\d{2}")) -> {
                    // Handle ISO 8601 date (e.g., "2024-12-29")
                    LocalDate.parse(input).atTime(0, 0) // Default to midnight
                }
                input.matches(Regex("\\d+")) -> {
                    // Handle Unix timestamp (e.g., "1703827200")
                    val epochSeconds = input.toLong()
                    Instant.fromEpochSeconds(epochSeconds).toLocalDateTime(TimeZone.currentSystemDefault())
                }
                else -> null // Unsupported format
            }
        } catch (e: Exception) {
            null // Return null for invalid input
        }
    }

    fun extractDate(unixTimestamp: Long): LocalDate {
        // Handle possible millisecond timestamp by converting to seconds
        val adjustedTimestamp = if (unixTimestamp > 9999999999L) unixTimestamp / 1000 else unixTimestamp

        // Convert to LocalDateTime and then extract the date
        return Instant.fromEpochSeconds(adjustedTimestamp)
            .toLocalDateTime(TimeZone.currentSystemDefault())
            .date
    }

    fun formatDateForDisplay(date: LocalDate): String {
        val day = date.dayOfMonth.toString().padStart(2, '0')
        val month = date.month.name.lowercase().replaceFirstChar { it.uppercase() }
        val year = date.year
        val dayOfWeek = date.dayOfWeek.name.lowercase().replaceFirstChar { it.uppercase() }
        return "$day $month $year, $dayOfWeek"
    }

}