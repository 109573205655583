package com.hyperether.goodjob.util

import androidx.compose.ui.graphics.Color
import com.hyperether.goodjob.theme.Green

class StatusColor {

    fun getStatusColor(status: String): Color {
        return when (status.lowercase()) {
            "pending" -> Color(0xFFA0522D)
            "scheduled" -> Color(0xFFFFA500)
            "travelling" -> Color(0xFF1E90FF)
            "in progress" -> Color(0xFFFF1493)
            "completed" -> Color(0xFF44AA30)
            "on hold", "delayed" -> Color(0xFF808080)
            "attention" -> Color(0xFFFFFF00)
            "cancelled" -> Color(0xFFFF0000)
            "external" -> Color(0xFF800080)
            else -> Green
        }
    }
}