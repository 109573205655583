package com.hyperether.goodjob.repository.prefs

interface Prefs {
    fun putString(key: String, value: String)
    fun getString(key: String, defaultValue: String = ""): String
    fun putInt(key: String, value: Int)
    fun getInt(key: String, defaultValue: Int = 0): Int
    fun putBool(key: String, value: Boolean)
    fun getBool(key: String, defaultValue: Boolean = false): Boolean
    fun putLong(key: String, value: Long)
    fun getLong(key: String, defaultValue: Long = 0): Long
    fun removeAll()
}

expect class Preferences() : Prefs {
    override fun putString(key: String, value: String)
    override fun getString(key: String, defaultValue: String): String
    override fun putInt(key: String, value: Int)
    override fun getInt(key: String, defaultValue: Int): Int
    override fun putBool(key: String, value: Boolean)
    override fun getBool(key: String, defaultValue: Boolean): Boolean
    override fun putLong(key: String, value: Long)
    override fun getLong(key: String, defaultValue: Long): Long
    override fun removeAll()
}