package com.hyperether.goodjob.database

import app.cash.sqldelight.async.coroutines.awaitAsList
import app.cash.sqldelight.async.coroutines.awaitAsOneOrNull
import app.cash.sqldelight.coroutines.asFlow
import app.cash.sqldelight.coroutines.mapToList
import com.hyperether.goodjob.mapper.Mapper
import com.hyperether.goodjob.models.Workspace
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.callbackFlow
import kotlinx.coroutines.flow.flow

class WorkspaceDaoImpl(private val sharedDatabase: SharedDatabase) : WorkspaceDao {

    override suspend fun deleteAllWorkspaces() {
        sharedDatabase { database ->
            database.appDatabaseQueries.deleteAllWorkspaces()
        }
    }

    override suspend fun insertWorkspace(workspace: Workspace) {
        sharedDatabase { database ->
            database.appDatabaseQueries.transaction {
                database.appDatabaseQueries.insertWorkspace(
                    _id = workspace._id,
                    name = workspace.name,
                    type = workspace.type,
                    plan = workspace.planId,
                    user_id = workspace.userId,
                    workspaceType = workspace.workspaceType,
                    lastAccessed = workspace.lastAccessed
                )
            }
        }
    }

    override suspend fun updateWorkspaceById(workspace: Workspace) {
        sharedDatabase { database ->
            database.appDatabaseQueries.transaction {
                database.appDatabaseQueries.updateWorkspaceByID(
                    id = workspace._id,
                    _id = workspace._id,
                    name = workspace.name,
                    type = workspace.type,
                    plan = workspace.planId,
                    user_id = workspace.userId,
                    workspaceType = workspace.workspaceType,
                    lastAccessed = workspace.lastAccessed
                )
            }
        }
    }

    override suspend fun getWorkspacesFlow(): Flow<List<com.hyperether.goodjob.database.Workspace>>? =
        sharedDatabase.getDatabase()?.appDatabaseQueries?.getWorkspaces()?.asFlow()?.mapToList(Dispatchers.Default)

    override suspend fun getWorkspaceById(id: String): Workspace? {
        return sharedDatabase { database ->
            database.appDatabaseQueries.getWorkspaceById(id).awaitAsOneOrNull()
                ?.let { Mapper().toWorkspace(it) }
        }
    }


    override suspend fun deleteWorkspaceById(id: Long) {
        sharedDatabase { database ->
            database.appDatabaseQueries.deleteWorkspaceById(id)
        }
    }
}