package com.hyperether.goodjob.repository.prefs

import com.hyperether.goodjob.models.User
import com.hyperether.goodjob.models.Workspace
import kotlinx.serialization.json.Json
import org.lighthousegames.logging.logging

class PrefsManager(val prefs: Prefs) {

    val log = logging("PrefsManager")

    companion object {
        const val TOKEN_KEY = "token_key"
        const val REFRESH_TOKEN_KEY = "refresh_token_key"
        const val EMAIL = "email"
        const val PASSWORD = "password"
        const val IS_ONBOARDING_SHOWN = "is_onboarding_shown"
        const val USER = "user"
        const val WORKSPACE_ID = "workspace_id"
        const val PLAN_ID = "plan_id"
        const val FLOW_TYPE = "flow_type"
        const val REFERRER = "referrer"
    }

    fun saveToken(token: String) {
        prefs.putString(TOKEN_KEY, token)
    }

    fun getToken(): String {
        return prefs.getString(TOKEN_KEY)
    }

    fun saveRefreshToken(token: String) {
        prefs.putString(REFRESH_TOKEN_KEY, token)
    }

    fun getRefreshToken(): String {
        return prefs.getString(REFRESH_TOKEN_KEY)
    }

    fun saveEmail(email: String) {
        prefs.putString(EMAIL, email)
    }

    fun getEmail(): String {
        return prefs.getString(EMAIL)
    }

    fun onBoardingShown(isOnboardingShown: Boolean) {
        prefs.putBool(IS_ONBOARDING_SHOWN, isOnboardingShown)
    }

    fun isOnboardingShown(): Boolean {
        return prefs.getBool(IS_ONBOARDING_SHOWN)
    }

    fun saveUser(user: User?) {
        if (user != null) {
            val userString = Json.encodeToString(User.serializer(), user)
            log.d("saveUser") { userString }
            prefs.putString(USER, userString)
        } else {
            prefs.putString(USER, "")
        }
    }

    fun deleteUser() {
        log.d("deleteUser") { "User deleted" }
        prefs.putString(USER, "")
    }

    fun getUser(): User? {
        val retrievedUserString = prefs.getString(USER)
        return if (retrievedUserString.isNotEmpty()) {
            log.d("getUser") { retrievedUserString }
            val user = Json.decodeFromString<User>(retrievedUserString)
            user
        } else {
            null
        }
    }

    fun saveWorkspaceId(workspaceId: String) {
        prefs.putString(WORKSPACE_ID, workspaceId)
    }

    fun getWorkspaceId(): String {
        return prefs.getString(WORKSPACE_ID)
    }

    fun savePlanIdLocally(planId: String) {
        log.d("savePlanIdLocally") { planId }
        prefs.putString(PLAN_ID, planId)
    }

    fun getSavedPlanId(): String {
        log.d("getSavedPlanId") { prefs.getString(PLAN_ID) }
        return prefs.getString(PLAN_ID)
    }

    fun removeAll() {
        log.d("Prefs") { "Remove all" }
        prefs.removeAll()
    }

    fun setFlow(flowType: String) {
        prefs.putString(FLOW_TYPE, flowType)
    }

    fun getFlow(): String {
        return prefs.getString(FLOW_TYPE)
    }

    fun isReferrerDone(): Boolean {
        return prefs.getBool(REFERRER)
    }

    fun referrerDone() {
        prefs.putBool(REFERRER, true)
    }
}