package com.hyperether.goodjob.scenes.jobs

import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateListOf
import androidx.compose.runtime.mutableStateMapOf
import androidx.compose.runtime.mutableStateOf
import androidx.compose.ui.graphics.painter.BitmapPainter
import androidx.lifecycle.ViewModel
import androidx.lifecycle.viewModelScope
import com.hyperether.goodjob.database.Contact
import com.hyperether.goodjob.models.Job
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.remote.model.FinishJobRequest
import com.hyperether.goodjob.repository.remote.model.Resource
import com.hyperether.goodjob.util.Constants
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import com.hyperether.goodjob.util.FileType
import com.hyperether.goodjob.util.FileUtil
import com.hyperether.goodjob.util.UploadManager
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.lighthousegames.logging.logging

class JobViewModel(
    val repository: Repository,
) : ViewModel() {
    val log = logging("JobViewModel")

    val showEditPopUp: MutableState<Boolean> = mutableStateOf(false)
    val showNotePopUp: MutableState<Boolean> = mutableStateOf(false)
    val isInEditMode: MutableState<Boolean> = mutableStateOf(false)

    val showPopUpSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showPopUpError: MutableState<Boolean> = mutableStateOf(false)
    val showFileDeleteSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showFinishedJobSuccess: MutableState<Boolean> = mutableStateOf(false)
    val showLoader: MutableState<Boolean> = mutableStateOf(false)
    val errorText: MutableState<String> = mutableStateOf("")
    val noteInput: MutableState<String> = mutableStateOf("")

    var jobSearchText = mutableStateOf("")
    var jobSortBy: MutableState<String?> = mutableStateOf(null)
    val showFilterDialog: MutableState<Boolean> = mutableStateOf(false)

    val jobList = mutableStateListOf<com.hyperether.goodjob.database.Job>()
    val contacts = mutableStateMapOf<String, Contact>()
    val selectedJobs = mutableStateListOf<com.hyperether.goodjob.database.Job>()

    private val _contact = MutableStateFlow<com.hyperether.goodjob.database.Contact?>(null)
    val contact: StateFlow<com.hyperether.goodjob.database.Contact?> = _contact

    private val _imagePainter = MutableStateFlow<BitmapPainter?>(null)
    val imagePainter: StateFlow<BitmapPainter?> = _imagePainter

    private val _pdfFilePath = MutableStateFlow<String?>(null)
    val pdfFilePath: StateFlow<String?> = _pdfFilePath
    val documentStatus = UploadManager.documentStatus
    val uploadFileName = UploadManager.fileName
    val fileId = UploadManager.fileId
    val showDocumentLoader: MutableState<Boolean> = mutableStateOf(false)


    init {
        viewModelScope.launch {
            if (isEmployee()) {
                repository.getAllJobsForUser()
            } else {
                repository.getAllJobsForWorkspace()
            }
        }
        CoroutineScope(Dispatchers.Default).launch {
            repository.getJobs()?.collect {
                val contactMap = mutableMapOf<String, Contact>()
                it.forEach { job ->
                    job.contactParentId?.let {
                        val contact = getContactForJob(it)
                        contact?._id?.let { id -> contactMap[id] = contact }
                    }
                }
                jobList.clear()
                contacts.clear()
                contacts.putAll(contactMap)
                jobList.addAll(it)
            }
        }
    }

    fun fetchContactById(id: String) {
        viewModelScope.launch {
            val result = repository.getContactById(id)
            _contact.value = result
        }
    }

    suspend fun getContactForJob(id: String): Contact? {
        return repository.getContactById(id)
    }

    private val _jobs_remote =
        MutableStateFlow<List<Job>>(emptyList())
    val jobs_remote: StateFlow<List<Job>> = _jobs_remote

    fun getAllJobsRemote() {
        viewModelScope.launch {
            if (isEmployee()) {
                when (val result = repository.getAllJobsForUser()) {
                    is Resource.Success -> {
                        result.data?.let { _jobs_remote.value = it }
                    }

                    is Resource.Error -> {
                        // Handle error state if needed
                    }
                }
            } else {
                when (val result = repository.getAllJobsForWorkspace()) {
                    is Resource.Success -> {
                        result.data?.let { _jobs_remote.value = it }
                    }

                    is Resource.Error -> {
                        // Handle error state if needed
                    }
                }
            }
        }
    }

    fun deleteSelectedJobs(
        onAllDeleted: () -> Unit,
        onError: (String) -> Unit
    ) {
        viewModelScope.launch {
            try {
                selectedJobs.forEachIndexed { index, job ->
                    val isLastJob = index == selectedJobs.size - 1

                    job._id?.let { id ->
                        val result = repository.deleteJobById(id)
                        if (result is Resource.Success && isLastJob) {
                            onAllDeleted()
                        } else if (result is Resource.Error) {
                            errorText.value = result.text ?: "An unknown error occurred"
                            onError(id)
                            return@launch
                        }
                    }
                }
                selectedJobs.clear()
            } catch (e: Exception) {
                onError("Unexpected error: ${e.message}")
            }
        }
    }

    fun deleteJob(id: String, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.deleteJobById(id)

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    onError()
                }
            }
        }
    }

    fun finishJob(id: String, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            val result = repository.finishJob(FinishJobRequest(id))

            when (result) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun updateJob(id: String, job: Job, onSuccess: () -> Unit, onError: () -> Unit) {
        viewModelScope.launch {
            when (val result = repository.updateJob(id, job)) {
                is Resource.Success -> {
                    onSuccess()
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    onError()
                }
            }
        }
    }

    fun isEmployee(): Boolean {
        return repository.getFlow() == Constants.FLOW_EMPLOYEE
    }

    fun downloadFile(fileId: String) {
        showLoader.value = true
        viewModelScope.launch {
            val existingFilePath = repository.getSavedFilePath(fileId)
            if (!existingFilePath.isNullOrBlank()) {
                log.d("downloadFile") { "File already exists: $existingFilePath" }

                when (FileUtil().getFileType(fileId)) {
                    FileType.Image -> {
                        val painter = repository.getImage(fileId)
                        _imagePainter.value = painter
                    }

                    FileType.PDF -> {
                        _pdfFilePath.value = existingFilePath
                    }

                    else -> log.e { "Unsupported file type for existing file" }
                }

                showLoader.value = false
            } else {
                when (val result = repository.getFile(fileId)) {
                    is Resource.Success -> {
                        val fileType = result.data?.fileType
                        when (fileType) {
                            FileType.Image -> {
                                val painter = repository.getImage(fileId)
                                _imagePainter.value = painter
                            }

                            FileType.PDF -> {
                                _pdfFilePath.value = repository.getSavedFilePath(fileId)
                            }

                            else -> log.e { "Unsupported file type" }
                        }
                    }

                    is Resource.Error -> {
                        errorText.value = "An error occurred while loading files"
                        showPopUpError.value = true
                        showLoader.value = false
                        log.d("Download File Error") { "Error downloading file: ${result.text}" }
                    }
                }
                showLoader.value = false
            }
        }
    }

    fun deleteFile(jobId: String, fileId: String) {
        viewModelScope.launch {
            when (val result = repository.deleteFile(jobId, fileId)) {
                is Resource.Success -> {
                    showLoader.value = false
                    _pdfFilePath.value = null
                    showFileDeleteSuccess.value = true
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showLoader.value = false
                    showPopUpError.value = true
                    log.d("Delete File Error") { "Error deleting file: ${result.text}" }
                }
            }
        }
    }

    fun deleteImage(jobId: String, imageName: String) {
        viewModelScope.launch {
            when (val result = repository.deleteFile(jobId, imageName)) {
                is Resource.Success -> {
                    showLoader.value = false
                    _imagePainter.value = null
                    showFileDeleteSuccess.value = true
                }

                is Resource.Error -> {
                    errorText.value = result.text ?: "An unknown error occurred"
                    showLoader.value = false
                    showPopUpError.value = true
                    log.d("Delete File Error") { "Error deleting image: ${result.text}" }
                }
            }
        }
    }
}