package com.hyperether.goodjob.scenes.workspace

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material.Button
import androidx.compose.material.ButtonDefaults
import androidx.compose.material.DropdownMenu
import androidx.compose.material.Icon
import androidx.compose.material.OutlinedTextField
import androidx.compose.material.TabRowDefaults.Divider
import androidx.compose.material.Text
import androidx.compose.material.TextFieldDefaults
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.TextUnit
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.hyperether.goodjob.Dp
import com.hyperether.goodjob.Sp
import com.hyperether.goodjob.isWebMobileView
import com.hyperether.goodjob.models.Workspace
import com.hyperether.goodjob.resources.Res
import com.hyperether.goodjob.resources.add_new_workspace
import com.hyperether.goodjob.resources.already_have_workspace
import com.hyperether.goodjob.resources.back_arrow
import com.hyperether.goodjob.resources.baseline_arrow_drop_down_24
import com.hyperether.goodjob.resources.choose_category
import com.hyperether.goodjob.resources.click_here
import com.hyperether.goodjob.resources.create
import com.hyperether.goodjob.resources.dropdown_arrow
import com.hyperether.goodjob.resources.enter_workspace_name
import com.hyperether.goodjob.resources.logo_good_job
import com.hyperether.goodjob.resources.new_workspace
import com.hyperether.goodjob.resources.set_up_workspace
import com.hyperether.goodjob.resources.workspace_name
import com.hyperether.goodjob.theme.BlueApp
import com.hyperether.goodjob.theme.DarkGrey
import com.hyperether.goodjob.theme.InputBorderColor
import com.hyperether.goodjob.theme.RegisterBorder
import com.hyperether.goodjob.theme.TextColor
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource

@Composable
fun AddNewWorkspaceScreen(
    isMobileView: Boolean,
    workspaceViewModel: WorkspaceViewModel,
    onChooseExistingWorkspace: () -> Unit
) {
    val workspaceName = remember { mutableStateOf("") }
    val workspaceType = remember { mutableStateOf("") }
    val expandedDropdown = remember { mutableStateOf(false) }

    val commonModifier = if (isMobileView) Modifier
        .fillMaxWidth() else Modifier.width(530.dp)
        .padding(horizontal = if (isMobileView) 20.dp else 0.dp)

    Column(
        modifier = if (!isMobileView) Modifier
            .fillMaxSize() else Modifier
            .fillMaxSize()
            .padding(16.dp),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        if (isMobileView) {
            Row(
                modifier = Modifier.fillMaxWidth()
            ) {
                HeaderImage(isMobileView, onChooseExistingWorkspace)
                Spacer(modifier = Modifier.width(15.dp))
                TitleText(
                    title = if (isMobileView) stringResource(Res.string.new_workspace) else stringResource(
                        Res.string.add_new_workspace
                    ),
                    fontSize = 24.sp,
                    lineHeight = 34.sp,
                    topPadding = 0,
                    fontWeight = FontWeight.Normal
                )
            }
        } else {
            HeaderImage(isMobileView, null)
            Spacer(modifier = Modifier.height(Dp(40f)))
            TitleText(
                title = if (isMobileView) stringResource(Res.string.new_workspace) else stringResource(
                    Res.string.add_new_workspace
                ),
                fontSize = if (isMobileView) 28.sp else Sp(40f),
                topPadding = 0,
                fontWeight = if (isMobileView) FontWeight.Normal else FontWeight.Bold,
                lineHeight = Sp(50f)
            )
        }

        if (!isMobileView) {
            Spacer(modifier = Modifier.height(Dp(12f)))
            Text(
                text = stringResource(Res.string.set_up_workspace),
                fontSize = if (!isMobileView) Sp(18f) else 18.sp,
                lineHeight = if (!isMobileView) Sp(24f) else 18.sp,
                color = DarkGrey,
                textAlign = TextAlign.Center
            )
            if (!isMobileView)
                Spacer(Modifier.height(Dp(20f)))
            else
                Spacer(Modifier.height(24.dp))
        }

        if (!isMobileView)
            Spacer(Modifier.height(Dp(20f)))
        else
            Spacer(Modifier.height(Dp(20f)))

        InputField(
            label = stringResource(Res.string.workspace_name),
            placeholder = stringResource(Res.string.enter_workspace_name),
            value = workspaceName.value,
            onValueChange = { workspaceName.value = it },
            modifier = commonModifier
        )

        if (!isMobileView)
            Spacer(Modifier.height(Dp(16f)))
        else
            Spacer(Modifier.height(16.dp))

//        WorkspaceTypeDropdown(
//            isMobileView = isMobileView,
//            selectedType = workspaceType.value,
//            onTypeSelected = { selectedType ->
//                workspaceType.value = selectedType
//                expandedDropdown.value = false
//            },
//            expanded = expandedDropdown.value,
//            onExpandToggle = { expandedDropdown.value = it },
//            modifier = commonModifier
//        )

//        WorkspaceTypeItem(workspaceType, label = "Workspace Type", placeholder = stringResource(Res.string.choose_category),  listOf("Category 1", "Category 2", "Category 3"))

        if (!isMobileView) {
            Spacer(Modifier.height(Dp(32f)))
        } else {
            Spacer(Modifier.height(40.dp))
        }

        ActionButton(
            text = stringResource(Res.string.create),
            onClick = {
                workspaceViewModel.addNewWorkspace(
                    Workspace(
                        name = workspaceName.value,
                        workspaceType = workspaceType.value
                    )
                )
                onChooseExistingWorkspace()
            },
            modifier = commonModifier.height(56.dp)
        )
        if (!isMobileView)
            Spacer(Modifier.height(Dp(32f)))

        if (!isMobileView) {
            ExistingWorkspaceOption(onChooseExistingWorkspace)
        }
    }
}

@Composable
fun HeaderImage(isMobileView: Boolean, onChooseExistingWorkspace: (() -> Unit)?) {
    val imageResource = if (isMobileView) Res.drawable.back_arrow else Res.drawable.logo_good_job
    Box(
        modifier = if (!isMobileView) Modifier
            .fillMaxWidth()
            .padding(top = Dp(32f), start = Dp(56f)) else Modifier.padding(top = 10.dp).clickable {
            onChooseExistingWorkspace?.invoke()
        },
        contentAlignment = Alignment.TopStart
    ) {
        Image(
            alignment = Alignment.TopStart,
            painter = painterResource(imageResource),
            contentDescription = null,
            modifier = if (isMobileView) Modifier else
                Modifier
                    .width(Dp(145f))
                    .height(Dp(38f))
        )
    }
}

@Composable
fun TitleText(
    title: String,
    fontSize: TextUnit,
    lineHeight: TextUnit,
    topPadding: Int,
    fontWeight: FontWeight
) {
    Text(
        text = title,
        fontSize = fontSize,
        lineHeight = lineHeight,
        modifier = Modifier.padding(top = topPadding.dp),
        fontWeight = fontWeight,
        color = Color.Black
    )
}

@Composable
fun InputField(
    label: String,
    placeholder: String,
    value: String,
    onValueChange: (String) -> Unit,
    modifier: Modifier = Modifier
) {
    OutlinedTextField(
        value = value,
        onValueChange = onValueChange,
        label = { Text(label) },
        placeholder = { Text(placeholder) },
        modifier = modifier,
        shape = if (!isWebMobileView()) RoundedCornerShape(Dp(12f)) else RoundedCornerShape(0f),
        colors = if (!isWebMobileView()) TextFieldDefaults.outlinedTextFieldColors(
            focusedLabelColor = InputBorderColor,
            focusedBorderColor = InputBorderColor,
            unfocusedBorderColor = RegisterBorder,
            disabledTextColor = TextColor,
            placeholderColor = InputBorderColor,
            disabledPlaceholderColor = InputBorderColor
        ) else TextFieldDefaults.outlinedTextFieldColors()
    )
}

@Composable
fun ActionButton(text: String, onClick: () -> Unit, modifier: Modifier = Modifier) {
    Button(
        onClick = onClick,
        modifier = modifier,
        shape = RoundedCornerShape(28.dp),
        colors = ButtonDefaults.buttonColors(backgroundColor = Color(0xFF336699))
    ) {
        Text(
            text = text,
            color = Color.White,
            fontSize = 16.sp,
            fontWeight = FontWeight.Bold
        )
    }
}

@Composable
fun WorkspaceTypeDropdown(
    isMobileView: Boolean,
    selectedType: String,
    onTypeSelected: (String) -> Unit,
    expanded: Boolean,
    onExpandToggle: (Boolean) -> Unit,
    modifier: Modifier = Modifier
) {
    Column {
        Box(
            modifier = if (!isMobileView) {
                Modifier
                    .width(Dp(530f))
                    .border(1.dp, RegisterBorder, RoundedCornerShape(Dp(12f)))
                    .padding(Dp(16f))
            } else {
                Modifier
                    .border(1.dp, DarkGrey, RoundedCornerShape(3.dp))
                    .padding(16.dp)
            }
                .clickable { onExpandToggle(true) }
                .background(Color.White),
            contentAlignment = Alignment.CenterStart
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.SpaceBetween
            ) {
                Text(
                    text = if (selectedType.isNotEmpty()) selectedType else stringResource(Res.string.choose_category),
                    color = if (selectedType.isEmpty()) Color.Gray else Color.Black,
                    textAlign = TextAlign.Start
                )
                Icon(
                    painter = if (!isMobileView) painterResource(Res.drawable.dropdown_arrow) else painterResource(
                        Res.drawable.baseline_arrow_drop_down_24
                    ),
                    contentDescription = "Dropdown arrow",
                    tint = DarkGrey,
                    modifier = if (!isMobileView) Modifier.size(Dp(12f)) else Modifier.size(20.dp)
                )
            }
        }
        DropdownMenu(
            expanded = expanded,
            onDismissRequest = { onExpandToggle(false) },
            modifier = modifier
        ) {
            // TODO: get categories
            listOf("Category 1", "Category 2", "Category 3").forEach { category ->
                DropdownMenuItem(
                    text = { Text(text = category) },
                    onClick = { onTypeSelected(category) }
                )
            }
        }
    }
}

@Composable
fun WorkspaceTypeItem(
    text: MutableState<String>,
    label: String,
    placeholder: String,
    dropdownItems: List<String>
) {
    var expanded = remember { mutableStateOf(false) }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .padding(top = 10.dp)
    ) {
        androidx.compose.material3.OutlinedTextField(
            value = text.value,
            onValueChange = {
                text.value = ""
                text.value = it
            },
            label = { Text(label, fontSize = 14.sp, color = TextColor) },
            placeholder = { Text(placeholder, fontSize = 14.sp, color = TextColor) },
            modifier = Modifier
                .fillMaxWidth(),

            trailingIcon = {
                Icon(
                    painter = painterResource(Res.drawable.baseline_arrow_drop_down_24),
                    contentDescription = "Dropdown",
                    modifier = Modifier.clickable { expanded.value = !expanded.value }
                )
            }
        )

        DropdownMenu(
            expanded = expanded.value,
            onDismissRequest = { expanded.value = false }
        ) {
            dropdownItems.forEach { item ->
                DropdownMenuItem(onClick = {
                    text.value = item
                    expanded.value = false
                }, text = {
                    Text(item)
                }
                )
            }
        }
    }
}


@Composable
fun ExistingWorkspaceOption(onChooseExistingWorkspace: () -> Unit) {
    Divider(
        color = Color.LightGray,
        thickness = Dp(1f),
        modifier = Modifier.width(Dp(530f))
    )
    Spacer(Modifier.height(Dp(32f)))
    Text(
        text = stringResource(Res.string.already_have_workspace),
        color = Color.Black,
        fontWeight = FontWeight.W700,
        fontSize = Sp(18f),
        lineHeight = Sp(22f)
    )
    Spacer(Modifier.height(Dp(12f)))
    Text(
        text = stringResource(Res.string.click_here),
        color = BlueApp,
        fontSize = Sp(16f),
        fontWeight = FontWeight.W400,
        modifier = Modifier.clickable { onChooseExistingWorkspace() },
        textAlign = TextAlign.Center,
    )
}
