package com.hyperether.goodjob.scenes.components.drawer

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material.BottomNavigation
import androidx.compose.material.MaterialTheme
import androidx.compose.material.Surface
import androidx.compose.material.Text
import androidx.compose.material3.DrawerValue
import androidx.compose.material3.ModalDrawerSheet
import androidx.compose.material3.ModalNavigationDrawer
import androidx.compose.material3.NavigationDrawerItem
import androidx.compose.material3.NavigationDrawerItemDefaults
import androidx.compose.material3.rememberDrawerState
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableIntStateOf
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.saveable.rememberSaveable
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.navigation.NavHostController
import com.hyperether.goodjob.navigation.AndroidNav
import com.hyperether.goodjob.navigation.NavGraph
import com.hyperether.goodjob.repository.Repository
import com.hyperether.goodjob.repository.prefs.PrefsManager
import com.hyperether.goodjob.scenes.addNew.AddNewViewModel
import com.hyperether.goodjob.scenes.employees.EmployeeDetailsViewModel
import com.hyperether.goodjob.scenes.employees.EmployeesViewModel
import com.hyperether.goodjob.scenes.pricing.PricingViewModel
import com.hyperether.goodjob.scenes.register.RegisterViewModel
import com.hyperether.goodjob.scenes.workspace.WorkspaceViewModel
import com.hyperether.goodjob.theme.TextColor
import kotlinx.coroutines.launch
import org.jetbrains.compose.resources.painterResource

@Composable
fun DrawerComponent(
    modifier: Modifier = Modifier,
    navController: NavHostController,
    repository: Repository,
    workspaceViewModel: WorkspaceViewModel,
    pricingViewModel: PricingViewModel,
    registerViewModel: RegisterViewModel,
    employeesViewModel: EmployeesViewModel,
    addNewViewModel: AddNewViewModel,
    prefsManager: PrefsManager,
    employeeDetailsViewModel: EmployeeDetailsViewModel,
    onChoosePlanClick: (Double, String) -> Unit,
    startRoute: String,
    isBottomNavigationBarVisible: Boolean,
    onLogout: () -> Unit,
    onUploadDocumentClick: () -> Unit,
    onOpenPdf: (String) -> Unit
) {

    val drawerItems = getDrawerItems(repository, prefsManager.getFlow())

    Surface(
        modifier = Modifier.fillMaxSize(),
        color = MaterialTheme.colors.background
    ) {
        val drawerState = rememberDrawerState(initialValue = DrawerValue.Closed)
        val scope = rememberCoroutineScope()
        var selectedItemIndex by rememberSaveable {
            mutableIntStateOf(-1)
        }
        ModalNavigationDrawer(
            drawerContent = {
                ModalDrawerSheet(modifier = Modifier.width(300.dp)) {
                    Column(modifier = modifier.fillMaxHeight().padding(8.dp)) {
                        NavigationDrawerItem(
                            modifier = Modifier.fillMaxWidth(),
                            label = {
                                Text(
                                    drawerItems[0].title,
                                    fontWeight = FontWeight.ExtraBold,
                                    fontSize = 18.sp,
                                    color = Color.Black
                                )
                            },
                            selected = 0 == selectedItemIndex,
                            onClick = {
                                selectedItemIndex = 0
                            },
                            icon = {
                                Image(
                                    painter = painterResource(drawerItems[0].painter),
                                    modifier = Modifier.size(30.dp),
                                    contentDescription = drawerItems[0].title
                                )
                            },
                            colors = NavigationDrawerItemDefaults.colors(
                                selectedContainerColor = Color.Transparent,
                                selectedBadgeColor = Color.Transparent,
                            )
                        )
                        Spacer(modifier = Modifier.height(30.dp))
                        androidx.compose.material.Divider(
                            color = Color.LightGray,
                            thickness = 1.dp
                        )
                        for (index in 1 until drawerItems.size - 1) {
                            NavigationDrawerItem(
                                label = {
                                    Text(text = drawerItems[index].title, color = TextColor)
                                },
                                selected = index == selectedItemIndex,
                                onClick = {
                                    selectedItemIndex = index
                                    scope.launch {
                                        drawerState.close()
                                    }
                                    when (index) {
                                        1 -> {
                                        }

                                        2 -> {
                                        }

                                        3 -> {
                                        }

                                        4 -> {

                                        }
                                    }
                                },
                                icon = {
                                    Image(
                                        painter = painterResource(drawerItems[index].painter),
                                        modifier = Modifier.size(30.dp),
                                        contentDescription = drawerItems[index].title,
                                    )
                                },
                                modifier = Modifier.fillMaxWidth().height(70.dp)
                            )
                            androidx.compose.material.Divider(
                                color = Color.LightGray,
                                thickness = 1.dp
                            )
                        }
                        Spacer(modifier = Modifier.weight(1f))

                        NavigationDrawerItem(
                            label = {
                                Text(
                                    text = drawerItems[drawerItems.size - 1].title,
                                    color = Color.Red
                                )
                            },
                            selected = ((drawerItems.size - 1) == selectedItemIndex),
                            onClick = {
                                selectedItemIndex = drawerItems.size - 1
                                scope.launch {
                                    drawerState.close()
                                }
                                onLogout()
                            },
                            icon = {
                                Image(
                                    painter = painterResource(drawerItems[drawerItems.size - 1].painter),
                                    modifier = Modifier.size(30.dp),
                                    contentDescription = drawerItems[drawerItems.size - 1].title,
                                    //tint = if (index == 6) Color.Red else TextColor
                                )
                            },
                            modifier = Modifier.fillMaxWidth()
                        )
                    }
                }
            },
            drawerState = drawerState,
            gesturesEnabled = drawerState.isOpen
        ) {
            Column {
                NavGraph(
                    navController,
                    repository,
                    modifier = Modifier.weight(1f),
                    workspaceViewModel = workspaceViewModel,
                    pricingViewModel = pricingViewModel,
                    registerViewModel = registerViewModel,
                    employeesViewModel = employeesViewModel,
                    addNewViewModel = addNewViewModel,
                    prefsManager = prefsManager,
                    currentRoute = startRoute,
                    onChoosePlanClick = onChoosePlanClick,
                    employeeDetailsViewModel = employeeDetailsViewModel,
                    drawerState = drawerState,
                    onUploadDocumentClick = onUploadDocumentClick,
                    onOpenPdf = onOpenPdf
                )
                if (isBottomNavigationBarVisible) {
                    BottomNavigation(backgroundColor = Color.White) {
                        AndroidNav(navController, prefsManager)
                    }
                }
            }

        }
//        if (showProgressBar.value) {
//            FullScreenProgressBar(showProgressBar = viewModel.showProgressBar)
//        }
    }
}