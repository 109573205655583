package com.hyperether.goodjob.repository.remote.model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateWorkspaceRequest(
    @SerialName("name") var name: String? = null,
    @SerialName("type") var type: String? = null,
    @SerialName("userId") var userId: String? = null,
    @SerialName("planId") var planId: String? = null,
    @SerialName("workspaceType") var workspaceType: String? = null,
    @SerialName("lastAccessed") var lastAccessed: String? = null
)